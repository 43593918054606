export const MATURE_CONTENT_AUTO_APPLIED = 'mature_content_auto_applied';

export const BAE_FEATURE_KEY = 'bae';
export const DESIGN_CARD_FEATURE_FLAG = 'colorways-on-cards';
export const SIMILAR_DESIGNS_FEATURE_FLAG = 'similar-designs-on-cards';
export const VINYL_WALLPAPER_FEATURE_FLAG = 'vinyl-wallpaper-on-filters';
export const CHALLENGE_WINNER_BADGE_FLAG = 'challenge-winner-badge';
export const CHALLENGE_WINNER_BADGE_TEST_VARIANT = 'c';
export const WINNERS_ONLY_FEATURE_FLAG = 'winners-only-on-filters';

