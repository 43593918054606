module.exports = {
	"title": "Mobile Navigation",
	"fabric": {
		"text": "Fabric"
	},
	"allFabricDesigns": {
		"text": "All Fabric",
		"href": "/en/shop?on=fabric"
	},
	"byCategoriesMenu": {
		"text": "By Categories"
	},
	"byColors": {
		"text": "By Color"
	},
	"fabricTypes": {
		"text": "Fabric Types",
		"href": "/en/fabric-shop"
	},
	"samplePack": {
		"text": "Sample Pack",
		"href": "/en/sample-pack"
	},
	"welcomePack": {
		"text": "Welcome Pack",
		"href": "/en/maker-welcome-pack"
	},
	"solidsSamplePack": {
		"text": "Solids Swatch Book",
		"href": "/en/petal-solids-swatch-book"
	},
	"fillAYard": {
		"text": "Fill-A-Yard ®",
		"href": "/fill-a-yard"
	},
	"curatedThemes": {
		"text": "Curated Themes",
		"href": "/themes"
	},
	"allColors": {
		"text": "All Colors"
	},
	"whites": {
		"text": "Whites"
	},
	"white": {
		"text": "White",
		"href": "/en/shop/white"
	},
	"beige": {
		"text": "Beige",
		"href": "/en/shop/beige"
	},
	"cornsilk": {
		"text": "Cornsilk",
		"href": "/en/shop/cornsilk"
	},
	"ecru": {
		"text": "Ecru",
		"href": "/en/shop/ecru"
	},
	"eggshell": {
		"text": "Eggshell",
		"href": "/en/shop/eggshell"
	},
	"flax": {
		"text": "Flax",
		"href": "/en/shop/flax"
	},
	"ivory": {
		"text": "Ivory",
		"href": "/en/shop/ivory"
	},
	"vanilla": {
		"text": "Vanilla",
		"href": "/en/shop/vanilla"
	},
	"whiteSmoke": {
		"text": "White Smoke",
		"href": "/en/shop/white-smoke"
	},
	"greys": {
		"text": "Greys"
	},
	"gray": {
		"text": "Gray",
		"href": "/en/shop/gray"
	},
	"grey": {
		"text": "Grey",
		"href": "/en/shop/grey"
	},
	"greyLightest": {
		"text": "Grey Lightest"
	},
	"greyLight": {
		"text": "Grey Light"
	},
	"greyDark": {
		"text": "Grey Dark"
	},
	"greyDarkest": {
		"text": "Grey Darkest"
	},
	"ashGray": {
		"text": "Ash Gray",
		"href": "/en/shop/ash-gray"
	},
	"battleshipGray": {
		"text": "Battleship Gray",
		"href": "/en/shop/battleship-gray"
	},
	"blueGray": {
		"text": "Blue-Gray",
		"href": "/en/shop/blue-gray"
	},
	"charcoal": {
		"text": "Charcoal",
		"href": "/en/shop/charcoal"
	},
	"gunmetal": {
		"text": "Gunmetal",
		"href": "/en/shop/gunmetal"
	},
	"platinum": {
		"text": "Platinum",
		"href": "/en/shop/platinum"
	},
	"silver": {
		"text": "Silver",
		"href": "/en/shop/silver"
	},
	"slate": {
		"text": "Slate",
		"href": "/en/shop/slate"
	},
	"taupe": {
		"text": "Taupe",
		"href": "/en/shop/taupe"
	},
	"pinks": {
		"text": "Pinks"
	},
	"pink": {
		"text": "Pink",
		"href": "/en/shop/pink"
	},
	"carnation": {
		"text": "Carnation",
		"href": "/en/shop/carnation"
	},
	"cerise": {
		"text": "Cerise",
		"href": "/en/shop/cerise"
	},
	"coral": {
		"text": "Coral",
		"href": "/en/shop/coral"
	},
	"cyclamen": {
		"text": "Cyclamen",
		"href": "/en/shop/cyclamen"
	},
	"fandango": {
		"text": "Fandango",
		"href": "/en/shop/fandango"
	},
	"fuschsia": {
		"text": "Fuschsia",
		"href": "/en/shop/fuchsia"
	},
	"hotPink": {
		"text": "Hot Pink",
		"href": "/en/shop/hot-pink"
	},
	"mauve": {
		"text": "Mauve",
		"href": "/en/shop/mauve"
	},
	"puce": {
		"text": "Puce",
		"href": "/en/shop/puce"
	},
	"raspberry": {
		"text": "Raspberry",
		"href": "/en/shop/raspberry"
	},
	"rosePink": {
		"text": "Rose Pink",
		"href": "/en/shop/rose-pink"
	},
	"roseQuartz": {
		"text": "Rose Quartz",
		"href": "/en/shop/rose-quartz"
	},
	"ruby": {
		"text": "Ruby",
		"href": "/en/shop/ruby"
	},
	"salmonPink": {
		"text": "Salmon Pink",
		"href": "/en/shop/salmon-pink"
	},
	"shockingPink": {
		"text": "Shocking Pink",
		"href": "/en/shop/shocking-pink"
	},
	"reds": {
		"text": "Reds"
	},
	"red": {
		"text": "Red",
		"href": "/en/shop/red"
	},
	"auburn": {
		"text": "Auburn",
		"href": "/en/shop/auburn"
	},
	"burgundy": {
		"text": "Burgundy",
		"href": "/en/shop/burgundy"
	},
	"cardinal": {
		"text": "Cardinal",
		"href": "/en/shop/cardinal"
	},
	"claret": {
		"text": "Claret",
		"href": "/en/shop/claret"
	},
	"crimson": {
		"text": "Crimson",
		"href": "/en/shop/crimson"
	},
	"mahogany": {
		"text": "Mahogany",
		"href": "/en/shop/mahogany"
	},
	"maroon": {
		"text": "Maroon",
		"href": "/en/shop/maroon"
	},
	"marsala": {
		"text": "Marsala",
		"href": "/en/shop/marsala"
	},
	"oxblood": {
		"text": "Oxblood",
		"href": "/en/shop/oxblood"
	},
	"redwood": {
		"text": "Redwood",
		"href": "/en/shop/redwood"
	},
	"rosewood": {
		"text": "Rosewood",
		"href": "/en/shop/rosewood"
	},
	"scarlet": {
		"text": "Scarlet",
		"href": "/en/shop/scarlet"
	},
	"terraCotta": {
		"text": "Terra Cotta",
		"href": "/en/shop/terra-cotta"
	},
	"vermilion": {
		"text": "Vermilion",
		"href": "/en/shop/vermilion"
	},
	"redLightest": {
		"text": "Red Lightest"
	},
	"redLight": {
		"text": "Red Light"
	},
	"redDark": {
		"text": "Red Dark"
	},
	"redDarkest": {
		"text": "Red Darkest"
	},
	"redMagentaLightest": {
		"text": "Red Magenta Lightest"
	},
	"redMagentaLight": {
		"text": "Red Magenta Light"
	},
	"redMagenta": {
		"text": "Red Magenta"
	},
	"redMagentaDark": {
		"text": "Red Magenta Dark"
	},
	"redMagentaDarkest": {
		"text": "Red Magenta Darkest"
	},
	"redGreyLightest": {
		"text": "Red Grey Lightest"
	},
	"redGreyLight": {
		"text": "Red Grey Light"
	},
	"redGrey": {
		"text": "Red Grey"
	},
	"redGreyDark": {
		"text": "Red Grey Dark"
	},
	"redGreyDarkest": {
		"text": "Red Grey Darkest"
	},
	"browns": {
		"text": "Browns"
	},
	"brown": {
		"text": "Brown",
		"href": "/en/shop/brown"
	},
	"brownLightest": {
		"text": "Brown Lightest"
	},
	"brownLight": {
		"text": "Brown Light"
	},
	"brownDark": {
		"text": "Brown Dark"
	},
	"brownDarkest": {
		"text": "Brown Darkest"
	},
	"bronze": {
		"text": "Bronze",
		"href": "/en/shop/bronze"
	},
	"buff": {
		"text": "Buff",
		"href": "/en/shop/buff"
	},
	"burntSienna": {
		"text": "Burnt sienna",
		"href": "/en/shop/burnt-sienna"
	},
	"burntUmber": {
		"text": "Burnt umber",
		"href": "/en/shop/burnt-umber"
	},
	"copper": {
		"text": "Copper",
		"href": "/en/shop/copper"
	},
	"desertSand": {
		"text": "Desert sand",
		"href": "/en/shop/desert-sand"
	},
	"khaki": {
		"text": "Khaki",
		"href": "/en/shop/khaki"
	},
	"ochre": {
		"text": "Ochre",
		"href": "/en/shop/ochre"
	},
	"russet": {
		"text": "Russet",
		"href": "/en/shop/russet"
	},
	"rust": {
		"text": "Rust",
		"href": "/en/shop/rust"
	},
	"sand": {
		"text": "Sand",
		"href": "/en/shop/sand"
	},
	"sepia": {
		"text": "Sepia",
		"href": "/en/shop/sepia"
	},
	"sienna": {
		"text": "Sienna",
		"href": "/en/shop/sienna"
	},
	"tan": {
		"text": "Tan",
		"href": "/en/shop/tan"
	},
	"wheat": {
		"text": "Wheat",
		"href": "/en/shop/wheat"
	},
	"magentas": {
		"text": "Magentas"
	},
	"magentaLightest": {
		"text": "Magenta Lightest"
	},
	"magentaLight": {
		"text": "Magenta Light"
	},
	"magentaDark": {
		"text": "Magenta Dark"
	},
	"magentaDarkest": {
		"text": "Magenta Darkest"
	},
	"magentaVioletRedLightest": {
		"text": "Magenta Violet Red Lightest"
	},
	"magentaVioletRedLight": {
		"text": "Magenta Violet Red Light"
	},
	"magentaVioletRed": {
		"text": "Magenta Violet Red"
	},
	"magentaVioletRedDark": {
		"text": "Magenta Violet Red Dark"
	},
	"magentaVioletRedDarkest": {
		"text": "Magenta Violet Red Darkest"
	},
	"magentaGreyLightest": {
		"text": "Magenta Grey Lightest"
	},
	"magentaGreyLight": {
		"text": "Magenta Grey Light"
	},
	"magentaGrey": {
		"text": "Magenta Grey"
	},
	"magentaGreyDark": {
		"text": "Magenta Grey Dark"
	},
	"magentaGreyDarkest": {
		"text": "Magenta Grey Darkest"
	},
	"oranges": {
		"text": "Oranges"
	},
	"orange": {
		"text": "Orange",
		"href": "/en/shop/orange"
	},
	"amber": {
		"text": "Amber",
		"href": "/en/shop/amber"
	},
	"apricot": {
		"text": "Apricot",
		"href": "/en/shop/apricot"
	},
	"marigold": {
		"text": "Marigold",
		"href": "/en/shop/marigold"
	},
	"orangeRed": {
		"text": "Orange Red",
		"href": "/en/shop/orange-red"
	},
	"peach": {
		"text": "Peach",
		"href": "/en/shop/peach"
	},
	"salmon": {
		"text": "Salmon",
		"href": "/en/shop/salmon"
	},
	"tangerine": {
		"text": "Tangerine",
		"href": "/en/shop/tangerine"
	},
	"orangeLightest": {
		"text": "Orange Lightest"
	},
	"orangeLight": {
		"text": "Orange Light"
	},
	"orangeDark": {
		"text": "Orange Dark"
	},
	"orangeDarkest": {
		"text": "Orange Darkest"
	},
	"orangeRedLightest": {
		"text": "Orange Red Lightest"
	},
	"orangeRedLight": {
		"text": "Orange Red Light"
	},
	"orangeRedDark": {
		"text": "Orange Red Dark"
	},
	"orangeRedDarkest": {
		"text": "Orange Red Darkest"
	},
	"orangeGreyLightest": {
		"text": "Orange Grey Lightest"
	},
	"orangeGreyLight": {
		"text": "Orange Grey Light"
	},
	"orangeGrey": {
		"text": "Orange Grey"
	},
	"orangeGreyDark": {
		"text": "Orange Grey Dark"
	},
	"orangeGreyDarkest": {
		"text": "Orange Grey Darkest"
	},
	"yellowOranges": {
		"text": "Yellow Oranges"
	},
	"yellowOrangeLightest": {
		"text": "Yellow Orange Lightest"
	},
	"yellowOrangeLight": {
		"text": "Yellow Orange Light"
	},
	"yellowOrange": {
		"text": "Yellow Orange"
	},
	"yellowOrangeDark": {
		"text": "Yellow Orange Dark"
	},
	"yellowOrangeDarkest": {
		"text": "Yellow Orange Darkest"
	},
	"yellowOrangeOrangeLightest": {
		"text": "Yellow Orange Orange Lightest"
	},
	"yellowOrangeOrangeLight": {
		"text": "Yellow Orange Orange Light"
	},
	"yellowOrangeOrange": {
		"text": "Yellow Orange Orange"
	},
	"yellowOrangeOrangeDark": {
		"text": "Yellow Orange Orange Dark"
	},
	"yellowOrangeOrangeDarkest": {
		"text": "Yellow Orange Orange Darkest"
	},
	"yellowOrangeGreyLightest": {
		"text": "Yellow Orange Grey Lightest"
	},
	"yellowOrangeGreyLight": {
		"text": "Yellow Orange Grey Light"
	},
	"yellowOrangeGrey": {
		"text": "Yellow Orange Grey"
	},
	"yellowOrangeGreyDark": {
		"text": "Yellow Orange Grey Dark"
	},
	"yellowOrangeGreyDarkest": {
		"text": "Yellow Orange Grey Darkest"
	},
	"yellows": {
		"text": "Yellows"
	},
	"yellow": {
		"text": "Yellow",
		"href": "/en/shop/yellow"
	},
	"citrine": {
		"text": "Citrine",
		"href": "/en/shop/citrine"
	},
	"gold": {
		"text": "Gold",
		"href": "/en/shop/gold"
	},
	"goldenrod": {
		"text": "Goldenrod",
		"href": "/en/shop/goldenrod"
	},
	"honey": {
		"text": "Honey",
		"href": "/en/shop/honey"
	},
	"jonquil": {
		"text": "Jonquil",
		"href": "/en/shop/jonquil"
	},
	"mustard": {
		"text": "Mustard",
		"href": "/en/shop/mustard"
	},
	"saffron": {
		"text": "Saffron",
		"href": "/en/shop/saffron"
	},
	"yellowLightest": {
		"text": "Yellow Lightest"
	},
	"yellowLight": {
		"text": "Yellow Light"
	},
	"yellowDark": {
		"text": "Yellow Dark"
	},
	"yellowDarkest": {
		"text": "Yellow Darkest"
	},
	"yellowYellowOrangeLightest": {
		"text": "Yellow Yellow Orange Lightest"
	},
	"yellowYellowOrangeLight": {
		"text": "Yellow Yellow Orange Light"
	},
	"yellowYellowOrange": {
		"text": "Yellow Yellow Orange"
	},
	"yellowYellowOrangeDark": {
		"text": "Yellow Yellow Orange Dark"
	},
	"yellowYellowOrangeDarkest": {
		"text": "Yellow Yellow Orange Darkest"
	},
	"yellowGreyLightest": {
		"text": "Yellow Grey Lightest"
	},
	"yellowGreyLight": {
		"text": "Yellow Grey Light"
	},
	"yellowGrey": {
		"text": "Yellow Grey"
	},
	"yellowGreyDark": {
		"text": "Yellow Grey Dark"
	},
	"yellowGreyDarkest": {
		"text": "Yellow Grey Darkest"
	},
	"yellowGreens": {
		"text": "Yellow Greens"
	},
	"yellowGreenLightest": {
		"text": "Yellow Green Lightest"
	},
	"yellowGreenLight": {
		"text": "Yellow Green Light"
	},
	"yellowGreen": {
		"text": "Yellow Green"
	},
	"yellowGreenDark": {
		"text": "Yellow Green Dark"
	},
	"yellowGreenDarkest": {
		"text": "Yellow Green Darkest"
	},
	"yellowGreenYellowLightest": {
		"text": "Yellow Green Yellow Lightest"
	},
	"yellowGreenYellowLight": {
		"text": "Yellow Green Yellow Light"
	},
	"yellowGreenYellow": {
		"text": "Yellow Green Yellow"
	},
	"yellowGreenYellowDark": {
		"text": "Yellow Green Yellow Dark"
	},
	"yellowGreenYellowDarkest": {
		"text": "Yellow Green Yellow Darkest"
	},
	"yellowGreenGreyLightest": {
		"text": "Yellow Green Grey Lightest"
	},
	"yellowGreenGreyLight": {
		"text": "Yellow Green Grey Light"
	},
	"yellowGreenGrey": {
		"text": "Yellow Green Grey"
	},
	"yellowGreenGreyDark": {
		"text": "Yellow Green Grey Dark"
	},
	"yellowGreenGreyDarkest": {
		"text": "Yellow Green Grey Darkest"
	},
	"greens": {
		"text": "Greens"
	},
	"green": {
		"text": "Green",
		"href": "/en/shop/green"
	},
	"artichoke": {
		"text": "Artichoke",
		"href": "/en/shop/artichoke"
	},
	"cladon": {
		"text": "Celadon",
		"href": "/en/shop/celadon"
	},
	"chartreuse": {
		"text": "Chartreuse",
		"href": "/en/shop/chartreuse"
	},
	"emerald": {
		"text": "Emerald",
		"href": "/en/shop/emerald"
	},
	"jade": {
		"text": "Jade",
		"href": "/en/shop/jade"
	},
	"kelly": {
		"text": "Kelly",
		"href": "/en/shop/kelly"
	},
	"mint": {
		"text": "Mint",
		"href": "/en/shop/mint"
	},
	"greenLightest": {
		"text": "Green Lightest"
	},
	"greenLight": {
		"text": "Green Light"
	},
	"greenDark": {
		"text": "Green Dark"
	},
	"greenDarkest": {
		"text": "Green Darkest"
	},
	"greenYellowGreenLightest": {
		"text": "Green Yellow Green Lightest"
	},
	"greenYellowGreenLight": {
		"text": "Green Yellow Green Light"
	},
	"greenYellowGreen": {
		"text": "Green Yellow Green"
	},
	"greenYellowGreenDark": {
		"text": "Green Yellow Green Dark"
	},
	"greenYellowGreenDarkest": {
		"text": "Green Yellow Green Darkest"
	},
	"greenGreyLightest": {
		"text": "Green Grey Lightest"
	},
	"greenGreyLight": {
		"text": "Green Grey Light"
	},
	"greenGrey": {
		"text": "Green Grey"
	},
	"greenGreyDark": {
		"text": "Green Grey Dark"
	},
	"greenGreyDarkest": {
		"text": "Green Grey Darkest"
	},
	"blueGreens": {
		"text": "Blue Greens"
	},
	"blueGreenLightest": {
		"text": "Blue Green Lightest"
	},
	"blueGreenLight": {
		"text": "Blue Green Light"
	},
	"blueGreen": {
		"text": "Blue Green"
	},
	"blueGreenDark": {
		"text": "Blue Green Dark"
	},
	"blueGreenDarkest": {
		"text": "Blue Green Darkest"
	},
	"blueGreenGreenLightest": {
		"text": "Blue Green Green Lightest"
	},
	"blueGreenGreenLight": {
		"text": "Blue Green Green Light"
	},
	"blueGreenGreen": {
		"text": "Blue Green Green"
	},
	"blueGreenGreenDark": {
		"text": "Blue Green Green Dark"
	},
	"blueGreenGreenDarkest": {
		"text": "Blue Green Green Darkest"
	},
	"blueGreenGreyLightest": {
		"text": "Blue Green Grey Lightest"
	},
	"blueGreenGreyLight": {
		"text": "Blue Green Grey Light"
	},
	"blueGreenGrey": {
		"text": "Blue Green Grey"
	},
	"blueGreenGreyDark": {
		"text": "Blue Green Grey Dark"
	},
	"blueGreenGreyDarkest": {
		"text": "Blue Green Grey Darkest"
	},
	"cyans": {
		"text": "Cyans"
	},
	"cyan": {
		"text": "Cyan",
		"href": "/en/shop/cyan"
	},
	"aqua": {
		"text": "Aqua",
		"href": "/en/shop/aqua"
	},
	"aquamarine": {
		"text": "Aquamarine",
		"href": "/en/shop/aquamarine"
	},
	"cerulean": {
		"text": "Cerulean",
		"href": "/en/shop/cerulean"
	},
	"teal": {
		"text": "Teal",
		"href": "/en/shop/teal"
	},
	"turquoise": {
		"text": "Turquoise",
		"href": "/en/shop/turquoise"
	},
	"verdigris": {
		"text": "Verdigris",
		"href": "/en/shop/verdigris"
	},
	"cyanLightest": {
		"text": "Cyan Lightest"
	},
	"cyanLight": {
		"text": "Cyan Light"
	},
	"cyanDark": {
		"text": "Cyan Dark"
	},
	"cyanDarkest": {
		"text": "Cyan Darkest"
	},
	"cyanBlueGreenLightest": {
		"text": "Cyan Blue Green Lightest"
	},
	"cyanBlueGreenLight": {
		"text": "Cyan Blue Green Light"
	},
	"cyanBlueGreen": {
		"text": "Cyan Blue Green"
	},
	"cyanBlueGreenDark": {
		"text": "Cyan Blue Green Dark"
	},
	"cyanBlueGreenDarkest": {
		"text": "Cyan Blue Green Darkest"
	},
	"cyanGreyLightest": {
		"text": "Cyan Grey Lightest"
	},
	"cyanGreyLight": {
		"text": "Cyan Grey Light"
	},
	"cyanGrey": {
		"text": "Cyan Grey"
	},
	"cyanGreyDark": {
		"text": "Cyan Grey Dark"
	},
	"cyanGreyDarkest": {
		"text": "Cyan Grey Darkest"
	},
	"blues": {
		"text": "Blues"
	},
	"blue": {
		"text": "Blue",
		"href": "/en/shop/blue"
	},
	"azure": {
		"text": "Azure",
		"href": "/en/shop/azure"
	},
	"babyBlue": {
		"text": "Baby Blue",
		"href": "/en/shop/baby-blue"
	},
	"cobalt": {
		"text": "Cobalt",
		"href": "/en/shop/cobalt"
	},
	"cornflower": {
		"text": "Cornflower",
		"href": "/en/shop/cornflower"
	},
	"denim": {
		"text": "Denim",
		"href": "/en/shop/denim"
	},
	"indigo": {
		"text": "Indigo",
		"href": "/en/shop/indigo"
	},
	"midnight": {
		"text": "Midnight",
		"href": "/en/shop/midnight"
	},
	"navy": {
		"text": "Navy",
		"href": "/en/shop/navy"
	},
	"periwinkle": {
		"text": "Periwinkle",
		"href": "/en/shop/periwinkle"
	},
	"sapphire": {
		"text": "Sapphire",
		"href": "/en/shop/sapphire"
	},
	"serenity": {
		"text": "Serenity",
		"href": "/en/shop/serenity"
	},
	"ultramarine": {
		"text": "Ultramarine",
		"href": "/en/shop/ultramarine"
	},
	"blueLightest": {
		"text": "Blue Lightest"
	},
	"blueLight": {
		"text": "Blue Light"
	},
	"blueDark": {
		"text": "Blue Dark"
	},
	"blueDarkest": {
		"text": "Blue Darkest"
	},
	"blueCyanLightest": {
		"text": "Blue Cyan Lightest"
	},
	"blueCyanLight": {
		"text": "Blue Cyan Light"
	},
	"blueCyan": {
		"text": "Blue Cyan"
	},
	"blueCyanDark": {
		"text": "Blue Cyan Dark"
	},
	"blueCyanDarkest": {
		"text": "Blue Cyan Darkest"
	},
	"blueGreyLightest": {
		"text": "Blue Grey Lightest"
	},
	"blueGreyLight": {
		"text": "Blue Grey Light"
	},
	"blueGrey": {
		"text": "Blue Grey"
	},
	"blueGreyDark": {
		"text": "Blue Grey Dark"
	},
	"blueGreyDarkest": {
		"text": "Blue Grey Darkest"
	},
	"violetReds": {
		"text": "Violet Reds"
	},
	"violetRedLightest": {
		"text": "Violet Red Lightest"
	},
	"violetRedLight": {
		"text": "Violet Red Light"
	},
	"violetRed": {
		"text": "Violet Red"
	},
	"violetRedDark": {
		"text": "Violet Red Dark"
	},
	"violetRedDarkest": {
		"text": "Violet Red Darkest"
	},
	"violetRedVioletLightest": {
		"text": "Violet Red Violet Lightest"
	},
	"violetRedVioletLight": {
		"text": "Violet Red Violet Light"
	},
	"violetRedViolet": {
		"text": "Violet Red Violet"
	},
	"violetRedVioletDark": {
		"text": "Violet Red Violet Dark"
	},
	"violetRedVioletDarkest": {
		"text": "Violet Red Violet Darkest"
	},
	"violetRedGreyLightest": {
		"text": "Violet Red Grey Lightest"
	},
	"violetRedGreyLight": {
		"text": "Violet Red Grey Light"
	},
	"violetRedGrey": {
		"text": "Violet Red Grey"
	},
	"violetRedGreyDark": {
		"text": "Violet Red Grey Dark"
	},
	"violetRedGreyDarkest": {
		"text": "Violet Red Grey Darkest"
	},
	"violets": {
		"text": "Violets"
	},
	"violet": {
		"text": "Violet",
		"href": "/en/shop/violet"
	},
	"amethyst": {
		"text": "Amethyst",
		"href": "/en/shop/amethyst"
	},
	"aubergine": {
		"text": "Aubergine",
		"href": "/en/shop/aubergine"
	},
	"eggplant": {
		"text": "Eggplant",
		"href": "/en/shop/eggplant"
	},
	"fuchsia": {
		"text": "Fuchsia",
		"href": "/en/shop/fuchsia"
	},
	"lavender": {
		"text": "Lavender",
		"href": "/en/shop/lavender"
	},
	"lilac": {
		"text": "Lilac",
		"href": "/en/shop/lilac"
	},
	"magenta": {
		"text": "Magenta",
		"href": "/en/shop/magenta"
	},
	"orchid": {
		"text": "Orchid",
		"href": "/en/shop/orchid"
	},
	"plum": {
		"text": "Plum",
		"href": "/en/shop/plum"
	},
	"purple": {
		"text": "Purple",
		"href": "/en/shop/purple"
	},
	"radiantOrchid": {
		"text": "Radiant Orchid",
		"href": "/en/shop/radiant-orchid"
	},
	"violetLightest": {
		"text": "Violet Lightest"
	},
	"violetLight": {
		"text": "Violet Light"
	},
	"violetDark": {
		"text": "Violet Dark"
	},
	"violetDarkest": {
		"text": "Violet Darkest"
	},
	"violetBlueLightest": {
		"text": "Violet Blue Lightest"
	},
	"violetBlueLight": {
		"text": "Violet Blue Light"
	},
	"violetBlue": {
		"text": "Violet Blue"
	},
	"violetBlueDark": {
		"text": "Violet Blue Dark"
	},
	"violetBlueDarkest": {
		"text": "Violet Blue Darkest"
	},
	"violetGreyLightest": {
		"text": "Violet Grey Lightest"
	},
	"violetGreyLight": {
		"text": "Violet Grey Light"
	},
	"violetGrey": {
		"text": "Violet Grey"
	},
	"violetGreyDark": {
		"text": "Violet Grey Dark"
	},
	"violetGreyDarkest": {
		"text": "Violet Grey Darkest"
	},
	"blacks": {
		"text": "Blacks"
	},
	"black": {
		"text": "Black",
		"href": "/en/shop/black"
	},
	"ebony": {
		"text": "Ebony",
		"href": "/en/shop/ebony"
	},
	"noir": {
		"text": "Noir",
		"href": "/en/shop/noir"
	},
	"onyx": {
		"text": "Onyx",
		"href": "/en/shop/onyx"
	},
	"topAndTrending": {
		"text": "Top & Trending"
	},
	"allTrending": {
		"text": "All Trending",
		"href": "/en/shop?main=new&on=fabric&view=main"
	},
	"blackAndWhite": {
		"text": "Black and White",
		"href": "/en/shop/black-and-white"
	},
	"kitchen": {
		"text": "Kitchen",
		"href": "/en/shop/kitchen"
	},
	"nautical": {
		"text": "Nautical",
		"href": "/en/shop/nautical"
	},
	"space": {
		"text": "Space",
		"href": "/en/shop/space"
	},
	"teaTowel": {
		"text": "Tea Towel",
		"href": "/en/shop/tea-towel"
	},
	"allWoodland": {
		"text": "Woodland",
		"href": "/en/shop/woodland"
	},
	"adultContent": {
		"text": "Adult content"
	},
	"product": {
		"text": "Products"
	},
	"allProducts": {
		"text": "All Products"
	},
	"productMenu": {
		"text": "All Products"
	},
	"collections": {
		"text": "Collections"
	},
	"colorMenu": {
		"text": "Colors"
	},
	"topic": {
		"text": "Topic"
	},
	"topicMenu": {
		"text": "All Topics"
	},
	"style": {
		"text": "Style"
	},
	"styleMenu": {
		"text": "All Styles"
	},
	"sortMenu": {
		"text": "Sort by"
	},
	"bestSelling": {
		"text": "Best Selling"
	},
	"mostFavorited": {
		"text": "Most Favorited"
	},
	"trending": {
		"text": "Trending"
	},
	"newest": {
		"text": "Newest"
	},
	"mostRelevant": {
		"text": "Most Relevant"
	},
	"winnersOnlyMenu": {
		"text": "Design Challenge Winners"
	},
	"showWinnersOnly": {
		"text": "Show Award Winners"
	},
	"showWinnersOnlyAriaLabel": {
		"text": "Show Design Challenge Winners Only"
	},
	"modern": {
		"text": "Modern"
	},
	"allModern": {
		"text": "All Modern",
		"href": "/en/shop/modern"
	},
	"abstract": {
		"text": "Abstract",
		"href": "/en/shop/abstract"
	},
	"chevron": {
		"text": "Chevron",
		"href": "/en/shop/chevron"
	},
	"hipster": {
		"text": "Hipster",
		"href": "/en/shop/hipster"
	},
	"midCenturyModern": {
		"text": "Mid Century Modern",
		"href": "/en/shop/midcentury-modern"
	},
	"mod": {
		"text": "Mod",
		"href": "/en/shop/mod"
	},
	"ombre": {
		"text": "Ombre",
		"href": "/en/shop/ombre"
	},
	"traditional": {
		"text": "Traditional"
	},
	"allTraditional": {
		"text": "All Traditional",
		"href": "/en/shop/traditional"
	},
	"check": {
		"text": "Check",
		"href": "/en/shop/check"
	},
	"damask": {
		"text": "Damask",
		"href": "/en/shop/damask"
	},
	"gingham": {
		"text": "Gingham",
		"href": "/en/shop/gingham"
	},
	"herringbone": {
		"text": "Herringbone",
		"href": "/en/shop/herringbone"
	},
	"houndstooth": {
		"text": "Houndstooth",
		"href": "/en/shop/houndstooth"
	},
	"paisley": {
		"text": "Paisley",
		"href": "/en/shop/paisley"
	},
	"plaid": {
		"text": "Plaid",
		"href": "/en/shop/plaid"
	},
	"preppy": {
		"text": "Preppy",
		"href": "/en/shop/preppy"
	},
	"shabbyChic": {
		"text": "Shabby Chic",
		"href": "/en/shop/shabby-chic"
	},
	"toile": {
		"text": "Toile",
		"href": "/en/shop/toile"
	},
	"historical": {
		"text": "Historical"
	},
	"allHistorical": {
		"text": "All Historical",
		"href": "/en/shop/historical"
	},
	"artDeco": {
		"text": "Art Deco",
		"href": "/en/shop/artdeco"
	},
	"artNouveau": {
		"text": "Art Nouveau",
		"href": "/en/shop/art-nouveau"
	},
	"gothic": {
		"text": "Gothic",
		"href": "/en/shop/gothic"
	},
	"medieval": {
		"text": "Medieval",
		"href": "/en/shop/medieval"
	},
	"popArt": {
		"text": "Pop Art",
		"href": "/en/shop/pop"
	},
	"renaissance": {
		"text": "Renaissance",
		"href": "/en/shop/renaissance"
	},
	"rococo": {
		"text": "Rococo",
		"href": "/en/shop/rococo"
	},
	"retro": {
		"text": "Retro",
		"href": "/en/shop/retro"
	},
	"steampunk": {
		"text": "Steampunk",
		"href": "/en/shop/steampunk"
	},
	"victorian": {
		"text": "Victorian",
		"href": "/en/shop/victorian"
	},
	"vintage": {
		"text": "Vintage",
		"href": "/en/shop/vintage"
	},
	"60s": {
		"text": "60s",
		"href": "/en/shop/60s"
	},
	"70s": {
		"text": "70s",
		"href": "/en/shop/70s"
	},
	"80s": {
		"text": "80s",
		"href": "/en/shop/80s"
	},
	"90s": {
		"text": "90s",
		"href": "/en/shop/90s"
	},
	"allNautical": {
		"text": "All Nautical",
		"href": "/en/shop/nautical"
	},
	"anchors": {
		"text": "Anchors",
		"href": "/en/shop/anchors"
	},
	"boats": {
		"text": "Boats",
		"href": "/en/shop/boats"
	},
	"buoys": {
		"text": "Buoys",
		"href": "/en/shop/buoys"
	},
	"knots": {
		"text": "Knots",
		"href": "/en/shop/knots"
	},
	"lighthouse": {
		"text": "Lighthouse",
		"href": "/en/shop/lighthouse"
	},
	"lobster": {
		"text": "Lobster",
		"href": "/en/shop/lobster"
	},
	"map": {
		"text": "Map",
		"href": "/en/shop/map"
	},
	"mermaid": {
		"text": "Mermaid",
		"href": "/en/shop/mermaid"
	},
	"shells": {
		"text": "Shells",
		"href": "/en/shop/shells"
	},
	"ship": {
		"text": "Ship",
		"href": "/en/shop/ship"
	},
	"artistic": {
		"text": "Artistic"
	},
	"allArtistic": {
		"text": "All Artistic",
		"href": "/en/shop/artistic"
	},
	"brushStroke": {
		"text": "Brush Stroke",
		"href": "/en/shop/brush-stroke"
	},
	"handDrawn": {
		"text": "Hand Drawn",
		"href": "/en/shop/hand-drawn"
	},
	"photography": {
		"text": "Photography",
		"href": "/en/shop/photography"
	},
	"sketches": {
		"text": "Sketches",
		"href": "/en/shop/sketches"
	},
	"splatterPaint": {
		"text": "Splatter Paint",
		"href": "/en/shop/splatter-paint"
	},
	"watercolor": {
		"text": "Watercolor",
		"href": "/en/shop/watercolor"
	},
	"shapes": {
		"text": "Shapes"
	},
	"allShapes": {
		"text": "All Shapes",
		"href": "/en/shop/shapes"
	},
	"arrows": {
		"text": "Arrows",
		"href": "/en/shop/arrows"
	},
	"circle": {
		"text": "Circle",
		"href": "/en/shop/circle"
	},
	"cross": {
		"text": "Cross",
		"href": "/en/shop/cross"
	},
	"dots": {
		"text": "Dots",
		"href": "/en/shop/dots"
	},
	"fleurDeLis": {
		"text": "Fleur de Lis",
		"href": "/en/shop/fleur-de-lis"
	},
	"geometric": {
		"text": "Geometric",
		"href": "/en/shop/geometric"
	},
	"grid": {
		"text": "Grid",
		"href": "/en/shop/grid"
	},
	"diamond": {
		"text": "Diamond",
		"href": "/en/shop/harlequin-diamonds"
	},
	"hexagon": {
		"text": "Hexagon",
		"href": "/en/shop/hexagon"
	},
	"honeycombPattern": {
		"text": "Honeycomb Pattern",
		"href": "/en/shop/honeycomb-pattern"
	},
	"pixel": {
		"text": "Pixel",
		"href": "/en/shop/pixel"
	},
	"quatrefoil": {
		"text": "Quatrefoil",
		"href": "/en/shop/quatrefoil"
	},
	"trellis": {
		"text": "Trellis",
		"href": "/en/shop/trellis"
	},
	"triangles": {
		"text": "Triangles",
		"href": "/en/shop/triangles"
	},
	"texture": {
		"text": "Texture"
	},
	"allTexture": {
		"text": "All Texture",
		"href": "/en/shop/texture"
	},
	"lace": {
		"text": "Lace",
		"href": "/en/shop/lace"
	},
	"linen": {
		"text": "Linen",
		"href": "/en/shop/linen"
	},
	"marble": {
		"text": "Marble",
		"href": "/en/shop/marble"
	},
	"shibori": {
		"text": "Shibori",
		"href": "/en/shop/shibori"
	},
	"stainedGlass": {
		"text": "Stained Glass",
		"href": "/en/shop/stained-glass"
	},
	"wood": {
		"text": "Wood",
		"href": "/en/shop/wood"
	},
	"woodgrain": {
		"text": "Woodgrain",
		"href": "/en/shop/woodgrain"
	},
	"geography": {
		"text": "Geography"
	},
	"allGeography": {
		"text": "All Geography",
		"href": "/en/shop/geography"
	},
	"asian": {
		"text": "Asian",
		"href": "/en/shop/asian"
	},
	"chinese": {
		"text": "Chinese",
		"href": "/en/shop/chinese"
	},
	"scandinavian": {
		"text": "Scandinavian",
		"href": "/en/shop/scandinavian"
	},
	"mexican": {
		"text": "Mexican",
		"href": "/en/shop/mexican"
	},
	"paris": {
		"text": "Paris",
		"href": "/en/shop/paris"
	},
	"chinoiserie": {
		"text": "Chinoiserie",
		"href": "/en/shop/chinoiserie"
	},
	"indian": {
		"text": "Indian",
		"href": "/en/shop/indian"
	},
	"kawaii": {
		"text": "Kawaii",
		"href": "/en/shop/kawaii"
	},
	"african": {
		"text": "African",
		"href": "/en/shop/african"
	},
	"london": {
		"text": "London",
		"href": "/en/shop/london"
	},
	"hawaiian": {
		"text": "Hawaiian",
		"href": "/en/shop/hawaiian"
	},
	"french": {
		"text": "French",
		"href": "/en/shop/french"
	},
	"tropical": {
		"text": "Tropical",
		"href": "/en/shop/tropical"
	},
	"japanese": {
		"text": "Japanese",
		"href": "/en/shop/japanese"
	},
	"turkish": {
		"text": "Turkish",
		"href": "/en/shop/turkish"
	},
	"worldly": {
		"text": "Worldly"
	},
	"allWorldly": {
		"text": "All Worldly",
		"href": "/en/shop/worldly"
	},
	"aztec": {
		"text": "Aztec",
		"href": "/en/shop/aztec"
	},
	"bohemian": {
		"text": "Bohemian",
		"href": "/en/shop/bohemian"
	},
	"celtic": {
		"text": "Celtic",
		"href": "/en/shop/celtic"
	},
	"ikat": {
		"text": "Ikat",
		"href": "/en/shop/ikat"
	},
	"mandala": {
		"text": "Mandala",
		"href": "/en/shop/mandala"
	},
	"mapleLeaf": {
		"text": "Maple Leaf",
		"href": "/en/shop/maple-leaf"
	},
	"moroccan": {
		"text": "Moroccan",
		"href": "/en/shop/moroccan"
	},
	"mudcloth": {
		"text": "Mudcloth",
		"href": "/en/shop/mudcloth"
	},
	"nativeAmerican": {
		"text": "Native American",
		"href": "/en/shop/native-american"
	},
	"persian": {
		"text": "Persian",
		"href": "/en/shop/persian"
	},
	"tribal": {
		"text": "Tribal",
		"href": "/en/shop/tribal"
	},
	"animals": {
		"text": "Animals"
	},
	"animalsMenu": {
		"text": "All Animals"
	},
	"allAnimals": {
		"text": "All Animals",
		"href": "/en/shop/animals"
	},
	"woodlandAnimals": {
		"text": "Woodland Animals"
	},
	"allWoodlandAnimals": {
		"text": "All Woodland Animals",
		"href": "/en/shop/woodland"
	},
	"deer": {
		"text": "Deer",
		"href": "/en/shop/deer"
	},
	"fox": {
		"text": "Fox",
		"href": "/en/shop/fox"
	},
	"farm": {
		"text": "Farm"
	},
	"allFarm": {
		"text": "All Farm",
		"href": "/en/shop/farm"
	},
	"chicken": {
		"text": "Chicken",
		"href": "/en/shop/chicken"
	},
	"cows": {
		"text": "Cows",
		"href": "/en/shop/cows"
	},
	"donkey": {
		"text": "Donkey",
		"href": "/en/shop/donkey"
	},
	"ducks": {
		"text": "Ducks",
		"href": "/en/shop/ducks"
	},
	"goat": {
		"text": "Goat",
		"href": "/en/shop/goat"
	},
	"hen": {
		"text": "Hen",
		"href": "/en/shop/hen"
	},
	"horse": {
		"text": "Horse",
		"href": "/en/shop/horse"
	},
	"pig": {
		"text": "Pig",
		"href": "/en/shop/pig"
	},
	"sheep": {
		"text": "Sheep",
		"href": "/en/shop/sheep"
	},
	"pets": {
		"text": "Pets"
	},
	"allPets": {
		"text": "All Pets",
		"href": "/en/shop/pets"
	},
	"bird": {
		"text": "Bird",
		"href": "/en/shop/bird"
	},
	"cat": {
		"text": "Cat",
		"href": "/en/shop/cat"
	},
	"dog": {
		"text": "Dog",
		"href": "/en/shop/dog"
	},
	"fish": {
		"text": "Fish",
		"href": "/en/shop/fish"
	},
	"hamster": {
		"text": "Hamster",
		"href": "/en/shop/hamster"
	},
	"snake": {
		"text": "Snake",
		"href": "/en/shop/snake"
	},
	"allOcean": {
		"text": "All Ocean",
		"href": "/en/shop/ocean"
	},
	"crab": {
		"text": "Crab",
		"href": "/en/shop/crab"
	},
	"dolphin": {
		"text": "Dolphin",
		"href": "/en/shop/dolphin"
	},
	"jellyfish": {
		"text": "Jellyfish",
		"href": "/en/shop/jellyfish"
	},
	"koi": {
		"text": "Koi",
		"href": "/en/shop/koi"
	},
	"octopus": {
		"text": "Octopus",
		"href": "/en/shop/octopus"
	},
	"penguin": {
		"text": "Penguin",
		"href": "/en/shop/penguin"
	},
	"seahorse": {
		"text": "Seahorse",
		"href": "/en/shop/seahorse"
	},
	"shark": {
		"text": "Shark",
		"href": "/en/shop/shark"
	},
	"starfish": {
		"text": "Starfish",
		"href": "/en/shop/starfish"
	},
	"whale": {
		"text": "Whale",
		"href": "/en/shop/whale"
	},
	"insects": {
		"text": "Insects"
	},
	"allInsects": {
		"text": "All Insects",
		"href": "/en/shop/insects"
	},
	"bee": {
		"text": "Bee",
		"href": "/en/shop/bee"
	},
	"beetle": {
		"text": "Beetle",
		"href": "/en/shop/beetle"
	},
	"bug": {
		"text": "Bug",
		"href": "/en/shop/bug"
	},
	"butterfly": {
		"text": "Butterfly",
		"href": "/en/shop/butterfly"
	},
	"caterpillar": {
		"text": "Caterpillar",
		"href": "/en/shop/caterpillar"
	},
	"dragonfly": {
		"text": "Dragonfly",
		"href": "/en/shop/dragonfly"
	},
	"firefly": {
		"text": "Firefly",
		"href": "/en/shop/firefly"
	},
	"allBirds": {
		"text": "All Birds",
		"href": "/en/shop/birds"
	},
	"owl": {
		"text": "Owl",
		"href": "/en/shop/owl"
	},
	"parrot": {
		"text": "Parrot",
		"href": "/en/shop/parrot"
	},
	"peacock": {
		"text": "Peacock",
		"href": "/en/shop/peacock"
	},
	"zoo": {
		"text": "Zoo"
	},
	"allZoo": {
		"text": "All Zoo",
		"href": "/en/shop/zoo"
	},
	"bears": {
		"text": "Bears",
		"href": "/en/shop/bears"
	},
	"llama": {
		"text": "Llama",
		"href": "/en/shop/llama"
	},
	"panda": {
		"text": "Panda",
		"href": "/en/shop/panda"
	},
	"safari": {
		"text": "Safari"
	},
	"allSafari": {
		"text": "All Safari",
		"href": "/en/shop/safari"
	},
	"cheetah": {
		"text": "Cheetah",
		"href": "/en/shop/cheetah"
	},
	"elephant": {
		"text": "Elephant",
		"href": "/en/shop/elephant"
	},
	"giraffe": {
		"text": "Giraffe",
		"href": "/en/shop/giraffe"
	},
	"hippo": {
		"text": "Hippo",
		"href": "/en/shop/hippo"
	},
	"leopard": {
		"text": "Leopard",
		"href": "/en/shop/leopard"
	},
	"lions": {
		"text": "Lions",
		"href": "/en/shop/lions"
	},
	"monkey": {
		"text": "Monkey",
		"href": "/en/shop/monkey"
	},
	"rhino": {
		"text": "Rhino",
		"href": "/en/shop/rhino"
	},
	"sloth": {
		"text": "Sloth",
		"href": "/en/shop/sloth"
	},
	"tigers": {
		"text": "Tigers",
		"href": "/en/shop/tigers"
	},
	"zebra": {
		"text": "Zebra",
		"href": "/en/shop/zebra"
	},
	"dogBreeds": {
		"text": "Dog Breeds"
	},
	"allDogBreeds": {
		"text": "All Dog Breeds",
		"href": "/en/shop/dog-breeds"
	},
	"bulldog": {
		"text": "Bulldog",
		"href": "/en/shop/bulldog"
	},
	"corgi": {
		"text": "Corgi",
		"href": "/en/shop/corgi"
	},
	"dachshund": {
		"text": "Dachshund",
		"href": "/en/shop/dachshund"
	},
	"dalmatian": {
		"text": "Dalmatian",
		"href": "/en/shop/dalmatian"
	},
	"goldenRetriever": {
		"text": "Golden Retriever",
		"href": "/en/shop/golden-retriever"
	},
	"greyhound": {
		"text": "Greyhound",
		"href": "/en/shop/greyhound"
	},
	"poodle": {
		"text": "Poodle",
		"href": "/en/shop/poodle"
	},
	"pug": {
		"text": "Pug",
		"href": "/en/shop/pug"
	},
	"yorkie": {
		"text": "Yorkie",
		"href": "/en/shop/yorkie"
	},
	"nature": {
		"text": "Nature"
	},
	"natureMenu": {
		"text": "All Nature"
	},
	"allNature": {
		"text": "All Nature",
		"href": "/en/shop/nature"
	},
	"botanical": {
		"text": "Botanical"
	},
	"allBotanical": {
		"text": "All Botanical",
		"href": "/en/shop/botanical"
	},
	"cactus": {
		"text": "Cactus",
		"href": "/en/shop/cactus"
	},
	"dandelion": {
		"text": "Dandelion",
		"href": "/en/shop/dandelion"
	},
	"fauna": {
		"text": "Fauna",
		"href": "/en/shop/fauna"
	},
	"flora": {
		"text": "Flora",
		"href": "/en/shop/flora"
	},
	"flowers": {
		"text": "Flowers",
		"href": "/en/shop/flowers"
	},
	"leaves": {
		"text": "Leaves",
		"href": "/en/shop/leaves"
	},
	"palm": {
		"text": "Palm",
		"href": "/en/shop/palm"
	},
	"peony": {
		"text": "Peony",
		"href": "/en/shop/peony"
	},
	"poppy": {
		"text": "Poppy",
		"href": "/en/shop/poppy"
	},
	"rose": {
		"text": "Rose",
		"href": "/en/shop/rose"
	},
	"seeds": {
		"text": "Seeds",
		"href": "/en/shop/seeds"
	},
	"succulents": {
		"text": "Succulents",
		"href": "/en/shop/succulents"
	},
	"sunflower": {
		"text": "Sunflower",
		"href": "/en/shop/sunflower"
	},
	"thistles": {
		"text": "Thistles",
		"href": "/en/shop/thistles"
	},
	"trees": {
		"text": "Trees",
		"href": "/en/shop/trees"
	},
	"woodlandNature": {
		"text": "Woodland Nature"
	},
	"allWoodlandNature": {
		"text": "All Woodland Nature",
		"href": "/en/shop/woodland"
	},
	"allSpace": {
		"text": "All Space",
		"href": "/en/shop/space"
	},
	"asteroids": {
		"text": "Asteroids",
		"href": "/en/shop/asteroids"
	},
	"astronaut": {
		"text": "Astronaut",
		"href": "/en/shop/astronaut"
	},
	"constellations": {
		"text": "Constellations",
		"href": "/en/shop/constellations"
	},
	"galaxy": {
		"text": "Galaxy",
		"href": "/en/shop/galaxy"
	},
	"moon": {
		"text": "Moon",
		"href": "/en/shop/moon"
	},
	"nebula": {
		"text": "Nebula",
		"href": "/en/shop/nebula"
	},
	"planets": {
		"text": "Planets",
		"href": "/en/shop/planets"
	},
	"sky": {
		"text": "Sky",
		"href": "/en/shop/sky"
	},
	"stars": {
		"text": "Stars",
		"href": "/en/shop/stars"
	},
	"sun": {
		"text": "Sun",
		"href": "/en/shop/sun"
	},
	"zodiac": {
		"text": "Zodiac",
		"href": "/en/shop/zodiac"
	},
	"weather": {
		"text": "Weather"
	},
	"allWeather": {
		"text": "All Weather",
		"href": "/en/shop/weather"
	},
	"clouds": {
		"text": "Clouds",
		"href": "/en/shop/clouds"
	},
	"ice": {
		"text": "Ice",
		"href": "/en/shop/ice"
	},
	"lightning": {
		"text": "Lightning",
		"href": "/en/shop/lightning"
	},
	"rain": {
		"text": "Rain",
		"href": "/en/shop/rain"
	},
	"rainbow": {
		"text": "Rainbow",
		"href": "/en/shop/rainbow"
	},
	"storm": {
		"text": "Storm",
		"href": "/en/shop/storm"
	},
	"umbrella": {
		"text": "Umbrella",
		"href": "/en/shop/umbrella"
	},
	"feathers": {
		"text": "Feathers",
		"href": "/en/shop/feathers"
	},
	"flamingo": {
		"text": "Flamingo",
		"href": "/en/shop/flamingo"
	},
	"hummingbird": {
		"text": "Hummingbird",
		"href": "/en/shop/hummingbird"
	},
	"nest": {
		"text": "Nest",
		"href": "/en/shop/nest"
	},
	"robin": {
		"text": "Robin",
		"href": "/en/shop/robin"
	},
	"sparrow": {
		"text": "Sparrow",
		"href": "/en/shop/sparrow"
	},
	"toucan": {
		"text": "Toucan",
		"href": "/en/shop/toucan"
	},
	"antler": {
		"text": "Antler",
		"href": "/en/shop/antler"
	},
	"bear": {
		"text": "Bear",
		"href": "/en/shop/bear"
	},
	"birchBark": {
		"text": "Birch Bark",
		"href": "/en/shop/birch-bark"
	},
	"hedgehog": {
		"text": "Hedgehog",
		"href": "/en/shop/hedgehog"
	},
	"moose": {
		"text": "Moose",
		"href": "/en/shop/moose"
	},
	"mushroom": {
		"text": "Mushroom",
		"href": "/en/shop/mushroom"
	},
	"rabbit": {
		"text": "Rabbit",
		"href": "/en/shop/rabbit"
	},
	"squirrel": {
		"text": "Squirrel",
		"href": "/en/shop/squirrel"
	},
	"stag": {
		"text": "Stag",
		"href": "/en/shop/stag"
	},
	"sea": {
		"text": "Sea"
	},
	"allSea": {
		"text": "All Sea",
		"href": "/en/shop/sea"
	},
	"island": {
		"text": "Island",
		"href": "/en/shop/island"
	},
	"ocean": {
		"text": "Ocean",
		"href": "/en/shop/ocean"
	},
	"seaweed": {
		"text": "Seaweed",
		"href": "/en/shop/seaweed"
	},
	"surf": {
		"text": "Surf",
		"href": "/en/shop/surf"
	},
	"waves": {
		"text": "Waves",
		"href": "/en/shop/waves"
	},
	"outdoors": {
		"text": "Outdoors"
	},
	"allOutdoors": {
		"text": "All Outdoors",
		"href": "/en/shop/outdoors"
	},
	"birch": {
		"text": "Birch",
		"href": "/en/shop/birch"
	},
	"campfire": {
		"text": "Campfire",
		"href": "/en/shop/campfire"
	},
	"camping": {
		"text": "Camping",
		"href": "/en/shop/camping"
	},
	"desert": {
		"text": "Desert",
		"href": "/en/shop/desert"
	},
	"forest": {
		"text": "Forest",
		"href": "/en/shop/forest"
	},
	"jungle": {
		"text": "Jungle",
		"href": "/en/shop/jungle"
	},
	"lake": {
		"text": "Lake",
		"href": "/en/shop/lake"
	},
	"mountain": {
		"text": "Mountain",
		"href": "/en/shop/mountain"
	},
	"smores": {
		"text": "Smores",
		"href": "/en/shop/smores"
	},
	"teepee": {
		"text": "Teepee",
		"href": "/en/shop/teepee"
	},
	"tents": {
		"text": "Tents",
		"href": "/en/shop/tents"
	},
	"novelty": {
		"text": "Novelty"
	},
	"noveltyMenu": {
		"text": "All Novelty"
	},
	"allNovelty": {
		"text": "All Novelty",
		"href": "/en/shop/novelty"
	},
	"foodAndBeverage": {
		"text": "Food & Beverage"
	},
	"allFood": {
		"text": "All Food",
		"href": "/en/shop/food"
	},
	"banana": {
		"text": "Banana",
		"href": "/en/shop/banana"
	},
	"coffee": {
		"text": "Coffee",
		"href": "/en/shop/coffee"
	},
	"cookies": {
		"text": "Cookies",
		"href": "/en/shop/cookies"
	},
	"donuts": {
		"text": "Donuts",
		"href": "/en/shop/donuts"
	},
	"fruit": {
		"text": "Fruit",
		"href": "/en/shop/fruit"
	},
	"iceCream": {
		"text": "Ice Cream",
		"href": "/en/shop/icecream"
	},
	"lemon": {
		"text": "Lemon",
		"href": "/en/shop/lemon"
	},
	"pineapple": {
		"text": "Pineapple",
		"href": "/en/shop/pineapple"
	},
	"pizza": {
		"text": "Pizza",
		"href": "/en/shop/pizza"
	},
	"popcorn": {
		"text": "Popcorn",
		"href": "/en/shop/popcorn"
	},
	"sprinkles": {
		"text": "Sprinkles",
		"href": "/en/shop/sprinkles"
	},
	"strawberry": {
		"text": "Strawberry",
		"href": "/en/shop/strawberry"
	},
	"sushi": {
		"text": "Sushi",
		"href": "/en/shop/sushi"
	},
	"watermelon": {
		"text": "Watermelon",
		"href": "/en/shop/watermelon"
	},
	"music": {
		"text": "Music"
	},
	"allMusic": {
		"text": "All Music",
		"href": "/en/shop/music"
	},
	"drums": {
		"text": "Drums",
		"href": "/en/shop/drums"
	},
	"guitar": {
		"text": "Guitar",
		"href": "/en/shop/guitar"
	},
	"jazz": {
		"text": "Jazz",
		"href": "/en/shop/jazz"
	},
	"musicNotes": {
		"text": "Music Notes",
		"href": "/en/shop/music-notes"
	},
	"piano": {
		"text": "Piano",
		"href": "/en/shop/piano"
	},
	"radio": {
		"text": "Radio",
		"href": "/en/shop/radio"
	},
	"rockAndRoll": {
		"text": "Rock and Roll",
		"href": "/en/shop/rock-and-roll"
	},
	"symphony": {
		"text": "Symphony",
		"href": "/en/shop/symphony"
	},
	"trumpets": {
		"text": "Trumpets",
		"href": "/en/shop/trumpets"
	},
	"violin": {
		"text": "Violin",
		"href": "/en/shop/violin"
	},
	"academia": {
		"text": "Academia"
	},
	"allAcademia": {
		"text": "All Academia",
		"href": "/en/shop/academia"
	},
	"atomic": {
		"text": "Atomic",
		"href": "/en/shop/atomic"
	},
	"book": {
		"text": "Book",
		"href": "/en/shop/book"
	},
	"geek": {
		"text": "Geek",
		"href": "/en/shop/geek"
	},
	"math": {
		"text": "Math",
		"href": "/en/shop/math"
	},
	"school": {
		"text": "School",
		"href": "/en/shop/school"
	},
	"science": {
		"text": "Science",
		"href": "/en/shop/science"
	},
	"teacher": {
		"text": "Teacher",
		"href": "/en/shop/teacher"
	},
	"sewingNotions": {
		"text": "Sewing Notions"
	},
	"allSewingNotions": {
		"text": "All Sewing Notions",
		"href": "/en/shop/sewing-notions"
	},
	"knitting": {
		"text": "Knitting",
		"href": "/en/shop/knitting"
	},
	"sewingMachines": {
		"text": "Sewing Machines",
		"href": "/en/shop/sewing-machines"
	},
	"scissors": {
		"text": "Scissors",
		"href": "/en/shop/scissors"
	},
	"tapeMeasure": {
		"text": "Tape Measure",
		"href": "/en/shop/tape-measure"
	},
	"thimbles": {
		"text": "Thimbles",
		"href": "/en/shop/thimbles"
	},
	"thread": {
		"text": "Thread",
		"href": "/en/shop/thread"
	},
	"sports": {
		"text": "Sports"
	},
	"allSports": {
		"text": "All Sports",
		"href": "/en/shop/sports"
	},
	"ballet": {
		"text": "Ballet",
		"href": "/en/shop/ballet"
	},
	"baseball": {
		"text": "Baseball",
		"href": "/en/shop/baseball"
	},
	"basketball": {
		"text": "Basketball",
		"href": "/en/shop/basketball"
	},
	"bicycle": {
		"text": "Bicycle",
		"href": "/en/shop/bicycle"
	},
	"equestrian": {
		"text": "Equestrian",
		"href": "/en/shop/equestrian"
	},
	"fishing": {
		"text": "Fishing",
		"href": "/en/shop/fishing"
	},
	"football": {
		"text": "Football",
		"href": "/en/shop/football"
	},
	"golf": {
		"text": "Golf",
		"href": "/en/shop/golf"
	},
	"gymnastics": {
		"text": "Gymnastics",
		"href": "/en/shop/gymnastics"
	},
	"soccer": {
		"text": "Soccer",
		"href": "/en/shop/soccer"
	},
	"surfing": {
		"text": "Surfing",
		"href": "/en/shop/surfing"
	},
	"tennis": {
		"text": "Tennis",
		"href": "/en/shop/tennis"
	},
	"scary": {
		"text": "Scary"
	},
	"allScary": {
		"text": "All Scary",
		"href": "/en/shop/scary"
	},
	"monster": {
		"text": "Monster",
		"href": "/en/shop/monster"
	},
	"skull": {
		"text": "Skull",
		"href": "/en/shop/skull"
	},
	"wolf": {
		"text": "Wolf",
		"href": "/en/shop/wolf"
	},
	"transportation": {
		"text": "Transportation"
	},
	"allTransportation": {
		"text": "All Transportation",
		"href": "/en/shop/transportation"
	},
	"airplanes": {
		"text": "Airplanes",
		"href": "/en/shop/airplanes"
	},
	"bicycles": {
		"text": "Bicycles",
		"href": "/en/shop/bicycles"
	},
	"cars": {
		"text": "Cars",
		"href": "/en/shop/cars"
	},
	"construction": {
		"text": "Construction",
		"href": "/en/shop/construction"
	},
	"helicopters": {
		"text": "Helicopters",
		"href": "/en/shop/helicopters"
	},
	"hotAirBalloon": {
		"text": "Hot Air Balloon",
		"href": "/en/shop/hot-air-balloon"
	},
	"motorcycles": {
		"text": "Motorcycles",
		"href": "/en/shop/motorcycles"
	},
	"retroCampers": {
		"text": "Retro Campers",
		"href": "/en/shop/retro-campers"
	},
	"rocketShip": {
		"text": "Rocket Ship",
		"href": "/en/shop/rocket-ship"
	},
	"spaceShip": {
		"text": "Space Ship",
		"href": "/en/shop/space-ship"
	},
	"ships": {
		"text": "Ships",
		"href": "/en/shop/ships"
	},
	"tractors": {
		"text": "Tractors",
		"href": "/en/shop/tractors"
	},
	"trucks": {
		"text": "Trucks",
		"href": "/en/shop/trucks"
	},
	"occasionMenu": {
		"text": "All Occasions"
	},
	"occasion": {
		"text": "Occasion"
	},
	"babyAndKids": {
		"text": "Baby & Kids"
	},
	"allChildren": {
		"text": "All Children",
		"href": "/en/shop/children"
	},
	"alphabet": {
		"text": "Alphabet",
		"href": "/en/shop/alphabet"
	},
	"babyBoy": {
		"text": "Baby Boy",
		"href": "/en/shop/baby-boy"
	},
	"babyGirl": {
		"text": "Baby Girl",
		"href": "/en/shop/baby-girl"
	},
	"ballerina": {
		"text": "Ballerina",
		"href": "/en/shop/ballerina"
	},
	"circus": {
		"text": "Circus",
		"href": "/en/shop/circus"
	},
	"dinosaur": {
		"text": "Dinosaur",
		"href": "/en/shop/dinosaur"
	},
	"doll": {
		"text": "Doll",
		"href": "/en/shop/doll"
	},
	"dragon": {
		"text": "Dragon",
		"href": "/en/shop/dragon"
	},
	"fairy": {
		"text": "Fairy",
		"href": "/en/shop/fairy"
	},
	"nursery": {
		"text": "Nursery",
		"href": "/en/shop/nursery"
	},
	"pirate": {
		"text": "Pirate",
		"href": "/en/shop/pirate"
	},
	"princess": {
		"text": "Princess",
		"href": "/en/shop/princess"
	},
	"robot": {
		"text": "Robot",
		"href": "/en/shop/robot"
	},
	"unicorn": {
		"text": "Unicorn",
		"href": "/en/shop/unicorn"
	},
	"birthday": {
		"text": "Birthday"
	},
	"allBirthday": {
		"text": "All Birthday",
		"href": "/en/shop/birthday"
	},
	"balloons": {
		"text": "Balloons",
		"href": "/en/shop/balloons"
	},
	"cake": {
		"text": "Cake",
		"href": "/en/shop/cake"
	},
	"confetti": {
		"text": "Confetti",
		"href": "/en/shop/confetti"
	},
	"cupcake": {
		"text": "Cupcake",
		"href": "/en/shop/cupcake"
	},
	"gifts": {
		"text": "Gifts",
		"href": "/en/shop/gifts"
	},
	"presents": {
		"text": "Presents",
		"href": "/en/shop/presents"
	},
	"wedding": {
		"text": "Wedding",
		"href": "/en/shop/wedding"
	},
	"graduation": {
		"text": "Graduation",
		"href": "/en/shop/graduation"
	},
	"seasonal": {
		"text": "Seasonal"
	},
	"seasonalMenu": {
		"text": "All Seasonal"
	},
	"allSeasonal": {
		"text": "All Seasonal",
		"href": "/en/shop/seasonal"
	},
	"winter": {
		"text": "Winter"
	},
	"allWinter": {
		"text": "All Winter",
		"href": "/en/shop/winter"
	},
	"chanukah": {
		"text": "Chanukah",
		"href": "/en/shop/chanukah"
	},
	"christmas": {
		"text": "Christmas",
		"href": "/en/shop/christmas"
	},
	"holiday": {
		"text": "Holiday",
		"href": "/en/shop/holiday"
	},
	"iceSkates": {
		"text": "Ice Skates",
		"href": "/en/shop/ice-skates"
	},
	"penguins": {
		"text": "Penguins",
		"href": "/en/shop/penguins"
	},
	"polarBears": {
		"text": "Polar Bears",
		"href": "/en/shop/polar-bears"
	},
	"skiing": {
		"text": "Skiing",
		"href": "/en/shop/skiiing"
	},
	"snow": {
		"text": "Snow",
		"href": "/en/shop/snow"
	},
	"spring": {
		"text": "Spring"
	},
	"allSpring": {
		"text": "All Spring",
		"href": "/en/shop/spring"
	},
	"birds": {
		"text": "Birds",
		"href": "/en/shop/birds"
	},
	"bunny": {
		"text": "Bunny",
		"href": "/en/shop/bunny"
	},
	"crocus": {
		"text": "Crocus",
		"href": "/en/shop/crocus"
	},
	"daffodil": {
		"text": "Daffodil",
		"href": "/en/shop/daffodil"
	},
	"floral": {
		"text": "Floral",
		"href": "/en/shop/floral"
	},
	"tulips": {
		"text": "Tulips",
		"href": "/en/shop/tulips"
	},
	"summer": {
		"text": "Summer"
	},
	"allSummer": {
		"text": "All Summer",
		"href": "/en/shop/summer"
	},
	"beach": {
		"text": "Beach",
		"href": "/en/shop/beach"
	},
	"july4th": {
		"text": "July 4th",
		"href": "/en/shop/july-4th"
	},
	"summerIceCream": {
		"text": "Ice Cream",
		"href": "/en/shop/ice-cream"
	},
	"lemonade": {
		"text": "Lemonade",
		"href": "/en/shop/lemonade"
	},
	"popsicle": {
		"text": "Popsicle",
		"href": "/en/shop/popsicle"
	},
	"tiki": {
		"text": "Tiki",
		"href": "/en/shop/tiki"
	},
	"fall": {
		"text": "Fall"
	},
	"allFall": {
		"text": "All Fall",
		"href": "/en/shop/fall"
	},
	"apple": {
		"text": "Apple",
		"href": "/en/shop/apple"
	},
	"autumn": {
		"text": "Autumn",
		"href": "/en/shop/autumn"
	},
	"backpack": {
		"text": "Backpack",
		"href": "/en/shop/backpack"
	},
	"cider": {
		"text": "Cider",
		"href": "/en/shop/cider"
	},
	"halloween": {
		"text": "Halloween",
		"href": "/en/shop/halloween"
	},
	"woodlandFall": {
		"text": "Woodland Fall",
		"href": "/en/shop/woodland"
	},
	"seasonalHolidays": {
		"text": "Holidays"
	},
	"menorah": {
		"text": "Menorah",
		"href": "/en/shop/menorah"
	},
	"pumpkins": {
		"text": "Pumpkins",
		"href": "/en/shop/pumpkins"
	},
	"santaClaus": {
		"text": "Santa Claus",
		"href": "/en/shop/santa-claus"
	},
	"spider": {
		"text": "Spider",
		"href": "/en/shop/spider"
	},
	"starsAndStripes": {
		"text": "Stars & Stripes",
		"href": "/en/shop/stars-stripes"
	},
	"supernatural": {
		"text": "Supernatural",
		"href": "/en/shop/supernatural"
	},
	"witches": {
		"text": "Witches",
		"href": "/en/shop/witches"
	},
	"zombies": {
		"text": "Zombies",
		"href": "/en/shop/zombies"
	},
	"holidays": {
		"text": "Holidays"
	},
	"holidaysMenu": {
		"text": "All Holidays"
	},
	"allHolidays": {
		"text": "All Holidays",
		"href": "/en/shop/holidays"
	},
	"allChristmas": {
		"text": "All Christmas",
		"href": "/en/shop/christmas"
	},
	"christmasTree": {
		"text": "Christmas Tree",
		"href": "/en/shop/christmas-tree"
	},
	"holly": {
		"text": "Holly",
		"href": "/en/shop/holly"
	},
	"pinecones": {
		"text": "Pinecones",
		"href": "/en/shop/pinecones"
	},
	"reindeer": {
		"text": "Reindeer",
		"href": "/en/shop/reindeer"
	},
	"retroChristmas": {
		"text": "Retro Christmas",
		"href": "/en/shop/retro-christmas"
	},
	"santa": {
		"text": "Santa",
		"href": "/en/shop/santa"
	},
	"sled": {
		"text": "Sled",
		"href": "/en/shop/sled"
	},
	"sleigh": {
		"text": "Sleigh",
		"href": "/en/shop/sleigh"
	},
	"snowmen": {
		"text": "Snowmen",
		"href": "/en/shop/snowmen"
	},
	"stocking": {
		"text": "Stocking",
		"href": "/en/shop/stocking"
	},
	"allJuly4th": {
		"text": "All July 4th",
		"href": "/en/shop/july-4th"
	},
	"americanFlag": {
		"text": "American Flag",
		"href": "/en/shop/american-flag"
	},
	"cookout": {
		"text": "Cookout",
		"href": "/en/shop/cookout"
	},
	"fireworks": {
		"text": "Fireworks",
		"href": "/en/shop/fireworks"
	},
	"hamburgers": {
		"text": "Hamburgers",
		"href": "/en/shop/hamburgers"
	},
	"hotdogs": {
		"text": "Hotdogs",
		"href": "/en/shop/hotdogs"
	},
	"independenceDay": {
		"text": "Independence Day",
		"href": "/en/shop/independence-day"
	},
	"patriotic": {
		"text": "Patriotic",
		"href": "/en/shop/patriotic"
	},
	"picnic": {
		"text": "Picnic",
		"href": "/en/shop/picnic"
	},
	"redWhiteAndBlue": {
		"text": "Red, White, & Blue",
		"href": "/en/shop/red-white-and-blue"
	},
	"stripes": {
		"text": "Stripes",
		"href": "/en/shop/stripes"
	},
	"valentinesDay": {
		"text": "Valentine’s Day"
	},
	"allValentinesDay": {
		"text": "All Valentine’s Day",
		"href": "/en/shop/valentines-day"
	},
	"candyHearts": {
		"text": "Candy Hearts",
		"href": "/en/shop/candy-hearts"
	},
	"cupid": {
		"text": "Cupid",
		"href": "/en/shop/cupid"
	},
	"hearts": {
		"text": "Hearts",
		"href": "/en/shop/hearts"
	},
	"love": {
		"text": "Love",
		"href": "/en/shop/love"
	},
	"allHalloween": {
		"text": "All Halloween",
		"href": "/en/shop/halloween"
	},
	"bat": {
		"text": "Bat",
		"href": "/en/shop/bat"
	},
	"blackCats": {
		"text": "Black Cats",
		"href": "/en/shop/black-cats"
	},
	"candy": {
		"text": "Candy",
		"href": "/en/shop/candy"
	},
	"ghost": {
		"text": "Ghost",
		"href": "/en/shop/ghost"
	},
	"jack-o-lantern": {
		"text": "Jack-o-lantern",
		"href": "/en/shop/jack-o-lantern"
	},
	"pumpkin": {
		"text": "Pumpkin",
		"href": "/en/shop/pumpkin"
	},
	"scarecrow": {
		"text": "Scarecrow",
		"href": "/en/shop/scarecrow"
	},
	"spiderWeb": {
		"text": "Spider Web",
		"href": "/en/shop/spider-web"
	},
	"sugarSkulls": {
		"text": "Sugar Skulls",
		"href": "/en/shop/sugar-skulls"
	},
	"vampire": {
		"text": "Vampire",
		"href": "/en/shop/vampire"
	},
	"witch": {
		"text": "Witch",
		"href": "/en/shop/witch"
	},
	"zombie": {
		"text": "Zombie",
		"href": "/en/shop/zombie"
	},
	"projects": {
		"text": "Projects"
	},
	"projectMenu": {
		"text": "All Projects"
	},
	"allProjects": {
		"text": "All Projects",
		"href": "/en/shop/projects"
	},
	"bags": {
		"text": "Bags",
		"href": "/en/shop/bags"
	},
	"border": {
		"text": "Border",
		"href": "/en/shop/border"
	},
	"bunting": {
		"text": "Bunting",
		"href": "/en/shop/bunting"
	},
	"calendarTeaTowels": {
		"text": "Calendar Tea Towels",
		"href": "/en/shop/calendar-tea-towels"
	},
	"cheaterQuilts": {
		"text": "Cheater Quilts",
		"href": "/en/shop/cheater-quilts"
	},
	"cutAndSew": {
		"text": "Cut and Sew",
		"href": "/en/shop/cut-and-sew"
	},
	"pencilPouch": {
		"text": "Pencil Pouch",
		"href": "/en/shop/pencil-pouch"
	},
	"plushies": {
		"text": "Plushies",
		"href": "/en/shop/plushies"
	},
	"quilt": {
		"text": "Quilt",
		"href": "/en/shop/quilt"
	},
	"quiltLabels": {
		"text": "Quilt Labels",
		"href": "/en/shop/quilt-labels"
	},
	"stockings": {
		"text": "Stockings",
		"href": "/en/shop/stockings"
	},
	"teaTowels": {
		"text": "Tea Towels",
		"href": "/en/shop/tea-towels"
	},
	"zipperPouch": {
		"text": "Zipper Pouch",
		"href": "/en/shop/zipper-pouch"
	},
	"contests": {
		"text": "All challenges",
		"href": "/design-challenge"
	},
	"challenge1": {
		"text": "Needlepoint",
		"href": "/design-challenge/needlepoint"
	},
	"challenge2": {
		"text": "Yarn",
		"href": "/design-challenge/yarn"
	},
	"challenge3": {
		"text": "Limited Color Palette Sewing",
		"href": "/design-challenge/limited-color-palette-sewing"
	},
	"challenge4": {
		"text": "Aurora Borealis",
		"href": "/design-challenge/aurora-borealis"
	},
	"challenge5": {
		"text": "Gnomes",
		"href": "/design-challenge/gnomes"
	},
	"challenge6": {
		"text": "Ditsy Misteltoe",
		"href": "/design-challenge/ditsy-mistletoe"
	},
	"challenge7": {
		"text": "Snow Day",
		"href": "/design-challenge/snow-day"
	},
	"challenge8": {
		"text": "2017 Spoonflower T-Shirt",
		"href": "/design-challenge/2017-spoonflower-t-shirt"
	},
	"challenge9": {
		"text": "Alpine Chalet Gift Wrap",
		"href": "/design-challenge/alpine-chalet-gift-wrap"
	},
	"challenge10": {
		"text": "Poinsettia Gift Tags",
		"href": "/design-challenge/poinsettia-gift-tags"
	},
	"challenge11": {
		"text": "Lace Gift Wrap",
		"href": "/design-challenge/lace-gift-wrap"
	},
	"challenge12": {
		"text": "2017 Calendars",
		"href": "/design-challenge/2017-calendars"
	},
	"wallpaper": {
		"text": "Wallpaper"
	},
	"allWallpaper": {
		"text": "All Wallpaper",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_peel_and_stick"
	},
	"learnWallpaper": {
		"text": "Learn About Wallpaper",
		"href": "/en/wallpaper"
	},
	"prepastedRemovableSmooth": {
		"text": "Pre-Pasted",
		"href": "/en/prepasted-removable-smooth-wallpaper"
	},
	"peelAndStick": {
		"text": "Peel and Stick",
		"href": "/en/peel-and-stick-removable-woven-wallpaper"
	},
	"nonPastedTraditionalPebble": {
		"text": "Non-Pasted",
		"href": "/en/non-pasted-traditional-pebble-wallpaper"
	},
	"grasscloth": {
		"text": "Grasscloth",
		"href": "/en/grasscloth-wallpaper"
	},
	"metallic": {
		"text": "Metallic",
		"href": "/en/metallic-wallpaper"
	},
	"wallpaperPeelAndStick": {
		"text": "Peel and Stick",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_peel_and_stick"
	},
	"wallpaperSmooth": {
		"text": "Pre-Pasted",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_smooth"
	},
	"wallpaperGrasscloth": {
		"text": "Grasscloth",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_grasscloth"
	},
	"wallpaperIsobar": {
		"text": "Non-Pasted",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_isobar"
	},
	"wallpaperSilverMetallic": {
		"text": "Silver Metallic",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_silver_metallic"
	},
	"wallpaperGoldMetallic": {
		"text": "Gold Metallic",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_gold_metallic"
	},
	"wallpaperVinyl": {
		"text": "Vinyl",
		"href": "/en/shop?on=wallpaper&fabric=wallpaper_vinyl"
	},
	"dining": {
		"text": "Dining"
	},
	"allDining": {
		"text": "All Dining",
		"href": "/en/dining",
		"apiValue": "dining"
	},
	"cocktailNapkins": {
		"text": "Cocktail Napkins",
		"href": "/en/home-decor/dining/cocktail-napkins"
	},
	"dinnerNapkins": {
		"text": "Dinner Napkins",
		"href": "/en/home-decor/dining/dinner-napkins"
	},
	"placemats": {
		"text": "Placemats",
		"href": "/en/home-decor/dining/placemats"
	},
	"teaTowelsLink": {
		"text": "Tea Towels",
		"href": "/en/home-decor/dining/tea-towel"
	},
	"rectangularTablecloths": {
		"text": "Rectangular Tablecloths",
		"href": "/en/home-decor/dining/rectangular-tablecloth"
	},
	"roundTablecloths": {
		"text": "Round Tablecloths",
		"href": "/en/home-decor/dining/round-tablecloth"
	},
	"tableRunners": {
		"text": "Table Runners",
		"href": "/en/home-decor/dining/table-runner"
	},
	"bedding": {
		"text": "Bedding"
	},
	"allBedding": {
		"text": "All Bedding",
		"href": "/en/bedding",
		"apiValue": "bedding"
	},
	"duvetCovers": {
		"text": "Duvet Covers",
		"href": "/en/home-decor/bedding/duvet-cover"
	},
	"sheetSets": {
		"text": "Sheet Sets",
		"href": "/en/home-decor/bedding/sheet-set"
	},
	"standardPillowShams": {
		"text": "Standard Pillow Shams",
		"href": "/en/home-decor/bedding/standard-pillow-sham"
	},
	"euroPillowShams": {
		"text": "Euro Pillow Shams",
		"href": "/en/home-decor/bedding/euro-pillow-sham"
	},
	"longLumbarThrowPillow": {
		"text": "Extra Long Lumbar Throw Pillow",
		"href": "/en/home-decor/bedding/extra-long-lumbar-throw-pillow"
	},
	"livingDecor": {
		"text": "Living & Decor"
	},
	"allLiving": {
		"text": "All Living & Decor",
		"href": "/en/living-decor",
		"apiValue": "living&decor"
	},
	"squareThrowPillows": {
		"text": "Square Throw Pillows",
		"href": "/en/home-decor/living-decor/square-throw-pillow"
	},
	"lumbarThrowPillows": {
		"text": "Lumbar Throw Pillows",
		"href": "/en/home-decor/living-decor/lumbar-throw-pillow"
	},
	"throwBlankets": {
		"text": "Throw Blankets",
		"href": "/en/home-decor/living-decor/throw-blanket"
	},
	"curtains": {
		"text": "Curtains",
		"href": "/en/home-decor/living-decor/curtains"
	},
	"wallHanging": {
		"text": "Wall Hangings",
		"href": "/en/home-decor/living-decor/wall-hangings"
	},
	"designAndSellMenu": {
		"text": "Design & Sell",
		"href": "/en/sell"
	},
	"uploadYourDesign": {
		"text": "Upload a Design",
		"href": "/designs/new"
	},
	"designChallenge": {
		"text": "Design Challenges",
		"href": "/design-challenge"
	},
	"designTools": {
		"text": "Design Tools",
		"href": "/en/design-tools"
	},
	"artistWelcomePack": {
		"text": "Welcome Pack",
		"href": "/en/artist-welcome-pack"
	},
	"sellingAndCommissions": {
		"text": "Selling & Commissions",
		"href": "/en/sell"
	},
	"sellerAgreement": {
		"text": "Seller Agreement",
		"href": "/seller_agreement"
	},
	"designFAQs": {
		"text": "Designing FAQs",
		"href": "https://support.spoonflower.com/hc/en-us/sections/200793490-Creating"
	},
	"sellingFAQs": {
		"text": "Selling FAQs",
		"href": "https://support.spoonflower.com/hc/en-us/sections/200793480-Selling"
	},
	"blogMenu": {
		"text": "Blog",
		"href": "https://blog.spoonflower.com/"
	},
	"artist": {
		"text": "Artist Tutorials",
		"href": "https://blog.spoonflower.com/category/tutorials/design-tutorials/"
	},
	"maker": {
		"text": "Maker Tutorials",
		"href": "https://blog.spoonflower.com/category/inspiration/diy/"
	},
	"homeDecorInspiration": {
		"text": "Home Decor Inspiration",
		"href": "https://blog.spoonflower.com/category/inspiration/home-decor/"
	},
	"community": {
		"text": "Community",
		"href": "https://blog.spoonflower.com/category/community/"
	},
	"smallBusinessResources": {
		"text": "Small Business Resources",
		"href": "https://blog.spoonflower.com/category/resources/small-business-handbook/"
	},
	"programsAndDiscountsMenu": {
		"text": "Programs & Discounts",
		"href": "/en/discounts"
	},
	"ourDiscounts": {
		"text": "Our Discounts",
		"href": "/en/promo-codes-coupons-discounts"
	},
	"ourHappinessGuarantee": {
		"text": "Our Happiness Guarantee",
		"href": "/en/happiness-guarantee"
	},
	"proMembership": {
		"text": "Pro Membership",
		"href": "/pro"
	},
	"tradeProgram": {
		"text": "Trade Program",
		"href": "/en/trade"
	},
	"wholesale": {
		"text": "Wholesale",
		"href": "/wholesale"
	},
	"smallBusinessGrant": {
		"text": "Small Business Grant",
		"href": "/en/small-business-grant"
	},
	"helpButton": {
		"text": "Help",
		"href": "/en/contact"
	},
	"helpMenu": {
		"text": "Help",
		"href": "https://support.spoonflower.com"
	},
	"ourProcess": {
		"text": "Our Process",
		"href": "/en/how-it-works"
	},
	"giftIdeas": {
		"text": "Gift Ideas",
		"href": "/en/gift-ideas"
	},
	"helpCenter": {
		"text": "Help Center",
		"href": "https://support.spoonflower.com/"
	},
	"helpChat": {
		"text": "Chat"
	},
	"helpMessage": {
		"text": "Submit a Message",
		"href": "https://support.spoonflower.com/hc/en-us/requests/new?ticket_form_id=360001159372"
	},
	"contactUs": {
		"text": "Contact Us",
		"href": "/en/contact"
	},
	"aboutUs": {
		"text": "About Us"
	},
	"aboutUsOverview": {
		"text": "Overview",
		"href": "/en/about"
	},
	"aboutUsPress": {
		"text": "Press",
		"href": "/en/press"
	},
	"aboutUsJobs": {
		"text": "Jobs",
		"href": "https://jobs.spoonflower.com/work-at-spoonflower"
	},
	"aboutUsTradeProgram": {
		"text": "Trade Program",
		"href": "/en/trade"
	},
	"terms": {
		"text": "Terms"
	},
	"termsOfService": {
		"text": "Terms of Service",
		"href": "/terms"
	},
	"termsSellerAgreement": {
		"text": "Seller Agreement",
		"href": "/seller_agreement"
	},
	"termsProTermsOfService": {
		"text": "Pro Terms of Service",
		"href": "/pro_terms"
	},
	"termsPrivacy": {
		"text": "Privacy",
		"href": "/en/privacy"
	},
	"contact": {
		"text": "Contact Us",
		"href": "/en/contact"
	},
	"contactReturnsAndExchanges": {
		"text": "Returns & Exchanges",
		"href": "https://support.spoonflower.com/hc/en-us/articles/204444690-Can-I-return-or-exchange-what-I-ordered"
	},
	"myAccount": {
		"text": "Account Settings"
	},
	"account": {
		"text": "Account"
	},
	"myStudio": {
		"text": "My Studio",
		"href": "https://www.spoonflower.com/"
	},
	"logIn": {
		"text": "Log In",
		"href": "/login"
	},
	"logOut": {
		"text": "Logout"
	},
	"join": {
		"text": "Join"
	},
	"loginORjoin": "or",
	"uploadDesign": {
		"text": "Upload a Design"
	},
	"welcome": "Welcome!",
	"cart": {
		"text": "Cart"
	},
	"clearFilter": {
		"text": "Clear Filter"
	},
	"subMenuHeader": {
		"useCase": "Use case",
		"typeMaterial": "Type / Material",
		"property": "Property",
		"others": "Others"
	},
	"tooltip": {
		"description": "The top row is pure {color1}, the middle row is mixed with {color2} and the bottom row is mixed with {color3}."
	},
	"about": {
		"text": "About Spoonflower",
		"href": "/en/about"
	}
};