import {DesignerCollection} from './designerCollection';
import {WallpaperDimensions} from './products';
import {DesignTags} from './tags';


interface EmptyDesign {
  designer: Record<string, unknown>;
  isFetching: boolean;
  isFetchingValidImage: boolean;
  isFetchingFavoriteDesign: boolean;
  id?: number;
  error: boolean;
  errorNumber?: number;
  name?: string;
  tags?: DesignTags;
  design_thumbnail?: string;
}

export interface DesignResponse extends EmptyDesign {
  all_locale_slugs: Record<string, string>;
  description: string;
  design_thumbnail: string;
  designer: {
    designer_description: string;
    designer_image: {
      mugshot: string;
      id: number;
      filename: string;
    };
    id: number;
    public_designs: number;
    screen_name: string;
  };
  designer_collection: DesignerCollection;
  favorite_count: number;
  filename: string;
  for_sale: boolean;
  for_sale_as_fabric: boolean;
  for_sale_as_wallpaper: boolean;
  for_sale_as_home_good: boolean;
  for_sale_as_tea_towel: boolean;
  for_sale_as_gift_wrap: boolean;
  id: number;
  images: {
    wallpaper: {
      [key: string]: {
        [key: string]: {
          thumbnail: string;
          desktop: string;
        };
      };
    };
    fabric: {
      [key: string]: {
        fabric_specific: Record<string, string>;
        ripple: Record<string, Record<string, string>>;
      };
    };
    home_good: {
      [key: string]: {
        fabric_specific: Record<string, string>;
      };
    };
  };
  name: string;
  recommended_fabric: string;
  short_description: string;
  slug: string;
  thumbnail_url: string;
  user_favorite: boolean;
  user_is_private_buyer: boolean;
  wallpaper_dimensions: WallpaperDimensions;
}

export interface OrderItemDesign {
  designer: string;
  designer_url: string;
  dpi: number;
  filename: string;
  id: number;
  name: string;
  repeat_type: string;
  thumbnail_url: string;
}

export interface MerchandiseDesign {
  name: string;
  thumbnail_url: string;
  // Unused Data
  // all_locale_slugs: Record<string, string>;
  // favorite_count: number;
  // filename: string;
  // for_sale: boolean;
  // for_sale_as_fabric: boolean;
  // for_sale_as_wallpaper: boolean;
  // for_sale_as_home_good: boolean;
  // for_sale_as_tea_towel: boolean;
  // for_sale_as_gift_wrap: boolean;
  // id: number;
  // user_favorite: boolean;
  // slug: string;
}

export type Design = EmptyDesign | DesignResponse;

/**
 * Type predicate to determine whether the `Design` has actually resolved to a
 * `DesignResponse`, allowing its use in components that require the network
 * request to have finished.
 */
export const isDesignResponse = (design: Design): design is DesignResponse => (
  (design as DesignResponse).id !== undefined
);

export interface CollectionDesign {
  designId: number;
  name: string;
  thumbnail: string;
  slug: string;
  user: {
    id: number;
    screenName: string;
  };
  productListing: {
    name: string;
    slug: string;
  };
}
