/* eslint-disable max-lines */

/**
 * API Parameter constants
 */

export const COUNTRY = 'country';
export const CURRENCY = 'currency';
export const FABRIC_SIZE = 'fabric_size';
export const LOCALE = 'locale';
export const MEASUREMENT_SYSTEM = 'measurement_system';
export const ADULT_CONTENT = 'adult_content';
export const FABRIC_PARAM = 'fabric';
export const DESIGN_ID_PARAM = 'design_id';
export const ADULT_CONTENT_ON = 'ADULT_CONTENT_ON';
export const ADULT_CONTENT_OFF = 'ADULT_CONTENT_OFF';

export const ORDER_ITEM_ID = 'order_item_id';
export const SIZE = 'size';

export const SORT_PARAM = 'sort';
export const PRODUCT_PARAM = 'product';
export const SUBSTRATE_PARAM = 'fabric';
export const TOPIC_PARAM = 'topic';
export const COLOR_PARAM = 'color';
export const COLOR_HEX_PARAM = 'eigColors';
export const STYLE_PARAM = 'style';
export const OFFSET_PARAM = 'page_offset';
export const ON_PARAM = 'on';
export const QUERY_PARAM = 'q';
export const DESIGNER_PARAM = 'designer';
export const SIZE_PARAM = 'size';
export const DESIGNS_PARAM = 'page_offset';
export const COLLECTIONS_PARAM = 'inner_page_offset';

export const ALL = 'All';

export const URL_QUERY_PARAM_SORT_ALPHABETICAL = 'alphabetical';
export const URL_QUERY_PARAM_SORT_NEW = 'newest';
export const URL_QUERY_PARAM_SORT_MOSTFAVORITED = 'mostFavorited';
export const URL_QUERY_PARAM_SORT_MOSTRELEVANT = 'mostRelevant';
export const URL_QUERY_PARAM_SORT_BESTSELLERS = 'bestSelling';
export const URL_QUERY_PARAM_SORT_TRENDING = 'trending';
export const URL_QUERY_PARAM_SORT_FRESH_PICKS = 'classic';

export const URL_QUERY_PARAM_SUBSTRATE_ALL = 'allproducts';
export const URL_QUERY_PARAM_SUBSTRATE_FABRIC = 'fabric';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER = 'wallpaper';
export const URL_QUERY_PARAM_PRODUCT_SQUARE_THROW_PILLOW = 'squarethrowpillows';
export const URL_QUERY_PARAM_PRODUCT_LUMBAR_THROW_PILLOW = 'lumbarthrowpillows';
export const URL_QUERY_PARAM_PRODUCT_LONG_LUMBAR_THROW_PILLOW = 'longlumbarthrowpillow';
export const URL_QUERY_PARAM_PRODUCT_THROW_BLANKET = 'throwblankets';
export const URL_QUERY_PARAM_PRODUCT_CURTAIN_PANEL = 'curtains';
export const URL_QUERY_PARAM_PRODUCT_COCKTAIL_NAPKIN = 'cocktailnapkins';
export const URL_QUERY_PARAM_PRODUCT_DINNER_NAPKIN = 'dinnernapkins';
export const URL_QUERY_PARAM_PRODUCT_PLACEMAT = 'placemats';
export const URL_QUERY_PARAM_PRODUCT_TEA_TOWEL = 'teatowels';
export const URL_QUERY_PARAM_PRODUCT_WALL_HANGING = 'wallhanging';
export const URL_QUERY_PARAM_PRODUCT_RECTANGULAR_TABLE_CLOTH = 'rectangulartablecloths';
export const URL_QUERY_PARAM_PRODUCT_ROUND_TABLE_CLOTH = 'roundtablecloths';
export const URL_QUERY_PARAM_PRODUCT_TABLE_RUNNER = 'tablerunners';
export const URL_QUERY_PARAM_PRODUCT_DUVET_COVER = 'duvetcovers';
export const URL_QUERY_PARAM_PRODUCT_SHEET_SET = 'sheetsets';
export const URL_QUERY_PARAM_PRODUCT_STANDARD_PILLOW_SHAM = 'standardpillowshams';
export const URL_QUERY_PARAM_PRODUCT_EURO_PILLOW_SHAM = 'europillowshams';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SMOOTH = 'wallpaper_smooth';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK = 'wallpaper_peel_and_stick';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GRASSCLOTH = 'wallpaper_grasscloth';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_ISOBAR = 'wallpaper_isobar';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_SILVER_METALLIC = 'wallpaper_silver_metallic';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_GOLD_METALLIC = 'wallpaper_gold_metallic';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_VINYL = 'wallpaper_vinyl';
export const URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_DEFAULT = URL_QUERY_PARAM_SUBSTRATE_WALLPAPER_PEEL_AND_STICK;

export const URL_QUERY_PARAM_CATEGORY_BEDDING_API_VALUE = 'Bedding Category';
export const URL_QUERY_PARAM_CATEGORY_DINING_API_VALUE = 'Dining Category';
export const URL_QUERY_PARAM_CATEGORY_LIVING_API_VALUE = 'Living Decor Category';

export const URL_QUERY_PARAM_CATEGORY_ALL_PRODUCTS = 'allProducts';
export const URL_QUERY_PARAM_CATEGORY_BEDDING = 'bedding';
export const URL_QUERY_PARAM_CATEGORY_DINING = 'dining';
export const URL_QUERY_PARAM_CATEGORY_LIVING = 'living&decor';

export const PRODUCT_QUERY_KEY = 'product';
export const PRODUCT_QUERY_FABRIC = URL_QUERY_PARAM_SUBSTRATE_FABRIC;
export const PRODUCT_QUERY_WALLPAPER = URL_QUERY_PARAM_SUBSTRATE_WALLPAPER;
export const PRODUCT_QUERY_KITCHEN = 'homegoods-kitchen-dining';
export const PRODUCT_QUERY_BEDDING = 'homegoods-bedding';
export const PRODUCT_QUERY_LIVING = 'homegoods-living-decor';

export const MENU_SUFFIX = 'Menu';

export const HOME_GOOD_ADDITION_COVER_ONLY = 'cover_only';

export const ALLCOLORS = 'allColors';
const ALL_COLORS = 'all-colors';

export const COLOR_API_PARAM_TO_COLOR_KEY_MAP = {
  [ALL_COLORS]: 'all-colors',
  '000000': 'black',
  ffffff: 'white',
  dedede: 'greyLightest',
  bcbcbc: 'greyLight',
  '999999': 'grey',
  '727272': 'greyDark',
  '4c4c4c': 'greyDarkest',

  e7dfd0: 'brownLightest',
  d1bfa0: 'brownLight',
  b89d6f: 'brown',
  '8a7553': 'brownDark',
  '5c4e37': 'brownDarkest',

  ffabdd: 'magentaLightest',
  ff57ba: 'magentaLight',
  ff0096: 'magenta',
  bf0170: 'magentaDark',
  '7f004a': 'magentaDarkest',
  f2afe3: 'magentaVioletRedLightest',
  e45fc5: 'magentaVioletRedLight',
  d50ba6: 'magentaVioletRed',
  '9f077c': 'magentaVioletRedDark',
  '6a0452': 'magentaVioletRedDarkest',
  dfaed0: 'magentaGreyLightest',
  c87ab0: 'magentaGreyLight',
  ae5491: 'magentaGrey',
  '7f436b': 'magentaGreyDark',
  '523047': 'magentaGreyDarkest',

  ffabab: 'redLightest',
  ff5758: 'redLight',
  ff0000: 'red',
  bf0101: 'redDark',
  '7f0000': 'redDarkest',
  ffabc4: 'redMagentaLightest',
  ff578a: 'redMagentaLight',
  ff004c: 'redMagenta',
  bf0138: 'redMagentaDark',
  '7f0025': 'redMagentaDarkest',
  e5acb7: 'redGreyLightest',
  d17887: 'redGreyLight',
  b8525f: 'redGrey',
  '83414b': 'redGreyDark',
  '553035': 'redGreyDarkest',

  ffd0ab: 'orangeLightest',
  ffa157: 'orangeLight',
  ff7000: 'orange',
  bf5200: 'orangeDark',
  '7f3701': 'orangeDarkest',
  ffc4ab: 'orangeRedLightest',
  fe8957: 'orangeRedLight',
  fe4c00: 'orangeRed',
  bf3800: 'orangeRedDark',
  '7f2500': 'orangeRedDarkest',
  e5bfac: 'orangeGreyLightest',
  d19878: 'orangeGreyLight',
  b87752: 'orangeGrey',
  '845a42': 'orangeGreyDark',
  '553d30': 'orangeGreyDarkest',

  feeaaa: 'yellowOrangeLightest',
  ffd458: 'yellowOrangeLight',
  ffbd01: 'yellowOrange',
  bf8d01: 'yellowOrangeDark',
  '7f5e00': 'yellowOrangeDarkest',
  ffe1aa: 'yellowOrangeOrangeLightest',
  ffc257: 'yellowOrangeOrangeLight',
  ffa301: 'yellowOrangeOrange',
  bf7a01: 'yellowOrangeOrangeDark',
  '7f5101': 'yellowOrangeOrangeDarkest',
  e5d3ab: 'yellowOrangeGreyLightest',
  d0b578: 'yellowOrangeGreyLight',
  b89852: 'yellowOrangeGrey',
  '836f42': 'yellowOrangeGreyDark',
  '554a30': 'yellowOrangeGreyDarkest',
  fffdaa: 'yellowLightest',
  fffa56: 'yellowLight',
  fff603: 'yellow',
  bfb801: 'yellowDark',
  '7f7a00': 'yellowDarkest',
  fff6ab: 'yellowYellowOrangeLightest',
  ffed57: 'yellowYellowOrangeLight',
  ffe300: 'yellowYellowOrange',
  bfaa00: 'yellowYellowOrangeDark',
  '807000': 'yellowYellowOrangeDarkest',
  e5e0ac: 'yellowGreyLightest',
  d1ca78: 'yellowGreyLight',
  b9b052: 'yellowGrey',
  '847f42': 'yellowGreyDark',
  '555330': 'yellowGreyDarkest',
  eaf5b3: 'yellowGreenLightest',
  d5eb67: 'yellowGreenLight',
  bee016: 'yellowGreen',
  '8ea712': 'yellowGreenDark',
  '5e6f0c': 'yellowGreenDarkest',
  f1f8b0: 'yellowGreenYellowLightest',
  e2f061: 'yellowGreenYellowLight',
  d3e810: 'yellowGreenYellow',
  '9eac0d': 'yellowGreenYellowDark',
  '697307': 'yellowGreenYellowDarkest',
  d9dfb1: 'yellowGreenGreyLightest',
  bec77d: 'yellowGreenGreyLight',
  a2ad59: 'yellowGreenGrey',
  '767d46': 'yellowGreenGreyDark',
  '4d5133': 'yellowGreenGreyDarkest',

  bee5aa: 'greenLightest',
  '7dca57': 'greenLight',
  '39ae01': 'green',
  '2b8201': 'greenDark',
  '1c5600': 'greenDarkest',
  d4edaf: 'greenYellowGreenLightest',
  a8db5f: 'greenYellowGreenLight',
  '7cc70c': 'greenYellowGreen',
  '5c9509': 'greenYellowGreenDark',
  '3e6304': 'greenYellowGreenDarkest',
  c3d7ae: 'greenGreyLightest',
  '98ba7a': 'greenGreyLight',
  '769c53': 'greenGrey',
  '597243': 'greenGreyDark',
  '3d4b30': 'greenGreyDarkest',

  b6e2e4: 'blueGreenLightest',
  '6ec4c8': 'blueGreenLight',
  '23a4ad': 'blueGreen',
  '1c7a81': 'blueGreenDark',
  '115156': 'blueGreenDarkest',
  b8e3d5: 'blueGreenGreenLightest',
  '73c6ac': 'blueGreenGreenLight',
  '28a77f': 'blueGreenGreen',
  '1d7d5f': 'blueGreenGreenDark',
  '14533f': 'blueGreenGreenDarkest',
  b5d1d1: 'blueGreenGreyLightest',
  '85b1b4': 'blueGreenGreyLight',
  '5f9397': 'blueGreenGrey',
  '4b6c6f': 'blueGreenGreyDark',
  '354749': 'blueGreenGreyDarkest',

  abe9ff: 'cyanLightest',
  '56d2ff': 'cyanLight',
  '00baff': 'cyan',
  '008bbf': 'cyanDark',
  '015c7f': 'cyanDarkest',
  b3e3ed: 'cyanBlueGreenLightest',
  '68c8dc': 'cyanBlueGreenLight',
  '1aaac8': 'cyanBlueGreen',
  '0f8096': 'cyanBlueGreenDark',
  '095564': 'cyanBlueGreenDarkest',
  afd4dd: 'cyanGreyLightest',
  '7cb4c6': 'cyanGreyLight',
  '5699ad': 'cyanGrey',
  '44707d': 'cyanGreyDark',
  '314a51': 'cyanGreyDarkest',

  abc4ee: 'blueLightest',
  '5788dd': 'blueLight',
  '004aca': 'blue',
  '013798': 'blueDark',
  '002465': 'blueDarkest',
  abd6f7: 'blueCyanLightest',
  '58acee': 'blueCyanLight',
  '0281e4': 'blueCyan',
  '0360aa': 'blueCyanDark',
  '004071': 'blueCyanDarkest',
  adc4dd: 'blueGreyLightest',
  '779cc2': 'blueGreyLight',
  '527aa8': 'blueGrey',
  '415c7a': 'blueGreyDark',
  '303e4e': 'blueGreyDarkest',

  d0b3f0: 'violetLightest',
  a167e0: 'violetLight',
  '7018cf': 'violet',
  '53109b': 'violetDark',
  '370b67': 'violetDarkest',
  c6b7ef: 'violetBlueLightest',
  '8e6fdf': 'violetBlueLight',
  '5324ce': 'violetBlue',
  '3e1a9a': 'violetBlueDark',
  '291166': 'violetBlueDarkest',
  c1b3da: 'violetGreyLightest',
  '9883bf': 'violetGreyLight',
  '795ea3': 'violetGrey',
  '5b4977': 'violetGreyDark',
  '3e344e': 'violetGreyDarkest',
  e2b2e7: 'violetRedLightest',
  c765d1: 'violetRedLight',
  aa14b8: 'violetRed',
  '7f0f89': 'violetRedDark',
  '540a5b': 'violetRedDarkest',
  dab3ec: 'violetRedVioletLightest',
  b367d8: 'violetRedVioletLight',
  '8d16c3': 'violetRedViolet',
  '691291': 'violetRedVioletDark',
  '460b61': 'violetRedVioletDarkest',
  cdb2d6: 'violetRedGreyLightest',
  ad80ba: 'violetRedGreyLight',
  '905a9e': 'violetRedGrey',
  '6a4673': 'violetRedGreyDark',
  '46334c': 'violetRedGreyDarkest',
};

const BLACK = 'black';
const SCHWARZ = 'schwarz';
const NEGRO = 'negro';
const NOIR = 'noir';
const WHITE = 'white';
const WEISS = 'weiß';
const BLANCO = 'blanco';
const BLANC = 'blanc';
const GRAY = 'grey';
const GRAU = 'grau';
const GRIS = 'gris';
const RED = 'red';
const ROT = 'rot';
const ROJO = 'rojo';
const ROUGE = 'rouge';
const ORANGE = 'orange';
const NARANJA = 'naranja';
const YELLOW = 'yellow';
const GELB = 'gelb';
const AMARILLO = 'amarillo';
const JAUNE = 'jaune';
const GREEN = 'green';
const GRÜN = 'grün';
const VERDE = 'verde';
const VERT = 'vert';
const BLUE = 'blue';
const BLAU = 'blau';
const AZUL = 'azul';
const BLEU = 'bleu';
const PURPLE = 'purple';
const LILA = 'lila';
const MORADO = 'morado';
const VIOLET = 'violet';
const PINK = 'pink';
const ROSA = 'rosa';
const ROSE = 'rose';
const BROWN = 'brown';
const BRAUN = 'braun';
const MARRÓN = 'marrón'; // ES translation present in SP-3005 is wrong, current is Brown, should be marrón
const MARRON = 'marron';

type ColorMap = {
  [key: string]: {
    [key: string]: string;
  };
};

export const COLOR_PARAM_TO_ENGLISH_MAP: ColorMap = {
  en: {
    [BLACK]: BLACK,
    [WHITE]: WHITE,
    [GRAY]: GRAY,
    [RED]: RED,
    [ORANGE]: ORANGE,
    [YELLOW]: YELLOW,
    [GREEN]: GREEN,
    [BLUE]: BLUE,
    [PURPLE]: PURPLE,
    [PINK]: PINK,
    [BROWN]: BROWN,
  },
  es: {
    [BLACK]: NEGRO,
    [WHITE]: BLANCO,
    [GRAY]: GRIS,
    [RED]: ROJO,
    [ORANGE]: NARANJA,
    [YELLOW]: AMARILLO,
    [GREEN]: VERDE,
    [BLUE]: AZUL,
    [PURPLE]: MORADO,
    [PINK]: ROSA,
    [BROWN]: MARRÓN,
  },
  fr: {
    [BLACK]: NOIR,
    [WHITE]: BLANC,
    [GRAY]: GRIS,
    [RED]: ROUGE,
    [ORANGE]: ORANGE,
    [YELLOW]: JAUNE,
    [GREEN]: VERT,
    [BLUE]: BLEU,
    [PURPLE]: VIOLET,
    [PINK]: ROSE,
    [BROWN]: MARRON,
  },
  de: {
    [BLACK]: SCHWARZ,
    [WHITE]: WEISS,
    [GRAY]: GRAU,
    [RED]: ROT,
    [ORANGE]: ORANGE,
    [YELLOW]: GELB,
    [GREEN]: GRÜN,
    [BLUE]: BLAU,
    [PURPLE]: LILA,
    [PINK]: ROSA,
    [BROWN]: BRAUN,
  },
};

export const COLOR_API_PARAM_TO_COLOR_KEY_MAP_OLD_COLOR_UI: { [key: string]: string } = {
  [ALL_COLORS]: ALL_COLORS,
  [BLACK]: BLACK,
  [SCHWARZ]: BLACK,
  [NEGRO]: BLACK,
  [NOIR]: BLACK,
  [WHITE]: WHITE,
  [WEISS]: WHITE,
  [BLANCO]: WHITE,
  [BLANC]: WHITE,
  [GRAY]: GRAY,
  [GRAU]: GRAY,
  [GRIS]: GRAY,
  [RED]: RED,
  [ROT]: RED,
  [ROJO]: RED,
  [ROUGE]: RED,
  [ORANGE]: ORANGE,
  [NARANJA]: ORANGE,
  [YELLOW]: YELLOW,
  [GELB]: YELLOW,
  [AMARILLO]: YELLOW,
  [JAUNE]: YELLOW,
  [GREEN]: GREEN,
  [GRÜN]: GREEN,
  [VERDE]: GREEN,
  [VERT]: GREEN,
  [BLUE]: BLUE,
  [BLAU]: BLUE,
  [AZUL]: BLUE,
  [BLEU]: BLUE,
  [PURPLE]: PURPLE,
  [LILA]: PURPLE,
  [MORADO]: PURPLE,
  [VIOLET]: PURPLE,
  [PINK]: PINK,
  [ROSA]: PINK,
  [ROSE]: PINK,
  [BROWN]: BROWN,
  [BRAUN]: BROWN,
  [MARRÓN]: BROWN,
  [MARRON]: BROWN,
};

export const STYLE_FILTERS: {[key: string]: {[key: string]: string}} = {
  en: {
    modern: 'Modern',
    allModern: 'All Modern',
    abstract: 'Abstract',
    chevron: 'Chevron',
    hipster: 'Hipster',
    midCenturyModern: 'Mid Century Modern',
    mod: 'Mod',
    ombre: 'Ombre',
    traditional: 'Traditional',
    allTraditional: 'All Traditional',
    check: 'Check',
    damask: 'Damask',
    gingham: 'Gingham',
    herringbone: 'Herringbone',
    houndstooth: 'Houndstooth',
    paisley: 'Paisley',
    plaid: 'Plaid',
    preppy: 'Preppy',
    shabbyChic: 'Shabby Chic',
    toile: 'Toile',
    historical: 'Historical',
    allHistorical: 'All Historical',
    artDeco: 'Art Deco',
    artNouveau: 'Art Nouveau',
    gothic: 'Gothic',
    medieval: 'Medieval',
    popArt: 'Pop Art',
    renaissance: 'Renaissance',
    rococo: 'Rococo',
    retro: 'Retro',
    steampunk: 'Steampunk',
    victorian: 'Victorian',
    vintage: 'Vintage',
    '60s': '60s',
    '70s': '70s',
    '80s': '80s',
    '90s': '90s',
    allNautical: 'All Nautical',
    anchors: 'Anchors',
    boats: 'Boats',
    buoys: 'Buoys',
    knots: 'Knots',
    lighthouse: 'Lighthouse',
    lobster: 'Lobster',
    map: 'Map',
    mermaid: 'Mermaid',
    shells: 'Shells',
    ship: 'Ship',
    artistic: 'Artistic',
    allArtistic: 'All Artistic',
    brushStroke: 'Brush Stroke',
    handDrawn: 'Hand Drawn',
    photography: 'Photography',
    sketches: 'Sketches',
    splatterPaint: 'Splatter Paint',
    watercolor: 'Watercolor',
    shapes: 'Shapes',
    allShapes: 'All Shapes',
    arrows: 'Arrows',
    circle: 'Circle',
    cross: 'Cross',
    dots: 'Dots',
    fleurDeLis: 'Fleur de Lis',
    geometric: 'Geometric',
    grid: 'Grid',
    diamond: 'Diamond',
    hexagon: 'Hexagon',
    honeycombPattern: 'Honeycomb Pattern',
    pixel: 'Pixel',
    quatrefoil: 'Quatrefoil',
    trellis: 'Trellis',
    triangles: 'Triangles',
    texture: 'Texture',
    allTexture: 'All Texture',
    lace: 'Lace',
    linen: 'Linen',
    marble: 'Marble',
    shibori: 'Shibori',
    stainedGlass: 'Stained Glass',
    wood: 'Wood',
    woodgrain: 'Woodgrain',
    geography: 'Geography',
    allGeography: 'All Geography',
    asian: 'Asian',
    chinese: 'Chinese',
    scandinavian: 'Scandinavian',
    mexican: 'Mexican',
    paris: 'Paris',
    chinoiserie: 'Chinoiserie',
    indian: 'Indian',
    kawaii: 'Kawaii',
    african: 'African',
    london: 'London',
    hawaiian: 'Hawaiian',
    french: 'French',
    tropical: 'Tropical',
    japanese: 'Japanese',
    turkish: 'Turkish',
    worldly: 'Worldly',
    allWorldly: 'All Worldly',
    aztec: 'Aztec',
    bohemian: 'Bohemian',
    celtic: 'Celtic',
    ikat: 'Ikat',
    mandala: 'Mandala',
    mapleLeaf: 'Maple Leaf',
    moroccan: 'Moroccan',
    mudcloth: 'Mudcloth',
    nativeAmerican: 'Native American',
    persian: 'Persian',
    tribal: 'Tribal',
  },
  de: {
    modern: 'Modern',
    allModern: 'Alles Modern',
    abstract: 'Abstrakt',
    chevron: 'Chevron',
    hipster: 'Hipster',
    midCenturyModern: 'Mid-Century Modern',
    mod: 'Mod',
    ombre: 'Farbverlauf',
    traditional: 'Traditionell',
    allTraditional: 'Alles Traditionell',
    check: 'Karo',
    damask: 'Damast',
    gingham: 'Vichykaro',
    herringbone: 'Fischgrätenmuster',
    houndstooth: 'Hahnentritt',
    paisley: 'Paisley',
    plaid: 'Schottenkaro',
    preppy: 'Adrett',
    shabbyChic: 'Shabby Chic',
    toile: 'Toile de Jouy',
    historical: 'Historisch',
    allHistorical: 'Alles Historisch',
    artDeco: 'Artdeco',
    artNouveau: 'Jugendstil',
    gothic: 'Gothic',
    medieval: 'Mittelalter',
    popArt: 'Pop',
    renaissance: 'Renaissance',
    rococo: 'Rokoko',
    retro: 'Retro',
    steampunk: 'Steampunk',
    victorian: 'Viktorianisch',
    vintage: 'Vintage',
    '60s': '60er Jahre',
    '70s': '70er Jahre',
    '80s': '80er Jahre',
    '90s': '90er Jahre',
    allNautical: 'Alles Nautisch',
    anchors: 'Anker',
    boats: 'Boote',
    buoys: 'Bojen',
    knots: 'Knoten',
    lighthouse: 'Leuchtturm',
    lobster: 'Hummer',
    map: 'Landkarte',
    mermaid: 'Meerjungfrau',
    shells: 'Muscheln',
    ship: 'Schiff',
    artistic: 'Künstlerisch',
    allArtistic: 'Alles Künstlerisch',
    brushStroke: 'Pinselstrich',
    handDrawn: 'Handgemalt',
    photography: 'Fotografie',
    sketches: 'Skizzen',
    splatterPaint: 'Farbspritzer',
    watercolor: 'Wasserfarben',
    shapes: 'Geometrisch',
    allShapes: 'Alles Geometrisch',
    arrows: 'Pfeile',
    circle: 'Kreis',
    cross: 'Kreuz',
    dots: 'Punkte',
    fleurDeLis: 'Fleur de Lis',
    geometric: 'Geometrisch',
    grid: 'Grid',
    diamond: 'Rauten',
    hexagon: 'Wabenmuster',
    honeycombPattern: 'Honigwabenmuster',
    pixel: 'Pixel',
    quatrefoil: 'Vierpass',
    trellis: 'Gitter',
    triangles: 'Dreiecke',
    texture: 'Texturiert',
    allTexture: 'Alles Texturiert',
    lace: 'Spitze',
    linen: 'Leinen',
    marble: 'Marmor',
    shibori: 'Shibori',
    stainedGlass: 'Farbiges Glas',
    wood: 'Holz',
    woodgrain: 'Holzmaserung',
    geography: 'Geografisch',
    allGeography: 'Alles Geografisch',
    asian: 'Asiatisch',
    chinese: 'Chinesisch',
    scandinavian: 'Skandinavisch',
    mexican: 'Mexikaner',
    paris: 'Paris',
    chinoiserie: 'Chinoiserie',
    indian: 'Indianisch',
    kawaii: 'Kawaii',
    african: 'Afrikanisch',
    london: 'London',
    hawaiian: 'Hawaiisch',
    french: 'Französisch',
    tropical: 'Tropisch',
    japanese: 'Japanisch',
    turkish: 'Türkisch',
    worldly: 'Weltgewandt',
    allWorldly: 'Alles Weltgewandt',
    aztec: 'Aztekisch',
    bohemian: 'Bohemien',
    celtic: 'Keltisch',
    ikat: 'Ikat',
    mandala: 'Mandala',
    mapleLeaf: 'Ahornblatt',
    moroccan: 'Marokkanisch',
    mudcloth: 'Bogolan',
    nativeAmerican: 'Indianer',
    persian: 'Persisch',
    tribal: 'Indigen',
  },
  es: {
    modern: 'Moderno',
    allModern: 'Todos moderno',
    abstract: 'Abstracto',
    arrows: 'Flechas',
    artDeco: 'Art Déco',
    chevron: 'Cheurón',
    geometric: 'Geométrico',
    hipster: 'Hipster',
    midCenturyModern: 'Mid Century Modern',
    ikat: 'Ikat',
    mod: 'Contra',
    ombre: 'Degradado',
    traditional: 'Tradicional',
    allTraditional: 'Todos tradicional',
    check: 'Cuadros',
    chinoiserie: 'Chinoiserie',
    damask: 'Damasco',
    dots: 'Puntos',
    gingham: 'Vichy',
    herringbone: 'Espiga',
    houndstooth: 'Pata de gallo',
    paisley: 'Cachemir',
    plaid: 'Tartán',
    preppy: 'Cuadros elegantes',
    shabbyChic: 'Shabby Chic',
    stripes: 'Rayas',
    toile: 'Lienzo',
    vintage: 'Vintage',
    historical: 'Histórico',
    allHistorical: 'Todos histórico',
    artNouveau: 'Art Nouveau',
    gothic: 'Gótico',
    medieval: 'Medieval',
    popArt: 'Pop Art',
    renaissance: 'Renacimiento',
    rococo: 'Rococó',
    retro: 'Retro',
    steampunk: 'Steampunk',
    victorian: 'Victoriano',
    '60s': 'Años 60',
    '70s': 'Años 70',
    '80s': 'Años 80',
    '90s': 'Años 90',
    nautical: 'Náutico',
    allNautical: 'Todos náutico',
    anchors: 'Anclas',
    boats: 'Barcos',
    buoys: 'Boyas',
    knots: 'Nudos',
    lighthouse: 'Faro',
    lobster: 'Langosta',
    map: 'Mapa',
    mermaid: 'Sirena',
    octopus: 'Pulpo',
    shells: 'Conchas',
    ship: 'Navío',
    artistic: 'Artístico',
    allArtistic: 'Todos artístico',
    brushStroke: 'Pincelada',
    handDrawn: 'Pintado a mano',
    photography: 'Fotografia',
    sketches: 'Bocetos',
    splatterPaint: 'Salpicaduras de pintura',
    watercolor: 'Acuarela',
    shapes: 'Formas',
    allShapes: 'Todos formas',
    circle: 'Circulo',
    cross: 'Cruz',
    fleurDeLis: 'Flor de lis',
    grid: 'Cuadrícula',
    diamond: 'Rombo',
    hearts: 'Corazones',
    hexagon: 'Hexágono',
    honeycombPattern: 'Patrón de nido de abeja',
    pixel: 'Pixel',
    quatrefoil: 'Lóbulo',
    stars: 'Estrellas',
    trellis: 'Celosía',
    triangles: 'Triangulos',
    texture: 'Textura',
    allTexture: 'Todos textura',
    birchBark: 'Corteza de abedul',
    lace: 'Encaje',
    linen: 'Lino',
    marble: 'Mármol',
    shibori: 'Shibori',
    stainedGlass: 'Vidriera',
    wood: 'Madera',
    woodgrain: 'Efecto madera',
    geography: 'Geografía',
    allGeography: 'Todos geografía',
    asian: 'Asiático',
    chinese: 'Chino',
    scandinavian: 'Escandinavo',
    mexican: 'Mexicano',
    paris: 'París',
    indian: 'Indio',
    kawaii: 'Kawaii',
    african: 'Africano',
    london: 'Londres',
    hawaiian: 'Hawaiano',
    french: 'Francés',
    tropical: 'Tropical',
    japanese: 'Japonés',
    turkish: 'Turco',
    worldly: 'De mundo',
    allWorldly: 'Todos del mundo',
    aztec: 'Azteca',
    bohemian: 'Bohemio',
    celtic: 'Celta',
    mandala: 'Mandala',
    mapleLeaf: 'Hoja de arce',
    moroccan: 'Marroquí',
    mudcloth: 'Tela de barro',
    nativeAmerican: 'Nativo americano',
    persian: 'Persa',
    tribal: 'Tribal',
  },
  fr: {
    modern: 'Moderne',
    allModern: 'Tous Moderne',
    abstract: 'Abstrait',
    arrows: 'Flèches',
    artDeco: 'Art Déco',
    chevron: 'Chevrons',
    geometric: 'Géométrique',
    hipster: 'Hipster',
    midCenturyModern: 'Fifties',
    ikat: 'Ikat',
    mod: 'Mods',
    ombre: 'Ombré',
    traditional: 'Traditionnel',
    allTraditional: 'Tous Traditionnel',
    check: 'Carreaux',
    chinoiserie: 'Chinoiseries',
    damask: 'Damassé',
    dots: 'Pois',
    gingham: 'Vichy',
    herringbone: 'Chevrons',
    houndstooth: 'Pied-de-poule',
    paisley: 'Cachemire',
    plaid: 'Plaid',
    preppy: 'Preppy',
    shabbyChic: 'Chic décontracté',
    stripes: 'Rayures',
    toile: 'Toile',
    vintage: 'Vintage',
    historical: 'Historique',
    allHistorical: 'Tous Historique',
    artNouveau: 'Art Nouveau',
    gothic: 'Gothique',
    medieval: 'Médiéval',
    popArt: 'Pop Art',
    renaissance: 'Renaissance',
    rococo: 'Rococo',
    retro: 'Rétro',
    steampunk: 'Steampunk',
    victorian: 'Victorien',
    '60s': 'Sixties',
    '70s': 'Seventies',
    '80s': 'Eighties',
    '90s': 'Nineties',
    nautical: 'Nautique',
    allNautical: 'Tous Nautique',
    anchors: 'Ancres',
    boats: 'Bateaux',
    buoys: 'Bouées',
    knots: 'Noeuds',
    lighthouse: 'Phare',
    lobster: 'Homard',
    map: 'Carte',
    mermaid: 'Sirène',
    octopus: 'Poulpe',
    shells: 'Coquillages',
    ship: 'Navire',
    artistic: 'Artistique',
    allArtistic: 'Tous Artistique',
    brushStroke: 'Coup de pinceau',
    handDrawn: 'Fait main',
    photography: 'Photo',
    sketches: 'Croquis',
    splatterPaint: 'Éclaboussures',
    watercolor: 'Aquarelle',
    shapes: 'Formes',
    allShapes: 'Tous Formes',
    circle: 'Cercle',
    cross: 'Croix',
    fleurDeLis: 'Fleur de lys',
    grid: 'Grille',
    diamond: 'Losange',
    hearts: 'Cœurs',
    hexagon: 'Hexagone',
    honeycombPattern: "Nid d'abeille",
    pixel: 'Pixel',
    quatrefoil: 'Quatre-feuilles',
    stars: 'Etoiles',
    trellis: 'Treillis',
    triangles: 'Triangles',
    texture: 'Textures',
    allTexture: 'Tous Textures',
    birchBark: 'Écorce de bouleau',
    lace: 'Dentelle',
    linen: 'Lin',
    marble: 'Marbré',
    shibori: 'Shibori',
    stainedGlass: 'Verre teinté',
    wood: 'Bois',
    woodgrain: 'Grain de bois',
    geography: 'Géographie',
    allGeography: 'Tous Géographie',
    asian: 'Asiatique',
    chinese: 'Chinois',
    scandinavian: 'Scandinave',
    mexican: 'Mexicain',
    paris: 'Paris',
    indian: 'Indien',
    kawaii: 'Kawaii',
    african: 'Africain',
    london: 'Londres',
    hawaiian: 'Hawaïen',
    french: 'Français',
    tropical: 'Tropical',
    japanese: 'Japonais',
    turkish: 'Turc',
    worldly: 'Civilisation',
    allWorldly: 'Tous Civilisation',
    aztec: 'Aztèque',
    bohemian: 'Bohème',
    celtic: 'Celte',
    mandala: 'Mandala',
    mapleLeaf: "Feuille d'érable",
    moroccan: 'Marocain',
    mudcloth: 'Africain',
    nativeAmerican: "Indiens d'Amérique",
    persian: 'Persan',
    tribal: 'Tribal',
  },
};

export const TOPIC_FILTERS: {[key: string]: {[key: string]: string}} = {
  en: {
    animals: 'Animals',
    animalsMenu: 'All Animals',
    allAnimals: 'All Animals',
    woodlandAnimals: 'Woodland Animals',
    allWoodlandAnimals: 'All Woodland Animals',
    deer: 'Deer',
    fox: 'Fox',
    farm: 'Farm',
    allFarm: 'All Farm',
    chicken: 'Chicken',
    cows: 'Cows',
    donkey: 'Donkey',
    ducks: 'Ducks',
    goat: 'Goat',
    hen: 'Hen',
    horse: 'Horse',
    pig: 'Pig',
    sheep: 'Sheep',
    pets: 'Pets',
    allPets: 'All Pets',
    bird: 'Bird',
    cat: 'Cat',
    dog: 'Dog',
    fish: 'Fish',
    hamster: 'Hamster',
    snake: 'Snake',
    allOcean: 'All Ocean',
    crab: 'Crab',
    dolphin: 'Dolphin',
    jellyfish: 'Jellyfish',
    koi: 'Koi',
    octopus: 'Octopus',
    penguin: 'Penguin',
    seahorse: 'Seahorse',
    shark: 'Shark',
    starfish: 'Starfish',
    whale: 'Whale',
    insects: 'Insects',
    allInsects: 'All Insects',
    bee: 'Bee',
    beetle: 'Beetle',
    bug: 'Bug',
    butterfly: 'Butterfly',
    caterpillar: 'Caterpillar',
    dragonfly: 'Dragonfly',
    firefly: 'Firefly',
    allBirds: 'All Birds',
    owl: 'Owl',
    parrot: 'Parrot',
    peacock: 'Peacock',
    zoo: 'Zoo',
    allZoo: 'All Zoo',
    bears: 'Bears',
    llama: 'Llama',
    panda: 'Panda',
    safari: 'Safari',
    allSafari: 'All Safari',
    cheetah: 'Cheetah',
    elephant: 'Elephant',
    giraffe: 'Giraffe',
    hippo: 'Hippo',
    leopard: 'Leopard',
    lions: 'Lions',
    monkey: 'Monkey',
    rhino: 'Rhino',
    sloth: 'Sloth',
    tigers: 'Tigers',
    zebra: 'Zebra',
    dogBreeds: 'Dog Breeds',
    allDogBreeds: 'All Dog Breeds',
    bulldog: 'Bulldog',
    corgi: 'Corgi',
    dachshund: 'Dachshund',
    dalmatian: 'Dalmatian',
    goldenRetriever: 'Golden Retriever',
    greyhound: 'Greyhound',
    poodle: 'Poodle',
    pug: 'Pug',
    yorkie: 'Yorkie',
    nature: 'Nature',
    natureMenu: 'All Nature',
    allNature: 'All Nature',
    botanical: 'Botanical',
    allBotanical: 'All Botanical',
    cactus: 'Cactus',
    dandelion: 'Dandelion',
    fauna: 'Fauna',
    flora: 'Flora',
    flowers: 'Flowers',
    leaves: 'Leaves',
    palm: 'Palm',
    peony: 'Peony',
    poppy: 'Poppy',
    rose: 'Rose',
    seeds: 'Seeds',
    succulents: 'Succulents',
    sunflower: 'Sunflower',
    thistles: 'Thistles',
    trees: 'Trees',
    woodlandNature: 'Woodland Nature',
    allWoodlandNature: 'All Woodland Nature',
    allSpace: 'All Space',
    asteroids: 'Asteroids',
    astronaut: 'Astronaut',
    constellations: 'Constellations',
    galaxy: 'Galaxy',
    moon: 'Moon',
    nebula: 'Nebula',
    planets: 'Planets',
    sky: 'Sky',
    stars: 'Stars',
    sun: 'Sun',
    zodiac: 'Zodiac',
    weather: 'Weather',
    allWeather: 'All Weather',
    clouds: 'Clouds',
    ice: 'Ice',
    lightning: 'Lightning',
    rain: 'Rain',
    rainbow: 'Rainbow',
    storm: 'Storm',
    umbrella: 'Umbrella',
    feathers: 'Feathers',
    flamingo: 'Flamingo',
    hummingbird: 'Hummingbird',
    nest: 'Nest',
    robin: 'Robin',
    sparrow: 'Sparrow',
    toucan: 'Toucan',
    antler: 'Antler',
    bear: 'Bear',
    birchBark: 'Birch Bark',
    hedgehog: 'Hedgehog',
    moose: 'Moose',
    mushroom: 'Mushroom',
    rabbit: 'Rabbit',
    squirrel: 'Squirrel',
    stag: 'Stag',
    sea: 'Sea',
    allSea: 'All Sea',
    island: 'Island',
    ocean: 'Ocean',
    seaweed: 'Seaweed',
    surf: 'Surf',
    waves: 'Waves',
    outdoors: 'Outdoors',
    allOutdoors: 'All Outdoors',
    birch: 'Birch',
    campfire: 'Campfire',
    camping: 'Camping',
    desert: 'Desert',
    forest: 'Forest',
    jungle: 'Jungle',
    lake: 'Lake',
    mountain: 'Mountain',
    smores: 'Smores',
    teepee: 'Teepee',
    tents: 'Tents',
    novelty: 'Novelty',
    noveltyMenu: 'All Novelty',
    allNovelty: 'All Novelty',
    foodAndBeverage: 'Food & Beverage',
    allFood: 'All Food',
    banana: 'Banana',
    coffee: 'Coffee',
    cookies: 'Cookies',
    donuts: 'Donuts',
    fruit: 'Fruit',
    iceCream: 'Ice Cream',
    lemon: 'Lemon',
    pineapple: 'Pineapple',
    pizza: 'Pizza',
    popcorn: 'Popcorn',
    sprinkles: 'Sprinkles',
    strawberry: 'Strawberry',
    sushi: 'Sushi',
    watermelon: 'Watermelon',
    music: 'Music',
    allMusic: 'All Music',
    drums: 'Drums',
    guitar: 'Guitar',
    jazz: 'Jazz',
    musicNotes: 'Music Notes',
    piano: 'Piano',
    radio: 'Radio',
    rockAndRoll: 'Rock and Roll',
    symphony: 'Symphony',
    trumpets: 'Trumpets',
    violin: 'Violin',
    academia: 'Academia',
    allAcademia: 'All Academia',
    atomic: 'Atomic',
    book: 'Book',
    geek: 'Geek',
    math: 'Math',
    school: 'School',
    science: 'Science',
    teacher: 'Teacher',
    sewingNotions: 'Sewing Notions',
    allSewingNotions: 'All Sewing Notions',
    knitting: 'Knitting',
    sewingMachines: 'Sewing Machines',
    scissors: 'Scissors',
    tapeMeasure: 'Tape Measure',
    thimbles: 'Thimbles',
    thread: 'Thread',
    sports: 'Sports',
    allSports: 'All Sports',
    ballet: 'Ballet',
    baseball: 'Baseball',
    basketball: 'Basketball',
    bicycle: 'Bicycle',
    equestrian: 'Equestrian',
    fishing: 'Fishing',
    football: 'Football',
    golf: 'Golf',
    gymnastics: 'Gymnastics',
    soccer: 'Soccer',
    surfing: 'Surfing',
    tennis: 'Tennis',
    scary: 'Scary',
    allScary: 'All Scary',
    monster: 'Monster',
    skull: 'Skull',
    wolf: 'Wolf',
    transportation: 'Transportation',
    allTransportation: 'All Transportation',
    airplanes: 'Airplanes',
    bicycles: 'Bicycles',
    cars: 'Cars',
    construction: 'Construction',
    helicopters: 'Helicopters',
    hotAirBalloon: 'Hot Air Balloon',
    motorcycles: 'Motorcycles',
    retroCampers: 'Retro Campers',
    rocketShip: 'Rocket Ship',
    spaceShip: 'Space Ship',
    ships: 'Ships',
    tractors: 'Tractors',
    trucks: 'Trucks',
    occasionMenu: 'All Occasions',
    occasion: 'Occasion',
    babyAndKids: 'Baby & Kids',
    allChildren: 'All Children',
    alphabet: 'Alphabet',
    babyBoy: 'Baby Boy',
    babyGirl: 'Baby Girl',
    ballerina: 'Ballerina',
    circus: 'Circus',
    dinosaur: 'Dinosaur',
    doll: 'Doll',
    dragon: 'Dragon',
    fairy: 'Fairy',
    nursery: 'Nursery',
    pirate: 'Pirate',
    princess: 'Princess',
    robot: 'Robot',
    unicorn: 'Unicorn',
    birthday: 'Birthday',
    allBirthday: 'All Birthday',
    balloons: 'Balloons',
    cake: 'Cake',
    confetti: 'Confetti',
    cupcake: 'Cupcake',
    gifts: 'Gifts',
    presents: 'Presents',
    wedding: 'Wedding',
    graduation: 'Graduation',
    seasonal: 'Seasonal',
    seasonalMenu: 'All Seasonal',
    allSeasonal: 'All Seasonal',
    winter: 'Winter',
    allWinter: 'All Winter',
    chanukah: 'Chanukah',
    christmas: 'Christmas',
    holiday: 'Holiday',
    iceSkates: 'Ice Skates',
    penguins: 'Penguins',
    polarBears: 'Polar Bears',
    skiing: 'Skiing',
    snow: 'Snow',
    spring: 'Spring',
    allSpring: 'All Spring',
    birds: 'Birds',
    bunny: 'Bunny',
    crocus: 'Crocus',
    daffodil: 'Daffodil',
    floral: 'Floral',
    tulips: 'Tulips',
    summer: 'Summer',
    allSummer: 'All Summer',
    beach: 'Beach',
    july4th: 'July 4th',
    summerIceCream: 'Ice Cream',
    lemonade: 'Lemonade',
    popsicle: 'Popsicle',
    tiki: 'Tiki',
    fall: 'Fall',
    allFall: 'All Fall',
    apple: 'Apple',
    autumn: 'Autumn',
    backpack: 'Backpack',
    cider: 'Cider',
    halloween: 'Halloween',
    woodlandFall: 'Woodland Fall',
    seasonalHolidays: 'Holidays',
    menorah: 'Menorah',
    pumpkins: 'Pumpkins',
    santaClaus: 'Santa Claus',
    spider: 'Spider',
    starsAndStripes: 'Stars & Stripes',
    supernatural: 'Supernatural',
    witches: 'Witches',
    zombies: 'Zombies',
    holidays: 'Holidays',
    holidaysMenu: 'All Holidays',
    allHolidays: 'All Holidays',
    allChristmas: 'All Christmas',
    christmasTree: 'Christmas Tree',
    holly: 'Holly',
    pinecones: 'Pinecones',
    reindeer: 'Reindeer',
    retroChristmas: 'Retro Christmas',
    santa: 'Santa',
    sled: 'Sled',
    sleigh: 'Sleigh',
    snowmen: 'Snowmen',
    stocking: 'Stocking',
    allJuly4th: 'All July 4th',
    americanFlag: 'American Flag',
    cookout: 'Cookout',
    fireworks: 'Fireworks',
    hamburgers: 'Hamburgers',
    hotdogs: 'Hotdogs',
    independenceDay: 'Independence Day',
    patriotic: 'Patriotic',
    picnic: 'Picnic',
    redWhiteAndBlue: 'Red, White, & Blue',
    stripes: 'Stripes',
    valentinesDay: 'Valentine’s Day',
    allValentinesDay: 'All Valentine’s Day',
    candyHearts: 'Candy Hearts',
    cupid: 'Cupid',
    hearts: 'Hearts',
    love: 'Love',
    allHalloween: 'All Halloween',
    bat: 'Bat',
    blackCats: 'Black Cats',
    candy: 'Candy',
    ghost: 'Ghost',
    'jack-o-lantern': 'Jack-o-lantern',
    pumpkin: 'Pumpkin',
    scarecrow: 'Scarecrow',
    spiderWeb: 'Spider Web',
    sugarSkulls: 'Sugar Skulls',
    vampire: 'Vampire',
    witch: 'Witch',
    zombie: 'Zombie',
    projects: 'Projects',
    projectMenu: 'All Projects',
    allProjects: 'All Projects',
    bags: 'Bags',
    border: 'Border',
    bunting: 'Bunting',
    calendarTeaTowels: 'Calendar Tea Towels',
    cheaterQuilts: 'Cheater Quilts',
    cutAndSew: 'Cut and Sew',
    pencilPouch: 'Pencil Pouch',
    plushies: 'Plushies',
    quilt: 'Quilt',
    quiltLabels: 'Quilt Labels',
    stockings: 'Stockings',
    teaTowels: 'Tea Towels',
    zipperPouch: 'Zipper Pouch',
  },
  de: {
    animals: 'Tiermotive',
    animalsMenu: 'Alles Tiere',
    allAnimals: 'Alles Tiere',
    woodlandAnimals: 'Waldtiere',
    allWoodlandAnimals: 'Alles Waldtiere',
    allWoodland: 'Alles Wald',
    deer: 'Hirsch',
    fox: 'Fuchs',
    farm: 'Bauernhof',
    allFarm: 'Alles Bauernhof',
    chicken: 'Huhn',
    cows: 'Kühe',
    donkey: 'Esel',
    ducks: 'Enten',
    goat: 'Ziege',
    hen: 'Henne',
    horse: 'Pferd',
    pig: 'Schwein',
    sheep: 'Schaf',
    pets: 'Haustiere',
    allPets: 'Alles Haustiere',
    bird: 'Vogel',
    cat: 'Katze',
    dog: 'Hund',
    fish: 'Fisch',
    hamster: 'Hamster',
    snake: 'Schlange',
    allOcean: 'Alles Ozean',
    crab: 'Krabbe',
    dolphin: 'Delphin',
    jellyfish: 'Qualle',
    koi: 'Koi',
    octopus: 'Krake',
    penguin: 'Pinguin',
    seahorse: 'Seepferdchen',
    shark: 'Hai',
    starfish: 'Seestern',
    whale: 'Wal',
    insects: 'Insekten',
    allInsects: 'Alles Insekten',
    bee: 'Biene',
    beetle: 'Käfer',
    bug: 'Insekt',
    butterfly: 'Schmetterling',
    caterpillar: 'Raupe',
    dragonfly: 'Libelle',
    firefly: 'Glühwürmchen',
    allBirds: 'Alles Vögel',
    owl: 'Eule',
    parrot: 'Papagei',
    peacock: 'Pfau',
    zoo: 'Zoo',
    allZoo: 'Alles Zoo',
    bears: 'Bären',
    llama: 'Lama',
    panda: 'Panda',
    safari: 'Safari',
    allSafari: 'Alles Safari',
    cheetah: 'Gepard',
    elephant: 'Elefant',
    giraffe: 'Giraffe',
    hippo: 'Nilpferd',
    leopard: 'Leopard',
    lions: 'Löwen',
    monkey: 'Affe',
    rhino: 'Nashorn',
    sloth: 'Faultier',
    tigers: 'Tiger',
    zebra: 'Zebra',
    dogBreeds: 'Hunderassen',
    allDogBreeds: 'Alles Hunderassen',
    bulldog: 'Bulldogge',
    corgi: 'Corgi',
    dachshund: 'Dackel',
    dalmatian: 'Dalmatiner',
    goldenRetriever: 'Golden Retriever',
    greyhound: 'Windhund',
    poodle: 'Pudel',
    pug: 'Mops',
    yorkie: 'Yorkshire',
    nature: 'Natur',
    natureMenu: 'Alles Natur',
    allNature: 'Alles Natur',
    woodlandNature: 'Wald Natur',
    allWoodlandNature: 'Alles Wald Natur',
    botanical: 'Botanisch',
    allBotanical: 'Alles Botanisch',
    cactus: 'Kaktus',
    dandelion: 'Löwenzahn',
    fauna: 'Fauna',
    flora: 'Flora',
    flowers: 'Blumen',
    leaves: 'Blätter',
    palm: 'Palme',
    peony: 'Pfingstrose',
    poppy: 'Mohn',
    rose: 'Rose',
    seeds: 'Samen',
    succulents: 'Sukkulenten',
    sunflower: 'Sonnenblume',
    thistles: 'Disteln',
    trees: 'Bäume',
    allSpace: 'Alles Weltraum',
    asteroids: 'Asteroiden',
    astronaut: 'Astronaut',
    constellations: 'Sternbilder',
    galaxy: 'Galaxie',
    moon: 'Mond',
    nebula: 'Sternennebel',
    planets: 'Planeten',
    sky: 'Himmel',
    stars: 'Sterne',
    sun: 'Sonne',
    zodiac: 'Sternzeichen',
    weather: 'Wetter',
    allWeather: 'Alles Wetter',
    clouds: 'Wolken',
    ice: 'Eis',
    lightning: 'Blitz',
    rain: 'Regen',
    rainbow: 'Regenbogen',
    storm: 'Sturm',
    umbrella: 'Regenschirm',
    feathers: 'Federn',
    flamingo: 'Flamingo',
    hummingbird: 'Kolibri',
    nest: 'Nest',
    robin: 'Rotkehlchen',
    sparrow: 'Spatz',
    toucan: 'Tukan',
    antler: 'Geweih',
    bear: 'Bär',
    birchBark: 'Birkenrinde',
    hedgehog: 'Igel',
    moose: 'Elch',
    mushroom: 'Pilz',
    rabbit: 'Kaninchen',
    squirrel: 'Eichhörnchen',
    stag: 'Hirsch',
    sea: 'Meer',
    allSea: 'Alles Meer',
    island: 'Insel',
    ocean: 'Ozean',
    seaweed: 'Seetang',
    surf: 'Surfen',
    waves: 'Wellen',
    outdoors: 'Outdoor',
    allOutdoors: 'Alles Outdoor',
    birch: 'Birke',
    campfire: 'Lagerfeuer',
    camping: 'Zelten',
    desert: 'Wüste',
    forest: 'Wald',
    jungle: 'Dschungel',
    lake: 'See',
    mountain: 'Berg',
    smores: 'Smores',
    teepee: 'Tipi',
    tents: 'Zelte',
    novelty: 'Krimskrams',
    noveltyMenu: 'Alles Krimskrams',
    allNovelty: 'Alles Krimskrams',
    foodAndBeverage: 'Essen & Trinken',
    allFood: 'Alles Essen',
    banana: 'Banane',
    coffee: 'Kaffee',
    cookies: 'Kekse',
    donuts: 'Donuts',
    fruit: 'Obst',
    iceCream: 'Eiscreme',
    lemon: 'Zitrone',
    pineapple: 'Ananas',
    pizza: 'Pizza',
    popcorn: 'Popcorn',
    sprinkles: 'Streusel',
    strawberry: 'Erdbeere',
    sushi: 'Sushi',
    watermelon: 'Wassermelone',
    music: 'Musik',
    allMusic: 'Alles Musik',
    drums: 'Schlagzeug',
    guitar: 'Gitarre',
    jazz: 'Jazz',
    musicNotes: 'Noten',
    piano: 'Klavier',
    radio: 'Radio',
    rockAndRoll: "Rock'n'Roll",
    symphony: 'Symphonie',
    trumpets: 'Trompeten',
    violin: 'Geige',
    academia: 'Akademisch',
    allAcademia: 'Alles Akademisch',
    atomic: 'Atom',
    book: 'Buch',
    geek: 'Nerd',
    math: 'Mathe',
    school: 'Schule',
    science: 'Wissenschaft',
    teacher: 'Lehrer',
    sewingNotions: 'Nähzutaten',
    allSewingNotions: 'Alles Nähzutaten',
    knitting: 'Stricken',
    sewingMachines: 'Nähmaschinen',
    scissors: 'Schere',
    tapeMeasure: 'Maßband',
    thimbles: 'Fingerhüte',
    thread: 'Garn',
    sports: 'Sport',
    allSports: 'Alles Sport',
    ballet: 'Ballett',
    baseball: 'Baseball',
    basketball: 'Basketball',
    bicycle: 'Fahrrad',
    equestrian: 'Reiten',
    fishing: 'Angeln',
    football: 'Football',
    golf: 'Golf',
    gymnastics: 'Gymnastik',
    soccer: 'Fußball',
    surfing: 'Surfen',
    tennis: 'Tennis',
    scary: 'Grusel',
    allScary: 'Alles Grusel',
    monster: 'Monster',
    skull: 'Totenkopf',
    wolf: 'Wolf',
    transportation: 'Verkehr',
    allTransportation: 'Alles Verkehr',
    airplanes: 'Flugzeuge',
    bicycles: 'Fahrräder',
    cars: 'Autos',
    construction: 'Baustelle',
    helicopters: 'Hubschrauber',
    hotAirBalloon: 'Heißluftballon',
    motorcycles: 'Motorräder',
    retroCampers: 'Retro Camper',
    rocketShip: 'Raketenschiff',
    spaceShip: 'Raumschiff',
    ships: 'Schiffe',
    tractors: 'Traktoren',
    trucks: 'LKWs',
    occasionMenu: 'Besondere Anlässe',
    occasion: 'Besondere Anlässe',
    babyAndKids: 'Babys & Kinder',
    allChildren: 'Alles Kinder',
    alphabet: 'Alphabet',
    babyBoy: 'Junge',
    babyGirl: 'Mädchen',
    ballerina: 'Ballerina',
    circus: 'Zirkus',
    dinosaur: 'Dinosaurier',
    doll: 'Puppe',
    dragon: 'Drache',
    fairy: 'Fee',
    nursery: 'Kinderzimmer',
    pirate: 'Pirat',
    princess: 'Prinzessin',
    robot: 'Roboter',
    unicorn: 'Einhorn',
    birthday: 'Geburtstag',
    allBirthday: 'Alles Geburtstag',
    balloons: 'Luftballons',
    cake: 'Kuchen',
    confetti: 'Konfetti',
    cupcake: 'Cupcake',
    gifts: 'Geschenke',
    presents: 'Present',
    wedding: 'Hochzeit',
    graduation: 'Schulabschluss',
    seasonal: 'Jahreszeiten',
    seasonalMenu: 'Alle Jahreszeiten',
    allSeasonal: 'Alle Jahreszeiten',
    winter: 'Winter',
    allWinter: 'Alles Winter',
    chanukah: 'Chanukka',
    christmas: 'Weihnachten',
    holiday: 'Urlaub',
    iceSkates: 'Schlittschuh',
    penguins: 'Pinguine',
    polarBears: 'Eisbären',
    skiing: 'Ski',
    snow: 'Schnee',
    spring: 'Frühling',
    allSpring: 'Alles Frühling',
    birds: 'Vögel',
    bunny: 'Hase',
    crocus: 'Krokus',
    daffodil: 'Narzisse',
    floral: 'Blumen',
    tulips: 'Tulpen',
    summer: 'Sommer',
    allSummer: 'Alles Sommer',
    beach: 'Strand',
    july4th: 'July 4th',
    summerIceCream: 'Eis',
    lemonade: 'Limonade',
    popsicle: 'Eis am Stiel',
    tiki: 'Tiki',
    fall: 'Herbst',
    allFall: 'Alles Herbst',
    apple: 'Apfel',
    autumn: 'Herbst',
    backpack: 'Rucksack',
    cider: 'Cider',
    halloween: 'Halloween',
    woodlandFall: 'Wald Herbst',
    seasonalHolidays: 'Feiertag',
    menorah: 'Menora',
    pumpkins: 'Kürbis',
    santaClaus: 'Weihnachtsmann',
    spider: 'Spinne',
    starsAndStripes: 'Sterne und Streifen',
    supernatural: 'Übernatürlich',
    witches: 'Hexen',
    zombies: 'Zombies',
    holidays: 'Feiertag',
    holidaysMenu: 'Alle Feiertage',
    allHolidays: 'Alle Feiertage',
    allChristmas: 'Alles Weihnachten',
    christmasTree: 'Weihnachtsbaum',
    holly: 'Stechpalme',
    pinecones: 'Kiefernzapfen',
    reindeer: 'Rentier',
    retroChristmas: 'Retro Weihnachten',
    santa: 'Weihnachtsmann',
    sled: 'Sled',
    sleigh: 'Schlitten',
    snowmen: 'Schneemänner',
    stocking: 'Strumpf',
    allJuly4th: 'Alles Independence Day',
    americanFlag: 'Amerikanische Flagge',
    cookout: 'Grill',
    fireworks: 'Feuerwerk',
    hamburgers: 'Hamburger',
    hotdogs: 'Hotdogs',
    independenceDay: 'Unabhängigkeitstag',
    patriotic: 'Patriotisch',
    picnic: 'Picknick',
    redWhiteAndBlue: 'Rot, Weiß, & Blau',
    stripes: 'Streifen',
    valentinesDay: 'Valentinstag',
    allValentinesDay: 'Alles Valentinstag',
    candyHearts: 'Süßigkeiten Herzen',
    cupid: 'Amor',
    hearts: 'Herzen',
    love: 'Liebe',
    allHalloween: 'Alles Halloween',
    bat: 'Fledermaus',
    blackCats: 'Schwarze Katzen',
    candy: 'Süßigkeiten',
    ghost: 'Geist',
    'jack-o-lantern': 'Ausgehöhlter Kürbis',
    pumpkin: 'Kürbis',
    scarecrow: 'Vogelscheuche',
    spiderWeb: 'Spinnennetz',
    sugarSkulls: 'Sugar Skulls',
    vampire: 'Vampir',
    witch: 'Hexe',
    zombie: 'Zombie',
    projects: 'Projekte',
    projectMenu: 'Alle Projekte',
    allProjects: 'Alle Projekte',
    bags: 'Taschen',
    border: 'Bordüre',
    bunting: 'Girlande',
    calendarTeaTowels: 'Geschirrtuch-Kalender',
    cheaterQuilts: 'Quilt Optik',
    cutAndSew: 'Cut and Sew',
    pencilPouch: 'Federtasche',
    plushies: 'Kuscheltiere',
    quilt: 'Quilt',
    quiltLabels: 'Quiltetiketten',
    stockings: 'Weihnachtsstrümpfe',
    teaTowels: 'Geschirrtücher',
    zipperPouch: 'Mäppchen',
  },
  es: {
    animals: 'Animales',
    animalsMenu: 'Todos animales',
    allAnimals: 'Todos animales',
    woodlandAnimals: 'Animales del bosque',
    allWoodlandAnimals: 'Todos los animales del bosque',
    bat: 'Murciélago',
    bear: 'Oso',
    deer: 'Ciervo',
    fox: 'Zorro',
    hedgehog: 'Erizo',
    moose: 'Alce',
    owl: 'Búho',
    rabbit: 'Rabbit',
    squirrel: 'Ardilla',
    stag: 'Venado',
    wolf: 'Lobo',
    farm: 'Granja',
    allFarm: 'Todos granja',
    chicken: 'Pollo',
    cows: 'Vacas',
    donkey: 'Burro',
    ducks: 'Patos',
    equestrian: 'Equitación',
    goat: 'Cabra',
    hen: 'Gallina',
    horse: 'Caballo',
    llama: 'Llama',
    pig: 'Cerdo',
    sheep: 'Oveja',
    pets: 'Mascotas',
    allPets: 'Todos mascotas',
    bird: 'Pájaro',
    cat: 'Gato',
    dog: 'Perro',
    fish: 'Pez',
    hamster: 'Hámster',
    parrot: 'Loro',
    snake: 'Serpiente',
    spider: 'Araña',
    allOcean: 'Todos oceánico',
    crab: 'Cangrejo',
    dolphin: 'Delfín',
    jellyfish: 'Medusa',
    koi: 'Carpa',
    penguin: 'Pingüino',
    seahorse: 'Caballito de mar',
    shark: 'Tiburón',
    starfish: 'Estrella de mar',
    whale: 'Ballena',
    insects: 'Insectos',
    allInsects: 'Todos insectos',
    bee: 'Abeja',
    beetle: 'Escarabajo',
    bug: 'Bicho',
    butterfly: 'Mariposa',
    caterpillar: 'Oruga',
    dragonfly: 'Libélula',
    firefly: 'Luciérnaga',
    allBirds: 'Todos los pájaros',
    flamingo: 'Flamenco',
    hummingbird: 'Colibrí',
    peacock: 'Pavo real',
    robin: 'Petirrojo',
    sparrow: 'Gorrión',
    toucan: 'Tucán',
    zoo: 'Zoo',
    allZoo: 'Todos zoo',
    bears: 'Osos',
    giraffe: 'Jirafa',
    hippo: 'Hipopótamo',
    lions: 'Leones',
    monkey: 'Mono',
    panda: 'Oso panda',
    rhino: 'Rinoceronte',
    tigers: 'Tigres',
    zebra: 'Cebra',
    safari: 'Safari',
    allSafari: 'Todos safari',
    cheetah: 'Guepardo',
    elephant: 'Elefante',
    leopard: 'Leopardo',
    sloth: 'Perezoso',
    dogBreeds: 'Razas de perros',
    allDogBreeds: 'Todos razas de perros',
    bulldog: 'Bulldog',
    corgi: 'Corgi',
    dachshund: 'Perro salchicha',
    dalmatian: 'Dálmata',
    goldenRetriever: 'Golden Retriever',
    greyhound: 'Galgo',
    poodle: 'Caniche',
    pug: 'Pug',
    yorkie: 'Yorkie',
    nature: 'Naturaleza',
    natureMenu: 'Todos naturaleza',
    allNature: 'Todos naturaleza',
    botanical: 'Botánico',
    allBotanical: 'Todos botánico',
    cactus: 'Cactus',
    dandelion: 'Diente de león',
    fauna: 'Fauna',
    feathers: 'Plumas',
    flora: 'Flora',
    flowers: 'Flores',
    fruit: 'Fruta',
    leaves: 'Hojas',
    mushroom: 'Setas',
    palm: 'Palmera',
    peony: 'Peonía',
    poppy: 'Amapola',
    rose: 'Rosa',
    seeds: 'Semillas',
    succulents: 'Suculentas',
    sunflower: 'Girasol',
    thistles: 'Cardos',
    trees: 'Árboles',
    woodlandNature: 'Silvestre',
    allWoodlandNature: 'Todos silvestre',
    space: 'Espacio',
    allSpace: 'Todos espacio',
    asteroids: 'Asteroides',
    astronaut: 'Astronauta',
    constellations: 'Constelaciones',
    galaxy: 'Galaxia',
    moon: 'Luna',
    nebula: 'Nebulosa',
    planets: 'Planetas',
    sky: 'Cielo',
    sun: 'Sol',
    zodiac: 'Zodiaco',
    weather: 'Clima',
    allWeather: 'Todos clima',
    clouds: 'Nubes',
    ice: 'Hielo',
    lightning: 'Rayos',
    rain: 'Lluvia',
    rainbow: 'Arcoiris',
    snow: 'Nieve',
    storm: 'Tormenta',
    umbrella: 'Paraguas',
    sea: 'Mar',
    allSea: 'Todos marítimo',
    coral: 'Coral',
    island: 'Isla',
    ocean: 'Océano',
    sand: 'Arena',
    seaweed: 'Algas',
    surf: 'Surf',
    waves: 'Olas',
    outdoors: 'Aire libre',
    allOutdoors: 'Todos aire libre',
    birch: 'Abedul',
    campfire: 'Fogata',
    camping: 'Camping',
    desert: 'Desierto',
    forest: 'Bosque',
    jungle: 'Selva',
    lake: 'Lago',
    mountain: 'Montaña',
    smores: 'Smores',
    teepee: 'Tipi',
    tents: 'Tiendas',
    novelty: 'Novedad',
    noveltyMenu: 'Todos novedad',
    allNovelty: 'Todos novedad',
    foodAndBeverage: 'Comida y bebida',
    allFood: 'Todos comida y bebida',
    banana: 'Plátano',
    candy: 'Caramelos',
    coffee: 'Café',
    cookies: 'Galletas',
    donuts: 'Donuts',
    iceCream: 'Helado',
    lemon: 'Limón',
    pineapple: 'Piña',
    pizza: 'Pizza',
    popcorn: 'Palomitas',
    popsicle: 'Polo',
    sprinkles: 'Virutas',
    strawberry: 'Fresa',
    sushi: 'Sushi',
    watermelon: 'Sandía',
    music: 'Música',
    allMusic: 'Todos música',
    drums: 'Tambores',
    guitar: 'Guitarra',
    jazz: 'Jazz',
    musicNotes: 'Notas musicales',
    piano: 'Piano',
    radio: 'Radio',
    rockAndRoll: 'Rock and Roll',
    symphony: 'Sinfonía',
    trumpets: 'Trompetas',
    violin: 'Violín',
    academia: 'Mundo académico',
    allAcademia: 'Todos academia',
    atomic: 'Atómico',
    book: 'Libro',
    geek: 'Cerebrito',
    math: 'Matemáticas',
    school: 'Colegio',
    science: 'Ciencia',
    teacher: 'Profesor',
    sewingNotions: 'Costura',
    allSewingNotions: 'Todos costura',
    knitting: 'Tejer',
    sewingMachines: 'Máquinas de coser',
    scissors: 'Tijeras',
    tapeMeasure: 'Cinta métrica',
    thimbles: 'Dedales',
    thread: 'Hilo',
    sports: 'Deporte',
    allSports: 'Todos deporte',
    ballet: 'Ballet',
    baseball: 'Baseball',
    basketball: 'Baloncesto',
    bicycle: 'Ciclismo',
    fishing: 'Pesca',
    football: 'Fúbol americano',
    golf: 'Golf',
    gymnastics: 'Gimnasia',
    soccer: 'Fútbol',
    surfing: 'Surf',
    tennis: 'Tenis',
    scary: 'Terror',
    allScary: 'Todos terror',
    ghost: 'Fantasma',
    monster: 'Monstruo',
    skull: 'Calavera',
    zombie: 'Zombie',
    transportation: 'Transporte',
    allTransportation: 'Todos transporte',
    airplanes: 'Aviones',
    bicycles: 'Bicicletas',
    cars: 'Coches',
    construction: 'Construcción',
    helicopters: 'Helicopteros',
    hotAirBalloon: 'Globo aerostático',
    motorcycles: 'Motocicletas',
    retroCampers: 'Caravanas retro',
    rocketShip: 'Cohete',
    spaceShip: 'Nave espacial',
    ships: 'Barcos',
    tractors: 'Tractores',
    trucks: 'Camiones',
    occasionMenu: 'Eventos',
    occasion: 'Eventos',
    babyAndKids: 'Bebés y niños',
    allChildren: 'Todos bebés y niños',
    alphabet: 'Abecedario',
    babyBoy: 'Niño',
    babyGirl: 'Niña',
    ballerina: 'Bailarina',
    circus: 'Circo',
    dinosaur: 'Dinosaurio',
    doll: 'Muñeca',
    dragon: 'Dragón',
    fairy: 'Hada',
    nursery: 'Cuarto del bebé',
    pirate: 'Pirata',
    princess: 'Princesa',
    robot: 'Robot',
    unicorn: 'Unicornio',
    birthday: 'Cumpleaños',
    allBirthday: 'Todos cumpleaños',
    balloons: 'Globos',
    cake: 'Tarta',
    confetti: 'Confeti',
    cupcake: 'Cupcake',
    gifts: 'Regalos',
    presents: 'Regalos',
    picnic: 'Picnic',
    wedding: 'Boda',
    graduation: 'Graduación',
    seasonal: 'Estacional',
    seasonalMenu: 'Todos estacional',
    allSeasonal: 'Todos estacional',
    winter: 'Invierno',
    allWinter: 'Todos invierno',
    antler: 'Cornamenta',
    chanukah: 'Chanukah',
    christmas: 'Navidad',
    holiday: 'Día festivo',
    holly: 'Acebo',
    iceSkates: 'Patines de hielo',
    penguins: 'Pingüinos',
    polarBears: 'Ososo polares',
    skiing: 'Esquí',
    stockings: 'Calcetines',
    spring: 'Primavera',
    allSpring: 'Todos primavera',
    birds: 'Pájaros',
    bunny: 'Conejo',
    crocus: 'Azafrán',
    daffodil: 'Narciso',
    floral: 'Floral',
    tulips: 'Tulipanes',
    summer: 'Verano',
    allSummer: 'Todos verano',
    beach: 'Playa',
    july4th: '4 de julio',
    summerIceCream: 'Helado',
    lemonade: 'Limonada',
    tiki: 'Tiki',
    fall: 'Otoño',
    allFall: 'Todos otoño',
    apple: 'Manzana',
    autumn: 'Otoño',
    backpack: 'Mochila',
    cider: 'Sidra',
    halloween: 'Halloween',
    pumpkins: 'Calabazas',
    woodlandFall: 'Bosque otoñal',
    seasonalHolidays: 'Festividades',
    menorah: 'Menorá',
    santaClaus: 'Papá Noel',
    starsAndStripes: 'Estrellas y rayas',
    supernatural: 'Sobrenatural',
    witches: 'Brujas',
    zombies: 'Zombies',
    holidays: 'Vacaciones',
    holidaysMenu: 'Todos vacaciones',
    allHolidays: 'Todos vacaciones',
    allChristmas: 'Todos navidad',
    christmasTree: 'Árbol de Navidad',
    pinecones: 'Piñas',
    reindeer: 'Reno',
    retroChristmas: 'Retro',
    santa: 'Papá Noel',
    sled: 'Trineo',
    sleigh: 'Trineo',
    snowmen: 'Muñeco de nieve',
    stocking: 'Calcetines',
    allJuly4th: 'Todos 4 de Julio',
    americanFlag: 'Bandera americada',
    cookout: 'Parrillada',
    fireworks: 'Fuegos artificiales',
    hamburgers: 'Hamburguesas',
    hotdogs: 'Perritos calientes',
    independenceDay: 'Díad de la independencia',
    patriotic: 'Patriota',
    redWhiteAndBlue: 'Rojo, blanco y azul',
    valentinesDay: 'San Valentín',
    allValentinesDay: 'Todos san Valentín',
    candyHearts: 'Corazones de caramelo',
    cupid: 'Cupido',
    love: 'Amor',
    allHalloween: 'Todos halloween',
    blackCats: 'Gatos negros',
    'jack-o-lantern': 'Linterna de calabaza',
    pumpkin: 'Calabazas',
    scarecrow: 'Cuervo',
    spiderWeb: 'Tela de araña',
    sugarSkulls: 'Calavera de alfeñique',
    vampire: 'Vampiro',
    witch: 'Brujas',
    projects: 'Proyectos',
    projectMenu: 'Todos proyectos',
    allProjects: 'Todos proyectos',
    bags: 'Bolsos',
    border: 'Ribetes',
    bunting: 'Banderines',
    calendarTeaTowels: 'Paños de cocina con calendario',
    cheaterQuilts: 'Colchas fáciles',
    cutAndSew: 'Cortar y coser',
    pencilPouch: 'Estuche',
    plushies: 'Peluches',
    quilt: 'Edredón',
    quiltLabels: 'Etiquetas de edredón',
    teaTowels: 'Pañols de cocina',
  },
  fr: {
    animals: 'Animaux',
    animalsMenu: 'Tous Animaux',
    allAnimals: 'Tous Animaux',
    woodlandAnimals: 'Forêt Animaux',
    allWoodlandAnimals: 'Tous Forêt Animaux',
    allWoodland: 'Tous Forêt',
    bat: 'Chauve-souris',
    bear: 'Ours',
    deer: 'Cerf',
    fox: 'Renard',
    hedgehog: 'Hérisson',
    moose: 'Élan',
    owl: 'Hibou',
    rabbit: 'Lapin',
    squirrel: 'Écureuil',
    stag: 'Cerf',
    wolf: 'Loup',
    farm: 'Ferme',
    allFarm: 'Tous Ferme',
    chicken: 'Poulet',
    cows: 'Vache',
    donkey: 'Âne',
    ducks: 'Canard',
    equestrian: 'Équitation',
    goat: 'Chèvre',
    hen: 'Poule',
    horse: 'Cheval',
    llama: 'Lama',
    pig: 'Porc',
    sheep: 'Mouton',
    pets: 'Domestiques',
    allPets: 'All Pets',
    bird: 'Oiseau',
    cat: 'Chat',
    dog: 'Chien',
    fish: 'Poisson',
    hamster: 'Hamster',
    parrot: 'Perroquet',
    snake: 'Serpent',
    spider: 'Araignée',
    allOcean: 'Tous Océan',
    crab: 'Crabe',
    dolphin: 'Dauphin',
    jellyfish: 'Méduse',
    koi: 'Carpe koï',
    penguin: 'Pingouin',
    seahorse: 'Hippocampe',
    shark: 'Requin',
    starfish: 'Étoile de mer',
    whale: 'Baleine',
    insects: 'Insectes',
    allInsects: 'Tous Insectes',
    bee: 'Abeille',
    beetle: 'Coccinelle',
    bug: 'Punaise',
    butterfly: 'Papillon',
    caterpillar: 'Chenille',
    dragonfly: 'Libellule',
    firefly: 'Luciole',
    allBirds: 'Tous Oiseaux',
    flamingo: 'Flamant rose',
    hummingbird: 'Colibri',
    nest: 'Nid',
    peacock: 'Paon',
    robin: 'Rouge-gorge',
    sparrow: 'Moineau',
    toucan: 'Toucan',
    zoo: 'Zoo',
    allZoo: 'Tous Zoo',
    bears: 'Ours',
    giraffe: 'Girafe',
    hippo: 'Hippopotame',
    lions: 'Lion',
    monkey: 'Singe',
    panda: 'Panda',
    rhino: 'Rhinocéros',
    tigers: 'Tigre',
    zebra: 'Zèbre',
    safari: 'Safari',
    allSafari: 'Tous Safari',
    cheetah: 'Guépard',
    elephant: 'Eléphant',
    leopard: 'Léopard',
    sloth: 'Paresseux',
    dogBreeds: 'Chiens',
    allDogBreeds: 'Tous Chiens',
    bulldog: 'Bouledogue',
    corgi: 'Corgi',
    dachshund: 'Teckel',
    dalmatian: 'Dalmatien',
    goldenRetriever: 'Golden Retriever',
    greyhound: 'Lévrier',
    poodle: 'Caniche',
    pug: 'Carlin',
    yorkie: 'Yorkshire',
    nature: 'Nature',
    natureMenu: 'Tous Nature',
    allNature: 'Tous Nature',
    woodlandNature: 'Forêt Nature',
    allWoodlandNature: 'Tous Forêt Nature',
    botanical: 'Botanique',
    allBotanical: 'Tous Botanique',
    cactus: 'Cactus',
    dandelion: 'Pissenlit',
    fauna: 'Faune',
    feathers: 'Plumes',
    flora: 'Flore',
    flowers: 'Fleurs',
    fruit: 'Fruta',
    leaves: 'Feuilles',
    mushroom: 'Champignon',
    palm: 'Palme',
    peony: 'Pivoine',
    poppy: 'Coquelicot',
    rose: 'Rose',
    seeds: 'Graines',
    succulents: 'Succulents',
    sunflower: 'Tournesol',
    thistles: 'Chardons',
    trees: 'Arbres',
    space: 'Espace',
    allSpace: 'Tous Espace',
    asteroids: 'Astéroïdes',
    astronaut: 'Astronaute',
    constellations: 'Constellations',
    galaxy: 'Galaxie',
    moon: 'Lune',
    nebula: 'Nébuleuse',
    planets: 'Planètes',
    sky: 'Ciel',
    sun: 'Soleil',
    zodiac: 'Zodiaque',
    weather: 'Climat',
    allWeather: 'All Weather',
    clouds: 'Nuages',
    ice: 'Glace',
    lightning: 'Éclair',
    rain: 'Pluie',
    rainbow: 'Arc-en-ciel',
    snow: 'Neige',
    storm: 'Tempête',
    umbrella: 'Parapluie',
    sea: 'Mer',
    allSea: 'Tous Mer',
    coral: 'Corail',
    island: 'Île',
    ocean: 'Océan',
    sand: 'Sable',
    seaweed: 'Algues',
    surf: 'Surf',
    waves: 'Vagues',
    outdoors: 'Extérieur',
    allOutdoors: 'Tous Extérieur',
    birch: 'Bouleau',
    campfire: 'Feu de camp',
    camping: 'Camping',
    desert: 'Désert',
    forest: 'Forêt',
    jungle: 'Jungle',
    lake: 'Lac',
    mountain: 'Montagne',
    smores: 'Chamallow',
    teepee: 'Tipi',
    tents: 'Tentes',
    novelty: 'Nouveauté',
    noveltyMenu: 'Tous Nouveauté',
    allNovelty: 'Tous Nouveauté',
    foodAndBeverage: 'Alimentation',
    allFood: 'Tous Alimentation',
    banana: 'Banane',
    candy: 'Bonbons',
    coffee: 'Café',
    cookies: 'Cookies',
    donuts: 'Donuts',
    iceCream: 'Glace',
    lemon: 'Citron',
    pineapple: 'Ananas',
    pizza: 'Pizza',
    popcorn: 'Popcorn',
    popsicle: 'Esquimau',
    sprinkles: 'Vermicelles',
    strawberry: 'Fraise',
    sushi: 'Sushi',
    watermelon: 'Pastèque',
    music: 'Musique',
    allMusic: 'Tous Musique',
    drums: 'Batterie',
    guitar: 'Guitare',
    jazz: 'Jazz',
    musicNotes: 'Notes de musique',
    piano: 'Piano',
    radio: 'Radio',
    rockAndRoll: 'Rock',
    symphony: 'Symphonie',
    trumpets: 'Trompettes',
    violin: 'Violon',
    academia: 'Études',
    allAcademia: 'Tous Études',
    atomic: 'Atomique',
    book: 'Livre',
    geek: 'Geek',
    math: 'Maths',
    school: 'École',
    science: 'Science',
    teacher: 'Prof',
    sewingNotions: 'Couture',
    allSewingNotions: 'Tous Couture',
    knitting: 'Tricoter',
    sewingMachines: 'Machine à coudre',
    scissors: 'Ciseaux',
    tapeMeasure: 'Mètre',
    thimbles: 'Dé à coudre',
    thread: 'Fil',
    sportsMenu: 'Sport',
    allSports: 'Tous Sports',
    ballet: 'Danse classique',
    baseball: 'Baseball',
    basketball: 'Basket-ball',
    bicycle: 'Vélo',
    fishing: 'Pêche',
    football: 'Football',
    golf: 'Golf',
    gymnastics: 'Gymnastique',
    soccer: 'Football',
    surfing: 'Surf',
    tennis: 'Tennis',
    scary: 'Frissons',
    allScary: 'Tous Frissons',
    ghost: 'Fantôme',
    monster: 'Monstre',
    skull: 'Crâne',
    zombie: 'Zombie',
    transportation: 'Transports',
    allTransportation: 'Tous Transports',
    airplanes: 'Avion',
    bicycles: 'Vélo',
    cars: 'Voiture',
    construction: 'Construction',
    helicopters: 'Hélicoptère',
    hotAirBalloon: 'Montgolfière',
    motorcycles: 'Moto',
    retroCampers: 'Camping-car',
    rocketShip: 'Fusée',
    spaceShip: 'Vaisseau spatial',
    ships: 'Bateau',
    tractors: 'Tracteur',
    trucks: 'Camion',
    occasionMenu: 'Occasion',
    occasion: 'Occasion',
    babyAndKids: 'Enfants & Bébés',
    allChildren: 'Tous Enfants',
    alphabet: 'Alphabet',
    babyBoy: 'Bébé Garçon',
    babyGirl: 'Bébé Fille',
    ballerina: 'Ballerine',
    circus: 'Cirque',
    dinosaur: 'Dinosaure',
    doll: 'Poupée',
    dragon: 'Dragon',
    fairy: 'Fée',
    nursery: 'Chambre de bébé',
    pirate: 'Pirate',
    princess: 'Princesse',
    robot: 'Robot',
    unicorn: 'Licorne',
    birthday: 'Anniversaire',
    allBirthday: 'Tous Anniversaire',
    balloons: 'Ballons',
    cake: 'Gâteau',
    confetti: 'Confettis',
    cupcake: 'Cupcake',
    gifts: 'Cadeaux',
    presents: 'Cadeaux',
    picnic: 'Pique-nique',
    wedding: 'Mariage',
    graduation: 'Diplôme',
    seasonal: 'Saisons',
    seasonalMenu: 'Tous Saisons',
    allSeasonal: 'Tous Saisons',
    winter: 'Hiver',
    allWinter: 'Tous Hiver',
    antler: 'Bois',
    chanukah: 'Hanoukka',
    christmas: 'Noël',
    holiday: 'Jour férié',
    holly: 'Houx',
    iceSkates: 'Patins à glace',
    penguins: 'Pingouins',
    polarBears: 'Ours polaire',
    skiing: 'Ski',
    stockings: 'Chaussettes',
    spring: 'Printemps',
    allSpring: 'Tous Printemps',
    birds: 'Oiseaux',
    bunny: 'Lapin',
    crocus: 'Crocus',
    daffodil: 'Jonquille',
    floral: 'Floral',
    tulips: 'Tulipes',
    summer: 'Été',
    allSummer: 'Tous Été',
    beach: 'Plage',
    july4th: 'Fête nationale',
    summerIceCream: 'Glace',
    lemonade: 'Limonade',
    tiki: 'Tiki',
    fall: 'Automne',
    allFall: 'Tous Automne',
    apple: 'Pomme',
    autumn: 'Automne',
    backpack: 'Sac à dos',
    cider: 'Cidre',
    halloween: 'Halloween',
    pumpkins: 'Citrouilles',
    woodlandFall: 'Forêt automne',
    seasonalHolidays: 'Fêtes',
    menorah: 'Menorah',
    santaClaus: 'Père Noël',
    starsAndStripes: 'Drapeau',
    supernatural: 'Surnaturel',
    witches: 'Sorcières',
    zombies: 'Zombies',
    holidays: 'Fêtes',
    holidaysMenu: 'Tous Fêtes',
    allHolidays: 'Tous Fêtes',
    allChristmas: 'Tous Noël',
    christmasTree: 'Arbre de Noël',
    pinecones: 'Pommes de pin',
    reindeer: 'Renne',
    retroChristmas: 'Rétro',
    santa: 'Père Noël',
    sled: 'Traîneau',
    sleigh: 'Traîneau',
    snowmen: 'Bonhomme de neige',
    stocking: 'Chaussettes',
    allJuly4th: 'Tous fête nationale',
    americanFlag: 'Drapeau américain',
    cookout: 'BBQ',
    fireworks: "Feux d'artifice",
    hamburgers: 'Hamburger',
    hotdogs: 'Hotdog',
    independenceDay: "Jour de l'indépendance",
    patriotic: 'Patriotique',
    redWhiteAndBlue: 'Rouge, blanc, bleu',
    valentinesDay: 'Saint-Valentin',
    allValentinesDay: 'Tous Saint-Valentin',
    candyHearts: 'Bonbons',
    cupid: 'Cupidon',
    love: 'Amour',
    allHalloween: 'Tous Halloween',
    blackCats: 'Chat noir',
    'jack-o-lantern': 'Citrouille',
    pumpkin: 'Citrouille',
    scarecrow: 'Épouvantail',
    spiderWeb: "Toile d'araignée",
    sugarSkulls: 'Bonbons',
    vampire: 'Vampire',
    witch: 'Sorcière',
    projects: 'Projets',
    projectMenu: 'All Projects',
    allProjects: 'All Projects',
    bags: 'Sacs',
    border: 'Bordure',
    bunting: 'Guirlande',
    calendarTeaTowels: 'Torchons calendriers',
    cheaterQuilts: 'Quilts simples',
    cutAndSew: 'Couture simple',
    pencilPouch: 'Trousse',
    plushies: 'Peluches',
    quilt: 'Quilt',
    quiltLabels: 'Étiquettes de quilt',
    teaTowels: 'Torchons',
    zipperPouch: 'Pochette à fermeture éclair',
  },
};
