import React from 'react';
import Link from 'react-router/lib/Link';

import useHostname from '../../../hostname/useHostname';
import {filterAllowedAttributes} from '../../../utils/formattingHelpers';
import {isInternalBaerlauchUrl, isClientSideRoute} from '../../../utils/url';
import {isNotUndefined} from '../../../utils/validation';


export interface LinkWrapperProps {
  target?: string;
  hrefValue: string;
  contents: JSX.Element | string;
  className?: string;
  itemProp?: string;
  title?: string;
  rel?: string;
  isDisabled?: boolean; // Only valid for Links within Baerlauch
  size?: string | number;
  tabIndex?: number;
  dataTestId?: string;
  customClickEvent?: () => void;
  [K: string]: unknown;
}

const LinkWrapper = ({
  target, hrefValue, contents, className, itemProp, title, rel, isDisabled, tabIndex, size, dataTestId,
  customClickEvent, ...rest
}: LinkWrapperProps): JSX.Element => {
  const allowedAttributesRaw = filterAllowedAttributes(rest);
  const hostname = useHostname();
  const getRelValue = (rel: string | undefined) => {
    const passedRelValue = isNotUndefined(rel) ? rel : undefined;

    if (target === '_blank') {
      return `noopener noreferrer ${passedRelValue ? passedRelValue : ''}`;
    } else if (target === '_self') {
      return `noreferrer ${passedRelValue ? passedRelValue : ''}`;
    }

    return passedRelValue;
  };

  const urlIsInsideBaerlauch = isInternalBaerlauchUrl(hrefValue, hostname);
  const sharedProps = {
    className,
    tabIndex,
    title,
    'aria-label': title,
    'data-testid': dataTestId,
    target,
    rel: getRelValue(rel),
    itemProp,
    onClick: isNotUndefined(customClickEvent) ? customClickEvent : undefined,
    ...(isNotUndefined(size) ? {
      'style': {
        width: `${size}px`
      }
    } : null)
  };

  if (urlIsInsideBaerlauch && isClientSideRoute(hrefValue, hostname)) {
    return (
      <Link
        disabled={isDisabled}
        to={hrefValue}
        {...sharedProps}
        {...allowedAttributesRaw}
      >
        {contents}
      </Link>
    );
  } else {
    return (
      <a
        href={hrefValue}
        aria-disabled={isDisabled}
        {...sharedProps}
        {...allowedAttributesRaw}
      >
        {contents}
      </a>
    );
  }
};

export default LinkWrapper;
