module.exports = {
	"loading": "Loading fabrics",
	"collapsibleText": "More",
	"collapsibleTextLess": "Less",
	"collapsibleShowText": "Show More",
	"collapsibleShowTextLess": "Show Less",
	"moreDetailsButton": "More Details",
	"buttonText": "Choose Fabric",
	"selectButtonText": "Select",
	"subTitle": "Select a Fabric",
	"subTitleWallpaper": "Select a Wallpaper",
	"filterCategories": "Filter by Categories",
	"filterByuseCase": "Filter by Use Case",
	"filterBytypeMaterial": "Filter by Type/Material",
	"filterByproperty": "Filter by Property",
	"clearCategories": "Clear Categories",
	"clearSearch": "Clear search",
	"sorting": {
		"ALPHABETICAL_SORTING": "A-Z",
		"PRICE_ASC_SORTING": "$-$$"
	},
	"allFabricTypes": "All Fabric Types",
	"changeSubstrate": "Change Fabric",
	"filterFabrics": "Filter Fabrics",
	"login": "login",
	"noteActualWidth": "Note: Actual width not shown in image",
	"SIZE": {
		"YARD": "{width}\" wide, {weight} oz per sq yard",
		"METER": "{width} cm wide, {weight}g per sq meter"
	},
	"SIZE_WIDTH": {
		"YARD": "{width}\" wide",
		"METER": "{width} cm wide"
	},
	"SIZE_WEIGHT": {
		"YARD": "{weight} oz per square yard",
		"METER": "{weight} g per square meter"
	},
	"SIZE_WIDTH_UNIVERSAL": "{widthIn}\" ({widthCm} cm) wide",
	"SIZE_WEIGHT_UNIVERSAL": "{weightOz} oz/square yd ({weightGsm} gsm)",
	"FABRIC_NONE_SELECTED": {
		"name": "Select a Fabric"
	},
	"FABRIC_BASIC_COTTON_ULTRA": {
		"name": "Retired Basic Cotton Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Retired Basic Cotton Ultra Fabric",
		"description": "100% natural combed cotton fabric with a simple, versatile construction",
		"details": "<li>Natural White</li> <li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for craft projects, quilting, lining apparel and bags, home and wedding decor, and soft accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for craft projects, quilting, lining apparel and bags, home and wedding decor, and soft accessories</li>"
	},
	"FABRIC_BASIC_CRAFT_COTTON": {
		"name": "Basic Craft Cotton",
		"description": "100% combed cotton fabric with a simple, versatile construction",
		"details": "<li>Natural White</li> <li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for craft projects, quilting, lining apparel and bags, home and wedding decor, and soft accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for craft projects, quilting, lining apparel and bags, home and wedding decor, and soft accessories</li>"
	},
	"FABRIC_SATIN": {
		"name": "Satin",
		"cartName": "{width} x {height} {measurementUnit} of Satin Fabric",
		"description": "Ultra shiny polyester fabric ideal for apparel, costumes and neckwear",
		"descriptionShop": "100% polyester satin fabric with a silky feel and high-gloss finish",
		"longDescription": "Satin is a 100% woven polyester fabric featuring vibrant color, crisp detail and strong color fastness. Characterized by an ultra shiny look and a lovely thin hand that softens the more it’s washed, Satin elevates any handmade project. Try Satin for creating apparel and costumes, lining jackets and bags, adding trim to blankets, or creating soft, silky accessories such as bow ties or clutches.",
		"title": "Satin Fabric | Spoonflower",
		"metaDescription": "Printed with ecologically-safe transfer sublimation inks, Spoonflower’s Satin fabric is a 100% woven polyester with great drape and vibrant color.",
		"useCases": "Lining for apparel & bags, wedding decor, soft silky accessories, costumes",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Woven, Satin Weave",
		"color": "White",
		"threadCount": 240,
		"shrinkage": "1% length x 2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"needleType": "Sharp/Microtex",
		"needleSize": "60, 65 (8, 9)",
		"threadSize": "16, 18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "100% polyester - Fine",
		"details": "<li>White</li> <li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for lining apparel and bags, wedding decor, and soft, silky accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for lining apparel and bags, wedding decor, and soft, silky accessories</li>"
	},
	"FABRIC_KONA_COTTON_ULTRA": {
		"name": "Retired Kona® Cotton Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Retired Kona® Cotton Ultra Fabric",
		"description": "100% natural combed cotton fabric with a wide weave made for quilting",
		"details": "<li>White</li> <li>Estimated shrinkage: 2-3% in length and 4-5% in width</li> <li>Appropriate for quilting, appliqué, shirting, dresses, children's clothing, and home decor</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 4-5% in width</li> <li>Appropriate for quilting, appliqué, shirting, dresses, children's clothing, and home decor</li>"
	},
	"FABRIC_COTTON_POPLIN_ULTRA": {
		"name": "Cotton Poplin",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Poplin Fabric",
		"description": "Lightweight quilting cotton for apparel, quilts and home accents",
		"descriptionShop": "100% natural cotton fabric with a fine weave and crisp, smooth feel",
		"longDescription": "Cotton Poplin is a woven 100% cotton fabric characterized by a crisp, smooth hand and structured feel. With a tight weave and soft finish, this fabric is perfect for apparel and quilting projects. Try Cotton Poplin for its strong wash durability and more muted, subtle color on products from blouses, dresses, shirts and pajamas, or home decor projects like accent pillows, curtains and napkins.",
		"title": "Cotton Poplin Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Cotton Poplin is a woven 100% cotton fabric with a crisp, smooth and structured feel. It’s perfect for apparel and quilting projects.",
		"useCases": "Quilting, shirts & skirts, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, Plain Weave",
		"color": "White",
		"threadCount": 200,
		"shrinkage": "2-4 length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"needleType": "Universal",
		"needleSize": "60,65 (8.9)",
		"threadSize": "16,18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "100% cotton - Fine",
		"details": "<li>White</li> <li>Estimated shrinkage: 2-4% in length and 0-1% in width</li> <li>Appropriate for quilting, shirting, skirts, dresses, pajamas, and home decor</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 0-1% in width</li> <li>Appropriate for quilting, shirting, skirts, dresses, pajamas, and home decor</li>"
	},
	"FABRIC_COTTON_POPLIN_BRAVA": {
		"name": "Cotton Poplin",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Poplin Fabric",
		"description": "Lightweight quilting cotton for apparel, quilts and home accents",
		"descriptionShop": "100% natural cotton fabric with a fine weave and crisp, smooth feel",
		"longDescription": "Cotton Poplin is a woven 100% cotton fabric characterized by a crisp, smooth hand and structured feel. With a tight weave and soft finish, this fabric is perfect for apparel and quilting projects. Try Cotton Poplin for its strong wash durability and more muted, subtle color on products from blouses, dresses, shirts and pajamas, or home decor projects like accent pillows, curtains and napkins.",
		"title": "Cotton Poplin Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Cotton Poplin is a woven 100% cotton fabric with a crisp, smooth and structured feel. It’s perfect for apparel and quilting projects.",
		"useCases": "Quilting, shirts & skirts, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, Plain Weave",
		"color": "White",
		"threadCount": 200,
		"shrinkage": "2-4 length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"needleType": "Universal",
		"needleSize": "60,65 (8.9)",
		"threadSize": "16,18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "100% cotton - Fine",
		"details": "<li>White</li> <li>Estimated shrinkage: 2-4% in length and 0-1% in width</li> <li>Appropriate for quilting, shirting, skirts, dresses, pajamas, and home decor</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 0-1% in width</li> <li>Appropriate for quilting, shirting, skirts, dresses, pajamas, and home decor</li>"
	},
	"FABRIC_PERFORMANCE_PIQUE": {
		"name": "Sport Piqué",
		"cartName": "{width} x {height} {measurementUnit} of Sport Piqué Fabric",
		"description": "Wrinkle-resistant with a lightweight drape for activewear and fabric posters",
		"longDescription": "Sport Piqué is a 100% polyester fabric with a diamond-like knit structure and clear definition of detail, vibrant color and strong wash durability. Comfortable and lightweight with a delicate drape, Sport Pique’s soft, stretchy composition is perfect for moisture-wicking athletic apparel such as tennis dresses, yoga tops and headbands, offering a soil-release finish for enhanced laundering. Wrinkle-resistant and easy to pack in a small bag, this is also the fabric of choice for conference presentations and scientific posters—no sewing required.",
		"title": "Sport Piqué Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Sport Piqué is a 100% polyester fabric with a diamond knit, clear detail, vibrant color and strong wash durability. Ideal for activewear!",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Knit, Swiss Piqué Knit",
		"useCases": "Dresses & skirts, headbands, activewear, science posters",
		"careInstructions": "Machine wash in cool water using a gentle machine cycle. Machine dry using a low temperature setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "Taiwan",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Optic white",
		"shrinkage": "1-2% length x 0-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "5",
		"uvaUvb": "98.07% / 99.37%",
		"upfRating": "50+",
		"colorFastnessAcid": "5",
		"colorFastnessAlkaline": "5",
		"needleType": "Ball Point/Stretch",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "14-18 per inch (6-7 per cm)",
		"threadType": "100% polyester – all purpose",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 1-2% in length and 0-2% in width</li> <li>Appropriate for yoga tops, polo shirts, sporty dresses and skirts, headbands, scarves and more</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 0-2% in width</li> <li>Appropriate for yoga tops, polo shirts, sporty dresses and skirts, headbands, scarves and more</li>"
	},
	"FABRIC_CHIFFON": {
		"name": "Chiffon",
		"cartName": "{width} x {height} {measurementUnit} of Chiffon Fabric",
		"description": "Airy and transparent for delicate apparel and home decor",
		"longDescription": "With a flowy drape and beautiful finish, Chiffon is a 100% polyester fabric that showcases vibrant color and strong wash durability. Lightweight and translucent, Chiffon is the ideal choice for airy scarves, robes and curtains where the printed design is visible on both sides. Chiffon is also our favorite fabric for creating delicate, personalized decor for weddings or other special occasions.",
		"title": "Chiffon Fabric | Spoonflower",
		"metaDescription": "With a flowy drape, our Chiffon is 100% polyester and showcases vibrant color and strong wash durability. It is a great choice for scarves, robes or curtains.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Woven, Plain Weave",
		"useCases": "Scarves, sheer curtains, special occasion decor and apparel",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Non-optic white",
		"shrinkage": "0-1% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"threadCount": "230",
		"needleType": "Microtex",
		"needleSize": "70(10)",
		"threadSize": "50",
		"stitchesInch": "11-13 per inch (4-5 per cm)",
		"threadType": "100% polyester - all purpose",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 0%-1% in length and width</li> <li>Appropriate for scarves, sheer curtains, and special occasion decor items</li>",
		"pdpDetails": "<li>Estimated shrinkage: 0%-1% in length and width</li> <li>Appropriate for scarves, sheer curtains, and special occasion decor items</li>"
	},
	"FABRIC_ORGANIC_SWEET_PEA_GAUZE_ULTRA": {
		"name": "Organic Sweet Pea Gauze Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Organic Sweet Pea Gauze Ultra Fabric",
		"description": "100% organic cotton double gauze fabric that crinkles in the wash",
		"details": "<li>Non-optic white</li> <li>Crinkles when washed</li> <li>Estimated shrinkage: 8% width and 14% in length</li> <li>Prints are likely to be off-grain</li> <li>Perfect for swaddle blankets, bibs, burp cloths, and reusable bags</li>",
		"pdpDetails": "<li>Crinkles when washed</li> <li>Estimated shrinkage: 8% width and 14% in length</li> <li>Prints are likely to be off-grain</li> <li>Perfect for swaddle blankets, bibs, burp cloths, and reusable bags</li>"
	},
	"FABRIC_ORGANIC_SWEET_PEA_GAUZE": {
		"name": "Organic Sweet Pea Gauze",
		"cartName": "{width} x {height} {measurementUnit} of Organic Sweet Pea Gauze Fabric",
		"description": "Eco-friendly double gauze with an open-weave muslin structure that crinkles when washed",
		"longDescription": "Developed exclusively for Spoonflower, our 100% organic cotton double gauze fabric features a subtle 1 inch (2.5 cm) grid that crinkles when washed and looks dreamy with lighter-color, scattered designs. With an open-weave texture similar to muslin, Organic Sweet Pea Gauze is the ideal choice for everything from baby bibs, swaddle blankets and burp cloths to produce bags, breezy apparel, scarves and swim cover-ups. Prints are likely to be off-grain.",
		"title": "Organic Sweet Pea Gauze Fabric | Spoonflower",
		"metaDescription": "A Spoonflower exclusive, our Sweet Pea Gauze is 100% cotton that looks dreamy in lighter-colors. It is perfect for everything from scarves to produce bags.",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Woven, Double Plain Weave",
		"useCases": "Swaddle blankets, bibs, burp cloths, reusable bags, lightweight apparel",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Non-optic white",
		"shrinkage": "6-10% length x 1-4% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"threadCount": "140",
		"needleType": "Universal",
		"needleSize": "80 (12)",
		"threadSize": "50",
		"stitchesInch": "9-11 per inch (3.5-4 per cm)",
		"threadType": "100% polyester – all purpose",
		"details": "<li>Non-optic white</li> <li>Crinkles when washed</li> <li>Estimated shrinkage: 8% width and 14% in length</li> <li>Prints are likely to be off-grain</li> <li>Perfect for swaddle blankets, bibs, burp cloths, and reusable bags</li>",
		"pdpDetails": "<li>Crinkles when washed</li> <li>Estimated shrinkage: 8% width and 14% in length</li> <li>Prints are likely to be off-grain</li> <li>Perfect for swaddle blankets, bibs, burp cloths, and reusable bags</li>"
	},
	"FABRIC_POLY_CREPE_DE_CHINE": {
		"name": "Poly Crepe de Chine",
		"cartName": "{width} x {height} {measurementUnit} of Poly Crepe de Chine Fabric",
		"description": "Delicate hand and soft drape for a semi-transparent elegant option for apparel and accessories",
		"descriptionShop": "100% polyester crepe de chine fabric with a semi-sheer look and delicate drape",
		"longDescription": "Poly Crepe de Chine is a 100% polyester crepe fabric with vibrant color and strong wash durability. Lightweight and semi-transparent, it’s ideal for projects that require a soft drape and delicate hand. Poly Crepe de Chine is a favorite choice for women’s apparel, including dresses, blouses, scarves, and lingerie, and offers a wrinkle-free, elegant option for window treatments. For a less sheer result, be sure to select a design with a darker background.",
		"title": "Poly Crepe de Chine Fabric | Spoonflower",
		"metaDescription": "Poly Crepe de Chine is a 100% polyester crepe fabric with bright color and strong wash durability. It’s ideal for projects that require a soft drape and delicate hand.",
		"useCases": "Blouses & dresses, scarves, semi-sheer curtains, lingerie",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Woven, Plain Weave",
		"color": "White",
		"threadCount": 310,
		"shrinkage": "0-1% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"needleType": "Sharp/Microtex",
		"needleSize": "60, 65 (8, 9)",
		"threadSize": "16, 18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "100% polyester - Fine",
		"details": "<li>White</li> <li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for blouses, dresses, lingerie, and sheer curtains</li>",
		"pdpDetails": "<li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for blouses, dresses, lingerie, and sheer curtains</li>"
	},
	"FABRIC_SILKY_FAILLE": {
		"name": "Silky Faille",
		"cartName": "{width} x {height} {measurementUnit} of Silky Faille Fabric",
		"description": "100% polyester fabric with a medium weight and slinky feel",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for drapes, dresses, blouses, and scarves</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for drapes, dresses, blouses, and scarves</li>"
	},
	"FABRIC_PERFORMANCE_KNIT": {
		"name": "Performance Knit",
		"cartName": "{width} x {height} {measurementUnit} of Performance Knit Fabric",
		"description": "100% polyester fabric with moisture management",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 1-2% in length and 2-4% in width</li> <li>Appropriate for athletic apparel</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 2-4% in width</li> <li>Appropriate for athletic apparel</li>"
	},
	"FABRIC_LIGHTWEIGHT_COTTON_TWILL_ULTRA": {
		"name": "Lightweight Cotton Twill Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Lightweight Cotton Twill Ultra Fabric",
		"description": "100% natural cotton twill fabric with a sturdy, drapable construction",
		"details": "<li>White</li> <li>Estimated shrinkage: 4-6% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>",
		"pdpDetails": "<li>Estimated shrinkage: 4-6% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>"
	},
	"FABRIC_MODERN_JERSEY": {
		"name": "Modern Jersey",
		"cartName": "{width} x {height} {measurementUnit} of Modern Jersey Fabric",
		"description": "Lightweight drapey knit with cotton-like feel beloved for color-rich apparel",
		"longDescription": "This 95% polyester, 5% spandex fabric with 2-way stretch feels like the softest cotton, but with the added benefits of strong wash durability and wrinkle resistance. With a gentle drape and vibrant color, Modern Jersey has a cool feel and nice stretch recovery that suits a wide variety of garments. Customers love this knit fabric for tops, dresses, maxi skirts, baby apparel, loungewear, headbands and much more. Developed exclusively for the Spoonflower community, you can’t get this fabric and its outstanding qualities anywhere else.",
		"title": "Modern Jersey Fabric | Spoonflower",
		"metaDescription": "Modern Jersey is a 100% polyester fabric with 4-way stretch but feels like the softest cotton. Toss in added benefits of wash durability and you have a winner!",
		"fabricContent": "95% polyester, 5% spandex",
		"fabricConstruction": "Knit, Plaited Jersey Knit",
		"useCases": "Baby clothes, t-shirts, dresses, lightweight blankets",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "0-3% length x 1-2% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "4.5",
		"needleType": "Stretch",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - all purpose",
		"details": "<li>White</li> <li>Advanced Moisture Management Wicking Finish</li> <li>Estimated shrinkage: 0-3% in length and 1-2% in width</li> <li>Appropriate for t-shirts, dresses, maxi skirts, headbands, tights, scarves, baby clothes and lightweight blankets</li>",
		"pdpDetails": "<li>Advanced Moisture Management Wicking Finish</li> <li>Estimated shrinkage: 0-3% in length and 1-2% in width</li> <li>Appropriate for t-shirts, dresses, maxi skirts, headbands, tights, scarves, baby clothes and lightweight blankets</li>"
	},
	"FABRIC_FLEECE": {
		"name": "Fleece",
		"cartName": "{width} x {height} {measurementUnit} of Fleece Fabric",
		"description": "Low-loft fleece with a hint of stretch and vibrant colors is perfect for blankets, plushies and loungewear",
		"longDescription": "Fleece is a low-loft 100% polyester napped fabric. Lightweight and slightly stretchy, it has the feel and character of traditional fleece, with half the weight and thickness. Its double-face construction creates a soft hand on both sides making it perfect for blankets (including no-sew tie blankets), pillows, plushies, adult and children’s loungewear and cold weather apparel. Because Fleece doesn't fray when cut, it’s a great fabric for novice and experienced crafters alike.",
		"title": "Fleece Fabric | Spoonflower",
		"metaDescription": "Fleece is a low-loft 100% polyester napped fabric. Lightweight and stretchy, it has the feel of traditional fleece, with half the weight and thickness.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Knit, Fleece Knit",
		"useCases": "Adult & youth loungewear, blankets & pillows, soft toys, cool weather apparel",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Optic white",
		"shrinkage": "0-1% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"fabricPilling": "1.5",
		"needleType": "Ball Point/ Universal",
		"needleSize": "80, 90, 100(12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - Heavy or all purpose",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for adult and youth loungewear, blankets and pillows, cool weather apparel</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for adult and youth loungewear, blankets and pillows, cool weather apparel</li>"
	},
	"FABRIC_POLARTEC_FLEECE": {
		"name": "Polartec® Fleece",
		"cartName": "{width} x {height} {measurementUnit} of Polartec® Fleece Fabric",
		"description": "Award winning, high-loft, pill-resistant fleece perfect for blankets and apparel",
		"awardDescription": "Printed on Spoonflower’s Award-Winning Polartec® Fleece",
		"longDescription": "Polartec® Fleece is a durable high-loft 100% polyester fabric. As a highly breathable and quick-drying fabric from the leaders in fleece construction, it offers warmth without the weight. With its slight stretch, incredibly soft nap and pill-resistant construction, Polartec® Fleece is the ideal fabric for cold weather apparel, including hats, ear muffs, gloves, scarves, jackets and more! Plus, it pairs perfectly with our endless design selection for cozy no-sew blankets, loungewear or soft books.",
		"title": "Polartec® Fleece Fabric | Spoonflower",
		"metaDescription": "High-loft, pill-resistant durable Polartec® fleece perfect for blankets and apparel.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Knit, Fleece Knit",
		"useCases": "Blankets, loungewear, cold weather apparel",
		"careInstructions": "Machine wash in warm or cool water on a gentle setting. Machine dry using a low temperature.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "Estimated shrinkage: 5% in length and 5% in width. Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"needleType": "Ball Point/Universal",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - Heavy or all purpose",
		"details": "<li>White</li> <li>Estimated shrinkage: 5% in length and 5% in width</li> <li>Blankets, loungewear, cold weather apparel</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5% in length and 5% in width</li> <li>Blankets, loungewear, cold weather apparel</li>"
	},
	"FABRIC_ORGANIC_COTTON_SATEEN_ULTRA": {
		"name": "Organic Cotton Sateen",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Sateen Fabric",
		"description": "100% organic cotton fabric with a subtle sheen and soft hand",
		"details": "<li>Optic White</li> <li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows</li>"
	},
	"FABRIC_SPORT_LYCRA": {
		"name": "Sport Lycra®",
		"cartName": "{width} x {height} {measurementUnit} of Sport Lycra Fabric",
		"description": "Durable, strong and stretchy for truly functional performance wear",
		"longDescription": "Sport Lycra is a durable knit fabric with an 88% polyester and 12% Lycra construction. Medium-weight and nearly opaque with 4-way stretch, Sport Lycra features vibrant color and UPF protection that stands up to swimming pools and saltwater alike, making it the perfect choice for custom swimwear. A moisture-wicking finish paired with good stretch recovery also makes Sport Lycra an ideal choice for athletic apparel, including dance costumes, leotards, leggings and headbands.",
		"title": "Sport Lycra® Fabric | Spoonflower",
		"metaDescription": "Sport Lycra is a durable knit fabric made from a poly and Lycra blend. Featuring 4-way stretch, it has vibrant color, UPF protection and is great for swimsuits.",
		"fabricContent": "88% polyester, 12% Invista Lycra®",
		"fabricConstruction": "Knit, Jersey",
		"useCases": "Athletic apparel, swimwear, leggings",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Optic white",
		"shrinkage": "2-3% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 25% vertical stretch",
		"fabricPilling": "5",
		"uvaUvb": "99.89%/ 99.84%",
		"upfRating": "50+",
		"colorFastnessAcid": "5",
		"colorFastnessAlkaline": "5",
		"colorFastnessSeaWater": "5",
		"colorFastnessPoolWater": "5",
		"features": "Advanced Moisture Management Wicking Finish",
		"needleType": "Stretch",
		"needleSize": "100, 110 (16, 18)",
		"threadSize": "40, 45, 50, 60",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - Heavy",
		"details": "<li>Optic White</li> <li>4-way: ≥ 50% horizontal stretch; ≥ 25% vertical stretch</li> <li>Colorfast to chlorinated and salt water</li> <li>Estimated shrinkage: 1-3% in length and 0-1% in width</li> <li>Appropriate for athletic apparel and swimwear</li>",
		"pdpDetails": "<li>4-way: ≥ 50% horizontal stretch; ≥ 25% vertical stretch</li> <li>Colorfast to chlorinated and salt water</li> <li>Estimated shrinkage: 1-3% in length and 0-1% in width</li> <li>Appropriate for athletic apparel and swimwear</li>"
	},
	"FABRIC_SPORT_LYCRA_RETIRED": {
		"name": "Retired Sport Lycra",
		"description": "88% polyester, 12% Lycra knit fabric with a stretchy, sturdy construction and moisture-wicking finish",
		"details": "<li>Optic White</li> <li>4-way: ≥ 50% horizontal stretch; ≥ 25% vertical stretch</li> <li>Colorfast to chlorinated and salt water</li> <li>Estimated shrinkage: 1-3% in length and 0-1% in width</li> <li>Appropriate for athletic apparel and swimwear</li>",
		"pdpDetails": "<li>4-way: ≥ 50% horizontal stretch; ≥ 25% vertical stretch</li> <li>Colorfast to chlorinated and salt water</li> <li>Estimated shrinkage: 1-3% in length and 0-1% in width</li> <li>Appropriate for athletic apparel and swimwear</li>"
	},
	"FABRIC_HEAVY_COTTON_TWILL": {
		"name": "Heavy Cotton Twill",
		"cartName": "{width} x {height} {measurementUnit} of Heavy Cotton Twill Fabric",
		"description": "100% natural cotton twill fabric with a sturdy, drapable construction",
		"details": "<li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 6-8% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>",
		"pdpDetails": "<li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 6-8% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>"
	},
	"FABRIC_ECO_CANVAS": {
		"name": "Eco Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Eco Canvas Fabric",
		"description": "100% polyester canvas fabric with 45% recycled content and a sturdy construction",
		"details": "<li>Produced in the U.S.</li> <li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for upholstery, bags, home decor, outdoor pillows, toys, play mats, and jackets</li>",
		"pdpDetails": "<li>Produced in the U.S.</li> <li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 0-1% in length and 1-2% in width</li> <li>Appropriate for upholstery, bags, home decor, outdoor pillows, toys, play mats, and jackets</li>"
	},
	"FABRIC_FAUX_SUEDE": {
		"name": "Faux Suede",
		"cartName": "{width} x {height} {measurementUnit} of Faux Suede Fabric",
		"description": "100% polyester woven napped fabric",
		"details": "<li>White</li> <li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 1-3% in length and 0-2% in width</li> <li>Appropriate for bags, upholstery, pillows, structured clothing, and toys</li>",
		"pdpDetails": "<li>Testing is recommended for applications where the printed fabric will be exposed to heavy abrasion (rubbing)</li> <li>Estimated shrinkage: 1-3% in length and 0-2% in width</li> <li>Appropriate for bags, upholstery, pillows, structured clothing, and toys</li>"
	},
	"FABRIC_CELOSIA_VELVET": {
		"name": "Celosia Velvet",
		"cartName": "{width} x {height} {measurementUnit} of Celosia Velvet Fabric",
		"description": "Elegant shimmer with a plush feel for durable, bespoke home decor and apparel",
		"longDescription": "Celosia Velvet™ is a heavyweight, 100% polyester fabric with a short pile and elegant shimmer. With rich and long-lasting color, Celosia Velvet adds bespoke luxury to a variety of projects from interior accents to structured apparel. Offering a plush feel and durable construction that softens in the wash, it’s the perfect choice for commercial or personal upholstery projects, pillows, and other home decor applications.",
		"title": "Celosia Velvet™ Fabric | Spoonflower",
		"metaDescription": "Celosia Velvet™ is a heavyweight, 100% polyester fabric with a short pile and elegant shimmer. With rich color, it adds bespoke luxury to any project.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Warp Pile Velvet",
		"useCases": "Indoor home decor, upholstery, drapery, heavyweight apparel",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles. Dry clean if preferred.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Non-Optic White",
		"shrinkage": "0-1% in length x 0-1% in width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "80,000+ double rubs (ASTM D4157)",
		"abrasionWyzenbeekWire": "80,000+ double rubs",
		"abrasionMartindale": "40,000+ cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 4.5 (AATCC TM 8)",
		"wetCrocking": "Exceeds 4 (AATCC TM 8)",
		"colorfastness": "40hrs - Exceeds 3 (AATCC TM 16.3)",
		"flammability": "Cal TB 117 meets | UFAC | NFPA 260",
		"pileHeight": "0.5 mm",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "90 (14)",
		"threadSize": "50",
		"stitchesInch": "9-11 per inch (3.5-4 per cm)",
		"threadType": "100% polyester - Heavy or all purpose",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 2-3% in length and 1-2% in width</li> <li>Appropriate for home decor and commercial-grade upholstery, heavyweight apparel, and luxurious accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 1-2% in width</li> <li>Appropriate for home decor and commercial-grade upholstery, heavyweight apparel, and luxurious accessories</li>"
	},
	"FABRIC_DOGWOOD_DENIM_ULTRA": {
		"name": "Dogwood Denim Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Dogwood Denim Ultra Fabric",
		"description": "100% natural cotton bull denim with a rugged twill construction",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 7-8% in length and 1-2% in width</li> <li>Appropriate for skirts, shorts, pants, outerwear, bags and backpacks, and low-traffic upholstery</li>",
		"pdpDetails": "<li>Estimated shrinkage: 7-8% in length and 1-2% in width</li> <li>Appropriate for skirts, shorts, pants, outerwear, bags and backpacks, and low-traffic upholstery</li>"
	},
	"FABRIC_SILK_CREPE_DE_CHINE": {
		"name": "Retired Silk Crepe de Chine",
		"cartName": "{width} x {height} {measurementUnit} of Retired Silk Crepe de Chine Fabric",
		"description": "100% natural silk fabric with a semi-sheer look and delicate drape",
		"details": "<li>White</li> <li>Estimated shrinkage: 3% in length and width</li> <li>Please note that the texture of the silk means that printing will not always be 'on grain,' and dark colors may bleed very slightly along the edges of design elements.</li> <li>Appropriate for dresses, blouses, lingerie, accessories, and lining.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3% in length and width</li> <li>Please note that the texture of the silk means that printing will not always be 'on grain,' and dark colors may bleed very slightly along the edges of design elements.</li> <li>Appropriate for dresses, blouses, lingerie, accessories, and lining.</li>"
	},
	"FABRIC_CYPRESS_COTTON": {
		"name": "Retired Cypress Cotton Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Retired Cypress Cotton Canvas Fabric",
		"description": "100% natural cotton canvas with a sophisticated basketweave structure",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 3-4% in length and 0-1% in width</li> <li>Perfect for upholstery projects, heavyweight drapery, home accents and bags.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and 0-1% in width</li> <li>Perfect for upholstery projects, heavyweight drapery, home accents and bags.</li>"
	},
	"FABRIC_COTTON_LAWN": {
		"name": "Retired Cotton Lawn Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Retired Cotton Lawn Ultra Fabric",
		"description": "100% natural cotton fabric with a semi-sheer look and buttery-soft hand",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 0-2% in length and 4-6% in width</li> <li>Perfect for lightweight home decor, quilting and apparel.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 0-2% in length and 4-6% in width</li> <li>Perfect for lightweight home decor, quilting and apparel.</li>"
	},
	"FABRIC_COTTON_LAWN_APPAREL": {
		"name": "Cotton Lawn",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Lawn Fabric",
		"description": "Lightweight, slightly translucent apparel and quilting fabric beloved for its silky hand and elegant drape",
		"longDescription": "Cotton Lawn is a smooth-weave 100% cotton fabric with a beautiful drape. Offering a silky soft touch, our lightweight Cotton Lawn is slightly translucent without being too sheer, making it the perfect warm weather apparel fabric. You’ll love our Cotton Lawn fabric’s unmatched fluidity and fine print detail on blouses, sundresses, scarves, doll clothes, modern quilts and accessories for both daily use and elevated occasions.",
		"title": "Cotton Lawn Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Cotton Lawn is a lightweight, slightly translucent apparel and quilting fabric beloved for its silky hand and elegant drape. Learn more here.",
		"fabricContent": "100% Cotton",
		"fabricConstruction": "Woven, plain weave",
		"useCases": "Dresses, blouses, top weight apparel",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "South Korea",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"threadCount": "268",
		"shrinkage": "0-3% length 1-2% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"dryCrocking": "4 (AATCC TM 8)",
		"wetCrocking": "4 (AATCC TM 8)",
		"needleType": "Microtex/Universal",
		"threadSize": "16, 18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "100% Cotton",
		"details": "<li>White</li> <li>Estimated shrinkage: 0-3% in length and 1-2% width</li> <li>Dresses, blouses, top weight apparel</li>",
		"pdpDetails": "<li>Estimated shrinkage: 0-3% in length and 1-2% width</li> <li>Dresses, blouses, top weight apparel</li>"
	},
	"FABRIC_PERFORMANCE_LINEN": {
		"name": "Performance Linen",
		"cartName": "{width} x {height} {measurementUnit} of Performance Linen",
		"description": "High-end linen fabric look with the easy-care qualities of polyester for upholstery, pillows and home decor",
		"longDescription": "Performance Linen fabric offers the natural appearance and texture of traditional linen fabrics with the durability of a 100% polyester fabric. Print on demand designs display with a slight luster and rich colors giving this faux linen fabric a luxurious, antiqued look. The soft handfeel and elegant drape of this polyester linen make it the ideal fabric for a variety of applications. Makers and interior designers need to look no further for a durable pet- and family-friendly fabric for upholstery, home decor, pillows, curtains and more. Performance Linen provides a premium linen look with the easy care of a performance polyester.",
		"title": "Performance Linen Fabric | Spoonflower",
		"metaDescription": "Our Performance Linen offers the texture of traditional linen fabrics with the durability of 100% polyester. This fabric prints with a truly luxe look!",
		"shrinkage": "1.5-3% in length and 0-2% in width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"color": "White",
		"useCases": "Pillows, upholstery and home decor",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Woven, plain weave",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles. Dry clean if preferred.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"needleType": "Universal",
		"needleSize": "90, 100",
		"threadSize": "40-60",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - all purpose",
		"fabricPilling": "5 (ASTM D 4970)",
		"abrasionWyzenbeekDuck": "21,000+ double rubs (ASTM D4157)",
		"abrasionMartindale": "35,000+ cycles (ASTM D4966)",
		"dryCrocking": "2.5 (AATCC TM 8)",
		"wetCrocking": "3 (AATCC TM 8)",
		"colorfastness": "60 hrs - 1 (AATCC TM 16.3)",
		"flammability": "Cal TB 117 meets | NFPA 260",
		"breakingStrength": "125+ lbs in both directions (ASTM D5034)",
		"threadCount": "24",
		"details": "<li>White</li> <li>Estimated shrinkage: 1.5-3% in length and 0-2% in width</li> <li>Pillows, upholstery and home decor</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1.5-3% in length and 0-2% in width</li> <li>Pillows, upholstery and home decor</li>"
	},
	"FABRIC_AC_ORGANIC_COTTON_JERSEY_NATURAL": {
		"name": "AC Organic Cotton Jersey Neutral",
		"cartName": "{width} x {height} {measurementUnit} of AC Organic Cotton Jersey Neutral Fabric",
		"description": "100% organic cotton knit printing for Alabama Chanin",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_AC_ORGANIC_COTTON_JERSEY_WHITE": {
		"name": "AC Organic Cotton Jersey White",
		"cartName": "{width} x {height} {measurementUnit} of AC Organic Cotton Jersey White Fabric",
		"description": "100% organic cotton knit printing for Alabama Chanin",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_AC_ORGANIC_COTTON_JERSEY_DYED": {
		"name": "AC Organic Cotton Jersey Dyed",
		"cartName": "{width} x {height} {measurementUnit} of AC Organic Cotton Jersey Dyed Fabric",
		"description": "100% organic cotton knit printing for Alabama Chanin",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_PERENNIAL_SATEEN_GRAND": {
		"name": "Wide Cotton Sateen 3.5 oz",
		"cartName": "{width} x {height} {measurementUnit} of Perennial Sateen Grand Fabric",
		"description": "116\" wide, lightweight, 100% cotton sateen fabric with a delicate drape and subtle sheen",
		"title": "Wide Cotton Sateen 3.5 oz Fabric | Spoonflower",
		"longDescription": "Our Wide Cotton Sateen 3.5 oz is a 116\" wide, 100% cotton sateen imported from Italy with a delicate drape and subtle sheen. This fabric is lightweight and features rich color, fine detail and strong wash durability without compromising softness. Sourced with bedding in mind, Wide Cotton Sateen 3.5 oz is the dreamiest fabric for custom duvets, comforters, quilts and sheeting. Its extra wide width also makes it the perfect choice for creating seamless, unique quilt backings, one-of-a-kind tapestries or backdrops, curtains and more.",
		"metaDescription": "Starting at just $5, create custom printed Perennial Sateen Grand fabric. Upload your own design or shop our marketplace of designs to create customized Perennial Sateen Grand fabric.",
		"color": "Non-Optic White",
		"useCases": "Perfect for making duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.",
		"careInstructions": "Machine wash warm. Tumble dry low. Warm iron as needed. Wool dryer balls can help with even drying. Wash items made of the same fiber content together. Do not bleach. Slight Shrinkage is expected during the first wash cycle.",
		"countryOrigin": "Italy",
		"printing": "Single-sided, digitally printed in the US using water-based inks.",
		"fabricContent": "100% Cotton",
		"fabricConstruction": "Woven, Sateen Weave",
		"shrinkage": "2-3% in length and 3-4% in width",
		"threadCount": "280",
		"needleType": "Universal",
		"needleSize": "70-80",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10 to 14",
		"threadType": "100% Polyester - all purpose",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for making duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for making duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>"
	},
	"FABRIC_COTTON_SPANDEX_JERSEY": {
		"name": "Cotton Spandex Jersey",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Spandex Jersey Ultra Fabric",
		"description": "A hint of stretch blended with a medium-weight cotton that’s perfect for apparel",
		"descriptionShop": "93% natural cotton, 7% spandex jersey knit fabric with plenty of softness and stretch",
		"longDescription": "Cotton Spandex Jersey offers a 4-way stretch with a light to medium-weight durable knit construction of 93% cotton and 7% spandex. With great stretch recovery and natural breathability due to the cotton content, this fabric is well suited for many projects, from loungewear and T-shirts to kids' and baby apparel. Best paired with lighter designs for softest feel and optimum color retention through washes.",
		"title": "Cotton Spandex Jersey Fabric | Spoonflower",
		"metaDescription": "Cotton Spandex Jersey offers a 4-way stretch with a medium-weight durable knit blend of cotton and spandex. Great for projects from loungewear and apparel!",
		"useCases": "Adult & children's apparel, loungewear, headbands & scarves",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "93% cotton, 7% spandex",
		"fabricConstruction": "Knit, Jersey Knit",
		"color": "Optic White",
		"shrinkage": "7-8% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Stretch",
		"needleSize": "70, 75 (10,11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>",
		"pdpDetails": "<li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>"
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_ULTRA": {
		"name": "Cotton Spandex Jersey Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Spandex Jersey Ultra Fabric",
		"description": "A hint of stretch blended with a medium-weight cotton that’s perfect for apparel",
		"descriptionShop": "93% natural cotton, 7% spandex jersey knit fabric with plenty of softness and stretch",
		"longDescription": "Cotton Spandex Jersey offers a 4-way stretch with a light to medium-weight durable knit construction of 93% cotton and 7% spandex. With great stretch recovery and natural breathability due to the cotton content, this fabric is well suited for many projects, from loungewear and T-shirts to kids' and baby apparel. Best paired with lighter designs for softest feel and optimum color retention through washes.",
		"title": "Cotton Spandex Jersey Fabric | Spoonflower",
		"metaDescription": "Cotton Spandex Jersey offers a 4-way stretch with a medium-weight durable knit blend of cotton and spandex. Great for projects from loungewear and apparel!",
		"useCases": "Adult & children's apparel, loungewear, headbands & scarves",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "93% cotton, 7% spandex",
		"fabricConstruction": "Knit, Jersey Knit",
		"color": "Optic White",
		"shrinkage": "7-8% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Stretch",
		"needleSize": "70, 75 (10,11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>",
		"pdpDetails": "<li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>"
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_S": {
		"name": "Cotton Spandex Jersey",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Spandex Jersey Ultra Fabric",
		"description": "A hint of stretch blended with a medium-weight cotton that’s perfect for apparel",
		"descriptionShop": "93% natural cotton, 7% spandex jersey knit fabric with plenty of softness and stretch",
		"longDescription": "Cotton Spandex Jersey offers a 4-way stretch with a light to medium-weight durable knit construction of 93% cotton and 7% spandex. With great stretch recovery and natural breathability due to the cotton content, this fabric is well suited for many projects, from loungewear and T-shirts to kids' and baby apparel. Best paired with lighter designs for softest feel and optimum color retention through washes.",
		"title": "Cotton Spandex Jersey Fabric | Spoonflower",
		"metaDescription": "Cotton Spandex Jersey offers a 4-way stretch with a medium-weight durable knit blend of cotton and spandex. Great for projects from loungewear and apparel!",
		"useCases": "Adult & children's apparel, loungewear, headbands & scarves",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "93% cotton, 7% spandex",
		"fabricConstruction": "Knit, Jersey Knit",
		"color": "Optic White",
		"shrinkage": "7-8% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Stretch",
		"needleSize": "70, 75 (10,11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>",
		"pdpDetails": "<li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>"
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_BRAVA": {
		"name": "Cotton Spandex Jersey",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Spandex Jersey Ultra Fabric",
		"description": "A hint of stretch blended with a medium-weight cotton that’s perfect for apparel",
		"descriptionShop": "93% natural cotton, 7% spandex jersey knit fabric with plenty of softness and stretch",
		"longDescription": "Cotton Spandex Jersey offers a 4-way stretch with a light to medium-weight durable knit construction of 93% cotton and 7% spandex. With great stretch recovery and natural breathability due to the cotton content, this fabric is well suited for many projects, from loungewear and T-shirts to kids' and baby apparel. Best paired with lighter designs for softest feel and optimum color retention through washes.",
		"title": "Cotton Spandex Jersey Fabric | Spoonflower",
		"metaDescription": "Cotton Spandex Jersey offers a 4-way stretch with a medium-weight durable knit blend of cotton and spandex. Great for projects from loungewear and apparel!",
		"useCases": "Adult & children's apparel, loungewear, headbands & scarves",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "93% cotton, 7% spandex",
		"fabricConstruction": "Knit, Jersey Knit",
		"color": "Optic White",
		"shrinkage": "7-8% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Stretch",
		"needleSize": "70, 75 (10,11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>",
		"pdpDetails": "<li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>"
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_PRIMA": {
		"name": "Cotton Spandex Jersey",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Spandex Jersey Ultra Fabric",
		"description": "A hint of stretch blended with a medium-weight cotton that’s perfect for apparel",
		"descriptionShop": "93% natural cotton, 7% spandex jersey knit fabric with plenty of softness and stretch",
		"longDescription": "Cotton Spandex Jersey offers a 4-way stretch with a light to medium-weight durable knit construction of 93% cotton and 7% spandex. With great stretch recovery and natural breathability due to the cotton content, this fabric is well suited for many projects, from loungewear and T-shirts to kids' and baby apparel. Best paired with lighter designs for softest feel and optimum color retention through washes.",
		"title": "Cotton Spandex Jersey Fabric | Spoonflower",
		"metaDescription": "Cotton Spandex Jersey offers a 4-way stretch with a medium-weight durable knit blend of cotton and spandex. Great for projects from loungewear and apparel!",
		"useCases": "Adult & children's apparel, loungewear, headbands & scarves",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"fabricContent": "93% cotton, 7% spandex",
		"fabricConstruction": "Knit, Jersey Knit",
		"color": "Optic White",
		"shrinkage": "7-8% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Stretch",
		"needleSize": "70, 75 (10,11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>",
		"pdpDetails": "<li>Stretch: 4-way: ≥ 50% horizontal stretch; ≥ 50% vertical stretch</li> <li>Estimated shrinkage: 7-10% in length and 1-3% in width</li> <li>Appropriate for adult and baby apparel and loungewear</li>"
	},
	"FABRIC_LONGLEAF_SATEEN_GRAND": {
		"name": "Wide Cotton Sateen 6.5 oz",
		"cartName": "{width} x {height} {measurementUnit} of Longleaf Sateen Grand Fabric",
		"description": "116\" wide, medium weight, 100% natural cotton fabric with a low-luster face and beautiful drape",
		"title": "Wide Cotton Sateen 6.5 oz Fabric | Spoonflower",
		"longDescription": "Our Wide Cotton Sateen 6.5 oz is a 116\" wide, low-luster 100% cotton sateen fabric. Characterized by a medium weight and a beautiful, structured drape with dense color, this is the perfect fabric for creating classic cotton tablecloths and runners. Though its width is perfectly suited to table linens and drapery, excellent printed detail and strong wash durability also make Wide Cotton Sateen 6.5 oz an ideal fabric for smaller home decor items and accessories like napkins and throw pillows.",
		"metaDescription": "Starting at just $5, create custom printed Longleaf Sateen Grand fabric. Upload your own design or shop our marketplace of designs to create customized Longleaf Sateen Grand fabric.",
		"color": "Optic White",
		"useCases": "Perfect for making tablecloths, table runners, drapery, napkins and throw pillows.",
		"careInstructions": "Machine wash warm. Tumble dry low. Warm iron as needed. Wool dryer balls can help with even drying. Wash items made of the same fiber content together. Do not bleach. Slight Shrinkage is expected during the first wash cycle.",
		"countryOrigin": "Pakistan",
		"printing": "Single-sided, digitally printed in the US using water-based inks.",
		"fabricContent": "100% Cotton",
		"fabricConstruction": "Woven, Sateen Weave",
		"shrinkage": "5-6% in length and 3-4% in width",
		"threadCount": "190",
		"needleType": "Universal",
		"needleSize": "90, 100",
		"threadSize": "24, 27, 30",
		"stitchesInch": "8 to 10",
		"threadType": "100% Polyester - all purpose",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for making tablecloths, table runners, drapery, napkins and throw pillows.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for making tablecloths, table runners, drapery, napkins and throw pillows.</li>"
	},
	"FABRIC_ORGANIC_COTTON_SATEEN_PRIMA": {
		"name": "Organic Cotton Sateen Prima",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Sateen Prima Fabric",
		"description": "100% organic cotton fabric with a subtle sheen and soft hand",
		"details": "<li>Optic White</li> <li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows.</li>"
	},
	"FABRIC_OSO_PIMA_COTTON": {
		"name": "OSO Pima Cotton",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_OSO_PIMA_COTTON_WIDE": {
		"name": "OSO Pima Cotton Wide",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_OSO_GAUZE": {
		"name": "OSO Gauze",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_OSO_FRENCH_TWILL": {
		"name": "OSO French Twill",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_OSO_NATURAL_TWILL": {
		"name": "OSO Natural Twill",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_PETAL_SIGNATURE_COTTON": {
		"name": "Petal Signature Cotton®",
		"cartName": "{width} x {height} {measurementUnit} of Petal Signature Cotton Fabric",
		"description": "Everyday-use cotton for quilting, crafts and apparel",
		"longDescription": "Petal Signature Cotton™ is an everyday-use 100% cotton fabric, with a weight that lends itself to any number of applications, including quilting, crafts, cut-and-sew projects—even apparel. Based on input from our community, we developed Petal to showcase vivid printed colors and clarity of design detail. Makers searching for the best choice for a custom-designed basic crafting cotton have met their match. Petal Signature Cotton has the look and feel of higher-priced quilting-weight cotton fabrics. You won't find this Spoonflower-exclusive fabric anywhere else!",
		"title": "Petal Signature Cotton® Fabric | Spoonflower",
		"metaDescription": "Our high-quality, 100% natural Petal Signature Cotton™ fabric is ideal for any project from quilting to crafting and digitally printed using quality inks.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, Plain Weave",
		"useCases": "Quilting, craft projects, costuming, toys & accessories",
		"careInstructions": "Machine wash warm or cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "Indonesia",
		"printing": "Single-sided, digitally printed in the US using non-toxic, water-based inks.",
		"color": "White",
		"shrinkage": "5-6% in length and 2-3% in width",
		"abrasionWyzenbeekDuck": "30,000 double rubs (ASTM D4157)",
		"abrasionMartindale": "20,000 cycles (ASTM D4966)",
		"fabricPilling": "4.5",
		"dryCrocking": "Exceeds 3.0 (AATCC 8)",
		"wetCrocking": "Exceeds 3.5 (AATCC 8)",
		"threadCount": "120",
		"needleType": "Universal",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-14 per inch (4-6 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>White</li> <li>Estimated shrinkage: 5-6% in length and 2-3% in width</li> <li>Perfect for quilting, craft projects, costuming, toys and accessories.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-6% in length and 2-3% in width</li> <li>Perfect for quilting, craft projects, costuming, toys and accessories.</li>"
	},
	"FABRIC_PETAL_SIGNATURE_COTTON_S": {
		"name": "Petal Signature Cotton",
		"description": "100% natural cotton fabric that's versatile, soft and easy to sew",
		"details": "<li>White</li> <li>Estimated shrinkage: 2-3% in length and 4-5% in width</li> <li>Perfect for quilting, craft projects, costuming, toys and accessories.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 4-5% in width</li> <li>Perfect for quilting, craft projects, costuming, toys and accessories.</li>"
	},
	"FABRIC_PETAL_SIGNATURE_COTTON_DE": {
		"name": "Petal Signature Cotton DE",
		"cartName": "{width} x {height} {measurementUnit} of Petal Signature Cotton DE Fabric",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_PETAL_SIGNATURE_COTTON_BRAVA": {
		"name": "Petal Signature Cotton Brava",
		"cartName": "{width} x {height} {measurementUnit} of Petal Signature Cotton Brava Fabric",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_TRIBUTE_01_ASH": {
		"name": "TRIBUTE 01 ASH",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_HARBOR_01_SAND": {
		"name": "HARBOR 01 SAND",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_HARBOR_02_MIST": {
		"name": "HARBOR 02 MIST",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_CREDENCE_01_BUFF": {
		"name": "CREDENCE 01 BUFF",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_HARMONY_02_GOLDEN": {
		"name": "HARMONY 02 GOLDEN",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_HARMONY_01_NATURAL": {
		"name": "HARMONY 01 NATURAL",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_GRACE_01_PURE": {
		"name": "GRACE 01 PURE",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_HONOR_01_BRIGHT": {
		"name": "HONOR 01 BRIGHT",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_GRANT_01_IVORY": {
		"name": "GRANT 01 IVORY",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_TRANQUILITY_01_CLOUD": {
		"name": "TRANQUILITY 01 CLOUD",
		"description": " ",
		"details": "<div></div>",
		"pdpDetails": "<div></div>"
	},
	"FABRIC_CYPRESS_COTTON_BRAVA": {
		"name": "Cypress Cotton Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Cypress Cotton Canvas Fabric",
		"description": "Sophisticated basketweave texture offers lived-in luxury for upholstery, bags and home decor",
		"longDescription": "This thick upholstery-grade 100% cotton fabric offers a sophisticated basketweave texture with rich color and an enviable weathered look after the first wash. Sturdy and structured, it features strong performance and is a pleasure to sew. From curtains and throw pillows to tote bags and home accents, Cypress Cotton Canvas is a top pick for furniture rehabbers, interior designers and hobby sewists.",
		"title": "Cypress Cotton Canvas Fabric | Spoonflower",
		"metaDescription": "This thick upholstery-grade 100% cotton fabric offers a sophisticated basketweave texture with rich color and an enviable weathered look after the first wash.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, 2x2 Basketweave",
		"useCases": "Upholstery, totes or bags, heavyweight drapery, indoor home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Non-optic white",
		"shrinkage": "5-7% length x 0-1% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "6,000 double rubs",
		"abrasionWyzenbeekWire": "51,000 double rubs",
		"abrasionMartindale": "25,000 cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 4.5 (AATCC TM 8)",
		"wetCrocking": "Exceeds 3 (AATCC TM 8)",
		"threadCount": "100",
		"colorfastness": "60 hrs - 5 (AATCC TM 16.3)",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "90 (14)",
		"threadSize": "50",
		"stitchesInch": "9-11 per inch (3.5-4 per cm)",
		"threadType": "100% cotton - Heavy or all purpose",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 5-7% in length and 0-1% in width</li> <li>Perfect for upholstery projects, heavyweight drapery, home accents and bags.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-7% in length and 0-1% in width</li> <li>Perfect for upholstery projects, heavyweight drapery, home accents and bags.</li>"
	},
	"FABRIC_ORGANIC_COTTON_SATEEN": {
		"name": "Organic Cotton Sateen",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Sateen Fabric",
		"description": "Eco-friendly, quilting-weight cotton with unmatched softness and slight sheen",
		"longDescription": "The ultimate quilting-weight cotton, 100% organic cotton sateen offers a very soft, smooth hand and subtle sheen with excellent color detail. Easy to sew with a beautiful drape, this fabric is a sophisticated choice for both home decor and apparel, from table linens and curtains to dresses, shirting and ties. Organic Cotton Sateen is our top pick for elegant quilts that feature fine detail, while it also shows versatility in applications such as lampshades, baby bonnets and eye masks.",
		"title": "Organic Cotton Sateen Fabric | Spoonflower",
		"metaDescription": "Our Sateen is the ultimate quilting-weight cotton! Made from 100% organic cotton, it offers a very soft, smooth hand and subtle sheen with ideal color detail.",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Woven, Sateen Weave",
		"useCases": "Craft projects, lining for bags & apparel, home & wedding decor, soft accessories",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Optic white",
		"shrinkage": "1-4% length x 3-4% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "6,000 double rubs",
		"abrasionMartindale": "25,000 double rubs",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 3.5 (AATCC TM 8)",
		"wetCrocking": "Exceeds 3.5 (AATCC TM 8)",
		"threadCount": "220",
		"colorfastness": "40 hrs - 5 (AATCC TM 16.3)|60 hrs - 5 (AATCC TM 16.3)",
		"needleType": "Universal",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "25, 27, 30",
		"stitchesInch": "10-14 per inch (4-6 per cm)",
		"threadType": "100% cotton - all purpose",
		"details": "<li>Optic White</li> <li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-4% in length and width</li> <li>Appropriate for clothing and pillows</li>"
	},
	"FABRIC_PERENNIAL_SATEEN_GRAND_ADMIN": {
		"name": "Perennial Sateen Grand Admin",
		"cartName": "{width} x {height} {measurementUnit} of Perennial Sateen Grand Admin Fabric",
		"description": "100% natural cotton fabric with a subtle sheen and smooth hand",
		"details": "<li>Non-optic white</li> <li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for making duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-3% in length and 3-4% in width</li> <li>Perfect for making duvets, comforters, sheeting, quilt backings, tapestries, backdrops, and drapery.</li>"
	},
	"FABRIC_LONGLEAF_SATEEN_GRAND_ADMIN": {
		"name": "Longleaf Sateen Grand Admin",
		"cartName": "{width} x {height} {measurementUnit} of Longleaf Sateen Grand Admin Fabric",
		"description": "116\" wide, medium weight, 100% natural cotton fabric with a low-luster face and beautiful drape",
		"details": "<li>Optic white</li> <li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for making tablecloths, table runners, drapery, napkins and throw pillows.</li>",
		"pdpDetails": "<li>Estimated shrinkage: 5-6% in length and 3-4% in width</li> <li>Perfect for making tablecloths, table runners, drapery, napkins and throw pillows.</li>"
	},
	"FABRIC_DOGWOOD_DENIM": {
		"name": "Dogwood Denim",
		"cartName": "{width} x {height} {measurementUnit} of Dogwood Denim Fabric",
		"description": "Rugged and sturdy for durable outerwear, bags, pants and upholstery",
		"longDescription": "This 100% cotton drill fabric is downright durable and softens with every wash. It’s the ideal choice for creating custom outerwear, bags, and bottom-weight apparel such as skirts, shorts and pants. With its sturdy construction, Dogwood Denim also could be the perfect pick for your next upholstery project, from floor poufs to ottomans.",
		"title": "Dogwood Denim Fabric | Spoonflower",
		"metaDescription": "This 100% cotton drill fabric is downright durable and softens with every wash. It’s the ideal choice for creating custom outerwear, bags, and apparel.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, 3x1 Twill Weave",
		"useCases": "Skirts, shorts & pants, jackets & outerwear, bags & backpacks, home decor, upholstery",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "Pakistan",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "6-9% length x 1-2% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "12,000 double rubs",
		"abrasionWyzenbeekWire": "21,000 double rubs",
		"abrasionMartindale": "40,000 cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 3 (AATCC TM 8)",
		"wetCrocking": "Exceeds 2.5 (AATCC TM 8)",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "100, 110 (16, 18)",
		"threadSize": "40, 45, 50, 60",
		"stitchesInch": "8-10 per inch (3-4 per cm)",
		"threadType": "100% polyester - Heavy or all purpose",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 7-8% in length and 1-2% in width</li> <li>Appropriate for skirts, shorts, pants, outerwear, bags and backpacks, and low-traffic upholstery</li>",
		"pdpDetails": "<li>Estimated shrinkage: 7-8% in length and 1-2% in width</li> <li>Appropriate for skirts, shorts, pants, outerwear, bags and backpacks, and low-traffic upholstery</li>"
	},
	"FABRIC_LIGHTWEIGHT_COTTON_TWILL": {
		"name": "Lightweight Cotton Twill",
		"cartName": "{width} x {height} {measurementUnit} of Lightweight Cotton Twill Fabric",
		"description": "Flexible twill construction ideal for structured apparel and bags",
		"longDescription": "Lightweight Cotton Twill is woven from 100% cotton with a flexible twill construction. Structured yet drapable and offering a soft hand and natural wrinkle resistance, this fabric is easy to sew with for home decor, apparel and pet accessories. Try Lightweight Cotton Twill for a variety of projects from pants, skirts and suiting to pillows, drapery or table linens. Aprons, tote bags, pet beds—even wall art and espadrilles—are some of the many creations that have been dreamed up by our community.",
		"title": "Lightweight Cotton Twill Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Lightweight Cotton Twill is a flexible woven fabric that is drapable with natural wrinkle resistance. Perfect for all your projects!",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Woven, 3x1 Twill Weave",
		"useCases": "Tote bags, pants, coats & jackets, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "Pakistan",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "4-5% length x 1-2% width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "12,000 double rubs",
		"abrasionWyzenbeekWire": "6,000 double rubs",
		"abrasionMartindale": "35,000 cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 3.5 (AATCC TM 8)",
		"wetCrocking": "Exceeds 3 (AATCC TM 8)",
		"threadCount": "100",
		"needleType": "Universal",
		"needleSize": "80-90",
		"threadSize": "24, 27, 30",
		"stitchesInch": "8-10 per inch (3-4 per cm)",
		"threadType": "100% polyester - all purpose",
		"details": "<li>White</li> <li>Estimated shrinkage: 4-6% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>",
		"pdpDetails": "<li>Estimated shrinkage: 4-6% in length and 1-2% in width</li> <li>Appropriate for home decorating including drapery, table linens and pillows, banners, tote bags, pants, coats and jackets</li>"
	},
	"FABRIC_BELGIAN_LINEN": {
		"name": "Belgian Linen",
		"cartName": "{width} x {height} {measurementUnit} of Belgian Linen",
		"description": "Unmatched elegance on upholstery, bags, curtains and home decor",
		"longDescription": "Our luxurious 100% Belgian Linen™ features the finest flax in the world, grown ecologically in the Flanders region and woven by Libeco™, which has set the highest standards for textiles since 1858. This medium-weight linen fabric showcases lustrous color over the beautifully textured surface. Stronger than cotton and naturally hypoallergenic, Belgian Linen™ will soften with everyday use and is a top-of-the-line offering for decorators and makers interested in unmatched elegance on upholstery, bags and home decor.",
		"title": "Belgian Linen Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s luxurious 100% Belgian Linen™ features the finest flax in the world, woven by Libeco™, which has set the highest standards for textiles since 1858.",
		"fabricContent": "100% Linen",
		"fabricConstruction": "Woven, Plain Weave",
		"useCases": "Indoor, home decor, upholstery, drapery, luxurious accessories, multi-purpose",
		"careInstructions": "Machine wash warm or cool on a gentle/delicate setting. Machine dry on a low temperature setting. Max temperature for both wash and dry is 100°F (38°C). To prevent wrinkling it is suggested to not fully dry the linen during laundering. Iron on the reverse side of the fabric with steam to help soften. Do not bleach.",
		"countryOrigin": "Belgium",
		"printing": "Printed in the US using eco-friendly, water based inks",
		"color": "White",
		"shrinkage": "9-11% length x 3-5% width",
		"abrasionWyzenbeekDuck": "27,000 double rubs",
		"abrasionWyzenbeekWire": "80,000 double rubs",
		"abrasionMartindale": "15,000 cycles",
		"fabricPilling": "4.5",
		"dryCrocking": "Exceeds 4 (AATCC TM 8)",
		"wetCrocking": "Exceeds 3.5 (AATCC TM 8)",
		"threadCount": "50",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "90, 100 (14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "Coats & Clark Dual Duty XP General Purpose or Dual Duty XP Heavy; Mettler Seracor, Seralon, Silk Finish Cotton 40, or 50; Gueterman Sew-All Thread or Extra Strong",
		"certification": "Certified Belgian Linen™",
		"details": "<li>Natural White</li> <li>Printable width: 54\" in (137 cm)</li> <li>9.88 oz per square yard (335 gsm)</li> <li>Perfect for home decor, upholstery and luxurious accessories</li>",
		"pdpDetails": "<li>Printable width: 54\" in (137 cm)</li> <li>9.88 oz per square yard (335 gsm)</li> <li>Perfect for home decor, upholstery and luxurious accessories</li>"
	},
	"FABRIC_LINEN_COTTON_CANVAS_ULTRA": {
		"name": "Linen Cotton Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Linen Cotton Canvas",
		"description": "Durability of linen with the softness of cotton for wall art, bags, tea towels and more",
		"longDescription": "With a lovely natural texture and medium weight, this fabric blend of 55% linen and 45% cotton offers the durability of linen with the softness of cotton. The slubby hand lends character to table linens, while its absorbency makes it a favorite for tea towels. Makers love creating drapes and Roman shades from this fabric due to its medium light transparency, and it adds charm to throw pillows. As one of our most versatile textiles, Linen Cotton Canvas offers applications from bags and backpacks to embroidery projects and stretched canvas art for a world of creative possibilities.",
		"fabricContent": "55% linen, 45% cotton",
		"fabricConstruction": "Woven, 2x1 Oxford Weave",
		"useCases": "Tea towels, table linens, tote bags & clutches, drapery, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred.  Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "3-6% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "12,000 double rubs",
		"abrasionWyzenbeekWire": "30,000 double rubs",
		"abrasionMartindale": "10,000 cycles",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 4 (AATCC TM 8)",
		"wetCrocking": "Exceeds 4 (AATCC TM 8)",
		"threadCount": "150",
		"colorfastness": "40 hrs - 5 (AATCC TM 16.3)|60 hrs - 5 (AATCC TM 16.3)",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - Heavy",
		"certification": "ISO 9001:2000 Certificate",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>"
	},
	"FABRIC_LINEN_COTTON_CANVAS_PRIMA": {
		"name": "Linen Cotton Canvas Prima",
		"cartName": "{width} x {height} {measurementUnit} of Linen Cotton Canvas Prima",
		"description": "Durability of linen with the softness of cotton for wall art, bags, tea towels and more",
		"longDescription": "With a lovely natural texture and medium weight, this fabric blend of 55% linen and 45% cotton offers the durability of linen with the softness of cotton. The slubby hand lends character to table linens, while its absorbency makes it a favorite for tea towels. Makers love creating drapes and Roman shades from this fabric due to its medium light transparency, and it adds charm to throw pillows. As one of our most versatile textiles, Linen Cotton Canvas offers applications from bags and backpacks to embroidery projects and stretched canvas art for a world of creative possibilities.",
		"fabricContent": "55% linen, 45% cotton",
		"fabricConstruction": "Woven, 2x1 Oxford Weave",
		"useCases": "Tea towels, table linens, tote bags & clutches, drapery, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred.  Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "3-6% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "12,000 double rubs",
		"abrasionWyzenbeekWire": "30,000 double rubs",
		"abrasionMartindale": "10,000 cycles",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 4 (AATCC TM 8)",
		"wetCrocking": "Exceeds 4 (AATCC TM 8)",
		"threadCount": "150",
		"colorfastness": "40 hrs - 5 (AATCC TM 16.3)|60 hrs - 5 (AATCC TM 16.3)",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - Heavy",
		"certification": "ISO 9001:2000 Certificate",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>"
	},
	"FABRIC_LINEN_COTTON_CANVAS": {
		"name": "Linen Cotton Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Linen Cotton Canvas Fabric",
		"description": "Durability of linen with the softness of cotton for wall art, bags, tea towels and more",
		"longDescription": "With a lovely natural texture and medium weight, this fabric blend of 55% linen and 45% cotton offers the durability of linen with the softness of cotton. The slubby hand lends character to table linens, while its absorbency makes it a favorite for tea towels. Makers love creating drapes and Roman shades from this fabric due to its medium light transparency, and it adds charm to throw pillows. As one of our most versatile textiles, Linen Cotton Canvas offers applications from bags and backpacks to embroidery projects and stretched canvas art for a world of creative possibilities.",
		"title": "Linen Cotton Canvas Fabric | Spoonflower",
		"metaDescription": "With a lovely natural texture and medium weight, our Linen Cotton Canvas offers a hybrid of durability and softness. Perfect for table linens or tea towels!",
		"fabricContent": "45% linen, 55% cotton",
		"fabricConstruction": "Woven, 2x1 Oxford Weave",
		"useCases": "Tea towels, table linens, tote bags & clutches, drapery, home decor",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric. Dry clean if preferred.  Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "3-6% length x 0-1% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "12,000 double rubs",
		"abrasionWyzenbeekWire": "30,000 double rubs",
		"abrasionMartindale": "10,000 cycles",
		"fabricPilling": "5",
		"dryCrocking": "Exceeds 4 (AATCC TM 8)",
		"wetCrocking": "Exceeds 4 (AATCC TM 8)",
		"threadCount": "150",
		"colorfastness": "40 hrs - 5 (AATCC TM 16.3)|60 hrs - 5 (AATCC TM 16.3)",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - Heavy",
		"certification": "ISO 9001:2000 Certificate",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>",
		"pdpDetails": "<li>Estimated shrinkage: 3-6% in length and 0-1% in width</li> <li>Appropriate for tablecloths, tea towels, dresses, bags, and pillows</li>"
	},
	"FABRIC_MINKY": {
		"name": "Minky",
		"cartName": "{width} x {height} {measurementUnit} of Minky Fabric",
		"description": "Ultra cuddly with a soft napped finish for blankets and cool weather projects",
		"longDescription": "A Spoonflower exclusive fabric, Minky is an incredibly plush 100% polyester fabric beloved for its soft, short pile and rich, saturated color. Offering a hint of stretch, this medium-weight fabric has strong wash durability and a luxe texture. Makers choose Minky for cuddle-worthy throw blankets, baby blankets, soft toys and even cool weather apparel. Quilters will love the drape of this cozy fabric for quilts and backings.",
		"title": "Minky Fabric | Spoonflower",
		"metaDescription": "A Spoonflower exclusive, our Minky is a polyester fabric beloved for its soft, short pile and rich color. This fabric is durable and has a luxe texture.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Knit, Warp Knit",
		"useCases": "Blankets, throws & quilts, loungewear, winter accessories, plushies & stuffed toys",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "China",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "1-2% in length and 0-1% in width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"fabricPilling": "5",
		"pileHeight": "2 mm",
		"needleType": "Ball Point/Stretch",
		"needleSize": "80, 90, 100 (12, 14, 16)",
		"threadSize": "30, 35, 40",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - Heavy or all purpose",
		"details": "<li>White</li> <li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for blankets, lounge wear, and cold weather accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-2% in length and 0-1% in width</li> <li>Appropriate for blankets, lounge wear, and cold weather accessories</li>"
	},
	"FABRIC_ORGANIC_COTTON_KNIT_ULTRA": {
		"name": "Organic Cotton Knit Ultra",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Knit Ultra Fabric",
		"description": "Eco-friendly, medium-weight interlock knit perfect for garments with a bit of structure",
		"longDescription": "Our 100% Organic Cotton Knit fabric offers a medium-weight interlock construction that is extremely soft with a smooth hand. Featuring a natural 2-way stretch, this fabric has been a longtime favorite of makers who want playful designs on cozy apparel and accessories. Made with ecologically grown cotton, it has the perfect laid-back look and feel, making it a great choice for baby bibs and hats as well as casual lounge pants, dresses and tops for adults. Since it doesn't fray, it's also ideal for no-sew reusable 'paper' towels!",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Knit, Interlock Knit",
		"useCases": "Children's apparel, lightweight blankets, changing pad covers",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "2-4% in length and 6-8% in width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Ball Point",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - All purpose",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>"
	},
	"FABRIC_ORGANIC_COTTON_KNIT_S": {
		"name": "Organic Cotton Knit",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Knit Fabric",
		"description": "Eco-friendly, medium-weight interlock knit perfect for garments with a bit of structure",
		"longDescription": "Our 100% Organic Cotton Knit fabric offers a medium-weight interlock construction that is extremely soft with a smooth hand. Featuring a natural 2-way stretch, this fabric has been a longtime favorite of makers who want playful designs on cozy apparel and accessories. Made with ecologically grown cotton, it has the perfect laid-back look and feel, making it a great choice for baby bibs and hats as well as casual lounge pants, dresses and tops for adults. Since it doesn't fray, it's also ideal for no-sew reusable 'paper' towels!",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Knit, Interlock Knit",
		"useCases": "Children's apparel, lightweight blankets, changing pad covers",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "2-4% in length and 6-8% in width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Ball Point",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - All purpose",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>"
	},
	"FABRIC_ORGANIC_COTTON_KNIT_PRIMA": {
		"name": "Organic Cotton Knit",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Knit Fabric",
		"description": "Eco-friendly, medium-weight interlock knit perfect for garments with a bit of structure",
		"longDescription": "Our 100% Organic Cotton Knit fabric offers a medium-weight interlock construction that is extremely soft with a smooth hand. Featuring a natural 2-way stretch, this fabric has been a longtime favorite of makers who want playful designs on cozy apparel and accessories. Made with ecologically grown cotton, it has the perfect laid-back look and feel, making it a great choice for baby bibs and hats as well as casual lounge pants, dresses and tops for adults. Since it doesn't fray, it's also ideal for no-sew reusable 'paper' towels!",
		"title": "Organic Cotton Knit Fabric | Spoonflower",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Knit, Interlock Knit",
		"useCases": "Children's apparel, lightweight blankets, changing pad covers",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "2-4% in length and 6-8% in width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Ball Point",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - All purpose",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>"
	},
	"FABRIC_ORGANIC_COTTON_KNIT_BRAVA": {
		"name": "Organic Cotton Knit",
		"cartName": "{width} x {height} {measurementUnit} of Organic Cotton Knit Fabric",
		"description": "Eco-friendly, medium-weight interlock knit perfect for garments with a bit of structure",
		"longDescription": "Our 100% Organic Cotton Knit fabric offers a medium-weight interlock construction that is extremely soft with a smooth hand. Featuring a natural 2-way stretch, this fabric has been a longtime favorite of makers who want playful designs on cozy apparel and accessories. Made with ecologically grown cotton, it has the perfect laid-back look and feel, making it a great choice for baby bibs and hats as well as casual lounge pants, dresses and tops for adults. Since it doesn't fray, it's also ideal for no-sew reusable 'paper' towels!",
		"title": "Organic Cotton Knit Fabric | Spoonflower",
		"metaDescription": "Our 100% Organic Cotton Knit fabric offers a medium-weight construction with a soft and smooth hand. Featuring ample stretch, it is a favorite of makers!",
		"fabricContent": "100% organic cotton",
		"fabricConstruction": "Knit, Interlock Knit",
		"useCases": "Children's apparel, lightweight blankets, changing pad covers",
		"careInstructions": "Machine wash cool on a gentle/delicate setting, using phosphate-free detergent. Machine dry on a low temperature setting. Iron on the reverse side of the fabric.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "Natural white",
		"shrinkage": "2-4% in length and 6-8% in width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"stretchDetail": "2-way: ≥ 25% horizontal stretch; < 25% vertical stretch",
		"fabricPilling": "5",
		"needleType": "Ball Point",
		"needleSize": "70, 75 (10, 11)",
		"threadSize": "24, 27, 30",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% cotton - All purpose",
		"details": "<li>Natural white</li> <li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>",
		"pdpDetails": "<li>Estimated shrinkage: 2-4% in length and 6-8% in width</li> <li>Appropriate for children and adult apparel items, including t-shirts, dresses, baby clothes, and more</li>"
	},
	"FABRIC_RECYCLED_CANVAS": {
		"name": "Recycled Canvas",
		"cartName": "{width} x {height} {measurementUnit} of Recycled Canvas",
		"description": "A woven canvas featuring REPREVE® recycled polyester suitable for seasonal outdoor use.",
		"longDescription": "Recycled Canvas is a 100% polyester fabric offering a smooth drape, soft hand and radiant color saturation with a subtle luster. Featuring 50% REPREVE high-quality recycled fiber that helps conserve energy and natural resources, each yard or meter of this performance fabric is made from 10 recycled bottles. Now that’s a better choice for the environment! From upholstery and throw pillows to dog collars, tote bags and wall hangings, this durable fabric holds up to the elements as an indoor/seasonal outdoor weather-resistant solution. Due to recycled content colors may shift from item to item.",
		"longDescriptionEmphazised": "REPREVE® is a registered trademark of Unifi, Inc. in the US and other regions.",
		"title": "Recycled Canvas Fabric | Spoonflower",
		"metaDescription": "Spoonflower’s Recycled Canvas is a 100% polyester fabric offering a smooth drape and radiant color saturation with a subtle luster. Ideal for upholstery!",
		"fabricContent": "50% REPREVE® recycled polyester; 50% polyester",
		"fabricConstruction": "Woven",
		"useCases": "Upholstery, home decor, outdoor pillows, toys & play mats, outerwear",
		"careInstructions": "Machine wash in cool water using a gentle machine cycle. Machine dry using a low temperature setting and remove promptly to avoid wrinkles.",
		"countryOrigin": "USA",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "0-3% length x 0-1% width.",
		"abrasionWyzenbeekDuck": "100,000+ double rubs (ASTM D4157 - 13)",
		"abrasionMartindale": "60,000+ cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "5",
		"wetCrocking": "5",
		"colorfastness": "60 hrs - 3 (AATCC TM 16.3)",
		"flammability": "Cal TB 117 meets | UFAC | NFPA 260 | NFPA 701\"",
		"needleType": "Universal",
		"needleSize": "80 - 90",
		"threadSize": "24, 27, 30",
		"stitchesInch": "8-10 per inch (3-4 per cm)",
		"threadType": "100% polyester - all purpose",
		"certification": "REPREVE® Certified",
		"details": "<li>White</li> <li>Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.</li> <li>REPREVE® is a registered trademark of Unifi, Inc. in the US and other regions.</li> <li>Appropriate for Upholstery, home decor, outdoor pillows, toys & play mats, outerwear, and more</li>",
		"pdpDetails": "<li>Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.</li> <li>REPREVE® is a registered trademark of Unifi, Inc. in the US and other regions.</li> <li>Appropriate for Upholstery, home decor, outdoor pillows, toys & play mats, outerwear, and more</li>"
	},
	"FABRIC_PERFORMANCE_VELVET": {
		"name": "Performance Velvet",
		"cartName": "{width} x {height} {measurementUnit} of Performance Velvet Fabric",
		"description": "Super-soft and durable matte velvet for statement-making decor, upholstery and accessories",
		"longDescription": "Performance Velvet is a highly durable 100% polyester knit fabric with a buttery soft feel and matte finish for bespoke home decor and upholstery projects. Featuring a short pile and rich, multidimensional texture, Performance Velvet looks similar to a high-end cotton-velvet fabric and offers a smooth, felted backing for beautiful drape. This family- and pet-friendly plush velvet upholstery fabric is resistant to stains and spills and offers a high abrasion threshold for applications such as throw pillows, poufs, blankets, curtains and upholstery projects. Makers will enjoy endless customization options for our luxurious, heavyweight Performance Velvet material including bags, accessories and seasonal decorations.",
		"title": "Performance Velvet Fabric | Spoonflower",
		"metaDescription": "Performance Velvet is a 100% polyester fabric with a short pile. Super-soft and durable matte velvet for statement-making decor, upholstery and accessories.",
		"fabricContent": "100% polyester",
		"fabricConstruction": "Knit, Warp Knit",
		"useCases": "Pillows, blankets, upholstery and accessories",
		"careInstructions": "Wash separately in cool water using a gentle machine cycle. Machine dry using a low temperature or permanent press setting and remove promptly to avoid wrinkles. Do not dry clean. Do not bleach.",
		"countryOrigin": "Turkey",
		"printing": "Single-sided, digitally printed in the US using eco-friendly, water-based inks.",
		"color": "White",
		"shrinkage": "1-3.5% in length x 0-1.5% in width – Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects.",
		"abrasionWyzenbeekDuck": "50,000+ double rubs (ASTM D4157)",
		"abrasionMartindale": "30,000+ cycles (ASTM D4966)",
		"fabricPilling": "5",
		"dryCrocking": "5 (AATCC TM 8)",
		"wetCrocking": "5 (AATCC TM 8)",
		"colorfastness": "60 hrs - 1 (AATCC TM 16.3)",
		"flammability": "Cal TB 117 meets | UFAC | NFPA 260",
		"breakingStrength": "50+ lbs in both directions (ASTM D5034)",
		"tearStrength": "6+ lbs in both directions (ASTM D2261)",
		"snagStrength": "5 (ASTM D3939)",
		"pileHeight": "1 mm",
		"needleType": "Universal Denim/Jeans",
		"needleSize": "90-110",
		"threadSize": "40-50",
		"stitchesInch": "10-12 per inch (4-5 per cm)",
		"threadType": "100% polyester - all purpose",
		"details": "<li>White</li> <li>Estimated shrinkage: 1-3.5% in length and 0-1.5% in width</li> <li>Appropriate for pillows, blankets, upholstery and accessories</li>",
		"pdpDetails": "<li>Estimated shrinkage: 1-3.5% in length and 0-1.5% in width</li> <li>Appropriate for pillows, blankets, upholstery and accessories</li>"
	},
	"FABRIC_COTTON_SILK": {
		"name": "Cotton Silk",
		"cartName": "{width} x {height} {measurementUnit} of Cotton Silk Fabric",
		"description": "A versatile, naturally breathable fabric with a slight sheen made of a luxurious blend of 51% cotton and 49% silk",
		"longDescription": "Cotton Silk, with the sheen of silk and the breathability of cotton, is a luxuriously comfortable fabric. Its versatility makes it a prime choice for projects that need a touch of elegance with a gentle feel. This fabric is ideal for dresses, blouses, top-weight apparel and accessories that need a graceful drape and flowy finish.",
		"title": "Shop our Cotton Silk Fabric | Spoonflower – Online Fabric Store",
		"metaDescription": "Shop our Cotton Silk fabric. A fabric with the sheen of silk and the comfortableness of cotton available in over 1 million brilliant designs from artists all over the world.",
		"fabricContent": "51% cotton, 49% silk",
		"fabricConstruction": "Sateen weave",
		"useCases": "Appropriate for dresses, blouses, top-weight apparel",
		"careInstructions": "Machine wash cold. Use only non-chlorine bleach when needed. Line dry. Cool iron if needed using a pressing cloth or by ironing on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Printed in the US using water based inks",
		"color": "White",
		"shrinkage": "4-6% length x 2-3% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects",
		"abrasionWyzenbeekDuck": null,
		"abrasionMartindale": null,
		"fabricPilling": "4",
		"dryCrocking": "4.5 (AATCC 8)",
		"wetCrocking": "4 (AATCC 8)",
		"threadCount": "368",
		"needleType": "Microtex/Universal",
		"needleSize": "70, 80",
		"threadSize": "16, 18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "All purpose 100% cotton or 100% polyester depending on your project",
		"details": "<li>Estimated Shrinkage: 4-6% in length and 2-3% width</li> <li>Appropriate for dresses, blouses, top-weight apparel</li>",
		"pdpDetails": "<li>Estimated Shrinkage: 4-6% in length and 2-3% width</li> <li>Appropriate for dresses, blouses, top-weight apparel</li>"
	},
	"FABRIC_ESSEX_LINEN": {
		"name": "Essex Linen",
		"cartName": "{width} x {height} {measurementUnit} of Essex Linen Fabric",
		"description": "A mid-weight fabric with a natural blend of 55% linen and 45% cotton for crisp yet drapable apparel projects that seek a softer finish",
		"longDescription": "Essex Linen a tastefully drapable fabric with a natural blend of 55% linen and 45% cotton. With a midweight feel and breathable qualities, this fabric offers the slight crispness of linen with the flexibility of cotton for apparel projects you'll want to wear year-round. This comfortable and versatile fabric choice makes stunningly soft skirts, dresses, pants, lightweight jackets, button-up shirts and more.",
		"title": "Shop our Essex Linen Fabric | Spoonflower – Online Fabric Store",
		"metaDescription": "Shop our Essex Linen fabric which is drapable and perfect for apparel making. This Cotton and Linen blend fabric is available in over 1 million brilliant designs from artists all over the world.",
		"fabricContent": "55% linen, 45% cotton",
		"fabricConstruction": "Plain weave",
		"useCases": "Appropriate for dresses, skirts, pants, lightweight jackets",
		"careInstructions": "Machine wash warm or cold. Use only non-chlorine bleach when needed. Tumble dry low. Warm iron if needed using a pressing cloth or by ironing on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Printed in the US using water based inks",
		"color": "White",
		"shrinkage": "4-6% length x 2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects",
		"abrasionWyzenbeekDuck": null,
		"abrasionMartindale": null,
		"fabricPilling": "4",
		"dryCrocking": "4.5 (AATCC 8)",
		"wetCrocking": "3.5 (AATCC 8)",
		"threadCount": "102",
		"needleType": "Universal",
		"needleSize": "80, 90",
		"threadSize": "24, 27, 30",
		"stitchesInch": "8-12 per inch (3-5 per cm)",
		"threadType": "All purpose 100% cotton or 100% polyester depending on your project",
		"details": "<li>Estimated Shrinkage: 4-6% in length and 2% in width</li> <li>Appropriate for dresses, skirts, pants, lightweight jackets</li>",
		"pdpDetails": "<li>Estimated Shrinkage: 4-6% in length and 2% in width</li> <li>Appropriate for dresses, skirts, pants, lightweight jackets</li>"
	},
	"FABRIC_SEERSUCKER": {
		"name": "Seersucker",
		"cartName": "{width} x {height} {measurementUnit} of Seersucker Fabric",
		"description": "A 100% cotton lightweight fabric with an alternating flat and puckered texture for breathable apparel to flaunt your favorite design",
		"longDescription": "A traditional pick with a classic texture, Seersucker is a 100% cotton fabric with alternating ridges of flat and puckered vertical stripes. This breathable fabric suits any top-weight apparel project, from dresses and blouses to sport coats and suits. With its well-known reputation and a distinct texture, this breathable fabric pairs well with an artist-made design to suit any fashion style.",
		"title": "Shop our Seersucker Fabric | Spoonflower – Online Fabric Store",
		"metaDescription": "Shop our Seersucker fabric which is 100% cotton and perfect for dresses and blouses and it's available in over 1 million brilliant designs from artists all over the world.",
		"fabricContent": "100% cotton",
		"fabricConstruction": "Seersucker (slack-tension) weave",
		"useCases": "Appropriate for dresses, blouses, top-weight apparel",
		"careInstructions": "Machine wash warm or cold. Use only non-chlorine bleach when needed. Tumble dry low. Warm iron if needed using a pressing cloth or by ironing on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Printed in the US using water based inks",
		"color": "White",
		"shrinkage": "2-4% length x 1-2% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects",
		"abrasionWyzenbeekDuck": null,
		"abrasionMartindale": null,
		"fabricPilling": "4",
		"dryCrocking": "4.5 (AATCC 8)",
		"wetCrocking": "3.5 (AATCC 8)",
		"threadCount": "132",
		"needleType": "Universal",
		"needleSize": "70, 80",
		"threadSize": "16, 18, 21, 24",
		"stitchesInch": "14-20 per inch (6-8 per cm)",
		"threadType": "All purpose 100% cotton or 100% polyester depending on your project",
		"details": "<li>Estimated Shrinkage: 2-4% in length and 1-2% in width</li> <li>Appropriate for dresses, blouses, top-weight apparel</li>",
		"pdpDetails": "<li>Estimated Shrinkage: 2-4% in length and 1-2% in width</li> <li>Appropriate for dresses, blouses, top-weight apparel</li>"
	},
	"FABRIC_SPORT_STRETCH_WOVEN": {
		"name": "Sport Stretch Woven",
		"cartName": "{width} x {height} {measurementUnit} of Sport Stretch Woven Fabric",
		"description": "A fast-drying, water-repellent fabric with 4-way stretch made for adventurous apparel including boardshorts, athletic wear and other outdoor clothing",
		"longDescription": "Sport Stretch Woven, made from 90% REPREVE® recycled polyester microfiber and 10% Spandex, is a fitting fabric for the outdoor enthusiast. This fast-drying, slight-stretch fabric is ready to take on waves or rays with a 50+ UPF rating and water-repellent coated finish. From boardshorts and athletic wear to outdoor apparel, Sport Stretch Woven has a soft, lightweight feel that won't slow you down when you're on your latest adventure.",
		"title": "Shop our Waterproof Sport Stretch Fabric | Spoonflower – Online Fabric Store",
		"metaDescription": "Shop our waterproof sport stretch woven fabric. This fast drying fabric has a 4 way stretch and is available in over 1 million brilliant designs from artists all over the world.",
		"fabricContent": "90% REPREVE® recycled polyester microfiber, 10% Spandex",
		"fabricConstruction": "Plain weave",
		"useCases": "Appropriate for boardshorts and athletic wear",
		"careInstructions": "Machine wash warm or cold. Use only non-chlorine bleach when needed. Tumble dry low. Warm iron if needed using a pressing cloth or by ironing on the unprinted side of the fabric. Woven fabrics may experience fraying when washed. We recommend serging or stay-stitching 1/4\"-1/2\" from the cut edge or using a delicates bag when pre-washing.",
		"countryOrigin": "China",
		"printing": "Printed in the US using water based inks",
		"color": "White",
		"shrinkage": "2% length x 2-3% width - Some shrinkage may occur during the print process and/or when washed. Pre-washing your fabric is recommended for most projects",
		"stretchDetail": "4-way stretch: <25% horizontal stretch; <25% vertical stretch",
		"abrasionWyzenbeekDuck": null,
		"abrasionMartindale": null,
		"fabricPilling": "4",
		"uvaUvb": "99.85% / 99.89%",
		"upfRating": "50+",
		"colorFastnessAcid": "4.5",
		"colorFastnessAlkaline": "4.5",
		"colorFastnessSeaWater": "4.5",
		"colorFastnessPoolWater": "4",
		"dryCrocking": "4.5 (AATCC 8)",
		"wetCrocking": "4.5 (AATCC 8)",
		"threadCount": "234",
		"needleType": "Ballpoint/Stretch",
		"needleSize": "80, 90",
		"threadSize": "24, 27, 30",
		"stitchesInch": "8-12 per inch (3-5 per cm)",
		"threadType": "All purpose 100% polyester",
		"details": "<li>Estimated Shrinkage: 2% in length and 2-3% in width</li> <li>4-way stretch: <25% horizontal stretch; <25% vertical stretch</li> <li>Appropriate for boardshorts and athletic wear</li>",
		"pdpDetails": "<li>Estimated Shrinkage: 2% in length and 2-3% in width</li> <li>4-way stretch: <25% horizontal stretch; <25% vertical stretch</li> <li>Appropriate for boardshorts and athletic wear</li>"
	},
	"WALLPAPER_SMOOTH": {
		"name": "Pre-Pasted",
		"subtitle": "Pre-Pasted - Easy to install, easy to remove, water-activated",
		"buttonName": "Pre-Pasted",
		"cartName": "{size} {measurementUnit} of Pre-Pasted Wallpaper",
		"description": "Water-activated, removable wallpaper that's simple to apply for a splash of pattern",
		"awardDescription": "Printed on Forest Stewardship Council® Certified wallpaper",
		"descriptionList": "Durable, with a water-activated adhesive backing | Fully removable (and recyclable) | Great for homes, rental spaces, or temporary art",
		"dimensions": {
			"METRIC": "61 cm wide | Available in lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "24 inches wide | Available in lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | 61 cm wide | Available in lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | 24 inches wide | Available in lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"details": "Subtle sheen, white | Formaldehyde-, phthalate-, and PVC-free",
		"collapsedDetails": {
			"METRIC": "<li>Durable, with a water-activated adhesive backing</li> <li>Fully removable (and recyclable)</li> <li class=\"space-bottom\">Great for homes, rental spaces, or temporary art</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>61 cm wide</li> <li class=\"space-bottom\">Available in lengths of 91 cm, 183 cm, 274 cm, 366 cm</li> <ul class=\"section-title\">Installation:</ul> <li>Water-activated, wet and apply</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/204279574\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Subtle sheen</li> <li class=\"space-bottom\">Formaldehyde-, phthalate-, and PVC-free</li> <ul class=\"section-title\">Note:</ul> <li>Not recommended for use on walls with texture or walls painted with “scrubbable” paints.</li> <li>For textured walls, wallpaper may not be a good option and rigorous testing is recommended.</li>",
			"IMPERIAL": "<li>Durable, with a water-activated adhesive backing</li> <li>Fully removable (and recyclable)</li> <li class=\"space-bottom\">Great for homes, rental spaces, or temporary art</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>24 in wide</li> <li class=\"space-bottom\">Available in lengths of 1 foot, 3, 6, 9 or 12 feet</li> <ul class=\"section-title\">Installation:</ul> <li>Water-activated, wet and apply</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/204279574\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Subtle sheen</li> <li class=\"space-bottom\">Formaldehyde-, phthalate-, and PVC-free</li> <ul class=\"section-title\">Note:</ul> <li>Not recommended for use on walls with texture or walls painted with \"scrubbable\" paints.</li> <li>For textured walls, wallpaper may not be a good option and rigorous testing is recommended.</li>"
		},
		"installation": "Water-activated, wet and apply {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://support.spoonflower.com/hc/en-us/articles/204279574",
		"note": "Not recommended for use on walls with texture or walls painted with \"scrubbable\" paints. | For textured walls, wallpaper may not be a good option and rigorous testing is recommended."
	},
	"WALLPAPER_ISOBAR": {
		"name": "Non-Pasted",
		"subtitle": "Non-Pasted - Traditional application for kitchens & bathrooms",
		"buttonName": "Non-Pasted",
		"cartName": "{size} {measurementUnit} of Non-Pasted Wallpaper",
		"description": "Scrub and scratch resistant wallpaper perfect for high traffic spaces - paste required",
		"awardDescription": "Printed on Forest Stewardship Council® Certified wallpaper",
		"descriptionList": "Subtle leathered texture, matte finish | Paste-activated, highly durable, resistant to mold, mildew and water | Great for residential and commercial spaces",
		"dimensions": {
			"METRIC": "Roll (installed): 61 cm W x 823 cm L | Untrimmed: 63.5 cm W x 823 cm L",
			"IMPERIAL": "Roll (installed): 24\" W x 27' L | Untrimmed: 25\" W x 27' L"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | Roll (installed): 61 cm W x 823 cm L | Untrimmed: 63.5 cm W x 823 cm L",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | Roll (installed): 24\" W x 27' L | Untrimmed: 25\" W x 27' L"
		},
		"details": "Matte finish with subtle leathered texture, natural white | Eco-friendly, Type II wallcovering, Class A/M1/B1 Fire Rated, PVC-free | Mold and mildew resistant",
		"collapsedDetails": {
			"METRIC": "<li>Subtle leathered texture, matte finish</li> <li>Paste-activated, highly durable, resistant to mold, mildew and water</li> <li class=\"space-bottom\">Great for residential and commercial spaces</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>Roll (installed): 61 cm W x 823 cm L</li> <li class=\"space-bottom\">Untrimmed: 63.5 cm W x 823 cm L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/360032679092\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Matte finish with subtle leathered texture</li> <li>Eco-friendly, Type II wallcovering, Class A/M1/B1 Fire Rated, PVC-free</li> <li class=\"space-bottom\">Mold and mildew resistant</li> <ul class=\"section-title\">Note:</ul> <li>Features scrub and scratch resistant performance</li>",
			"IMPERIAL": "<li>Subtle leathered texture, matte finish</li> <li>Paste-activated, highly durable, resistant to mold, mildew and water</li> <li class=\"space-bottom\">Great for residential and commercial spaces</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>Roll (installed): 24\" W x 27' L</li> <li class=\"space-bottom\">Untrimmed: 25\" W x 27' L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/360032679092\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Matte finish with subtle leathered texture</li> <li>Eco-friendly, Type II wallcovering, Class A/M1/B1 Fire Rated, PVC-free</li> <li class=\"space-bottom\">Mold and mildew resistant</li> <ul class=\"section-title\">Note:</ul> <li>Features scrub and scratch resistant performance</li>"
		},
		"installation": "Paste required and professional paper installer recommended {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://support.spoonflower.com/hc/en-us/articles/360032679092",
		"note": "Features scrub and scratch resistant performance"
	},
	"WALLPAPER_WOVEN": {
		"name": "Peel and Stick Removable Woven",
		"subtitle": "Peel and Stick Removable Woven - High-quality paper for simple DIY installation",
		"buttonName": "Peel and Stick Woven",
		"cartName": "{size} {measurementUnit} of Peel and Stick Removable Woven Wallpaper",
		"description": "Self adhesive, removable and repositionable wallcovering ideal for rented spaces and DIY decals",
		"descriptionList": "Woven-textured fabric with adhesive backing | Removable (and repositionable) | Great for walls, stair risers, drawer liners, bookcases, and an array of craft and decorating projects",
		"dimensions": {
			"METRIC": "61 cm wide | Available in panel lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "24 inches wide | Available in panel lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | 61 cm wide | Available in panel lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | 24 inches wide | Available in panel lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"details": "Matte finish, optic-white | Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free",
		"collapsedDetails": {
			"METRIC": "<li>{repeatSize} centimeter vertical repeat of the design</li> <li>61 cm wide and available in lengths of 91 cm, 183 cm, 274 cm, 366 cm</li> <li>Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free</li> <li>Perfect for accent walls, drawer liners, shelf liners, wall or tech decals, craft and home dec projects</li> <li>Matte finish</li> <li>NOTE: Not recommended for use on walls with texture, walls painted with “scrubbable” paints, or in children’s rooms. For children’s rooms, Pre-Pasted or Non-Pasted Wallpaper is preferred. For textured walls, wallpaper may not be a good option and rigorous testing is recommended.</li>",
			"IMPERIAL": "<li>{repeatSize} inch vertical repeat of the design</li> <li>24 inches wide and available in lengths of 1 foot, 3, 6, 9 or 12 feet</li> <li>Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free</li> <li>Perfect for accent walls, drawer liners, shelf liners, wall or tech decals, craft and home dec projects</li> <li>Matte finish</li> <li>NOTE: Not recommended for use on walls with texture, walls painted with “scrubbable” paints, or in children’s rooms. For children’s rooms, Pre-Pasted or Non-Pasted Wallpaper is preferred. For textured walls, wallpaper may not be a good option and rigorous testing is recommended.</li>"
		},
		"installation": "Removable, self-adhesive, thick and tear resistant {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://support.spoonflower.com/hc/en-us/articles/204279854",
		"note": "Not recommended for use on walls with texture or walls painted with \"scrubbable\" paints. | For textured walls, wallpaper may not be a good option and rigorous testing is recommended. | For children's rooms, Pre-Pasted or Non-Pasted Wallpaper is preferred."
	},
	"WALLPAPER_GRASSCLOTH": {
		"name": "Grasscloth",
		"subtitle": "Grasscloth - Unique, luxurious texture from 100% natural sisal",
		"buttonName": "Grasscloth",
		"cartName": "{size} {measurementUnit} of Grasscloth Wallpaper",
		"description": "Natural woven grasscloth wallpaper with a slight luster for the ultimate in timeless elegance - paste required",
		"descriptionList": "100% handcrafted natural sisal | Subtle luster, natural white with unique variations and striations | Recommended for lower traffic areas from dining rooms to accent walls | Paste required and professional paper installer recommended",
		"dimensions": {
			"METRIC": "Roll (installed): 61 cm W x 823 cm L",
			"IMPERIAL": "Roll (installed): 24\" W x 27' L"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | Roll (installed): 61 cm W x 823 cm L",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | Roll (installed): 24\" W x 27' L"
		},
		"details": "Type I wall covering - (Light Duty) typically used in areas such as residential spaces or lower traffic / touch and only light abrasion areas. | Class A fire-rated | Natural White",
		"collapsedDetails": {
			"METRIC": "<li>100% handcrafted natural sisal</li> <li>Subtle luster, natural white with unique variations and striations</li> <li>Recommended for lower traffic areas from dining rooms to accent walls</li> <li class=\"space-bottom\">Paste required and professional paper installer recommended</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li class=\"space-bottom\">Roll (installed): 61 cm W x 823 cm L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required and professional paper installer recommended</li> <li>Double cut - hand trimming required</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/4418183605901\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Type I wall covering - (Light Duty) typically used in areas such as residential spaces or lower traffic / touch and only light abrasion areas.</li> <li class=\"space-bottom\">Class A fire-rated</li> <ul class=\"section-title\">Note:</ul> <li>Disclaimer: Grasscloth is a natural handcrafted wallpaper with unique variations and irregularities in texture, color, and weave, which is an inherent quality of this material. When installed, the seams between the panels of grasscloth will be visible. Variations in color between batches may occur due to the manufacturing process. This paper is not suitable for high moisture areas such as bathrooms with showers or bathtubs.</li> <li>Care Instructions: Natural wallcoverings are very low maintenance. They may be lightly brushed with a soft surface feather duster to remove dust. Never use cleaning agents to remove a stain. If necessary only use the foam from a gentle household dishwashing liquid and rub softly with a soft white cloth.</li>",
			"IMPERIAL": "<li>100% handcrafted natural sisal</li> <li>Subtle luster, natural white with unique variations and striations</li> <li>Recommended for lower traffic areas from dining rooms to accent walls</li> <li class=\"space-bottom\">Paste required and professional paper installer recommended</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li class=\"space-bottom\">Roll (installed): 24\" W x 27' L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required and professional paper installer recommended</li> <li>Double cut - hand trimming required</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/4418183605901\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Type I wall covering - (Light Duty) typically used in areas such as residential spaces or lower traffic / touch and only light abrasion areas.</li> <li class=\"space-bottom\">Class A fire-rated</li> <ul class=\"section-title\">Note:</ul> <li>Disclaimer: Grasscloth is a natural handcrafted wallpaper with unique variations and irregularities in texture, color, and weave, which is an inherent quality of this material. When installed, the seams between the panels of grasscloth will be visible. Variations in color between batches may occur due to the manufacturing process. This paper is not suitable for high moisture areas such as bathrooms with showers or bathtubs.</li> <li>Care Instructions: Natural wallcoverings are very low maintenance. They may be lightly brushed with a soft surface feather duster to remove dust. Never use cleaning agents to remove a stain. If necessary only use the foam from a gentle household dishwashing liquid and rub softly with a soft white cloth.</li>"
		},
		"installation": "Paste required and professional paper installer recommended {urlLink} Double cut - hand trimming required",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://support.spoonflower.com/hc/en-us/articles/4418183605901",
		"note": "Disclaimer: Grasscloth is a natural handcrafted wallpaper with unique variations and irregularities in texture, color, and weave, which is an inherent quality of this material. When installed, the seams between the panels of grasscloth will be visible. Variations in color between batches may occur due to the manufacturing process. This paper is not suitable for high moisture areas such as bathrooms with showers or bathtubs.\t| Care Instructions: Natural wallcoverings are very low maintenance. They may be lightly brushed with a soft surface feather duster to remove dust. Never use cleaning agents to remove a stain. If necessary only use the foam from a gentle household dishwashing liquid and rub softly with a soft white cloth."
	},
	"WALLPAPER_PEEL_AND_STICK": {
		"name": "Peel and Stick",
		"subtitle": "Peel and Stick - High-quality paper for simple DIY installation",
		"buttonName": "Peel and Stick",
		"cartName": "{size} {measurementUnit} of Peel and Stick Wallpaper",
		"description": "Subtle linen textured, DIY-friendly self-adhesive, removable and cleanable wallcovering for smooth, flat, non-textured surfaces",
		"awardDescription": "Printed on Forest Stewardship Council® Certified wallpaper",
		"descriptionList": "Subtle woven texture with peel and stick backing | Removable and washable with mild soap and water | Great for upgrading rooms, temporary spaces, bookcases and an array of craft projects",
		"dimensions": {
			"METRIC": "61 cm wide | Available in panel lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "24 inches wide | Available in panel lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | 61 cm wide | Available in panel lengths of 91 cm, 183 cm, 274 cm, 366 cm",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | 24 inches wide | Available in panel lengths of 1 foot, 3, 6, 9 or 12 feet"
		},
		"details": "Matte finish, optic-white | Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free | Country of Origin: USA",
		"collapsedDetails": {
			"METRIC": "<li>Subtle woven texture with peel and stick backing</li> <li>Removable and washable with mild soap and water</li> <li class=\"space-bottom\">Great for upgrading rooms, temporary spaces, bookcases and an array of craft projects</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>61 cm wide</li> <li class=\"space-bottom\">Available in panel lengths of 91 cm, 183 cm, 274 cm, 366 cm</li> <ul class=\"section-title\">Installation:</ul> <li>Self adhesive, removable and repositionable</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/204279854\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Matte finish</li> <li>Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free</li> <li class=\"space-bottom\">Country of Origin: USA</li> <ul class=\"section-title\">Note:</ul> <li>Surface type, primers and paints will affect adhesion and removabilty of this wallpaper.</li> <li>Washable paints and no-VOC paints prevent full adhesion.</li> <li>For smooth, flat, non-textured surfaces.</li>",
			"IMPERIAL": "<li>Subtle woven texture with peel and stick backing</li> <li>Removable and washable with mild soap and water</li> <li class=\"space-bottom\">Great for upgrading rooms, temporary spaces, bookcases and an array of craft projects</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>24 in wide</li> <li class=\"space-bottom\">Available in panel lengths of 1 foot, 3, 6, 9 or 12 feet</li> <ul class=\"section-title\">Installation:</ul> <li>Self adhesive, removable and repositionable</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://support.spoonflower.com/hc/en-us/articles/204279854\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Matte finish</li> <li>Eco-friendly inks, formaldehyde-, phthalate-, and PVC-free</li> <li class=\"space-bottom\">Country of Origin: USA</li> <ul class=\"section-title\">Note:</ul> <li>Surface type, primers and paints will affect adhesion and removabilty of this wallpaper.</li> <li>Washable paints and no-VOC paints prevent full adhesion.</li> <li>For smooth, flat, non-textured surfaces.</li>"
		},
		"installation": "Self adhesive, removable and repositionable {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://support.spoonflower.com/hc/en-us/articles/204279854",
		"note": "Surface type, primers and paints will affect adhesion and removabilty of this wallpaper. | Washable paints and no-VOC paints prevent full adhesion. | For smooth, flat, non-textured surfaces."
	},
	"WALLPAPER_SILVER_METALLIC": {
		"name": "Silver Metallic",
		"subtitle": "Silver Metallic - Lustrous cool silver, durable textured paper",
		"buttonName": "Silver Metallic",
		"cartName": "{size} {measurementUnit} of Silver Metallic Wallpaper",
		"description": "Non-woven wallpaper with a silver metallic base and fibrous texture",
		"descriptionList": "Paste the wall application | Washable with mild detergent and clear water using a soft sponge | Type I, Class A Fire Rated",
		"dimensions": {
			"METRIC": "Roll (installed): 61 cm W x 823 cm L | Untrimmed: 62 cm W x 823 cm L",
			"IMPERIAL": "Roll (installed): 24\" W x 27' L | Untrimmed: 24.5\" W x 27' L"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | Roll (installed): 61 cm W x 823 cm L",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | Roll (installed): 24\" W x 27' L"
		},
		"details": "Printed with water-based inks, PVC-free | Non-woven paper with 85% paper, 10% polyester & 5% other materials | Silver Metallic",
		"collapsedDetails": {
			"METRIC": "<li>Paste the wall application</li> <li>Washable with mild detergent and clear water using a soft sponge</li> <li class=\"space-bottom\">Type I, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>Roll (installed): 61 cm W x 823 cm L</li> <li class=\"space-bottom\">Untrimmed: 62 cm W x 823 cm L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste the wall application and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-metallic\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Printed with water-based inks, PVC-free</li> <li class=\"space-bottom\">Non-woven paper with 85% paper, 10% polyester & 5% other materials</li>",
			"IMPERIAL": "<li>Paste the wall application</li> <li>Washable with mild detergent and clear water using a soft sponge</li> <li class=\"space-bottom\">Type I, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>Roll (installed): 24\" W x 27' L</li> <li class=\"space-bottom\">Untrimmed: 24.5\" W x 27' L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste the wall application and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-metallic\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Printed with water-based inks, PVC-free</li> <li class=\"space-bottom\">Non-woven paper with 85% paper, 10% polyester & 5% other materials</li>"
		},
		"installation": "Paste the wall application and professional paper installer recommended {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://www.spoonflower.com/install-metallic"
	},
	"WALLPAPER_GOLD_METALLIC": {
		"name": "Gold Metallic",
		"subtitle": "Gold Metallic - Shimmering warm gold, durable textured paper",
		"buttonName": "Gold Metallic",
		"cartName": "{size} {measurementUnit} of Gold Metallic Wallpaper",
		"description": "Non-woven wallpaper with a gold metallic base and fibrous texture",
		"descriptionList": "Paste the wall application | Washable with mild detergent and clear water using a soft sponge | Type I, Class A Fire Rated",
		"dimensions": {
			"METRIC": "Roll (installed): 61 cm W x 823 cm L | Untrimmed: 62 cm W x 823 cm L",
			"IMPERIAL": "Roll (installed): 24\" W x 27' L | Untrimmed: 24.5\" W x 27' L"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | Roll (installed): 61 cm W x 823 cm L",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | Roll (installed): 24\" W x 27' L"
		},
		"details": "Printed with water-based inks, PVC-free | Non-woven paper with 85% paper, 10% polyester & 5% other materials | Gold Metallic",
		"collapsedDetails": {
			"METRIC": "<li>Paste the wall application</li> <li>Washable with mild detergent and clear water using a soft sponge</li> <li class=\"space-bottom\">Type I, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>Roll (installed): 61 cm W x 823 cm L</li> <li class=\"space-bottom\">Untrimmed: 62 cm W x 823 cm L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste the wall application and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-metallic\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Printed with water-based inks, PVC-free</li> <li class=\"space-bottom\">Non-woven paper with 85% paper, 10% polyester & 5% other materials</li>",
			"IMPERIAL": "<li>Paste the wall application</li> <li>Washable with mild detergent and clear water using a soft sponge</li> <li class=\"space-bottom\">Type I, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>Roll (installed): 24\" W x 27' L</li> <li class=\"space-bottom\">Untrimmed: 24.5\" W x 27' L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste the wall application and professional paper installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-metallic\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Printed with water-based inks, PVC-free</li> <li class=\"space-bottom\">Non-woven paper with 85% paper, 10% polyester & 5% other materials</li>"
		},
		"installation": "Paste the wall application and professional paper installer recommended {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://www.spoonflower.com/install-metallic"
	},
	"WALLPAPER_VINYL": {
		"name": "Vinyl",
		"subtitle": "Long-lasting, washable wallpaper for heavy traffic areas",
		"buttonName": "Vinyl",
		"cartName": "{size} {measurementUnit} of Vinyl Wallpaper",
		"description": "Durable, washable wallpaper that stands up to scrubs, scratches and heavy traffic areas.",
		"descriptionList": "Subtle leather textured vinyl; can be installed on lightly textured walls | Recommended for event spaces, offices, hotels, restaurants and other commercial spaces | Type II, Class A Fire Rated",
		"dimensions": {
			"METRIC": "Roll dimensions (installed): 61 cm W x 823 cm L | Roll dimensions (uninstalled): 62 cm W x 823 cm L",
			"IMPERIAL": "Roll dimensions (installed): 24\" W x 27' L | Roll dimensions (uninstalled): 24.5\" W x 27' L"
		},
		"designDimensions": {
			"METRIC": "{repeatSize} centimeter vertical repeat of the design | Roll dimensions (installed): 61 cm W x 823 cm L | Roll dimensions (uninstalled):  62 cm W x 823 cm L",
			"IMPERIAL": "{repeatSize} inch vertical repeat of the design | Roll dimensions (installed): 24\" W x 27' L | Roll dimensions (uninstalled): 24.5\" W x 27' L"
		},
		"details": "Highly durable, scrub and scratch-resistant | Washable",
		"collapsedDetails": {
			"METRIC": "<li>Subtle leather textured vinyl; can be installed on lightly textured walls</li> <li>Recommended for event spaces, offices, hotels, restaurants and other commercial spaces</li> <li class=\"space-bottom\">Type II, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} cm vertical repeat of the design</li> <li>Roll dimensions (installed): 61 cm W x 823 cm L</li> <li class=\"space-bottom\">Roll dimensions (uninstalled): 62 cm W x 823 cm L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required, professional installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-vinyl\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Printed with water-based inks, PVC-free</li> <li class=\"space-bottom\">Non-woven paper with 85% paper, 10% polyester & 5% other materials</li>",
			"IMPERIAL": "<li>Subtle leather textured vinyl; can be installed on lightly textured walls</li> <li>Recommended for event spaces, offices, hotels, restaurants and other commercial spaces</li> <li class=\"space-bottom\">Type II, Class A Fire Rated</li> <ul class=\"section-title\">Dimensions:</ul> <li>{repeatSize} in vertical repeat of the design</li> <li>Roll dimensions (installed): 24\" W x 27' L</li> <li class=\"space-bottom\">Roll dimensions (uninstalled): 24.5\" W x 27' L</li> <ul class=\"section-title\">Installation:</ul> <li>Paste required, professional installer recommended</li> <li class=\"space-bottom\"><a class=\"list-link\" href=\"https://www.spoonflower.com/install-vinyl\">Installation Instructions</a></li> <ul class=\"section-title\">Details:</ul> <li>Highly durable, scrub and scratch-resistant</li> <li class=\"space-bottom\">Washable</li>"
		},
		"installation": "Paste required, professional installer recommended {urlLink}",
		"urlInstructions": "Installation Instructions",
		"urlInstructionsLink": "https://www.spoonflower.com/install-vinyl"
	}
};