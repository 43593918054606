import {DE, supportedLanguages} from '../constants/SupportedLanguages';
import {isUndefined, isEmpty, objectIsEmpty, isNotUndefined} from './validation';
import {MultiLocaleRouter} from '../services';
import Translate from 'i18n-react';
import {COUNTRY, CURRENCY, FABRIC_SIZE, MEASUREMENT_SYSTEM} from '../constants/Parameters';
import {IDME_GROUPS} from '../constants/Payment';
import {clientSideRoutes} from '../constants/clientSideRoutes';


/**
 * Creates a query string for a URL or appends an existing one, avoiding double
 * '?'s and adding '&'s where needed.  NOTE: This implementation is far less
 * ideal than using the whatwg-url API, however after too much time spent trying
 * to get the webpack / babel shim for universal-url-lite to actually work, time
 * constraints required us to revert to this custom built URL search parsing /
 * updating solution.
 *
 * @param pathnameSearchHash {string} A relative URL that consists of pathname, search(optional), hash(optional) according to this spec: https://javascript.info/url
 * @param params {object=} Object, such as {fabric: 'minky', fabric_size:
 *   'fat_quarter'}, which would then become fabric=minky&fabric_size=fat_quarter
 *   in URL
 * @param currentOrigin {string} Required argument. Origin that application is currently loaded from. Needed to construct the full URL in case `origin` is omitted
 * @param origin {string=} Target origin you want your request to point to. Optional argument. Current origin is taken by default. Provide to specify URL with origin other than
 *   window.location.origin. Optional argument.
 * @returns {string} The URL. Includes FQDN (with origin) only if origin is provided
 */
export function upsertUrlQuery(pathnameSearchHash, params, currentOrigin, origin) {
  let existingQuery = {};

  const url = new URL(pathnameSearchHash, currentOrigin);

  if (typeof url.search !== 'undefined' && url.search !== '') {
    existingQuery = queryToObject(url.search.substr(1));
  }

  const upsertQueryParams = Object.assign({}, existingQuery, params);

  const pathname = url.pathname;

  const urlComponents = [pathname, objectToQueryString(upsertQueryParams), url.hash];

  if (typeof origin !== 'undefined') {
    urlComponents.unshift(origin);
  }

  return urlComponents
    .filter((item) => !isEmpty(item))
    .join('');
}

export function objectToQueryString(params) {
  let separator = '?';
  let queryString = '';

  for (const key of Object.keys(params)) {
    const queryValue = params[key];

    if (isUndefined(queryValue) || queryValue === '') {
      continue;
    }

    queryString += `${separator}${key}=${encodeURIComponent(queryValue)}`;
    if (separator === '?') {
      separator = '&';
    }
  }

  return queryString;
}

// https://stackoverflow.com/a/8649003
export const queryToObject = (queryString) => {
  const partialJson = decodeURI(queryString)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"');

  return JSON.parse(`{"${partialJson}"}`);
};

export function getHostnameFromUrl(relativeUrl, hostname) {
  const urlObj = new URL(relativeUrl, `https://${hostname}`);

  return urlObj.host;
}

export function isInternalBaerlauchUrl(url, hostname) {
  const hostnameFromUrl = getHostnameFromUrl(url, hostname);

  if (isNotUndefined(hostname) && isNotUndefined(hostnameFromUrl) && hostnameFromUrl !== hostname) {
    return false;
  }
  const possibleLangCodeRegex = supportedLanguages.join('|');
  // eslint-disable-next-line no-useless-escape
  const urlWithTwoCharLangCode = new RegExp(`\/(${possibleLangCodeRegex})(\/.*)?$`);

  return urlWithTwoCharLangCode.test(url);
}

export const getPathname = (href, hostname) => {
  try {
    const {pathname} = new URL(href, `https://${hostname}`);

    return pathname;
  } catch {
    return href.split('?')[0];
  }
};

export const isClientSideRoute = (href, hostname) => {
  const pathname = getPathname(href, hostname);

  return clientSideRoutes.some((item) => item.test(pathname));
};

export function shopHomeUrl(locale) {
  return locale === 'en' ? '/en/shop' : MultiLocaleRouter.localePathname('shop.home');
}

// TODO EN-1661: check if this still applies
export const isDuplicatedUrl = (locationQuery) => (
  !objectIsEmpty(locationQuery)
);

export const reloadPageWithoutShoppingParams = () => {
  const currentUrl = new URL(window.location.toString());

  // Validate that the URL is of the same origin
  if (currentUrl.origin !== window.location.origin) {
    console.error('Invalid URL origin');

    return;
  }

  const searchParams = new URL(window.location.toString()).searchParams;
  let hasShoppingParams = false;

  const shoppingParams = [COUNTRY, CURRENCY, FABRIC_SIZE, MEASUREMENT_SYSTEM];

  for (const searchParamKey of searchParams.keys()) {
    if (shoppingParams.includes(searchParamKey)) {
      hasShoppingParams = true;
      break;
    }
  }

  if (hasShoppingParams) {
    for (const param of shoppingParams) {
      searchParams.delete(param);
    }

    // Ensure the new search string is safe
    const newSearchString = searchParams.toString();

    if (/^[a-zA-Z0-9=&%._-]*$/.test(newSearchString)) {
      window.location.search = newSearchString;
    } else {
      console.error('Invalid search parameters');
    }
  } else {
    // Remove URL fragment linking to a page section or else page reload would be prevented.
    window.location.assign(window.location.href.split('#')[0]);
  }
};

// TODO SP-7621: Update method to pass DE subscribe link to Subscribe.js
export function newsletterSubscribeUrl(locale) {
  const localeIsGerman = locale === DE;

  return localeIsGerman ? Translate.translate('xurls.subscribe.de') : Translate.translate('xurls.subscribe.en');
}

export function idMeUrl(redirectUri, idMeClientId) {
  const idMeApiUrl = Translate.translate('xurls.idMe.groups');

  return `${idMeApiUrl}?client_id=${idMeClientId}&redirect_uri=https://${redirectUri}&response_type=code&scopes=${IDME_GROUPS}`;
}

export const translateOrEmptyString = (key) => Translate.translate(key)?.toString() || '';
