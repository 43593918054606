import classNames from 'classnames';
import React from 'react';


export interface IconProps {
  iconName: string;
  iconTitle?: string;
  extensionClass?: string;
  id?: string;
  [K: string]: unknown;
}

const Icon = ({iconName, iconTitle, extensionClass, id, ...rest}: IconProps): JSX.Element => {
  const iconClasses = classNames('ico', `ico-${iconName}`, extensionClass);

  // if we have a title assume that the icon is not only decorative, therefore it's not hidden for screen readers
  return (
    <span
      className={iconClasses}
      aria-hidden={!iconTitle}
      title={iconTitle}
      {
        ...(id && {
          id
        })
      }
      {...rest}
    >
      <span className='visuallyhidden'>{iconTitle}</span>
    </span>
  );
};

export default React.memo(Icon);
