module.exports = {
	"FABRIC_BASIC_COTTON_ULTRA": {
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "3.2",
			"METER": "108.49"
		}
	},
	"FABRIC_SATIN": {
		"fabricWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "2.2",
			"METER": "75"
		}
	},
	"FABRIC_KONA_COTTON_ULTRA": {
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "4.5",
			"METER": "152.57"
		}
	},
	"FABRIC_COTTON_POPLIN_ULTRA": {
		"fabricWidth": {
			"YARD": "44",
			"METER": "112"
		},
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "3.3",
			"METER": "115"
		}
	},
	"FABRIC_COTTON_POPLIN_BRAVA": {
		"fabricWidth": {
			"YARD": "44",
			"METER": "112"
		},
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "3.3",
			"METER": "115"
		}
	},
	"FABRIC_PERFORMANCE_PIQUE": {
		"fabricWidth": {
			"YARD": "59",
			"METER": "150"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "4.1",
			"METER": "140"
		}
	},
	"FABRIC_CHIFFON": {
		"fabricWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "1.5",
			"METER": "50"
		}
	},
	"FABRIC_ORGANIC_SWEET_PEA_GAUZE": {
		"fabricWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.5",
			"METER": "120"
		}
	},
	"FABRIC_POLY_CREPE_DE_CHINE": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "52",
			"METER": "132"
		},
		"weight": {
			"YARD": "1.9",
			"METER": "65"
		}
	},
	"FABRIC_SILKY_FAILLE": {
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "4.4",
			"METER": "149.18"
		}
	},
	"FABRIC_PERFORMANCE_KNIT": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "4.1",
			"METER": "139.01"
		}
	},
	"FABRIC_LIGHTWEIGHT_COTTON_TWILL": {
		"fabricWidth": {
			"YARD": "61",
			"METER": "155"
		},
		"printableWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"weight": {
			"YARD": "5.8",
			"METER": "195"
		}
	},
	"FABRIC_MODERN_JERSEY": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "6.2",
			"METER": "210"
		}
	},
	"FABRIC_COTTON_SPANDEX_JERSEY": {
		"fabricWidth": {
			"YARD": "63",
			"METER": "160"
		},
		"printableWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "185"
		}
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_PRIMA": {
		"fabricWidth": {
			"YARD": "63",
			"METER": "160"
		},
		"printableWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "185"
		}
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_BRAVA": {
		"fabricWidth": {
			"YARD": "63",
			"METER": "160"
		},
		"printableWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "185"
		}
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_ULTRA": {
		"fabricWidth": {
			"YARD": "63",
			"METER": "160"
		},
		"printableWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "185"
		}
	},
	"FABRIC_COTTON_SPANDEX_JERSEY_S": {
		"fabricWidth": {
			"YARD": "63",
			"METER": "160"
		},
		"printableWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "185"
		}
	},
	"FABRIC_FLEECE": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "6.6",
			"METER": "225"
		}
	},
	"FABRIC_POLARTEC_FLEECE": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "7.4",
			"METER": "251"
		}
	},
	"FABRIC_LINEN_COTTON_CANVAS_ULTRA": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "6.4",
			"METER": "215"
		}
	},
	"FABRIC_LINEN_COTTON_CANVAS": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "6.4",
			"METER": "215"
		}
	},
	"FABRIC_ORGANIC_COTTON_KNIT_ULTRA": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "5.8",
			"METER": "196.65"
		}
	},
	"FABRIC_ORGANIC_COTTON_SATEEN_ULTRA": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.8",
			"METER": "128.842"
		}
	},
	"FABRIC_ORGANIC_COTTON_SATEEN": {
		"fabricWidth": {
			"YARD": "59",
			"METER": "150"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.8",
			"METER": "130"
		}
	},
	"FABRIC_MINKY": {
		"fabricWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "6.6",
			"METER": "225"
		}
	},
	"FABRIC_SPORT_LYCRA": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "8.5",
			"METER": "290"
		}
	},
	"FABRIC_SPORT_LYCRA_RETIRED": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "8.4",
			"METER": "284.80"
		}
	},
	"FABRIC_HEAVY_COTTON_TWILL": {
		"printableWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"weight": {
			"YARD": "8",
			"METER": "271.24"
		}
	},
	"FABRIC_ECO_CANVAS": {
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "8.7",
			"METER": "294.98"
		}
	},
	"FABRIC_FAUX_SUEDE": {
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "7.5",
			"METER": "254.29"
		}
	},
	"FABRIC_CELOSIA_VELVET": {
		"fabricWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "9.2",
			"METER": "313"
		}
	},
	"FABRIC_DOGWOOD_DENIM": {
		"fabricWidth": {
			"YARD": "61",
			"METER": "155"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "11.7",
			"METER": "395"
		}
	},
	"FABRIC_SILK_CREPE_DE_CHINE": {
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "2.2",
			"METER": "74.6"
		}
	},
	"FABRIC_CYPRESS_COTTON": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "10.6",
			"METER": "360"
		}
	},
	"FABRIC_COTTON_LAWN": {
		"printableWidth": {
			"YARD": "42",
			"METER": "107"
		},
		"weight": {
			"YARD": "2.3",
			"METER": "78"
		}
	},
	"FABRIC_COTTON_LAWN_APPAREL": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "2.4",
			"METER": "81"
		}
	},
	"FABRIC_PERFORMANCE_LINEN": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "144"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "7.6",
			"METER": "260"
		}
	},
	"FABRIC_PERENNIAL_SATEEN_GRAND": {
		"fabricWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"printableWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"weight": {
			"YARD": "3.5",
			"METER": "120"
		}
	},
	"FABRIC_ORGANIC_COTTON_KNIT_PRIMA": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "6.3",
			"METER": "215"
		}
	},
	"FABRIC_ORGANIC_COTTON_KNIT_BRAVA": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "6.3",
			"METER": "215"
		}
	},
	"FABRIC_ORGANIC_COTTON_KNIT_S": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "6.3",
			"METER": "215"
		}
	},
	"FABRIC_LONGLEAF_SATEEN_GRAND": {
		"fabricWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"printableWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"weight": {
			"YARD": "6.5",
			"METER": "220"
		}
	},
	"FABRIC_ORGANIC_COTTON_SATEEN_PRIMA": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.8",
			"METER": "128.842"
		}
	},
	"FABRIC_LINEN_COTTON_CANVAS_PRIMA": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "6.4",
			"METER": "215"
		}
	},
	"FABRIC_PETAL_SIGNATURE_COTTON": {
		"fabricWidth": {
			"YARD": "44",
			"METER": "112"
		},
		"printableWidth": {
			"YARD": "42",
			"METER": "107"
		},
		"weight": {
			"YARD": "4.4",
			"METER": "150"
		}
	},
	"FABRIC_PETAL_SIGNATURE_COTTON_S": {
		"printableWidth": {
			"YARD": "42",
			"METER": "106"
		},
		"weight": {
			"YARD": "4.3",
			"METER": "145"
		}
	},
	"FABRIC_CYPRESS_COTTON_BRAVA": {
		"fabricWidth": {
			"YARD": "60",
			"METER": "152"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "10.6",
			"METER": "360"
		}
	},
	"FABRIC_LINEN_COTTON_CANVAS_BRAVA": {
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "6.4",
			"METER": "215"
		}
	},
	"FABRIC_ORGANIC_COTTON_SATEEN_BRAVA": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.8",
			"METER": "128.842"
		}
	},
	"FABRIC_PERENNIAL_SATEEN_GRAND_ADMIN": {
		"printableWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"weight": {
			"YARD": "3.5",
			"METER": "120"
		}
	},
	"FABRIC_LONGLEAF_SATEEN_GRAND_ADMIN": {
		"printableWidth": {
			"YARD": "116",
			"METER": "295"
		},
		"weight": {
			"YARD": "6.5",
			"METER": "220"
		}
	},
	"FABRIC_DOGWOOD_DENIM_BRAVA": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "11.6",
			"METER": "393.3"
		}
	},
	"FABRIC_LIGHTWEIGHT_COTTON_TWILL_BRAVA": {
		"printableWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"weight": {
			"YARD": "5.6",
			"METER": "189.87"
		}
	},
	"FABRIC_ORGANIC_SWEET_PEA_GAUZE_BRAVA": {
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "3.2",
			"METER": "108.49"
		}
	},
	"FABRIC_BELGIAN_LINEN": {
		"fabricWidth": {
			"YARD": "57",
			"METER": "145"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "9.8",
			"METER": "335"
		}
	},
	"FABRIC_RECYCLED_CANVAS": {
		"fabricWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "7.1",
			"METER": "240"
		}
	},
	"FABRIC_PERFORMANCE_VELVET": {
		"fabricWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"printableWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"weight": {
			"YARD": "11.5",
			"METER": "389"
		}
	},
	"FABRIC_COTTON_SILK": {
		"fabricWidth": {
			"YARD": "55",
			"METER": "139"
		},
		"printableWidth": {
			"YARD": "52",
			"METER": "132"
		},
		"weight": {
			"YARD": "2.4",
			"METER": "82"
		}
	},
	"FABRIC_ESSEX_LINEN": {
		"fabricWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"printableWidth": {
			"YARD": "52",
			"METER": "132"
		},
		"weight": {
			"YARD": "5.5",
			"METER": "186"
		}
	},
	"FABRIC_SEERSUCKER": {
		"fabricWidth": {
			"YARD": "54",
			"METER": "137"
		},
		"printableWidth": {
			"YARD": "52",
			"METER": "132"
		},
		"weight": {
			"YARD": "3.25",
			"METER": "110"
		}
	},
	"FABRIC_SPORT_STRETCH_WOVEN": {
		"fabricWidth": {
			"YARD": "58",
			"METER": "147"
		},
		"printableWidth": {
			"YARD": "56",
			"METER": "142"
		},
		"weight": {
			"YARD": "5.0",
			"METER": "170"
		}
	},
	"SOLID_FABRIC_PETAL": {
		"fabricWidth": {
			"YARD": "44",
			"METER": "112"
		},
		"weight": {
			"YARD": "4.3",
			"METER": "145"
		}
	}
};