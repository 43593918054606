import {createSelector} from 'reselect';

import {BAE_FEATURE_KEY} from '../../../constants/Search';
import {State} from '../../../store/initialState';


export const selectSearchResults = (state: State): State['searchResults'] => state.searchResults;

export const selectFeatureFlags = createSelector(
  [selectSearchResults],
  (searchResults) =>
    new Set(searchResults.feature_flags?.[BAE_FEATURE_KEY] || []),
);
