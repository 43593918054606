module.exports = {
	"logo": "Afterpay logo",
	"messaging": {
		"checkout": "Pay now with {afterpay}",
		"eligibleWithPrice": "Or 4 interest-free installments of {amount} with {afterpay}",
		"paymentEligibleWithPrice": "4 interest-free installments of {amount} with {afterpay}",
		"eligibleWithPriceRange": "Or 4 interest-free installments on orders\n{minPrice} – {maxPrice} with {afterpay}",
		"eligibleWithoutPrice": "Or 4 interest-free installments with {afterpay}",
		"eligibleNoThreshold": "{afterpay} available for orders between {minPrice} – {maxPrice}",
		"notEligible": "This order is not eligible for {afterpay}",
		"installmentsByAfterpay": "Installments by Afterpay",
		"redirectAndBillingNotice": "You will be redirected to the Afterpay website to fill out your payment information. You will be redirected back to our site to complete your order. Afterpay can only be used as a payment method for orders with a shipping and billing address within the US.",
		"redirectAndBillingNoticeAU": "You will be redirected to the Afterpay website to fill out your payment information. You will be redirected back to our site to complete your order. Afterpay can only be used as a payment method for orders with a shipping and billing address within AU."
	},
	"installmentAgreement": "Installment Agreement",
	"installmentPieChart": "Pie chart of installment payment",
	"installmentDetails": {
		"firstInstallment": "First Installment",
		"secondInstallment": "2 weeks later",
		"thirdInstallment": "4 weeks later",
		"fourthInstallment": "6 weeks later"
	},
	"modal": {
		"motto": "Shop now. Pay later. Always interest-free.",
		"process": {
			"stepOne": "Add your favorites to cart",
			"stepTwo": "Select Afterpay at checkout",
			"stepThree": "Log into or create your Afterpay account, with instant approval decision",
			"stepFour": "Your payment will be split into 4 payments payable every 2 weeks"
		},
		"legal": {
			"text": "You must be over 18, a resident of the U.S. and meet additional eligibility criteria to qualify. Late fees may apply. Estimated payment amounts shown on product pages exclude taxes and shipping charges, which are added at checkout. Click the Installment Agreement link below for complete terms. Loans to California residents made or arranged pursuant to a California Finance Lenders Law license. © 2020 Afterpay",
			"linkText": "Installment Agreement"
		},
		"legalAU": {
			"text": "Late fees and additional eligibility criteria apply. The first payment may be due at the time of purchase. For complete terms visit {link}"
		},
		"openDetailModal": "Open afterpay details modal",
		"closeDetailModal": "Close afterpay details modal"
	},
	"landing": {
		"preferenceButton": "Change your user preferences accordingly to read on",
		"afterpayLink": "Or visit Afterpay.com"
	}
};