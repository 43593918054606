import React from 'react';

import 'url-polyfill';
import './_substrate-filter.scss';
import {SortedCategoryType} from '../../containers/Fabric/FabricShopContainer';
import {translate} from '../../services/index';
import {ViewportType} from '../../shapes/viewport';
import {onClickPreventPropagation} from '../../utils/events';
import {isNotUndefined, isEmpty} from '../../utils/validation';
import Filter, {FilterCategory, FilterMenuItem} from '../Reusable/Filter/Filter';


export interface SubstrateFilterProps {
  viewport: ViewportType;
  clearFilters: () => void;
  currentCategory?: string;
  sortedCategories: SortedCategoryType;
  searchQuery?: string;
}

const SubstrateFilter = ({clearFilters, viewport, currentCategory, sortedCategories, searchQuery}: SubstrateFilterProps): JSX.Element => {
  const getMenus = () =>
    // eslint-disable-next-line array-callback-return
    Object.keys(sortedCategories).map((categoryGroup) => {
      const groupChildren: Record<number, FilterMenuItem> = sortedCategories[categoryGroup];

      if (!isEmpty(Object.keys(groupChildren))) {
        const categoriesTempObject: FilterCategory = {
          text: translate(`fabricShop.filterBy${categoryGroup}`),
          children: groupChildren
        };

        return (
          <Filter
            key={categoryGroup}
            viewport={viewport as ViewportType}
            menuItems={{
              byCategory: categoriesTempObject
            }}
            subMenuHeader={categoryGroup}
            searchQuery={searchQuery}
            filtersAreExpanded={true}
            filterFixedExpanded={true}
            columnExtensionClass={'x-search-columns'}
            useLinkComponent={true}
            currentCategory={currentCategory}
          />
        );
      }
    });

  return (
    <section className='b-substrate-filter' onClick={onClickPreventPropagation}>
      <div className='filter-heading-group'>
        {isNotUndefined(clearFilters) &&
          <button className='clear-filters' onClick={clearFilters}>
            <span className='clear-filters-text'>{translate('navigation.clearFilter')}</span>
          </button>
        }
      </div>
      {getMenus()}
    </section>
  );
};

export default SubstrateFilter;
