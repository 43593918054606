module.exports = {
	"head": {
		"title": "Fabric",
		"shopTitle": "Shop Fabric",
		"chooseFabric": "Choose a fabric"
	},
	"progress": {
		"step1": "Design",
		"step2": "Fabric",
		"step3": "Amount",
		"step4": "Cart"
	},
	"headlines": {
		"main": "Fabric",
		"fabric": "Fabric",
		"type": "QTY",
		"quantity": "Quantity",
		"price": "Price",
		"size": "Size",
		"style": "Style",
		"rollSize": "Roll Size",
		"chunking": "Cut",
		"color": "Color",
		"chooseColor": "Choose a color",
		"note": {
			"IMPERIAL": "(Note: This fabric is {width} inches wide)",
			"METRIC": "(Note: This fabric is {width} cm wide)"
		}
	},
	"content": {
		"fabricRecommendation": {
			"IMPERIAL": "This designer recommends {fabric} or a fabric wider than {width} inches.",
			"METRIC": "This designer recommends {fabric} or a fabric wider than {width} cm."
		},
		"by": "by",
		"changelink": "change",
		"lowerQuantity": "Decrease quantity",
		"higherQuantity": "Increase quantity",
		"infoVAT": "VAT included (where applicable)",
		"addToCart": "Add to Cart",
		"addToCartTitle": "Add {product} to Cart",
		"added": "Added",
		"buyATestSwatch": "Buy a Fabric Sample for {context}",
		"updateCart": "Update Item",
		"updated": "Updated",
		"cancelCart": "Cancel",
		"priceAfterSelection": "Price is calculated after fabric selection",
		"chunkPiecesInfo": "at {chosenChunkSize} {chunkUnit}",
		"remainingChunkPiecesInfo": "at {remainingChunkSize} {chunkUnit}",
		"pieceAmount": {
			"1": "{context} piece",
			"2..99": "{context} pieces"
		}
	},
	"availability": {
		"notForSale": "This design is not yet for sale, but send {designer} a message if you'd like to buy it.",
		"unavailable": "This product is currently unavailable."
	},
	"pageLinks": {
		"wallpaper": "Wallpaper"
	},
	"aboutSpoonflower": {
		"learnMore": "Learn more",
		"intro": "Custom Fabric & Home Decor",
		"info": "Spoonflower supports independent designers as the world’s largest Marketplace for these eco-friendly, printed-on-demand products."
	},
	"happinessGuarantee": {
		"title": "Happiness Guarantee",
		"intro": "Your order is custom printed just for you, and we want you to love it.",
		"info": "If you think that your order has a flaw or it is not what you expected, we want to make it right.",
		"learnMore": "Learn more"
	},
	"designerInfo": "About {designer}",
	"trade": {
		"title": "Spoonflower Trade",
		"info": "Are you an Interior Designer? Take your project to the next level with custom fabric, wallpaper, and home decor, all delivered at e-commerce speed. Apply to Spoonflower’s Trade program and enjoy competitive business pricing, free swatches, and concierge to-the-trade service.",
		"learnMore": "Learn more"
	},
	"discounts": {
		"title": "Discounts",
		"info": "Spoonflower gives you lots of ways to save with everyday designer discounts, PRO-memberships and more.",
		"learnMore": "Learn more"
	},
	"userSubmittedPhotos": {
		"sectionHeading": "User Submitted Photos",
		"commentPhotoAltText": "Submitted photo by {user}",
		"commentPhotoModalHeading": "Submitted with a Comment",
		"reviewPhotoModalHeading": "Submitted with a Review"
	},
	"shippingInfo": {
		"shippingAndReturns": "Shipping & Returns",
		"fasterOptionsLink": "Faster options",
		"helpPageText": "help page",
		"content": "Spoonflower products are made-to-order, meaning we don’t have a warehouse of ready-to-ship items. This enables us to offer a huge library of designs by independent designers, available on over 50 different products. And we back every purchase up with our {guaranteeLink}. Visit our {helpPageLink} for information on returns and exchanges.\n"
	},
	"aboutFabric": {
		"headline": "Fabric Details",
		"designNr": "Design #{designId}",
		"learnMore": "Learn more",
		"title": "Learn more",
		"newLabel": "new"
	},
	"fabricDetail": {
		"useCases": "Use Cases",
		"careInstructions": "Care Instructions",
		"countryOrigin": "Country of Origin",
		"printing": "Printing",
		"moreButtonTitle": "Jump to more product details",
		"specifications": "Specifications",
		"printableWidth": "Printable Width",
		"fabricWidth": "Fabric Width",
		"weight": "Weight",
		"fabricContent": "Fabric Content",
		"fabricConstruction": "Fabric Construction",
		"color": "Color",
		"shrinkage": "Estimated Shrinkage",
		"abrasionWyzenbeekDuck": "Abrasion - Wyzenbeek (Cotton Duck Abradant)",
		"abrasionWyzenbeekWire": "Abrasion - Wyzenbeek (Wire Abradant)",
		"abrasionMartindale": "Abrasion - Martindale",
		"stretchDetail": "Stretch",
		"uvaUvb": "UVA/UVB",
		"upfRating": "UPF Rating",
		"fabricPilling": "Pilling|(1 Severe Pilling - 5 No Pilling)",
		"dryCrocking": "Crocking - Dry|(1 high degree of color transfer - 5 no color transfer)",
		"wetCrocking": "Crocking - Wet|(1 high degree of color transfer - 5 no color transfer)",
		"colorFastnessAcid": "Colorfastness to Perspiration - Acid|(1 high degree of color change/staining - 5 no color change/staining)",
		"colorFastnessAlkaline": "Colorfastness to Perspiration - Alkaline|(1 high degree of color change/staining - 5 no color change/staining)",
		"colorFastnessSeaWater": "Colorfastness to Sea Water|(1 high degree of color change/staining - 5 no color change/staining)",
		"colorFastnessPoolWater": "Colorfastness to Pool Water|(1 high degree of color change/staining - 5 no color change/staining)",
		"features": "Features",
		"threadCount": "Thread Count",
		"colorfastness": "Colorfastness to light|(1 high degree of fading - 5 no fading)",
		"flammability": "Flammability|(Class 1 or Pass)",
		"breakingStrength": "Breaking Strength",
		"tearStrength": "Tear Strength",
		"snagStrength": "Snag Strength|(1 high degree of yarn pulls - 5 no yarn pulls)",
		"pileHeight": "Pile Height",
		"sewingRecommendations": "Sewing Recommendations",
		"needleType": "Needle Type",
		"needleSize": "Needle Size",
		"threadSize": "Thread Size",
		"stitchesInch": "Stitches per inch/cm",
		"threadType": "Thread Type",
		"certifications": "Certifications",
		"shopDesigns": "Shop Designs",
		"selectDifferentFabric": "View all fabrics",
		"dimensions": "Dimensions:",
		"details": "Details:",
		"installation": "Installation:",
		"note": "Note:"
	},
	"productSize": {
		"YARD": {
			"1": "Yard",
			"2..99": "Yards"
		},
		"METER": {
			"1": "Meter",
			"2..99": "Meters"
		}
	},
	"outOfStock": {
		"message": "We are temporarily out of this fabric type; please select another.",
		"messageShort": "We are temporarily out of this fabric type.",
		"messageGeneric": "Temporarily Out of Stock"
	},
	"carouselImages": {
		"squareThrowPillows": "Shop Throw Pillows",
		"throwBlankets": "Shop Throw Blankets",
		"duvetCovers": "Shop Duvet Covers",
		"teaTowels": "Shop Tea Towels"
	}
};