import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {calculatorModalErrorReset} from '../../actions/addToCart';
import {modalClose} from '../../actions/pageSetup';
import Modal, {ModalProps} from '../../components/Reusable/Modal/Modal';
import {EN} from '../../constants/SupportedLanguages';
import {useA11yDialogFocusTrap} from '../../hooks/useA11yDialogFocusTrap';
import {TranslateWrapper} from '../../services';
import {State} from '../../store/initialState';


const ModalContainer = (props: Omit<ModalProps, 'modalClose' | 'displayModal' | 'initialLocale'>): JSX.Element => {
  const {displayModal, initialLocale} = useSelector((state: State) => ({
    displayModal: state.pageSetup.modal.displayModal || false,
    initialLocale: state.user.preferences.locale || EN
  }), shallowEqual);
  const dispatch = useDispatch();
  const dispatchModalClose = (initialLocale = EN, modalWithReset = false, clearErrors = false) => {
    if (modalWithReset) {
      TranslateWrapper.setLangCode(initialLocale);
    }

    if (clearErrors) {
      dispatch(calculatorModalErrorReset());
    }

    dispatch(modalClose());

    props.initiatorRef?.current?.focus();
  };
  const {
    modalRef,
    initiatorRef,
    ...rest
  } = props;

  const modalProps = {
    ...rest,
    modalRef,
  };

  useA11yDialogFocusTrap({
    dialogRef: modalRef,
    initiatorRef,
  });

  return <Modal {...modalProps} modalClose={dispatchModalClose} displayModal={displayModal} initialLocale={initialLocale}/>;
};

export default ModalContainer;
