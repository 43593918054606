/* eslint-disable max-lines */
// TODO SP-6816: Break up when adding types to Redux files

import {URL} from 'url';

import {AxiosResponse} from 'axios';

import {SPOONFLOWER} from '../constants/Common';
import {COUNTRY_CODE_US} from '../constants/Countries';
import {IMPERIAL, MeasurementSystem} from '../constants/Measurements';
import {ADULT_CONTENT_OFF, URL_QUERY_PARAM_SORT_BESTSELLERS, URL_QUERY_PARAM_SUBSTRATE_FABRIC} from '../constants/Parameters';
import {USD} from '../constants/Payment';
import {StockItemCodes, StockProduct} from '../constants/StockItems';
import {EN} from '../constants/SupportedLanguages';
import {UNAUTHENTICATED} from '../constants/userTypes';
import {VIEWPORT_DESKTOP} from '../constants/Viewports';
import apiHosts, {ApiHosts} from '../entities/pageSetup/apiHosts';
import type {CountriesCollection} from '../entities/pageSetup/countries/types';
import type {PageLang, PageName} from '../entities/pageSetup/types';
import {States} from '../entities/states/types';
import {RuntimeEnv} from '../environment/runtimeEnv';
import {stickyDefaults} from '../reducers/pageSetup';
import {MultiLocaleRouterConfig} from '../server/bootstrap/loadMultiLocaleRouterConfig/MultiLocaleRouterConfigTypes';
import {Collection, UserCollections} from '../shapes/collections';
import {Colorway} from '../shapes/colorways';
import {CrossSell} from '../shapes/crossSell';
import {Design} from '../shapes/design';
import {HomeDecorOutOfStock} from '../shapes/fabrics';
import {OrderItemObject, SavedOrder} from '../shapes/orderitems';
import {ProductPricingMaps} from '../shapes/pricing';
import {Promo} from '../shapes/promo';
import {RoutesPropsParams, ParseRoutePropsParams} from '../shapes/routes';
import {ColorData, ImagesData, PricingData} from '../shapes/solids';
import {TurnaroundTime} from '../shapes/turnaroundTime';
import {UserState} from '../shapes/user';
import {ViewportType} from '../shapes/viewport';
import {RequestError} from '../utils/errors';
import {RequireRedirectError} from '../utils/redirectError';


interface ApiCalled {
  url: string;
  params: Record<string, string>;
  time: number;
  code: number;
}

export interface State {
  server: {
    errors: (RequestError | RequireRedirectError)[];
    apiResponses: Record<string, AxiosResponse>;
    cacheTtl: number | null;
    multiLocaleRouterConfig: MultiLocaleRouterConfig;
  };
  apiCalled: ApiCalled[];
  shouldRenderErrorPage: {
    shouldRender404: boolean;
  };
  addToCart: {
    isFetching: boolean;
    productSize?: string;
    availability: boolean;
    pricing?: ProductPricingMaps;
    quantity?: number;
    tax_rate?: number;
    price_per?: number; // presumed
    promo?: Promo;
    analytics?: unknown;
    chunkSize?: number; // presumed
    designId?: number; // presumed
    selectedSubstrate?: string;
    selectedColor?: string;
    isPostingMeasurements: boolean;
    measurementsPosted: boolean;
    turnarounds?: TurnaroundTime;
    orderItemSubstrateCodeSet: boolean;
    allowed_fabrics?: { // To-do This comes from the server, we need to refactor how this is done
      fabrics: HomeDecorOutOfStock[];
      default: string;
    };
    fabric_code?: string;
  };
  preloads: {
    images: string[];
    scripts: string[];
    styles: string[];
  };
  carts: {
    count: {
      count: number;
      isCartItemsCountFetching: boolean;
    };
    pending: {
      id?: number;
      pendingCartFetched: boolean;
      isFetching: boolean;
      pricing?: {
        raw_total: number;
      };
      order_items?: OrderItemObject[];
      payment_options?: {
        afterpay: {
          available: boolean;
        };
        id_me: {
          available: boolean;
          api_key?: string;
        };
      };
      factory?: string;
    };
    saved: SavedOrder | Record<string, unknown>;
    deliveryOptions: Record<string, unknown>;
    lastPaid: Record<string, unknown>;
    payment: Record<string, unknown>;
    paypal: {
      braintreeInitialized: boolean;
      braintreeAlreadyInitializing: boolean;
    };
    stripe: {
      saveNewPaymentInfo: boolean;
      customerData: Record<string, unknown>;
    };
    afterpay?: {
      token: string;
    };
  };
  user: UserState;
  userStats: {
    data_layer: {
      userID?: string;
      segment?: string;
      contestVoter?: string;
      designForSale?: string;
      marketplaceBuyer?: string;
      accountAge?: string;
      numOrders?: string;
      proUser?: string;
      currencyCode: string; // presumed
      countryCode: string; // presumed
      pageType: string;
    };
  };
  tabSets: Record<string, unknown>;
  flashMessage: Record<string, unknown>;
  transitionMessage: Record<string, unknown>;
  searchResults: { // Todo add pageResults with new type defined ProductType
    abTestSearchVariant: string;
    product_filter: {
      product: string;
      terms: string;
      substrate: string;
    };
    query_hit_count: {
      total_listings: string;
    };
    results?: {
      id: unknown;
      num_favorites: number;
    }[];
    resultsLoading?: boolean;
    tag?: string;
    mature_content_auto_applied?: boolean;
    feature_flags?: Record<string, string[]>;
  };
  invoice: {
    loading: boolean;
    order: Record<string, unknown>;
    gateway_metadata: Record<string, unknown>;
  };
  colorways: {
    isFetching: boolean;
    colorways: Colorway[];
  };
  multiLocaleRouter: {
    alternateLocales: Record<string, unknown>;
  };
  pageSetup: {
    displayFooter: boolean;
    displayHeader: boolean;
    displayNavigation: boolean;
    displayHeaderSearch: boolean;
    displayModal: boolean;
    modal: {
      modalName: string;
      displayModal: boolean;
    };
    showFilters?: boolean;
    slideInComponent?: unknown;
    showSlideInComponent: boolean;
    touchDetected: boolean;
    viewport: ViewportType;
    windowWidth?: number;
    windowHeight?: number;
    windowPosition?: number;
    usStates: States;
    canadaStates: States;
    pageTitle: string;
    pageName: PageName;
    pageKey: string;
    pageLang: PageLang;
    hostname: string;
    runtimeEnv?: RuntimeEnv;
    apiHosts: ApiHosts;
    // eslint-disable-next-line @typescript-eslint/ban-types
    countries: CountriesCollection | {};
    uuid: string;
    origin: URL['origin'];
    isTagsPage?: boolean;
    isCollectionsPage?: boolean;
  };
  searchParams: {
    q: string;
    designer: string;
    on: string;
    sort: string;
    topic: string;
    color: string;
    eigColors: string;
    style: string;
    fabric: string;
  };
  fabrics: {
    isFetching: boolean;
    fabrics: {[key: string]: {
      active: boolean;
      oos: boolean;
      standby: boolean;
      pricing: {[measurementUnit: string]: {price_undiscounted: number}};
      id: number;
    };
   };
    default?: string;
    measurement_system?: MeasurementSystem;
    categories?: Record<string, Record<string, FabricShopCategory>>;
    categories_groups?: Record<string, unknown>;
    fabricShopSearch: string;
  };
  allProducts: {
    isFetching: boolean;
    design?: Design;
  };
  fillayard: {
    collection: Record<string, unknown>;
    selections: Record<string, unknown>;
    masterTemplates: Record<string, unknown>;
    imageTemplate: Record<string, unknown>;
  };
  design: Design;
  crossSell: CrossSell;
  announcements: never[];
  reviews: {
    reviews: [];
    summary: never[];
    isFetched: boolean;
    isFetching: boolean;
    eligibility: boolean;
    review_submitted: boolean;
  };
  userCollections: {
    isFetching: boolean;
    } | UserCollections;
  userFavoriteDesigns: {
    isFetching: boolean;
    favoriteDesigns?: Record<number, boolean>;
  };
  wallpapers: {
    isFetching: boolean;
    wallpapers: Record<string, {
      active: boolean;
      pricing?: Record<string, unknown>;
      price?: number;
      price_undiscounted?: number;
      discounts_applied?: boolean;
      fabric_size?: string;
      oos: boolean;
      error?: string;
      standby: boolean;
      id: number;
    }>;
    default?: string;
  };
  stockItems: {
    isFetching: boolean;
    pricing?: Record<StockItemCodes, Record<string, unknown>>;
    stock_products?: StockProduct[];
    vat_rate?: number;
  };
  afterpay: { // Only relevant for afterpay landing page
    isFetching: boolean;
    available: boolean;
    config: {
      min_value?: number;
      max_value?: number;
      currency?: string;
    };
  };
  routing: {
    locationBeforeTransitions: {
      pathname: string;
      query: LocationQuery;
      search: string;
      hash: string;
      action: string;
    };
  };
  accountInfo: {
    isFetching: boolean;
    email: string;
    resident: boolean;
    request: string;
    dataDeletionLoading?: boolean;
  };
  newsletter: {
    isFetching: boolean;
    uid: string;
    email: string;
    country: string;
    tel: string;
    sms_text: boolean;
    shopping: boolean;
    business_owner: boolean;
    creator: boolean;
    designer: boolean;
    fabric: boolean;
    home_decor: boolean;
    wallpaper: boolean;
    exclusive_deals: boolean;
    email_subscription: boolean;
    sms_subscription: boolean;
    update_attentive?: boolean;
    uuid?: string;
    isError: boolean;
    isLoaded: boolean;
  };
  routingData: ParseRoutePropsParams;
  solids: Solids;
  collection: Collection;
  featureFlags: string[];
}

export interface Solids extends PricingData {
  promo: Promo;
  availableColors: {isFetching: boolean; colors?: ColorData};
  crossSells?: Record<string, unknown>;
  images: {isFetching: boolean; images?: ImagesData};
  isFetching: boolean;
  turnarounds?: TurnaroundTime;
}

export interface LocationQuery {
  country?: string;
  currency?: string;
  measurement_system?: MeasurementSystem;
  fabric?: string;
  fabric_size?: string;
  size?: string;
  order_item_id?: string;
  code?: string; // ID.me authcode from redirect.
  color?: string; // Petal Solids support
  design_id?: string;
  q?: string;
  page_offset?: string; // TODO: Shift to camelCase for consistency
  inner_page_offset?: string;
  designer?: string;
  style?: string;
  eigColors?: string;
  topic?: string;
  sort?: string;
  on?: string;
  id?: string;
  productType?: string;
  autoApply?: boolean;
  winnersOnly?: boolean;
  uid?: string;
  showMatureContent?: string;
  riid?: string;
}

export interface FabricShopCategory {
  name: string;
  slug: string;
  best_source_id: number;
  id: number;
}

export const initialState: State = {
  server: {
    errors: [],
    apiResponses: {},
    cacheTtl: null,
    multiLocaleRouterConfig: {}
  },
  apiCalled: [],
  shouldRenderErrorPage: {
    shouldRender404: false,
  },
  preloads: {
    images: [],
    scripts: [],
    styles: []
  },
  carts: {
    count: {
      count: 0,
      isCartItemsCountFetching: false
    },
    pending: {
      pendingCartFetched: false,
      isFetching: false,
      payment_options: {
        afterpay: {
          available: false
        },
        id_me: {
          available: false
        }
      }
    }, // GET /order/:id => $.data
    saved: {},
    deliveryOptions: {},
    lastPaid: {},
    payment: {},
    paypal: {
      braintreeInitialized: false,
      braintreeAlreadyInitializing: false
    },
    stripe: {
      saveNewPaymentInfo: false,
      customerData: {}
    }
  },
  user: { // GET /user/me => $.data
    cookies: {},
    userType: UNAUTHENTICATED,
    isFetching: false,
    newsletter_opt_in: false,
    preferences: {
      adult_content: ADULT_CONTENT_OFF,
      country: COUNTRY_CODE_US,
      locale: EN,
      currency: USD,
      measurement_system: IMPERIAL,
    },
    userDefaults: {
      country: COUNTRY_CODE_US
    },
    userHasJwt: false,
    userGroup: '',
    user_groups: []
  },
  userStats: {
    data_layer: {
      currencyCode: USD,
      countryCode: COUNTRY_CODE_US,
      pageType: '',
    },
  },
  tabSets: {},
  flashMessage: {},
  transitionMessage: {},
  searchResults: {
    abTestSearchVariant: '',
    product_filter: {
      product: '',
      terms: '',
      substrate: '',
    },
    query_hit_count: {
      total_listings: '10' // Todo all the default values should be moved into constants and should be stored in const like DEFAULT_TOTAL_LISTING
    }
  },
  invoice: {
    loading: true,
    order: {},
    gateway_metadata: {}
  },
  colorways: {
    isFetching: false,
    colorways: []
  },
  multiLocaleRouter: {
    alternateLocales: {}
  },
  pageSetup: {
    pageTitle: SPOONFLOWER,
    viewport: VIEWPORT_DESKTOP,
    countries: {},
    canadaStates: {} as States,
    usStates: {} as States,
    pageName: 'home',
    pageKey: '',
    pageLang: EN,
    hostname: '',
    origin: '',
    apiHosts: apiHosts[RuntimeEnv.Development].client,
    uuid: '',
    ...stickyDefaults,
  },
  searchParams: {
    q: '',
    designer: '',
    on: URL_QUERY_PARAM_SUBSTRATE_FABRIC,
    sort: URL_QUERY_PARAM_SORT_BESTSELLERS,
    topic: '',
    color: '',
    eigColors: '',
    style: '',
    fabric: '',
  },
  fabrics: {
    isFetching: false,
    fabrics: {},
    fabricShopSearch: '',
  },
  addToCart: {
    isFetching: false,
    isPostingMeasurements: false,
    measurementsPosted: false,
    availability: true, // product is generally available unless the backend says otherwise
    orderItemSubstrateCodeSet: false,
  },
  allProducts: {
    isFetching: false
  },
  fillayard: {
    collection: {},
    selections: {},
    masterTemplates: {},
    imageTemplate: {}
  },
  design: {
    designer: {},
    isFetching: false,
    isFetchingValidImage: false,
    isFetchingFavoriteDesign: false,
    error: false
  },
  crossSell: {
    isFetching: false,
    designs: []
  },
  announcements: [],
  reviews: {
    reviews: [],
    summary: [],
    isFetched: false,
    isFetching: false,
    eligibility: false,
    review_submitted: false
  },
  accountInfo: {
    isFetching: false,
    email: '',
    resident: false,
    request: ''
  },
  newsletter: {
    isFetching: false,
    uid: '',
    email: '',
    country: '',
    tel: '',
    sms_text: false,
    shopping: false,
    business_owner: false,
    creator: false,
    designer: false,
    fabric: false,
    home_decor: false,
    wallpaper: false,
    exclusive_deals: true,
    email_subscription: false,
    sms_subscription: false,
    isError: false,
    isLoaded: false
  },
  userCollections: {
    isFetching: false
  },
  userFavoriteDesigns: {
    isFetching: false
  },
  wallpapers: {
    isFetching: false,
    wallpapers: {}
  },
  stockItems: {
    isFetching: false
  },
  afterpay: {
    isFetching: false,
    available: false,
    config: {}
  },
  solids: {
    isFetching: false,
    code: '',
    currency: 'USD',
    fabricCode: '',
    colorCode: '',
    standby: false,
    afterpay: {
      isFetching: false,
      available: false
    },
    pricing: {},
    promo: {},
    images: {
      isFetching: false
    },
    availableColors: {
      isFetching: false,
    },
    crossSells: {
      isFetching: false
    }
  },
  routing: {
    locationBeforeTransitions: {
      pathname: '', search: '', hash: '', action: 'POP', query: {}
    },
  },
  routingData: {
    routesProps: {} as RoutesPropsParams,
    routesParams: {} as RoutesPropsParams
  },
  collection: {
    collection_name: '',
    description: '',
    design_docs: [],
    design_thumbnails: [],
    designer_only: false,
    id: 0,
    num_public_designs: 0,
    slug: '',
    show_collection: false,
    user: {
      user_id: 0,
      id: 0,
      screen_name: ''
    },
    mugshot: {
      mugshot_id: 0,
      mugshot_url: ''
    }
  },
  featureFlags: []
};

export default initialState;
