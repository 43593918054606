module.exports = {
	"calculatorButtonTextOne": "How much",
	"calculatorButtonTextTwo": "wallpaper do I need?",
	"calculator": {
		"heading": "How much wallpaper should I order?",
		"fieldsetHeading": "Enter your wall dimensions",
		"heightInputHeading": "Wall height",
		"widthInputHeading": "Wall width",
		"cm": "cm",
		"in": "in",
		"ft": "ft",
		"addWall": "Add another wall",
		"removeWall": "Remove selected wall",
		"calculate": "Calculate",
		"close": "Close",
		"save": "Close",
		"addToCart": "Add to Cart",
		"suggestionIntro": "We think you'll need:",
		"panel": {
			"1": "{context} panel",
			"2..99999": "{context} panels"
		},
		"roll": {
			"1": "{context} roll",
			"2..99999": "{context} rolls"
		},
		"suggestion": "{wallpaperForm} of {size} wallpaper",
		"disclaimer": "Please note: this calculation is purely an estimate based on the measurements provided. Spoonflower is not responsible for shortages or overages. Depending on the repeat size of the selected design more wallpaper might be required.",
		"interiorDesignerInfo": "Interior Designer? Join Trade & save on every purchase.",
		"learnMore": "Learn More",
		"maxHeightImperial": "12 ft",
		"maxHeightMetric": "366 cm",
		"metricUnit": "cm",
		"imperialUnit": "ft/in",
		"userPreferencesUpdateLink": "Change to {unit}",
		"contactLinkText": "contact our help team",
		"contactUrl": "/en/contact",
		"salesEmail": "sales@spoonflower.com",
		"WALLPAPER_CALCULATOR_ERROR_PANEL_HEIGHT": "For walls higher than {maxHeight} please select {alternatives}",
		"WALLPAPER_CALCULATOR_ERROR_ROLL_HEIGHT": "Your wall height exceeds our longest roll length",
		"WALLPAPER_CALCULATOR_ERROR_PANEL_REPEAT": "This design requires more wallpaper to cover a wall of this height. Please select {alternatives}",
		"WALLPAPER_CALCULATOR_ERROR_ROLL_REPEAT": "This design will not work for a wall of this height, please try a different design or {link}",
		"WALLPAPER_CALCULATOR_ERROR_UNKNOWN_DIMENSIONS": "Numeric characters only",
		"WALLPAPER_CALCULATOR_ERROR_DECIMAL": "Round up to the nearest whole number",
		"WALLPAPER_CALCULATOR_ERROR_CALCULATION_FAILURE": "For walls higher than {maxHeight} please select {alternatives}",
		"WALLPAPER_CALCULATOR_ERROR_MAX_ITEM_QUANTITY": "This order is too large (over 99 items) to automatically add to your cart. Please contact {email} for prompt assistance"
	},
	"landingPage": {
		"title": "How much wallpaper do I need?",
		"description": "Use the calculator below to estimate the cost of your next wallpaper project. With {linkedWords} to choose from and over a million designs, {boldWords} Based on the measurements of your wall or surface, we’ll let you know how much paper we think you’ll need.",
		"linkedWords": "multiple types of wallpaper",
		"boldWords": "the possibilities are endless.",
		"altAttribute": "wallpaper rolls",
		"resultHeading": "Wallpaper Estimate",
		"resultsHeading": "Wallpaper Estimate (Choose one):",
		"limitedSelection": "While we offer multiple types of wallpaper, only the following will work for walls over 12ft:",
		"selectBtn": "Select {wallpaper}",
		"for": "for",
		"panels": {
			"1": "panel* of",
			"2..99999": "panels* of"
		},
		"rolls": {
			"1": "roll* of",
			"2..99999": "rolls* of"
		},
		"of": "of ",
		"disclaimerLandingPage": "*Please note: depending on the repeat size of the selected design more wallpaper might be required. For the best estimate, we recommend calculating your coverage from the product page of the design you want to use. This calculation is purely an estimate based on the measurements provided. Spoonflower is not responsible for shortages or overages."
	},
	"productDetails": {
		"verticalRepeat": {
			"IMPERIAL": "Vertical Repeat: {context}\"",
			"METRIC": "Vertical Repeat: {context} cm"
		},
		"specialNote": {
			"heading": "Special Note",
			"IMPERIAL": "This design repeats as a 24” half-drop. To install correctly the second piece will be offset {context}” vertically from the first piece. We will automatically account for this in the calculation.",
			"METRIC": "This design repeats as a 60 cm half-drop. To install correctly the second piece will be offset {context} cm vertically from the first piece. We will automatically account for this in the calculation."
		},
		"measuringTipsHeading": "Tips for Measuring",
		"firstTip": {
			"IMPERIAL": "Do round up to the nearest whole inch (in)",
			"METRIC": "Do round up to the nearest whole cm"
		},
		"secondTip": "Do use the tallest wall for your height measurement",
		"thirdTip": "Do measure the entirety of your wall, including doors and windows",
		"fourthTip": "Do not include baseboards or molding",
		"howToMeasureLink": "How to measure your wall",
		"wallpaperTypeInfoHeading": "About {context} Wallpaper",
		"WALLPAPER_ISOBAR": {
			"firstInfo": "Subtle leathered texture, matte finish",
			"secondInfo": "Paste-activated, highly durable, resistant to mold, mildew and water.",
			"thirdInfo": "Great for residential and commercial spaces"
		},
		"WALLPAPER_WOVEN": {
			"firstInfo": "Woven-textured fabric with adhesive backing",
			"secondInfo": "Removable (and repositionable)",
			"thirdInfo": "Great for walls, stair risers, drawer liners, bookcases and an array of craft and decorating projects"
		},
		"WALLPAPER_SMOOTH": {
			"firstInfo": "Durable, with a water-activated adhesive backing",
			"secondInfo": "Fully removable (and recyclable)",
			"thirdInfo": "Great for homes, rental spaces or temporary art"
		},
		"WALLPAPER_GRASSCLOTH": {
			"firstInfo": "100% handcrafted natural sisal",
			"secondInfo": "Paste required and professional paper installer recommended",
			"thirdInfo": "Recommended for lower traffic areas from dining rooms to accent walls"
		},
		"WALLPAPER_PEEL_AND_STICK": {
			"firstInfo": "Subtle woven texture with peel and stick backing",
			"secondInfo": "Removable and washable with mild soap and water",
			"thirdInfo": "Great for upgrading rooms, temporary spaces, bookcases and an aray of craft projects"
		},
		"WALLPAPER_SILVER_METALLIC": {
			"firstInfo": "Paste the wall application",
			"secondInfo": "Washable with mild detergent and clear water using a soft sponge",
			"thirdInfo": "Type I, Class A Fire Rated"
		},
		"WALLPAPER_GOLD_METALLIC": {
			"firstInfo": "Paste the wall application",
			"secondInfo": "Washable with mild detergent and clear water using a soft sponge",
			"thirdInfo": "Type I, Class A Fire Rated"
		},
		"WALLPAPER_VINYL": {
			"firstInfo": "Subtle leather textured vinyl; can be installed on lightly textured walls",
			"secondInfo": "Recommended for event spaces, offices, hotels, restaurants and other commercial spaces",
			"thirdInfo": "Type II, Class A Fire Rated"
		}
	}
};