/* eslint-disable max-lines */
import React from 'react';
import Route from 'react-router/lib/Route';
import {
  HOME_GOOD_DUVET_COVER, HOME_GOOD_EURO_PILLOW_SHAM, HOME_GOOD_RECTANGULAR_TABLE_CLOTH, HOME_GOOD_ROUND_TABLE_CLOTH,
  HOME_GOOD_PLACEMAT, HOME_GOOD_COCKTAIL_NAPKIN, HOME_GOOD_DINNER_NAPKIN, HOME_GOOD_TEA_TOWEL, HOME_GOOD_SHEET_SET,
  HOME_GOOD_STANDARD_PILLOW_SHAM, HOME_GOOD_TABLE_RUNNER, HOME_GOOD_SQUARE_THROW_PILLOW, HOME_GOOD_LUMBAR_THROW_PILLOW,
  HOME_GOOD_LONG_LUMBAR_THROW_PILLOW, HOME_GOOD_THROW_BLANKET, HOME_GOOD_CURTAIN_PANEL, WALLPAPER, FABRIC,
  FABRIC_BLANK, STOCK_ITEM_SPOONFLOWER_HANDBOOK, STOCK_ITEM_SPOONFLOWER_SEW_BOOK, STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE,
  STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK, STOCK_ITEM_WELCOME_PACK_ARTISTS, HOME_GOOD_WALL_HANGING, SOLID, STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK
} from './Codes';
import {
  PAGE_TYPE_PRODUCT_DETAIL, PAGE_TYPE_CATEGORY, PAGE_TYPE_SEARCH_RESULTS, PAGE_TYPE_DESIGN_PRODUCTS,
  PAGE_TYPE_INTERNAL, PAGE_TYPE_LANDING, PAGE_TYPE_FABRIC_DETAIL,
  PAGE_TYPE_COLLECTIONS, PAGE_TYPE_STOCK_ITEM_DETAIL
} from './pageTypes';
import GlobalStructureContainer from '../containers/GlobalStructureContainer';
import CmsContainer from '../containers/Cms/CmsContainer';
import FabricDetailContainerLazy from '../containers/Fabric/FabricDetailContainerLazy';
import TagContainerLazy from '../containers/Shop/TagContainerLazy';
import ShopContainerLazy from '../containers/Shop/ShopContainerLazy';
import ProductSharedContainerLazy from '../containers/Product/ProductSharedContainerLazy';
import WallpaperCalculatorContainerLazy from '../containers/Product/WallpaperCalculatorContainerLazy';
import ProductsOverviewContainerLazy from '../containers/Product/ProductsOverviewContainerLazy';
import FabricShopContainerLazy from '../containers/Fabric/FabricShopContainerLazy';
import ConfirmContainerLazy from '../containers/Business/ConfirmContainerLazy';
import DesignGrantContainerLazy from '../containers/DesignGrant/DesignGrantContainerLazy';
import BusinessGrantContainerLazy from '../containers/BusinessGrant/BusinessGrantContainerLazy';
import CollectionContainerLazy from '../containers/Design/CollectionContainerLazy';
import HandbookContainerLazy from '../containers/StockItems/HandbookContainerLazy';
import WelcomePackArtistsContainerLazy from '../containers/StockItems/WelcomePackArtistsContainerLazy';
import SewBookContainerLazy from '../containers/StockItems/SewBookContainerLazy';
import BookBundleContainerLazy from '../containers/StockItems/BookBundleContainerLazy';
import SamplePackContainerLazy from '../containers/StockItems/SamplePackContainerLazy';
import GrassclothSamplePackContainerLazy from '../containers/StockItems/GrassclothSamplePackContainerLazy';
import DebugContainerLazy from '../containers/Debug/DebugContainerLazy';
import ThrowUncaughtPromiseRejectionContainerLazy from '../containers/Debug/ThrowUncaughtPromiseRejectionContainerLazy';
import ThrowUncaughtExceptionInReactComponentLifecycleContainerLazy from '../containers/Debug/ThrowUncaughtExceptionInReactComponentLifecycleContainerLazy';
import PatternLibraryContainerLazy from '../containers/PatternLibraryContainerLazy';
import FillayardContainerLazy from '../containers/Fillayard/FillayardContainerLazy';
import OptionsContainerLazy from '../containers/Fillayard/OptionsContainerLazy';
import CanvasContainerLazy from '../containers/Fillayard/CanvasContainerLazy';
import PrivacyContainerLazy from '../containers/Privacy/PrivacyContainerLazy';
import InvoiceContainerLazy from '../containers/Invoice/InvoiceContainerLazy';
import CancelledDataDeletionContainerLazy from '../containers/AccountInfoResponse/Cancelled/CancelledDataDeletionContainerLazy';
import ExpiredDataDeletionContainerLazy from '../containers/AccountInfoResponse/Expired/ExpiredDataDeletionContainerLazy';
import ConfirmedDataDeletionContainerLazy from '../containers/AccountInfoResponse/Confirmed/ConfirmedDataDeletionContainerLazy';
import InProgressDataDeletionContainerLazy from '../containers/AccountInfoResponse/InProgress/InProgressDataDeletionContainerLazy';
import RequestAccountInfoContainerLazy from '../containers/AccountInfoRequest/RequestAccountInfoContainerLazy';
import RepresentResidentContainerLazy from '../containers/AccountInfoRequest/RepresentResidentContainerLazy';
import ConfirmedDataInformationContainerLazy from '../containers/AccountInfoResponse/InformationConfirmed/ConfirmedDataInfornationContainerLazy';
import ExpiredDataInformationContainerLazy from '../containers/AccountInfoResponse/InformationExpired/ExpiredDataDeletionContainerLazy';
import CancelledDataInformationContainerLazy from '../containers/AccountInfoResponse/InformationCanceled/CancelledDataInformationContainerLazy';
import RequestGuestDeletionContainerLazy from '../containers/AccountInfoRequest/RequestGuestDeletionContainerLazy';
import SubscribeNewsletterContainerLazy from '../containers/Newsletter/SubscribeNewsletterContainerLazy';
import SubscribeNewsletterSuccessContainerLazy from '../containers/Newsletter/SuccessContainers/SubscribeNewsletterSuccessContainerLazy';
import NewsletterPreferencesContainerLazy from '../containers/Newsletter/NewsletterPreferencesContainerLazy';
import NewsletterPreferencesSuccessContainerLazy from '../containers/Newsletter/SuccessContainers/NewsletterPreferencesSuccessContainerLazy';
import UnsubscribeNewsletterContainerLazy from '../containers/Newsletter/UnsubscribeNewsletterContainerLazy';
import SaveForLaterContainerLazy from '../containers/SaveForLater/SaveForLaterContainerLazy';

const routes = (
  <>
    { /** home **/ }
    <Route exact path='/' component={GlobalStructureContainer} />
    <Route exact path='/en' component={GlobalStructureContainer} />
    <Route exact path='/fr' component={GlobalStructureContainer} />
    <Route exact path='/de' component={GlobalStructureContainer} />
    <Route exact path='/es' component={GlobalStructureContainer} />

    <Route exact path='/en/home' component={GlobalStructureContainer} />
    <Route exact path='/en/home-trade' component={GlobalStructureContainer} />
    <Route exact path='/fr/home-fr' component={GlobalStructureContainer} />
    <Route exact path='/de/home-de' component={GlobalStructureContainer} />
    <Route exact path='/es/home-es' component={GlobalStructureContainer} />
    {/* end of home*/}

    <Route path=':pageLang' component={GlobalStructureContainer}>

      {/* subscription newsletter form */}
      <Route
        path='preferences'
        component={NewsletterPreferencesContainerLazy}
      />
      <Route
        path='preferences/success'
        component={NewsletterPreferencesSuccessContainerLazy}
      />
      <Route
        path='subscribe'
        component={SubscribeNewsletterContainerLazy}
      />
      <Route
        path='subscribe/success'
        component={SubscribeNewsletterSuccessContainerLazy}
      />
      <Route
        path='unsubscribe'
        component={UnsubscribeNewsletterContainerLazy}
      />
      {/* end of subscription newsletter form */}

      {/* belgian-linen */}
      <Route
        path='belgian-linen'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='belgischer-leinenstoff'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='lino-belga'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='lin-naturel-de-belgique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of belgian-linen */}

      {/* cotton-silk */}
      <Route
        path='cotton-silk'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of cotton-silk */}

      {/* essex-linen */}
      <Route
        path='essex-linen'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of essex-linen */}

      {/* seersucker */}
      <Route
        path='seersucker'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of seersucker */}

      {/* sport-stretch-woven */}
      <Route
        path='sport-stretch-woven'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of sport-stretch-woven */}

      {/* linen-cotton-canvas */}
      <Route
        path='linen-cotton-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='leinen-baumwoll-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='loneta-de-algodon-y-lino'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='toile-coton-lin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of linen-cotton-canvas */}

      {/* organic-cotton-knit */}
      <Route
        path='organic-cotton-knit'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='bio-baumwoll-interlock-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='punto-de-algodon-organico'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='maille-biologique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of organic-cotton-knit */}

      {/* cypress-cotton-canvas */}
      <Route
        path='cypress-cotton-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='panama-baumwoll-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='loneta-de-algodon-tejida'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='toile-coton'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of cypress-cotton-canvas */}

      {/* petal-signature-cotton */}
      <Route
        path='petal-signature-cotton'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='baumwollstoff-klassik'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='tela-de-algodon-clasico'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='tissu-coton-classique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of petal-signature-cotton */}

      {/* modern-jersey */}
      <Route
        path='modern-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='synthetik-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='punto-de-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='maille-moderne'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of modern-jersey */}

      {/* celosia-velvet */}
      <Route
        path='celosia-velvet'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='samt'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='terciopelo'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='velours'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of celosia-velvet */}

      {/* dogwood-denim */}
      <Route
        path='dogwood-denim'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='jeansstoff'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='jean'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='denim'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of dogwood-denim */}

      {/* lightweight-cotton-twill */}
      <Route
        path='lightweight-cotton-twill'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='leichter-baumwoll-twill'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='sarga-de-algodon-ligera'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='coton-serge-leger'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of lightweight-cotton-twill */}

      {/* chiffon */}
      <Route
        path='chiffon'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='gasa'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='mousseline-de-soie'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of chiffon */}

      {/* fleece */}
      <Route
        path='fleece'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='polar'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='polaire'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of fleece */}
      {/* polartec fleece */}
      <Route
        path='polartec-fleece'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='polartec-polar'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='polartec-polaire'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of polartec fleece */}

      {/* sport-lycra */}
      <Route
        path='sport-lycra'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='lycra-deportiva'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='lycra-sport'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of sport-lycra */}

      {/* recycled-canvas */}
      <Route
        path='recycled-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='recycelter-canvas'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='loneta-reciclada'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='toile-recyclee'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of recycled-canvas */}

      { /* organic-sweet-pea-gauze */}
      <Route
        path='organic-sweet-pea-gauze'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='bio-musselin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='gasa-organica'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='gaze-biologique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of organic-sweet-pea-gauze */}

      { /* performance-pique */}
      <Route
        path='performance-pique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      { /* end of performance-pique */}

      { /* sport-pique */}
      <Route
        path='sport-pique'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      { /* end of sport-pique */}

      { /* organic-cotton-sateen */}
      <Route
        path='organic-cotton-sateen'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='bio-baumwoll-satin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='saten-de-algodon-organico'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='coton-biologique-satine'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      { /* end organic-cotton-sateen */}

      { /* wide-cotton-sateen-3-5-oz */}
      <Route
        path='wide-cotton-sateen-3-5-oz'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='extrabreiter-leichter-baumwollsatin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='saten-ligero-grand'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='satin-leger-grand-format'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      { /* end of wide-cotton-sateen-3-5-oz */}

      { /* wide-cotton-sateen-6-5-oz-fabric */}
      <Route
        path='wide-cotton-sateen-6-5-oz'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='extrabreiter-schwerer-baumwollsatin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='saten-de-alto-gramaje-grand'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='satin-epais-grand-format'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      { /* end of wide-cotton-sateen-6-5-oz-fabric */}

      {/* cotton-poplin */}
      <Route
        path='cotton-poplin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='baumwoll-popeline'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='popelin-de-algodon'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='popeline-de-coton'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of cotton-poplin */}

      {/* satin */}
      <Route
        path='satin'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='saten'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of satin */}

      {/* cotton-spandex-jersey */}
      <Route
        path='cotton-spandex-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='baumwoll-spandex-jersey'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='punto-de-jersey-con-elastano-premium'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='jersey-coton-spandex'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of cotton-spandex-jersey */}

      {/* poly-crepe-de-chine */}
      <Route
        path='poly-crepe-de-chine'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='seidenimitat-crepe-de-chine'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='crepe-de-poliester'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='crepe-de-chine-polyester'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of poly-crepe-de-chine */}

      {/* performance-velvet */}
      <Route
        path='performance-velvet'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='matter-samt'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='terciopelo-de-rendimiento'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='velours-d-ameublement'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of performance-velvet */}

      {/* cotton-lawn */}
      <Route
        path='cotton-lawn'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='baumwollbatist'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='batista-de-algodon'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='batiste-de-coton'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of cotton-lawn */}

      {/* performance-linen */}
      <Route
        path='performance-linen'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='synthetik-leinenstoff'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='el-lino-de-rendimiento'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      <Route
        path='tissu-lin-dameublement'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of performance-linen */}

      {/* minky */}
      <Route
        path='minky'
        productType={FABRIC_BLANK}
        pageType={PAGE_TYPE_FABRIC_DETAIL}
        component={FabricDetailContainerLazy}
      />
      {/* end of minky */}

      {/* Shop by tag */}
      <Route
        path='markt/:tag-stoffe'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={TagContainerLazy}
      />
      <Route
        path='march%C3%A9/tissu-:tag'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={TagContainerLazy}
      />
      <Route
        path='mercado/:tag-tela'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={TagContainerLazy}
      />
      <Route path='shop/:tag' component={TagContainerLazy} />
      {/* end Shop by tag */}

      {/* german */}
      <Route
        path='markt'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={ShopContainerLazy}
      />
      <Route
        path='stoffe/:designId'
        productType={FABRIC}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='tapete/:designId'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='uni-stoffe'
        productType={SOLID}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='tapeten-rechner'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_LANDING}
        component={WallpaperCalculatorContainerLazy}
      />
      <Route
        path='produkte/:designId'
        pageType={PAGE_TYPE_DESIGN_PRODUCTS}
        component={ProductsOverviewContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/rechteckige-tischdecke/:designId'
        productType={HOME_GOOD_RECTANGULAR_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/runde-tischdecke/:designId'
        productType={HOME_GOOD_ROUND_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/tischlaufer/:designId'
        productType={HOME_GOOD_TABLE_RUNNER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/tischset/:designId'
        productType={HOME_GOOD_PLACEMAT}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/cocktail-servietten/:designId'
        productType={HOME_GOOD_COCKTAIL_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/servietten/:designId'
        productType={HOME_GOOD_DINNER_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/tischwasche/geschirrtuch/:designId'
        productType={HOME_GOOD_TEA_TOWEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/bettwasche/bettbezuge/:designId'
        productType={HOME_GOOD_DUVET_COVER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/bettwasche/bettwasche-set/:designId'
        productType={HOME_GOOD_SHEET_SET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/bettwasche/amerikanische-kissenbezuge/:designId'
        productType={HOME_GOOD_STANDARD_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/bettwasche/europaische-kissenbezuge/:designId'
        productType={HOME_GOOD_EURO_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/bettwasche/bezug-fur-rechteckige-dekokissen/:designId'
        productType={HOME_GOOD_LONG_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/wohnen-deko/quadratisches-zierkissen/:designId'
        productType={HOME_GOOD_SQUARE_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/wohnen-deko/rechteckiges-zierkissen/:designId'
        productType={HOME_GOOD_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/wohnen-deko/tagesdecke/:designId'
        productType={HOME_GOOD_THROW_BLANKET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/wohnen-deko/vorhange/:designId'
        productType={HOME_GOOD_CURTAIN_PANEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='heimtextilien/wohnen-deko/wandbehang/:designId'
        productType={HOME_GOOD_WALL_HANGING}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='stoffe-shop'
        pageType={PAGE_TYPE_CATEGORY}
        component={FabricShopContainerLazy}
      >
        <Route path=':category' pageType={PAGE_TYPE_CATEGORY} />
      </Route>
      <Route
        path='business/confirm'
        component={ConfirmContainerLazy}
      />
      <Route
        path='stipendium-fur-studenten'
        pageType={PAGE_TYPE_LANDING}
        component={DesignGrantContainerLazy}
      />
      <Route
        path='f%C3%B6rderung-kleinunternehmer'
        pageType={PAGE_TYPE_LANDING}
        component={BusinessGrantContainerLazy}
      />
      <Route
        path='kollektionen/:collectionId'
        pageType={PAGE_TYPE_COLLECTIONS}
        component={CollectionContainerLazy}
      />
      <Route
        path='handbuch'
        productType={STOCK_ITEM_SPOONFLOWER_HANDBOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={HandbookContainerLazy}
      />
      <Route
        path='willkommenspaket-fuer-Kuenstler-in'
        productType={STOCK_ITEM_WELCOME_PACK_ARTISTS}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={WelcomePackArtistsContainerLazy}
      />
      <Route
        path='quick-sew-project-buch'
        productType={STOCK_ITEM_SPOONFLOWER_SEW_BOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SewBookContainerLazy}
      />
      <Route
        path='DIY-buch-set'
        productType={STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={BookBundleContainerLazy}
      />
      <Route
        path='testdruck-kollektion'
        productType={STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SamplePackContainerLazy}
      />
      <Route
        path='grastapete-musterdruck-buch'
        productType={STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={GrassclothSamplePackContainerLazy}
      />
      <Route
        path='datenschutz'
        pageType={PAGE_TYPE_LANDING}
        component={PrivacyContainerLazy}
      />
      {/* end of german */}

      {/* french */}
      <Route
        path='march%C3%A9'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={ShopContainerLazy}
      />
      <Route
        path='tissus/:designId'
        productType={FABRIC}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='papier-peint/:designId'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='tissus-unis'
        productType={SOLID}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='calculateur-papier-peint'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_LANDING}
        component={WallpaperCalculatorContainerLazy}
      />
      <Route
        path='produits/:designId'
        pageType={PAGE_TYPE_DESIGN_PRODUCTS}
        component={ProductsOverviewContainerLazy}
      />
      <Route
        path='maison/linge-de-table/nappe-rectangulaire/:designId'
        productType={HOME_GOOD_RECTANGULAR_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/nappe-ronde/:designId'
        productType={HOME_GOOD_ROUND_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/chemin-de-table/:designId'
        productType={HOME_GOOD_TABLE_RUNNER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/sets-de-table/:designId'
        productType={HOME_GOOD_PLACEMAT}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/serviettes-cocktail/:designId'
        productType={HOME_GOOD_COCKTAIL_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/serviettes-de-table/:designId'
        productType={HOME_GOOD_DINNER_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-table/torchons/:designId'
        productType={HOME_GOOD_TEA_TOWEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-lit/housse-de-couette/:designId'
        productType={HOME_GOOD_DUVET_COVER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-lit/draps/:designId'
        productType={HOME_GOOD_SHEET_SET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-lit/housse-de-coussin-usa/:designId'
        productType={HOME_GOOD_STANDARD_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-lit/housse-de-coussin-europe/:designId'
        productType={HOME_GOOD_EURO_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/linge-de-lit/housse-de-coussin-lombaire-extra-long/:designId'
        productType={HOME_GOOD_LONG_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/decoration/coussin-carre/:designId'
        productType={HOME_GOOD_SQUARE_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/decoration/coussin-lombaire/:designId'
        productType={HOME_GOOD_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/decoration/couverture/:designId'
        productType={HOME_GOOD_THROW_BLANKET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/decoration/rideaux/:designId'
        productType={HOME_GOOD_CURTAIN_PANEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='maison/decoration/tenture-murale/:designId'
        productType={HOME_GOOD_WALL_HANGING}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='magasin-tissu'
        pageType={PAGE_TYPE_CATEGORY}
        component={FabricShopContainerLazy}
      >
        <Route path=':category' pageType={PAGE_TYPE_CATEGORY} />
      </Route>
      <Route
        path='entreprise/confirm'
        component={ConfirmContainerLazy}
      />
      <Route
        path='bourse-pour-etudiant'
        pageType={PAGE_TYPE_LANDING}
        component={DesignGrantContainerLazy}
      />
      <Route
        path='aide-petite-entreprise'
        pageType={PAGE_TYPE_LANDING}
        component={BusinessGrantContainerLazy}
      />
      <Route
        path='collections/:collectionId'
        pageType={PAGE_TYPE_COLLECTIONS}
        component={CollectionContainerLazy}
      />
      <Route
        path='manuel'
        productType={STOCK_ITEM_SPOONFLOWER_HANDBOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={HandbookContainerLazy}
      />
      <Route
        path='Paquete-de-bienvenida-para-artistas'
        productType={STOCK_ITEM_WELCOME_PACK_ARTISTS}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={WelcomePackArtistsContainerLazy}
      />
      <Route
        path='manuel-de-couture-rapide'
        productType={STOCK_ITEM_SPOONFLOWER_SEW_BOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SewBookContainerLazy}
      />
      <Route
        path='lot-de-livres'
        productType={STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={BookBundleContainerLazy}
      />
      <Route
        path='pack-echantillons'
        productType={STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SamplePackContainerLazy}
      />
      <Route
        path='carnet-echantillons-de-papier-peint-sisal'
        productType={STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={GrassclothSamplePackContainerLazy}
      />
      <Route
        path='confidentialite'
        pageType={PAGE_TYPE_LANDING}
        component={PrivacyContainerLazy}
      />
      {/* end of french */}

      {/* spanish */}
      <Route
        path='mercado'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={ShopContainerLazy}
      />
      <Route
        path='telas/:designId'
        productType={FABRIC}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='papel-tapiz/:designId'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='tela-lisa'
        productType={SOLID}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='calculadora-papel-tapiz'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_LANDING}
        component={WallpaperCalculatorContainerLazy}
      />
      <Route
        path='productos/:designId'
        pageType={PAGE_TYPE_DESIGN_PRODUCTS}
        component={ProductsOverviewContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/mantel-rectangular/:designId'
        productType={HOME_GOOD_RECTANGULAR_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/mantel-redondo/:designId'
        productType={HOME_GOOD_ROUND_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/camino-mesa/:designId'
        productType={HOME_GOOD_TABLE_RUNNER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/manteles-individuales/:designId'
        productType={HOME_GOOD_PLACEMAT}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/coctel-servilletas/:designId'
        productType={HOME_GOOD_COCKTAIL_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/cena-servilletas/:designId'
        productType={HOME_GOOD_DINNER_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/comedor/panos-de-cocina/:designId'
        productType={HOME_GOOD_TEA_TOWEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/ropa-cama/funda-edredon/:designId'
        productType={HOME_GOOD_DUVET_COVER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/ropa-cama/sabanas/:designId'
        productType={HOME_GOOD_SHEET_SET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/ropa-cama/cojin-decorativo-estandar/:designId'
        productType={HOME_GOOD_STANDARD_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/ropa-cama/cojin-decorativo-euro/:designId'
        productType={HOME_GOOD_EURO_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/ropa-cama/funda-de-cojin-lumbar-extra-larga/:designId'
        productType={HOME_GOOD_LONG_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/decoracion-de-interiores/cojin-cuadrado/:designId'
        productType={HOME_GOOD_SQUARE_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/decoracion-de-interiores/cojin-lumbar/:designId'
        productType={HOME_GOOD_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/decoracion-de-interiores/manta/:designId'
        productType={HOME_GOOD_THROW_BLANKET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/decoracion-de-interiores/cortinas/:designId'
        productType={HOME_GOOD_CURTAIN_PANEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='decoracion-hogar/decoracion-de-interiores/tapiz-de-pared/:designId'
        productType={HOME_GOOD_WALL_HANGING}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='tienda-de-telas'
        pageType={PAGE_TYPE_CATEGORY}
        component={FabricShopContainerLazy}
      >
        <Route path=':category' pageType={PAGE_TYPE_CATEGORY} />
      </Route>
      <Route
        path='negocio/confirm'
        component={ConfirmContainerLazy}
      />
      <Route
        path='becas-para-estudiantes'
        pageType={PAGE_TYPE_LANDING}
        component={DesignGrantContainerLazy}
      />
      <Route
        path='ayudas-peque%C3%B1as-empresas'
        pageType={PAGE_TYPE_LANDING}
        component={BusinessGrantContainerLazy}
      />
      <Route
        path='colecciones/:collectionId'
        pageType={PAGE_TYPE_COLLECTIONS}
        component={CollectionContainerLazy}
      />
      <Route
        path='manual-de-spoonflower'
        productType={STOCK_ITEM_SPOONFLOWER_HANDBOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={HandbookContainerLazy}
      />
      <Route
        path='pack-bienvenue-artistes'
        productType={STOCK_ITEM_WELCOME_PACK_ARTISTS}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={WelcomePackArtistsContainerLazy}
      />
      <Route
        path='libro-de-proyectos-de-costura'
        productType={STOCK_ITEM_SPOONFLOWER_SEW_BOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SewBookContainerLazy}
      />
      <Route
        path='lote-de-libros-diy'
        productType={STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={BookBundleContainerLazy}
      />
      <Route
        path='paquete-de-muestra'
        productType={STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SamplePackContainerLazy}
      />
      <Route
        path='libro-de-muestras-de-tela-de-hierba'
        productType={STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={GrassclothSamplePackContainerLazy}
      />
      <Route
        path='privacidad'
        pageType={PAGE_TYPE_LANDING}
        component={PrivacyContainerLazy}
      />
      {/* end of spanish */}

      {/* english/general */}
      <Route
        path='shop'
        pageType={PAGE_TYPE_SEARCH_RESULTS}
        component={ShopContainerLazy}
      />
      <Route
        path='fabric/:designId'
        productType={FABRIC}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='wallpaper/:designId'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='wallpaper-calculator'
        productType={WALLPAPER}
        pageType={PAGE_TYPE_LANDING}
        component={WallpaperCalculatorContainerLazy}
      />
      <Route
        path='products/:designId'
        pageType={PAGE_TYPE_DESIGN_PRODUCTS}
        component={ProductsOverviewContainerLazy}
      />
      <Route
        path='home-decor/dining/rectangular-tablecloth/:designId'
        productType={HOME_GOOD_RECTANGULAR_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/round-tablecloth/:designId'
        productType={HOME_GOOD_ROUND_TABLE_CLOTH}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/table-runner/:designId'
        productType={HOME_GOOD_TABLE_RUNNER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/placemats/:designId'
        productType={HOME_GOOD_PLACEMAT}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/cocktail-napkins/:designId'
        productType={HOME_GOOD_COCKTAIL_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/dinner-napkins/:designId'
        productType={HOME_GOOD_DINNER_NAPKIN}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/dining/tea-towel/:designId'
        productType={HOME_GOOD_TEA_TOWEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/bedding/duvet-cover/:designId'
        productType={HOME_GOOD_DUVET_COVER}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/bedding/sheet-set/:designId'
        productType={HOME_GOOD_SHEET_SET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/bedding/standard-pillow-sham/:designId'
        productType={HOME_GOOD_STANDARD_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/bedding/euro-pillow-sham/:designId'
        productType={HOME_GOOD_EURO_PILLOW_SHAM}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/bedding/extra-long-lumbar-throw-pillow/:designId'
        productType={HOME_GOOD_LONG_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/living-decor/square-throw-pillow/:designId'
        productType={HOME_GOOD_SQUARE_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/living-decor/lumbar-throw-pillow/:designId'
        productType={HOME_GOOD_LUMBAR_THROW_PILLOW}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/living-decor/throw-blanket/:designId'
        productType={HOME_GOOD_THROW_BLANKET}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/living-decor/curtains/:designId'
        productType={HOME_GOOD_CURTAIN_PANEL}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='home-decor/living-decor/wall-hangings/:designId'
        productType={HOME_GOOD_WALL_HANGING}
        pageType={PAGE_TYPE_PRODUCT_DETAIL}
        component={ProductSharedContainerLazy}
      />
      <Route
        path='handbook'
        productType={STOCK_ITEM_SPOONFLOWER_HANDBOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={HandbookContainerLazy}
      />
      <Route
        path='artist-welcome-pack'
        productType={STOCK_ITEM_WELCOME_PACK_ARTISTS}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={WelcomePackArtistsContainerLazy}
      />
      <Route
        path='quick-sew-project-book'
        productType={STOCK_ITEM_SPOONFLOWER_SEW_BOOK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SewBookContainerLazy}
      />
      <Route
        path='book-bundle'
        productType={STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={BookBundleContainerLazy}
      />
      <Route
        path='sample-pack'
        productType={STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={SamplePackContainerLazy}
      />
      <Route
        path='grasscloth-sample-book'
        productType={STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK}
        pageType={PAGE_TYPE_STOCK_ITEM_DETAIL}
        component={GrassclothSamplePackContainerLazy}
      />
      <Route
        path='fabric-shop'
        pageType={PAGE_TYPE_CATEGORY}
        component={FabricShopContainerLazy}
      >
        <Route path=':category' pageType={PAGE_TYPE_CATEGORY} />
      </Route>
      <Route
        path='debug'
        pageType={PAGE_TYPE_INTERNAL}
        component={DebugContainerLazy}
      >
        <Route
          path='throw_uncaught_promise_rejection'
          pageType={PAGE_TYPE_INTERNAL}
          component={ThrowUncaughtPromiseRejectionContainerLazy}
        />
        <Route
          path='throw_uncaught_exception_in_react_component_lifecycle'
          pageType={PAGE_TYPE_INTERNAL}
          component={ThrowUncaughtExceptionInReactComponentLifecycleContainerLazy}
        />
      </Route>
      <Route
        path='pattern_library'
        pageType={PAGE_TYPE_INTERNAL}
        component={PatternLibraryContainerLazy}
      />
      <Route
        path='fill-a-yard'
        pageType={PAGE_TYPE_INTERNAL}
        component={FillayardContainerLazy}
      >
        <Route
          path='options'
          pageType={PAGE_TYPE_INTERNAL}
          component={OptionsContainerLazy}
        />
        <Route
          path='canvas'
          pageType={PAGE_TYPE_INTERNAL}
          component={CanvasContainerLazy}
        />
      </Route>
      <Route
        path='business/confirm'
        component={ConfirmContainerLazy}
      />
      <Route
        path='design-grant'
        pageType={PAGE_TYPE_LANDING}
        component={DesignGrantContainerLazy}
      />
      <Route
        path='small-business-grant'
        pageType={PAGE_TYPE_LANDING}
        component={BusinessGrantContainerLazy}
      />
      <Route
        path='privacy'
        pageType={PAGE_TYPE_LANDING}
        component={PrivacyContainerLazy}
      />
      <Route
        path='account-data-deletion'
        pageType={PAGE_TYPE_LANDING}
      >
        <Route
          path='cancelled'
          component={CancelledDataDeletionContainerLazy}
        />
        <Route
          path='expired'
          component={ExpiredDataDeletionContainerLazy}
        />
        <Route
          path='in-progress'
          component={InProgressDataDeletionContainerLazy}
        />
        <Route
          path='confirmed'
          component={ConfirmedDataDeletionContainerLazy}
        />
      </Route>
      <Route
        path='account-data-information'
        pageType={PAGE_TYPE_LANDING}
      >
        <Route
          path='cancelled'
          component={CancelledDataInformationContainerLazy}
        />
        <Route
          path='expired'
          component={ExpiredDataInformationContainerLazy}
        />
        <Route
          path='confirmed'
          component={ConfirmedDataInformationContainerLazy}
        />
      </Route>
      <Route
        path='request-for-information'
        pageType={PAGE_TYPE_LANDING}
        component={RequestAccountInfoContainerLazy}
      />
      <Route
        path='represent-resident'
        pageType={PAGE_TYPE_LANDING}
        component={RepresentResidentContainerLazy}
      />
      <Route
        path='request-to-delete'
        pageType={PAGE_TYPE_LANDING}
        component={RequestGuestDeletionContainerLazy}
      />
      <Route
        path='invoice/:orderNumber'
        component={InvoiceContainerLazy}
      />
      <Route
        path='save-for-later'
        component={SaveForLaterContainerLazy}
      />
      <Route
        path='collections/:collectionId'
        pageType={PAGE_TYPE_COLLECTIONS}
        component={CollectionContainerLazy}
      />

      <Route
        path=':pageName'
        component={CmsContainer}
      />

      {/* end of english/general */}
    </Route>
  </>
);

export const Routes = () => routes;

const routesArr = [routes];

export default routesArr;
