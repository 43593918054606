import {RefObject, useEffect} from 'react';


interface IA11yDialogFocusTrap {
  dialogRef?: RefObject<HTMLElement>;
  initiatorRef?: RefObject<HTMLElement>;
}

const FOCUSABLE_ELEMENTS_SELECTOR = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const INVISIBILITY_CSS_CONFIG = [
  {
    property: 'display', conditionValue: 'none'
  },
  {
    property: 'visibility', conditionValue: 'hidden'
  },
];

const visibilityFilter = (element: HTMLElement) => {
  const computedStyle = window?.getComputedStyle(element);

  return !INVISIBILITY_CSS_CONFIG.some(
    ({property, conditionValue}) =>
      computedStyle.getPropertyValue(property) === conditionValue
  );
};

export const useA11yDialogFocusTrap = ({dialogRef, initiatorRef}: IA11yDialogFocusTrap): void => {
  useEffect(() => {
    if (!dialogRef?.current || typeof window === 'undefined') {
      return;
    }
    const dialogElement = dialogRef.current;
    const focusableElements = dialogElement.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS_SELECTOR);
    const visibleFocusableElements = Array.from(focusableElements).filter(visibilityFilter);
    const firstElement = visibleFocusableElements[0];
    const lastElement = visibleFocusableElements[visibleFocusableElements.length - 1];

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') {
        return;
      }
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();

        return;
      }
      if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    };

    dialogElement.addEventListener('keydown', handleTabKeyPress);
    dialogElement.focus();

    return () => {
      dialogElement.removeEventListener('keydown', handleTabKeyPress);
      const focusableElements = initiatorRef?.current?.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS_SELECTOR);

      focusableElements?.[0]?.focus();
    };
  }, [dialogRef]);
};
