import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, {ChangeEvent} from 'react';
import './_radio-group.scss';


export interface Option {
  label: string | JSX.Element;
  value: string;
}

interface RadioGroupProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: Option[];
  value: string;
  label?: string;
  error?: string;
  name?: string;
}

const RadioButtonStyles = {
  '&.Mui-checked': {
    color: '#000'
  }
};

const RadioGroupContainer = ({value, onChange, options, label, error, name}: RadioGroupProps): JSX.Element => {
  const groupLabelId = label ? `${label.replace(/\s+/g, '-')}-label` : undefined;

  return (
    <div className='radio-group-mui'>
      {label && <p id={groupLabelId} className='radio-group-label'>{label}</p>}
      {error && <p className='radio-group-error'>{error}</p>}
      <RadioGroup aria-labelledby={groupLabelId} value={value} onChange={onChange} name={name}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio sx={RadioButtonStyles}/>}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioGroupContainer;
