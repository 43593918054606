module.exports = {
	"info": {
		"title": "Collection",
		"designsCount": {
			"0": "{context} designs",
			"1": "{context} design",
			"2..99": "{context} designs"
		},
		"curatedBy": "Curated by {collectionOwner}",
		"designedBy": "Designed by {collectionOwner}",
		"topTags": "Top tags for this collection"
	},
	"links": {
		"choose": "Choose a different collection",
		"collectionTilesTitle": "Go to collection {collectionName}",
		"tileImageAlt": "Collection Cover Design",
		"tilePlaceholderAlt": "Collection Cover Placeholder",
		"designAlsoIn": "Also in collection",
		"shopSimilar": "Shop similar collections",
		"searchOtherCollections": "Search other collections",
		"favoriteCollectionButton": "Favorite this collection",
		"unfavoriteCollectionButton": "Unfavorite this collection"
	},
	"userCollections": {
		"queryPlaceholder": "Quick-search my collections",
		"searchIconTitle": "Search",
		"addToButton": "Add to collection",
		"addToIcon": "You can add {designTitle} to a collection",
		"countText": "In {designInCollectionCount} of your collections",
		"sectionTitleCreate": "Create a New Collection",
		"nameLabel": "Name",
		"namePlaceholder": "Enter new collection name",
		"descriptionLabel": "Description",
		"descriptionPlaceholder": "Enter collection description (optional)",
		"checkboxLabelText": "Make this collection visible to the public?",
		"buttonTitleCreate": "Create this collection",
		"buttonTextCreate": "Create",
		"buttonTitleCreateNew": "Create a new collection",
		"buttonTextCreateNew": "Create New",
		"buttonDone": "Done",
		"sectionTitleAdd": "Add to Collections",
		"listTitleRecent": "Recent",
		"listTitleAllOthers": "All Others",
		"listTitleSearchResults": "Search Results",
		"listTitleAlreadyIn": "Already In",
		"linkIconTitle": "Go to collection",
		"privateIconTitle": "Collection is private",
		"addTo": "add to",
		"removeFrom": "remove from",
		"titleButton": "You can {yamlCopy} collection",
		"titleIcon": "You can {yamlCopy} collection",
		"addToDialog": "Add to collection"
	},
	"collection": {
		"edit": "Edit",
		"sample": "Sample",
		"designAmount": {
			"0": "{context} Items",
			"1": "{context} Item",
			"2..99": "{context} Items"
		},
		"moreInfo": "More info",
		"lessInfo": "Less info",
		"fay": "Fill a yard of fabric with this collection",
		"fayTip": "Also great for making cheater quilts and scarves"
	}
};