import React from 'react';

import {CODE_EVENT_SPACE, KEY_EVENT_ENTER} from '../constants/Common';


export const ariaKeyboardHandler = (cb: (event: React.SyntheticEvent) => void) => (event: React.KeyboardEvent<HTMLElement>): void => {
  if ([KEY_EVENT_ENTER, CODE_EVENT_SPACE, ' '].includes(event.key)) {
    cb(event);
    event.stopPropagation();
    event.preventDefault();
  }
};
