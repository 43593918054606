module.exports = {
	"subTypes": {
		"PRODUCT_SUB_TYPE_SPOONFLOWER_SAMPLE_PACK": "Fabric Sample Pack",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_COLOR_GUIDE": "Spoonflower Color Guide",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_HANDBOOK": "Spoonflower Handbook",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_SEW_BOOK": "The Spoonflower Quick-Sew Project Book",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_BOOK_BUNDLE": "Spoonflower DIY Book Bundle",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_DIY_PROJECT": "Spoonflower DIY Project",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_CORGI_PILLOW": "Corgi Pillow",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_S": "Spoonflower T-Shirt (Size S)",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_M": "Spoonflower T-Shirt (Size M)",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_L": "Spoonflower T-Shirt (Size L)",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XL": "Spoonflower T-Shirt (Size XL)",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XXL": "Spoonflower T-Shirt (Size XXL)",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_GRAB_BAG": "Grab Bag",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_GRAND_SATEEN_COLOR_MAP": "Sateen Grand Color Map",
		"STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK": "Fabric Sample Pack",
		"STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK": "Petal Signature Cotton® Solids Swatch Book",
		"ORDER_ITEM_TYPE_SOLID_ITEM": "Petal Signature Cotton® Solids",
		"STOCK_ITEM_SPOONFLOWER_COLOR_GUIDE": "Spoonflower Color Guide",
		"STOCK_ITEM_SPOONFLOWER_HANDBOOK": "Spoonflower Handbook",
		"STOCK_ITEM_SPOONFLOWER_SEW_BOOK": "The Spoonflower Quick-Sew Project Book",
		"STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE": "Spoonflower Book Bundle",
		"STOCK_ITEM_SUB_TYPE_SPOONFLOWER_DIY_PROJECT": "Spoonflower DIY Project",
		"STOCK_ITEM_SPOONFLOWER_CORGI_PILLOW": "Corgi Pillow",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_S": "Spoonflower T-Shirt (Size S)",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_M": "Spoonflower T-Shirt (Size M)",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_L": "Spoonflower T-Shirt (Size L)",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XL": "Spoonflower T-Shirt (Size XL)",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XXL": "Spoonflower T-Shirt (Size XXL)",
		"STOCK_ITEM_SPOONFLOWER_GRAB_BAG": "Grab Bag",
		"STOCK_ITEM_SPOONFLOWER_PERENNIAL_SATEEN_GRAND_COLOR_MAP": "Perennial Sateen Grand Color Map",
		"STOCK_ITEM_SPOONFLOWER_LONGLEAF_SATEEN_GRAND_COLOR_MAP": "Longleaf Sateen Grand Color Map",
		"STOCK_ITEM_WELCOME_PACK_ARTISTS": "Spoonflower Welcome Pack for Artists",
		"STOCK_ITEM_WELCOME_PACK_MAKERS": "Spoonflower Welcome Pack for Makers",
		"KIT_SUB_TYPE_LARGE_SAMPLER": "Large Sampler",
		"KIT_SUB_TYPE_SAMPLER": "Sampler",
		"KIT_SUB_TYPE_SMALL_SAMPLER": "Small Sampler",
		"KIT_SUB_TYPE_FAT_QUARTER_BUNDLE": "Fat Quarter Bundle",
		"KIT_SUB_TYPE_SEW_SHIRT": "Sew Shirt™",
		"KIT_SUB_TYPE_SEW_TOTE": "Sew Tote™",
		"UNPRINTED_ITEM_SUB_TYPE_BLANK": "Blank Fabric",
		"WALLPAPER_SMOOTH": "Pre-Pasted Wallpaper",
		"WALLPAPER_WOVEN": "Peel and Stick Removable Woven Wallpaper",
		"WALLPAPER_ISOBAR": "Non-Pasted Wallpaper",
		"WALLPAPER_GRASSCLOTH": "Grasscloth Wallpaper",
		"WALLPAPER_PEEL_AND_STICK": "Peel and Stick Wallpaper",
		"WALLPAPER_SILVER_METALLIC": "Silver Metallic Wallpaper",
		"WALLPAPER_GOLD_METALLIC": "Gold Metallic Wallpaper",
		"WALLPAPER_VINYL": "Vinyl Wallpaper",
		"SERVICE_ITEM_TYPE_COLOR_MAP": "Spoonflower Color Map",
		"SERVICE_ITEM_TYPE_COLOR_GUIDE": "Spoonflower Color Guide",
		"SERVICE_ITEM_TYPE_WELCOME_PACK": "Spoonflower Welcome Pack",
		"SERVICE_ITEM_TYPE_GIFT_CERTIFICATE": "Spoonflower Gift Certificate",
		"SERVICE_ITEM_TYPE_SWATCH_BOOK": "Spoonflower Swatch Book",
		"SERVICE_ITEM_TYPE_PRO_PURCHASE": "Pro Membership",
		"SERVICE_ITEM_TITLE_PURCHASING_PRO": "Pro Membership V1",
		"SERVICE_ITEM_TITLE_PURCHASING_PRO_RENEWAL": "Pro Membership V1 with Renewal",
		"SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO": "Pro Membership V1",
		"SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO_RENEWAL": "Pro Membership V1 with Renewal",
		"SERVICE_ITEM_TYPE_SPOONDOLLAR_CREDIT": "Spoondollar credit to designer",
		"SERVICE_ITEM_TYPE_SPOONDOLLAR_DEBIT": "Spoondollar amount debited from order",
		"SERVICE_ITEM_TYPE_SPOONDOLLAR_PAYOUT": "Spoondollar payout to designer",
		"SERVICE_ITEM_TYPE_CHUNKED_SPLIT_ITEM": "Chunked or split item"
	},
	"repeatType": {
		"FABRIC_ITEM_REPEAT_TYPE_CENTERED": "Centered",
		"FABRIC_ITEM_REPEAT_TYPE_BASIC": "Basic Repeat",
		"FABRIC_ITEM_REPEAT_TYPE_HALF_DROP": "Half-Drop Repeat",
		"FABRIC_ITEM_REPEAT_TYPE_HALF_BRICK": "Half-Brick Repeat",
		"FABRIC_ITEM_REPEAT_TYPE_MIRRORED": "Mirrored Repeat"
	},
	"description": {
		"SERVICE_ITEM_TYPE_COLOR_MAP": "V2.1 of the Spoonflower Wallpaper Color Map is designed 24 in by 4 ft and is comprised of nearly 1500 individual colors.",
		"SERVICE_ITEM_TYPE_WELCOME_PACK": "Includes $35 in Spoondollar credit, samples of printable fabrics and materials, & Heather Ross Prints. Please note that Welcome Packs always ship within two business days.",
		"SERVICE_ITEM_TYPE_GIFT_CERTIFICATE": "{amount} in Spoondollar credit. Please note that Gift Certificates are digital goods and do not ship.",
		"SERVICE_ITEM_TITLE_PURCHASING_PRO": "1 year membership for {amount}. Benefits are activated with your next order.",
		"SERVICE_ITEM_TITLE_PURCHASING_PRO_RENEWAL": "1 year membership for {amount}. Benefits are activated with your next order. Includes auto renewal.",
		"SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO": "1 year renewal membership for {amount}",
		"SERVICE_ITEM_TITLE_CURRENT_PRO_PURCHASING_PRO_RENEWAL": "1 year renewal membership for {amount}. Includes auto renewal.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_SAMPLE_PACK": "A booklet containing a square sample of each Spoonflower fabric.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_DIY_PROJECT": "Get a unique DIY project every month! Our projects are perfect for novice crafters and experts alike. Each one includes a fat quarter of Organic Cotton Sateen featuring our limited-run designs and a postcard of printed instructions.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_COLOR_GUIDE": "A fabric swatch containing 171 colors chosen for their print quality.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_HANDBOOK": "A DIY Guide to Designing Fabric, Wallpaper, and Gift Wrap with 30+ projects. Soft-back book with 208 pages of glossy color images.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_SEW_BOOK": "Stitch up a storm with 30+ new fabric stash-friendly projects. With DIYs grouped into fabric quantities like swatches, fat quarters, 1+ yard projects, there's sure to be something fun and interesting for all.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_BOOK_BUNDLE": "This 2-book bundle, featuring The Spoonflower Handbook and The Spoonflower Quick-Sew Project Book, covers the gamut from how to design your own fabric to 34 DIY projects that help you use every bit of fabric in your stash.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_CORGI_PILLOW": "Cute and cuddly corgi for a cause. $12 from the sale of each corgi pillow donated to The Coalition to Unchain Dogs.",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_S": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_M": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_L": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XL": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XXL": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"PRODUCT_SUB_TYPE_SPOONFLOWER_GRAND_SATEEN_COLOR_MAP": "The Spoonflower Color Map, printed on Grand Sateen fabric, is approximately 39 inches wide by 36 inches tall and includes nearly 1500 individual colors.",
		"STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK": "A booklet containing a square sample of each Spoonflower fabric.",
		"STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK": "A booklet containing a square sample of each Petal Signature Cotton® Solids.",
		"STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK": "Spoonflower’s Grasscloth Sample Book offers ten sample designs chosen to showcase the natural features of grasscloth wallpaper.",
		"STOCK_ITEM_SUB_TYPE_SPOONFLOWER_DIY_PROJECT": "Get a unique DIY project every month! Our projects are perfect for novice crafters and experts alike. Each one includes a fat quarter of Organic Cotton Sateen featuring our limited-run designs and a postcard of printed instructions.",
		"STOCK_ITEM_SPOONFLOWER_COLOR_GUIDE": "A fabric swatch containing 171 colors chosen for their print quality.",
		"STOCK_ITEM_SPOONFLOWER_HANDBOOK": "A DIY Guide to Designing Fabric, Wallpaper, and Gift Wrap with 30+ projects. Soft-back book with 208 pages of glossy color images.",
		"STOCK_ITEM_SPOONFLOWER_SEW_BOOK": "Stitch up a storm with 30+ new fabric stash-friendly projects. With DIYs grouped into fabric quantities like swatches, fat quarters, 1+ yard projects, there's sure to be something fun and interesting for all.",
		"STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE": "This 2-book bundle, featuring The Spoonflower Handbook and The Spoonflower Quick-Sew Project Book, covers the gamut from how to design your own fabric to 34 DIY projects that help you use every bit of fabric in your stash.",
		"STOCK_ITEM_SPOONFLOWER_CORGI_PILLOW": "Cute and cuddly corgi for a cause. $12 from the sale of each corgi pillow donated to The Coalition to Unchain Dogs.",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_S": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_M": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_L": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XL": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XXL": "“For the Love of Spoonflower” was the popularly-voted winner of the 2017 Spoonflower T-shirt design challenge and is now available for you to take home!",
		"STOCK_ITEM_SPOONFLOWER_PERENNIAL_SATEEN_GRAND_COLOR_MAP": "The Spoonflower Color Map, printed on Grand Sateen fabric, is approximately 39 inches wide by 36 inches tall and includes nearly 1500 individual colors.",
		"STOCK_ITEM_SPOONFLOWER_LONGLEAF_SATEEN_GRAND_COLOR_MAP": "The Spoonflower Color Map, printed on Grand Sateen fabric, is approximately 39 inches wide by 36 inches tall and includes nearly 1500 individual colors.",
		"STOCK_ITEM_WELCOME_PACK_ARTISTS": "",
		"STOCK_ITEM_WELCOME_PACK_MAKERS": ""
	}
};