import TreeItem, {useTreeItem} from '@mui/lab/TreeItem';
import React from 'react';

import {translate} from '../../../services';
import {ariaKeyboardHandler} from '../../../utils/ariaHelpers';
import {isNotUndefined} from '../../../utils/validation';
import Icon from '../../Reusable/Icon/Icon';

import {TransitionComponent} from './AnimatedTreeItem';
import {CollapsedContent} from './CollapsedContent';
import {ColorTreeItemContent} from './ColorSearchItem';
import {useSeoLabel} from './useSeoLabel';


export interface TreeBranch {
  id: string;
  name: string;
  href?: string;
  children?: readonly TreeBranch[];
  apiValue?: string;
  dataCategory?: string;
  dataCustomComponent?: string;
}

interface SearchTreeComponent {
  nodes: TreeBranch;
  branchesIds: Set<string>;
  selectedLeafs: Array<string>;
}

export const CloseBtnIdentifier = 'close-btn';

export const constructLeafRenderId = (node: TreeBranch): string => (
  `${node.id}+${isNotUndefined(node.apiValue) && node.id.includes('productMenu') ? node.apiValue : node.name}`
);

export const SearchTree = ({nodes, branchesIds, selectedLeafs}: SearchTreeComponent): JSX.Element => {
  const nodeHasChildren = Array.isArray(nodes.children);
  // Only leafs are used to make API requests
  // If a node is a leaf, we attach +apiValue to the ID
  // And use it as a marker that the incoming node is a leaf, not a branch
  const nodeId = nodeHasChildren ? nodes.id : constructLeafRenderId(nodes);
  const treeProps = useTreeItem(nodeId);

  const {handleSelection} = treeProps;

  const label = useSeoLabel({
    nodes,
    nodeProps: {
      role: 'treeitem',
      tabIndex: selectedLeafs.includes(nodeId) ? -1 : 0,
      'aria-hidden': selectedLeafs.includes(nodeId),
      onKeyDown: ariaKeyboardHandler(handleSelection)
    }
  });

  const isColorFilter = nodes.id.includes('colorMenu');

  const productLabel = nodes?.name || '';
  const removeTextOnItem = isColorFilter ?
    translate('search.removeColorSelection', {
      color: translate(`navigation.${nodes.apiValue ?? nodes.id}.text`)
    }) :
    translate('search.removeFilterSelection', {
      filter: productLabel
    });

  return (
    <TreeItem
      nodeId={nodeId}
      label={label}
      id={nodes.id}
      key={nodeId}
      data-category={nodes.dataCategory ?? ''}
      data-collapsible={nodeHasChildren && nodes.children?.length ? 'collapsible' : ''}
      TransitionComponent={TransitionComponent}
      ContentProps={{
        className: nodes.dataCategory ? `${nodes.dataCategory}-content` : '',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedLeafs,
        nodes
      }}
      {
        ...(selectedLeafs.includes(nodeId) && {
          icon: <Icon
            id={`${nodeId}-${CloseBtnIdentifier}`}
            iconName='close'
            iconTitle={removeTextOnItem}
            extensionClass='cross-icon'
            tabIndex={0}
            onKeyDown={ariaKeyboardHandler(handleSelection)}
            role='button'
          />
        })
      }
      {
        ...((isColorFilter && nodes.dataCategory !== 'parentCategory') && {
          ContentComponent: ColorTreeItemContent
        })
      }
      {
        ...(nodeHasChildren && {
          ContentComponent: CollapsedContent
        })
      }
    >
      {nodeHasChildren && isNotUndefined(branchesIds) ?
        branchesIds.add(nodeId) &&
        isNotUndefined(nodes.children) &&
        nodes.children.map((node: TreeBranch) => (
          <SearchTree key={node.id} nodes={node} branchesIds={branchesIds} selectedLeafs={selectedLeafs}/>
        )) :
        null
      }
    </TreeItem>
  );
};
