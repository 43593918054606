import {Switch, SwitchProps} from '@mui/material';
import React from 'react';
import './_ant-switch.scss';


export const AntSwitch = ({checked = false, onChange, ...props}: SwitchProps): JSX.Element => {
  const [checkedState, setCheckedState] = React.useState(checked);


  return <Switch
    className='ant-switch-mui'
    checked={checkedState}
    onChange={(event) => {
      onChange?.(event, Boolean(!checkedState));
      setCheckedState((!checkedState));
    }} {...props}
  />;
};

