import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import {FormControlLabel, FormGroup, FormLabel} from '@mui/material';
import classNames from 'classnames';
import {snakeCase} from 'lodash';
import get from 'lodash/get';
import xor from 'lodash/xor';
import React, {SyntheticEvent, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {COLLECTIONS} from '../../../constants/Collections';
import {
  ON_PARAM,
  ADULT_CONTENT_ON,
  ADULT_CONTENT_OFF,
  COLOR_PARAM,
  PRODUCT_PARAM,
  URL_QUERY_PARAM_SUBSTRATE_WALLPAPER,
  SUBSTRATE_PARAM,
} from '../../../constants/Parameters';
import {VINYL_WALLPAPER_FEATURE_FLAG, WINNERS_ONLY_FEATURE_FLAG} from '../../../constants/Search';
import {toggleMatureContentFilter} from '../../../constants/UserPreferences';
import {REGEX_REMOVE_ALL_SPACES} from '../../../constants/Validation';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import {selectFeatureFlags} from '../../../entities/searchResults/selectors';
import {translate} from '../../../services';
import {SearchParamType} from '../../../shapes/searchParams';
import {onClickPreventPropagation} from '../../../utils/events';
import './_search-filters.scss';
import {
  navigationByColors,
  navigationByProduct,
  navigationByStyle,
  navigationByTopic,
  navigationByWinnersOnly,
} from '../../../utils/navigation/navigation';
import {getLeafValue, onRemoveFilter} from '../../../utils/search';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import {AntSwitch} from '../../Reusable/Content/AntSwitch/AntSwitch';
import RadioGroupContainer from '../../Reusable/RadioGroup/RadioGroup';
import {SearchTree, TreeBranch, CloseBtnIdentifier} from '../SearchTree/SearchTree';

import {useSsrExpandedBranches} from './useSsrExpandedBranches';
import {
  convertIdToPathIds, findLeafByPath,
  treeInitialBranchesAndLeafs
} from './utils';


interface FilterTreeComponentProps {
  extensionClass: string;
  searchParams: SearchParamType;
  adultContent: string;
  winnersOnly: boolean;
  dispatchMatureContentFilterToggle: () => void;
  updateSearchMatureContentFilter: () => void;
  dispatchOnSubmitQuery: (payload: Record<string, string>) => void;
  shopType: string;
  setRemovedFilter: (payload: string) => void;
}

export interface Tree {
  [key: string]: TreeBranch;
}

/*
 * Tree = represented as an object of nodes
 * Branch = unselectable node that contains children
 * Node = term is used when it's undefined whether var a branch or a leaf
 * Leaf = selectable node whose value is being used to
 * make API requests
 * Path = concatenated list of ids from the first level Branch
 * to the Leaf
 * */
const SearchFilters = ({
  extensionClass,
  searchParams,
  adultContent,
  shopType,
  winnersOnly,
  updateSearchMatureContentFilter,
  dispatchOnSubmitQuery,
  dispatchMatureContentFilterToggle,
  setRemovedFilter,
}: FilterTreeComponentProps): JSX.Element => {
  const featureFlags = useSelector(selectFeatureFlags);
  const showVinylWallpaper = featureFlags.has(VINYL_WALLPAPER_FEATURE_FLAG);
  const showWinnersOnly = featureFlags.has(WINNERS_ONLY_FEATURE_FLAG);
  const additionalSubstrates = showVinylWallpaper ? ['wallpaperVinyl'] : [];
  const shopPageFilters = [
    navigationByProduct(additionalSubstrates),
    navigationByWinnersOnly(),
    navigationByTopic(),
    navigationByStyle(),
    navigationByColors(),
  ];

  const searchFiltersTree: Tree = {
    productMenu: navigationByProduct(additionalSubstrates),
    colorMenu: navigationByColors(),
    styleMenu: navigationByStyle(),
    topicMenu: navigationByTopic(),
  };

  const currentOrigin = useOrigin();
  const branchesIds = new Set(['mui-1', 'mui-2']);

  const {branchesIdsToExpandInitially, leafsIdsToSelectInitially} = treeInitialBranchesAndLeafs(searchParams, searchFiltersTree);
  const [branchesToExpand, setBranchesToExpand] = useState<string[]>(branchesIdsToExpandInitially);
  const [leafsToSelect, setLeafsToSelect] = useState<string[]>(leafsIdsToSelectInitially);

  const collectionsPageFilters = [navigationByStyle(), navigationByColors(), navigationByTopic()];

  const selectedFilters = shopType === COLLECTIONS ? collectionsPageFilters : shopPageFilters;

  useEffect(() => {
    const {
      branchesIdsToExpandInitially: branches,
      leafsIdsToSelectInitially: leafs
    } = treeInitialBranchesAndLeafs(searchParams, searchFiltersTree);
    const detectLeafDeletion = leafsToSelect.find((item) => !leafs.includes(item));
    const detectLeafAddition = leafs.find((item) => !leafsToSelect.includes(item));

    if (!detectLeafDeletion && !detectLeafAddition) return;

    let parentBranchDeletion, parentBranchAddition;

    if (detectLeafDeletion) {
      parentBranchDeletion = detectLeafDeletion.split('.');
      const deletedLeaf = parentBranchDeletion.pop();
      const removedFilterLabel = deletedLeaf?.split('+').pop();

      setRemovedFilter(removedFilterLabel ?? '');
      parentBranchDeletion = parentBranchDeletion.join('.');
    } else {
      setRemovedFilter('');
    }
    if (detectLeafAddition) {
      parentBranchAddition = detectLeafAddition.split('.');
      parentBranchAddition.pop();
      parentBranchAddition = parentBranchAddition.join('.');
    }

    if ((detectLeafDeletion && detectLeafAddition) && parentBranchDeletion !== parentBranchAddition) {
      setBranchesToExpand(branches);
    }

    setLeafsToSelect(leafs);
  }, [searchParams]);

  const handleNodeToggle = (event: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
    const pressedBranch = xor(branchesToExpand, nodeIds)[0]; // detect selected branch

    if (branchesToExpand.includes(pressedBranch)) { // if user want to close it
      setBranchesToExpand(xor(branchesToExpand, [pressedBranch]));

      return;
    }
    // // if user want to open it
    const branchRootId = pressedBranch.split('.');
    const updatedBranches = branchesToExpand.filter((branch) => !branch.includes(branchRootId[0]));
    const branchPathIds = convertIdToPathIds(nodeIds[0]);

    setBranchesToExpand([...updatedBranches, ...branchPathIds]);
  };

  const expanded = useSsrExpandedBranches({
    branchesToExpand, selectedFilters
  });

  // todo split handler to reduce complexity
  const handleNodeSelect = (event: SyntheticEvent<Element, Event>, [selectId]: string[]) => {
    const element = event.target as HTMLElement;
    const [parentCategoryName, category] = selectId.split('.');
    const facetParam = getLeafValue(parentCategoryName);
    const searchParam = facetParam === PRODUCT_PARAM ? ON_PARAM : facetParam;
    const apiValue = selectId.split('+')[1];
    let searchText = isNotUndefined(apiValue) && (selectId.includes('productMenu') || selectId.includes('colorMenu')) ? apiValue : element.innerText;
    const isWallpaper = category === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER;
    const isProduct = searchParam === ON_PARAM;

    if (element.id && element.id.includes(CloseBtnIdentifier)) {
      const nodeId = element.id.split(`-${CloseBtnIdentifier}`)[0];
      const parentCategoryName = nodeId.split('.').shift();
      const node = findLeafByPath(searchFiltersTree[parentCategoryName as string], nodeId);
      const isProductMenu = isNotUndefined(node?.apiValue) && selectId.includes('productMenu');
      const nodeValue = isProductMenu ? node.apiValue : node?.name;
      const removableParam = isWallpaper ? SUBSTRATE_PARAM : searchParam;
      const removableValue = node ? nodeValue : searchText;

      onRemoveFilter(searchParams, removableParam, removableValue, dispatchOnSubmitQuery, currentOrigin);

      return;
    }
    if (branchesIds.has(selectId) || leafsToSelect.includes(selectId)) {
      // if click on branch or already selected leaf - do nothing
      return;
    }

    if (facetParam === COLOR_PARAM) {
      if (isNotUndefined(searchParams) && searchParams.color.split(',').includes(searchText)) {
        return;
      }
      const colorParams = get(searchParams, COLOR_PARAM, '');

      searchText = !isEmpty(colorParams) ? colorParams.concat(`,${searchText}`) : searchText;
    }

    const filterValues = isProduct ?
      searchText.replace(REGEX_REMOVE_ALL_SPACES, '') :
      searchText;

    const productOverrideParams: Record<string, string> = isProduct ? {
      [SUBSTRATE_PARAM]: ''
    } : {};

    const wallpaperOverrideParams: Record<string, string> = isWallpaper ? {
      [ON_PARAM]: URL_QUERY_PARAM_SUBSTRATE_WALLPAPER,
      [SUBSTRATE_PARAM]: snakeCase(filterValues),
    } : {};

    const queryParams = {
      [searchParam]: filterValues,
      ...productOverrideParams,
      ...wallpaperOverrideParams,
    };

    dispatchOnSubmitQuery(queryParams);
  };

  const searchClasses = classNames('b-search-filters', extensionClass);

  const onAdultContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const adultContentValue = event.target.value === ADULT_CONTENT_ON ? ADULT_CONTENT_OFF : ADULT_CONTENT_ON;

    toggleMatureContentFilter(
      adultContentValue,
      updateSearchMatureContentFilter,
      dispatchMatureContentFilterToggle,
      currentOrigin
    );
  };

  const adultContentOptions = [
    {
      label: translate('userPreferences.blocked'),
      value: ADULT_CONTENT_OFF
    },
    {
      label: translate('userPreferences.allowed'),
      value: ADULT_CONTENT_ON
    },
  ];

  return (
    <div className={searchClasses} onClick={onClickPreventPropagation}>
      <div className='filter-header-title' id='filter-header-2-title-id'> {translate('search.filters')}:</div>
      <div className='filter-wrapper'>
        <TreeView
          aria-labelledby='filter-header-2-title-id'
          expanded={expanded}
          selected={leafsToSelect}
          onNodeSelect={handleNodeSelect}
          onNodeToggle={handleNodeToggle}
          defaultCollapseIcon={<ExpandLessIcon/>}
          defaultExpandIcon={<ExpandMoreIcon/>}
          multiSelect
          sx={{
            flexGrow: 1, maxWidth: 400, overflowY: 'auto'
          }}
        >
          {selectedFilters.map((productFilter) => {
            if (productFilter.id === 'winnersOnlyMenu') {
              return showWinnersOnly &&
                <FormGroup className='embedded-filter-wrapper'>
                  <FormLabel className='filter-group-label'>{productFilter.name}</FormLabel>
                  <FormControlLabel
                    className='filter-group-control'
                    label={productFilter.children?.[0].name}
                    control={
                      <AntSwitch
                        onChange={({target}) => {
                          dispatchOnSubmitQuery?.({
                            winnersOnly: String(target.checked)
                          });
                        }}
                        checked={winnersOnly}
                        inputProps={{
                          'aria-label': translate('navigation.showWinnersOnlyAriaLabel.text'),
                          'aria-checked': winnersOnly,
                        }}
                      />
                    }
                  />
                </FormGroup>;
            }

            return <SearchTree key={productFilter.id} nodes={productFilter} branchesIds={branchesIds} selectedLeafs={leafsToSelect} />;
          }
          )}
        </TreeView>
        <div className='filter-group-wrapper'>
          <RadioGroupContainer
            label={translate('navigation.adultContent.text')}
            options={adultContentOptions}
            onChange={onAdultContentChange}
            value={adultContent}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
