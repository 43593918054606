import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {reduxForm} from 'redux-form';
import {translate} from '../../services';
import camelCase from 'lodash/camelCase';
import {updateSearchParamsAndSubmit} from '../../actions/searchParams';
import {isEmpty} from '../../utils/validation';
import {isTabletDown} from '../../constants/Viewports';
import {SEARCH_PARAMS_RESET, SEARCH_PARAMS_UPDATED} from '../../constants/ActionTypes';
import {REGEX_WHITESPACE_AMPERSANDS} from '../../constants/Validation';
import {
  ON_PARAM, SORT_PARAM, URL_QUERY_PARAM_SUBSTRATE_FABRIC,
  FABRIC_PARAM, ALLCOLORS, COLOR_PARAM
} from '../../constants/Parameters';
import {ErrorBoundary} from '../../components/Reusable/ErrorBoundary/ErrorBoundary';
import SearchFilters from '../../components/Shop/SearchFilters/SearchFilters';
import useOrigin from '../../entities/pageSetup/origin/useOrigin';

const SearchFiltersContainer = (props) => {
  const {
    extensionClass, hideSlideInComponent,
    isSearchFilterVisible, toggleSearchFilters, dispatchMatureContentFilterToggle, updateSearchMatureContentFilter,
    isTagsPage, setRemovedFilter
  } = props;
  const {searchParams, shopType, viewport, adultContent, winnersOnly} = useSelector((state) => ({
    searchParams: state.searchParams,
    shopType: state.searchParams.on,
    viewport: state.pageSetup.viewport,
    adultContent: state.user.preferences.adult_content,
    winnersOnly: state.routing.locationBeforeTransitions.query.winnersOnly === 'true',
  }), shallowEqual);

  const dispatch = useDispatch();
  const currentOrigin = useOrigin();

  function dispatchResetAllParams() {
    dispatch(updateSearchParamsAndSubmit({
      on: URL_QUERY_PARAM_SUBSTRATE_FABRIC,
    }, SEARCH_PARAMS_RESET, currentOrigin, true));
  }
  const dispatchOnSubmitQuery = useCallback(function dispatchOnSubmitQuery(payload) {
    dispatch(updateSearchParamsAndSubmit(payload, SEARCH_PARAMS_UPDATED, currentOrigin, !isTagsPage));
  }, [dispatch, currentOrigin]);

  const toggleHideFilters = isTabletDown(viewport) ? hideSlideInComponent : toggleSearchFilters;

  function getCurrentSelection(typeOfFilter, searchParams) {
    let currentSelection = '';

    switch (typeOfFilter) {
      case SORT_PARAM:
        currentSelection = !isEmpty(searchParams[typeOfFilter]) ?
          translate(`navigation.${searchParams[typeOfFilter]}`) :
          translate('navigation.bestSelling');
        break;
      case ON_PARAM: {
        const translationParam = !isEmpty(searchParams[typeOfFilter]) ?
          camelCase(searchParams[typeOfFilter].replace(REGEX_WHITESPACE_AMPERSANDS,' ')) :
          FABRIC_PARAM;

        currentSelection = translate(`navigation.${translationParam}`);
        break;
      }
      case COLOR_PARAM:
        currentSelection = isEmpty(searchParams[typeOfFilter]) ?
          ALLCOLORS :
          searchParams[typeOfFilter];
        break;
      default:
        currentSelection = searchParams[typeOfFilter];
    }

    return currentSelection;
  }

  return (
    <ErrorBoundary>
      <SearchFilters
        shopType={shopType}
        clearFilterValues={dispatchResetAllParams}
        dispatchOnSubmitQuery={dispatchOnSubmitQuery}
        extensionClass={extensionClass}
        getCurrentSelection={getCurrentSelection}
        isSearchFilterVisible={isSearchFilterVisible}
        searchParams={searchParams}
        searchQuery={searchParams.q}
        adultContent={adultContent}
        toggleHideFilters={toggleHideFilters}
        dispatchMatureContentFilterToggle={dispatchMatureContentFilterToggle}
        updateSearchMatureContentFilter={updateSearchMatureContentFilter}
        winnersOnly={winnersOnly}
        viewport={viewport}
        setRemovedFilter={setRemovedFilter}
      />
    </ErrorBoundary>
  );
};

SearchFiltersContainer.propTypes = {
  // defined by parent
  hideSlideInComponent: PropTypes.oneOfType([
    PropTypes.func.isRequired, // in Desktop
  ]),
  // defined by shopcontainer
  extensionClass: PropTypes.string,
  toggleSearchFilters: PropTypes.func,
  isSearchFilterVisible: PropTypes.bool,
  isTagsPage: PropTypes.bool,
  updateSearchMatureContentFilter: PropTypes.func,
  dispatchMatureContentFilterToggle: PropTypes.func,
  setRemovedFilter: PropTypes.func,
};

const SearchFiltersWrapper = reduxForm({
  form: 'searchFilters'
})(SearchFiltersContainer);

export default SearchFiltersWrapper;
