import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'i18n-react';
import classNames from 'classnames';
import './_login-join.scss';
import {translate} from '../../../../services';
import {isNotUndefined} from '../../../../utils/validation';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';

const LogInJoin = (props) => {
  const {extensionClasses, buttonClasses} = props;
  const wrapperClasses = classNames('b-login-join', extensionClasses);

  return (
    <section className={wrapperClasses}>
      <LinkWrapper
        hrefValue={Translate.translate('xurls.login')}
        className={buttonClasses}
        contents={translate('navigation.logIn')}/>
      {(isNotUndefined(extensionClasses) && extensionClasses.includes('x-menu')) &&
      <span className='connecting-text'>{translate('navigation.loginORjoin')}</span>}
      <LinkWrapper
        hrefValue={Translate.translate('xurls.header.join')}
        className={buttonClasses}
        contents={translate('navigation.join')}/>
    </section>
  );
};

LogInJoin.propTypes = {
  extensionClasses: PropTypes.string,
  buttonClasses: PropTypes.string
};

export default LogInJoin;
